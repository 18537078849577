import React, { Component } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import LoginForm from './login-form';
// import session from '../../../core/session';
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';

const animationClass = {
  appearActive: 'animated slideInRight',
  enter: 'animated',
  enterActive: 'animated slideInRight',
  exit: 'animated',
  exitActive: 'animated slideOutLeft',
}
export default class LoginPage extends Component {
  render() {
    const { history } = this.props;
    return (
      <div className='view overflow-hidden'>
        <TransitionGroup className="content">
          <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='login' appear>
            <div className="login-page">
              <Container>
                <div className="px-2 px-md-0 my-md-4">
                  <LoginForm history={history} />
                </div>
              </Container>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}


//=====================================
//  CONNECT
//-------------------------------------
/*
const mapStateToProps = createSelector(
  getCurrentProvincia,
  getCurrentSearch,
  getSucursalesForCurrentSearch,
  getUserLocationCoords,
  getUserLocationError,
  getUserLocationIsPending,
  getCurrentSucursalesRelacionadas,
  getSucursalesForCurrentSucursalesRelacionadas,
  (provincia, search, sucursales, coords, location_error, location_is_pending, suc_rel, sucursales_relacionadas) => ({
    provincia,
    search,
    sucursales,
    coords,
    location_error,
    location_is_pending,
    suc_rel,
    sucursales_relacionadas,
    displayLoadingIndicator: provincia.isPending || search.isPending || suc_rel.isPending,
  })
)

const mapDispatchToProps = {
  navigateToPath: appActions.navigateToPath,
  loadProvincia: provinciaActions.loadProvincias,
  loadSearchResults: searchActions.loadSearchResults,
  loadLocation: locationActions.loadLocation,
  loadSucursalesRelacionadasResults: sucursalesRelacionadasActions.loadSucursalesRelacionadasResults
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
*/
