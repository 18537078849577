import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { contactActions } from '../../../core/contact/actions';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Container, Col, Row } from 'reactstrap';
import LoadingIndicator from '../../components/loading-indicator';
const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}
const PrivacyPage = ({ privacy, pagePrivacy, resetContact, user, systemCheck }) => {
    const history = useHistory();

    console.log(privacy.toJS())
    useEffect(() => {
        pagePrivacy();
        return () => {
            resetContact()
        }
    }, [])

    return (
        <div className='view overflow-hidden bg-white'>
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='confirm' appear >
                    <div className="register-confirm-page">
                    {!user.isPending && !privacy.isPending && !systemCheck.isPending && 
                        <Container>
                            <div className="px-2 px-md-0 my-md-4">
                                <div className="content-view">
                                    <Row className="mb-5">
                                        <Col sm={{ size: 1 }}>
                                            <span
                                                onClick={() => history.goBack()}
                                                style={{
                                                    color: '#66b2bd',
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft} />
                                            </span>
                                        </Col>
                                        <Col sm={{ size: 6, offset: 4 }}>
                                            <h2 className="pl-3 pl-md-0">{privacy.data && privacy.data.title}</h2>
                                        </Col>
                                    </Row>

                                    <div
                                        dangerouslySetInnerHTML={{ __html: privacy.data && privacy.data.content }}
                                    ></div>
                                </div>
                                
                            </div>
                        </Container>}
                        {
                            privacy.isPending || user.isPending || systemCheck.isPending &&
                            <LoadingIndicator />
                        }
                    </div>
                    
                </CSSTransition>
            </TransitionGroup>
        </div>
    )
}
//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {
    const { contact, user, systemCheck } = state;
    return {
        privacy: contact,
        user,
        systemCheck
    };
};

const mapDispatchToProps = {
    pagePrivacy: contactActions.pagePrivacy,
    resetContact: contactActions.resetContact
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivacyPage);