

export const profileActions = {
    FETCH_PROFILE_FAILED: 'FETCH_PROFILE_FAILED',
    FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
    FETCH_PROFILE_PENDING: 'FETCH_PROFILE_PENDING',
    UPDATE_PROFILE: 'UPDATE_PROFILE',
    UPDATE_PICTURE: 'UPDATE_PICTURE',
    UPDATE_STORY: 'UPDATE_STORY',

    fetchProfileSuccess: (data) => ({
        type: profileActions.FETCH_PROFILE_SUCCESS,
        payload: {
            data
        }
    }),

    fetchProfileFailed: error => ({
        type: profileActions.FETCH_PROFILE_FAILED,
        payload: error
    }),

    fetchProfilePending: () => ({
        type: profileActions.FETCH_PROFILE_PENDING,
    }),

    updateProfile: (data) => ({
        type: profileActions.UPDATE_PROFILE,
        payload: {
            data
        }
    }),

    updatePicture: file => ({
        type: profileActions.UPDATE_PICTURE,
        payload: {
            file
        }
    }),

    updateStory: data => ({
        type: profileActions.UPDATE_STORY,
        payload: {
            data
        }
    }),
};


export const profileRequestActions = {
    failed: profileActions.fetchProfileFailed,
    success: profileActions.fetchProfileSuccess,
    pending: profileActions.fetchProfilePending
};
