import React, { useState, useRef, useEffect } from 'react';
import { Alert, Col, FormGroup, Row, CustomInput, Container, Collapse, InputGroup, InputGroupText, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { questionnaireStoreOptionsActions } from '../../../../core/questionnaire-storeOptions/actions';
import { questionnaireItemsActions } from '../../../../core/questionnaire-items/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select'
import { Optioncustom } from './OptionCustom';
import ReactGA from 'react-ga'
import { trackingActions } from '../../../../core/tracking';

export const Question = ({ question, index, questionnaie_id, questionnaire }) => {
    const dispatch = useDispatch();
    const [optionSelected, setOptionSelected] = useState([]);
    const [showQuestion, setShowQuestion] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [allValid, setAllValid] = useState(true);

    const selectRef = useRef();

    const eventGA = () => {
        ReactGA.event({
            category: 'Questionnaires',
            action: `Review answers ${questionnaire?.name} completed`
        })
    }

    const ourTracking = () => {
        dispatch(trackingActions.saveTrackingEvent({category: 'Questionnaires',action: `Review answers ${questionnaire?.code || questionnaire?.name} completed`}))
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) { isSelected() }
        return () => {
            isMounted = false;
        }
    }, [])

    useEffect(() => {
        disabledbtn();
        areAllValid() ? setAllValid(true) : setAllValid(allValid);
    }, [optionSelected])

    const toggleQuestion = () => {
        setShowQuestion(!showQuestion);
    }

    const buildOptions = (optionId, checked) => {
        if (checked) {
            setOptionSelected([
                ...optionSelected,
                {
                    id: optionId,
                    checked,
                }
            ])
        } else {
            setOptionSelected(
                optionSelected.filter(option => option.id !== optionId)
            )
            document.getElementById(optionId).removeAttribute('checked')
        }
    }

    const isSelected = () => {
        if (question.type === 'sel' && question.selected.length > 0) {
            setOptionSelected([{ id: question.selected[0].option_id, custom_value: question.selected[0].custom_value ? question.selected[0].custom_value : '' }])
            question.options.forEach(option => {
                if(option.id === question.selected[0].option_id){
                    option.custom_value = question.selected[0].custom_value ? question.selected[0].custom_value : '' 
                } else {
                    option.custom_value = ''
                }
            })
        }
        else {
            question.options.forEach(option => {
                option.custom_value = ''
                question.selected.forEach(selected => {
                    if (option.id.toString() === selected.option_id.toString()) {
                        document.getElementById(option.id).setAttribute('checked', true)
                        let optionsSelected = optionSelected;
                        optionsSelected.push({
                            id: option.id.toString(),
                            checked: true,
                            custom_value: selected.custom_value ? selected.custom_value : '',
                        });
                        option.custom_value = selected.custom_value ? selected.custom_value : '';
                    }
                });
            })
        }
    }

    const saveAnswer = () => {
        if(areAllValid()){
            dispatch(questionnaireStoreOptionsActions.saveQuestionnaireStoreOptions({ questionnaireId: questionnaie_id, questionId: question.id, options: optionSelected }));
            dispatch(questionnaireItemsActions.loadQuestionnaireItems(questionnaie_id));
            toggleQuestion();
        } else {
            setAllValid(false);
        }
    }

    const disabledbtn = () => {
        var valid = true;

        if (question && question.minimum && question.maximum) {
            if (optionSelected.length > question.maximum || optionSelected.length < question.minimum) {
                valid = false;
            } else {
                valid = true;
            }

            setDisabled(!valid);
            setShowAlert(!valid);
        }
    }

    const onChangeSelectedOption = (selectedOption) => {
        setAllValid(true);
        let value = '';
        question.selected.forEach(option => {
            if (option.option_id.toString() === selectedOption.value.toString()) {
                value = option.custom_value
            }
        });
        setOptionSelected([{ id: selectedOption.value, custom_value: value }])
    }

    const transformItemsIntoNameValue = (list) => {
        return list && list.map((element) => {
            return { value: element.id, label: element.title };
        });
    }

    let preSelected
    const getPreselected = () => {
        question.options.forEach(option => {
            if (option.id === question.selected[0].option_id) {
                preSelected = option.title
            }
        });
    }
    getPreselected()

    const optionCustomSelected = (opt) => {
        let value = false
        if (opt.custom_enable) {
            optionSelected.forEach(option => {
                if (option.id.toString() === opt.id.toString()) {
                    value = true
                }
            });
        }
        return value
    }

    const setValue = (target) => {
        optionSelected.forEach(option => {
            if (target.id.toString() === option.id.toString()) {
                option.custom_value = target.value;
            }
            if(areAllValid()) {
                setAllValid(true);
            }
        });
    }

    const validText = (optionId, mandatory) => {
        let valid = true;
        if (mandatory) {
            optionSelected.forEach(opt => {
                if (optionId.toString() === opt.id.toString()) {
                    if (!opt.custom_value || opt.custom_value === undefined || opt.custom_value == '') {
                        valid = false;
                    }
                }
            });
        }
        return valid
    }

    const areAllValid = () => {
        let boolean = true;
        question.options.forEach(option => {
            optionSelected.forEach(opt => {
                if(option.custom_mandatory && option.id.toString() === opt.id.toString()){
                    if (!validText(option.id, option.custom_mandatory)) {
                        boolean = false
                    }
                }
            });
        });
        return boolean;
    }

    const newListOptions = transformItemsIntoNameValue(question && question.options && question.options);

    return (
        <>
            <div className="cursor-pointer d-flex align-items-start" onClick={() => toggleQuestion()} >
                <div><div className="rounded-circle question-member bg-celeste text-center mr-3">{index + 1}</div></div>
                <div className="pt-2">
                    <h5 className="text-uppercase" style={{ paddingTop: '2px' }}>{question.title}</h5>
                    <p className="mb-0">{question.subtitle}</p>
                </div>

                {showQuestion ?
                    <div className="flex-fill text-right"><i><FontAwesomeIcon className="fa-2x mt-2" icon={faAngleUp} /></i></div> : <div className="flex-fill text-right"><i><FontAwesomeIcon className="fa-2x mt-2" icon={faAngleDown} /></i></div>
                }

            </div>

            <Collapse isOpen={showQuestion}>
                <Alert color="primary" className="text-md-center font-weight-bold mt-3">{question.help_text}</Alert>
                {question.type !== 'sel' &&
                    <Row>
                        {question.options && question.options.map((option) =>
                            <Col md={{ size: 6 }} key={question.id + "-" + option.id}>
                                {
                                    question.type === 'chk' &&

                                    <FormGroup className="border-bottom py-3 mb-0">
                                        <CustomInput className="styled-checkbox d-flex align-items-center" type="checkbox" onClick={({ target }) => buildOptions(target.id, target.checked)} id={option.id}
                                            label={
                                                <>
                                                    {option.title ? <strong>{option.title}</strong> : ''}
                                                    {option.description ? <><br/><i> {option.description}</i></> : ''}
                                                </>
                                            }
                                        />
                                        {optionCustomSelected(option) &&
                                            <Optioncustom
                                                option={option}
                                                setValue={setValue}
                                                validText={validText}
                                                allValid={allValid}
                                            />
                                        }
                                    </FormGroup>
                                }
                                {question.type === 'opt' &&
                                    <FormGroup className="border-bottom py-3 mb-0">
                                        <CustomInput className="styled-checkbox d-flex align-items-center" type="radio" onClick={({ target }) => setOptionSelected([{ id: target.id }])} name={question.id} id={option.id}
                                            label={
                                                <>
                                                    {option.title ? <strong>{option.title}</strong> : ''}
                                                    {option.description ? <><br/><i> {option.description}</i></> : ''}
                                                </>
                                            }
                                        />
                                        {optionCustomSelected(option) &&
                                            <Optioncustom
                                            option={option}
                                            setValue={setValue}
                                            validText={validText}
                                            allValid={allValid}
                                        />
                                        }
                                    </FormGroup>
                                }
                            </Col>
                        )}
                    </Row>
                }
                <Row>
                    <div className="ml-3">
                        {
                            question.type === "sel" &&
                            <div className="select-years-content pl-1 pt-3 ml-md-0 pl-md-0 pt-md-0">
                                <Select options={newListOptions} ref={selectRef} defaultValue={{ label: preSelected, value: question.selected[0].option_id }}
                                    onChange={onChangeSelectedOption} className="form-control-select select-years d-md-inline-block" classNamePrefix="form-control" placeholder={question && question.help_text} />
                                {question.options && question.options.map((option) =>
                                    <div key={option.id}>
                                        {
                                            optionCustomSelected(option) &&
                                            <Optioncustom
                                                option={option}
                                                setValue={setValue}
                                                validText={validText}
                                                allValid={allValid}
                                                type='select'
                                            />
                                        }
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </Row>
                <div className={`text-danger text-center ${(disabled && showAlert) ? 'visible pt-2' : 'invisible'}`}>
                    <p className="mb-0">{question && question.validation_message}</p>
                </div>
                {/* <div className={`text-danger text-center ${(!allValid) ? 'visible pb-2' : 'invisible'} ${!allValid && disabled && showAlert ? 'pt-1' : ''}`}>
                    <p className="mb-0">Missing fields</p>
                </div> */}
                <Row>
                    <Col md={{ size: 2, offset: 5 }}>
                        <button disabled={disabled || !allValid} className="btn btn-primary text-uppercase btn-block rounded-pill" onClick={() => {saveAnswer(); eventGA(); ourTracking()}}>Update</button>
                    </Col>
                </Row>
            </Collapse>
        </>
    )
}