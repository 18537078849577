import React, { useState } from 'react'
import { Radio } from '../common'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse, Media } from 'reactstrap';
import quest_ilustracion from '../../../assets/img/iconos-01.svg'

const RearrengeItem  = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const { data, hanndleResponse, questions, custom_style } = props;
  const question =  questions.find( (question) => question.id === data.item_id )

  if (!question) return null;
  return (
      <>
      <div className="item rearrenge-item clearfix mb-3">
        <div id={ `toggler${question.id}` } onClick={toggle} className="cursor-pointer">
          <Media>
            <Media left className="mr-3 ml-md-n0">
              <img className="media-object" width="48" height="48" src={question.icon} alt={ question.name } style={custom_style}/>
            </Media>
            <Media body>
              <Media heading tag="p" className="mb-n1 mt-1">{ question.name }</Media>
              <p className="text-reset line-height-normal mb-2"><small>{question.question}</small></p>
            </Media>
          </Media>
        </div>
        <Collapse isOpen={isOpen}>
          <Media>
            <Media left className="mr-3"><div style={{ width: "48px"}}></div></Media>
            <Media body>
              <p className="text-reset line-height-normal"><small>{question.description}</small></p>
              <div className="btn-group btn-group-toggle">
              { data.value === '2' && <Radio handleRadioChange={hanndleResponse} item_id={data.item_id} group="gives_me_energy_" label="ALWAYS" value="1" /> }
              { data.value !== '2' && <Radio handleRadioChange={hanndleResponse} item_id={data.item_id} group="gives_me_energy_" label="SOMETIMES" value="2" /> }
              { data.value === '2' && <Radio handleRadioChange={hanndleResponse} item_id={data.item_id} group="gives_me_energy_" label="NEVER" value="3" /> }
            </div>
            </Media>
          </Media>
        </Collapse>
      </div>
      </>
    )
  
}

export default RearrengeItem
