import { DEFAULT_SESSION_ID } from "../constants";

export const assessmentsListActions = {

  FETCH_ASSESSMENTS_FAILED: 'FETCH_ASSESSMENTS_FAILED',
  FETCH_ASSESSMENTS_SUCCESS: 'FETCH_ASSESSMENTS_SUCCESS',
  FETCH_ASSESSMENTS_PENDING: 'FETCH_ASSESSMENTS_PENDING',
  CREATE_ASSESSMENTS: 'CREATE_ASSESSMENTS',
  LOAD_ASSESSMENTS: 'LOAD_ASSESSMENTS',
  SAVE_ASSESSMENT: 'SAVE_ASSESSMENT',

  fetchAssessmentsFailed: error => ({
    type: assessmentsListActions.FETCH_ASSESSMENTS_FAILED,
    payload: {
      error
    }
  }),

  fetchAssessmentsSuccess: (assessmentsId, data) => ({
    type: assessmentsListActions.FETCH_ASSESSMENTS_SUCCESS,
    payload: {
      data,
      assessmentsId
    }
  }),

  fetchAssessmentsPending: assessmentsId => ({
    type: assessmentsListActions.FETCH_ASSESSMENTS_PENDING,
    payload: {
      assessmentsId
    }
  }),

  loadAssessments: () => ({
    type: assessmentsListActions.LOAD_ASSESSMENTS,
    payload: {
      assessmentsId: DEFAULT_SESSION_ID
    }
  }),

  createAssessments: () => ({
    type: assessmentsListActions.CREATE_ASSESSMENTS,
    payload: {
      assessmentsId: DEFAULT_SESSION_ID
    }
  }),

  saveAssessment: (assessmentItemId, data) => ({
    type: assessmentsListActions.SAVE_ASSESSMENT,
    payload: {
      assessmentItemId: assessmentItemId,
      data
    }
  }),

};

export const assessmentsListRequestActions = {
  failed: assessmentsListActions.fetchAssessmentsFailed,
  success: assessmentsListActions.fetchAssessmentsSuccess,
  pending: assessmentsListActions.fetchAssessmentsPending
};
