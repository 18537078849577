import { List , Map } from 'immutable';
import { questionsListActions } from './actions';
import { QuestionsList } from './questions-list';

export const questionsListReducer = (state =  new QuestionsList(), {payload, type} ) => {

  switch (type) {
    case questionsListActions.FETCH_QUESTIONS_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case questionsListActions.FETCH_QUESTIONS_SUCCESS:
      return state.withMutations(questionsList => {
        questionsList.merge({
          token: payload.data.token,
          isPending:false,
          isNew:false,
          answers: new Map(payload.data.answers),
          range: new Map(payload.data.range),
          items: new List(payload.data.questions),
          quadrants: new List(payload.data.quadrants)
        })
        .merge(payload.data.account);
      });
    case questionsListActions.FETCH_QUESTIONS_PENDING:
      return state.set('isPending', true);
    case questionsListActions.LOAD_QUESTIONS:
      return state.set('id',payload.questionsListId);
    default:
      return state;
  }
}
