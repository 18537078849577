export const mindPatternsStoreOptionsActions = {

  FETCH_MINDPATTERNS_STOREOPTIONS_FAILED: 'FETCH_MINDPATTERNS_STOREOPTIONS_FAILED',
  FETCH_MINDPATTERNS_STOREOPTIONS_SUCCESS: 'FETCH_MINDPATTERNS_STOREOPTIONS_SUCCESS',
  FETCH_MINDPATTERNS_STOREOPTIONS_PENDING: 'FETCH_MINDPATTERNS_STOREOPTIONS_PENDING',
  SAVE_MINDPATTERNS_STORE_OPTIONS: 'SAVE_MINDPATTERNS_STORE_OPTIONS',


  fetchMindPatternsOptionsDataFailed: error => ({
    type: mindPatternsStoreOptionsActions.FETCH_MINDPATTERNS_STOREOPTIONS_FAILED,
    payload: {
      error
    }
  }),

  fetchMindPatternsOptionsDataSuccess: (mindPatternsDataId, data) => ({
    type: mindPatternsStoreOptionsActions.FETCH_MINDPATTERNS_STOREOPTIONS_SUCCESS,
    payload: {
      data,
      mindPatternsDataId
    }
  }),

  fetchMindPatternsOptionsDataPending: mindPatternsDataId => ({
    type: mindPatternsStoreOptionsActions.FETCH_MINDPATTERNS_STOREOPTIONS_PENDING,
    payload: {
      mindPatternsDataId
    }
  }),


  mindPatternsSaveStoreOptions: data => ({
    type: mindPatternsStoreOptionsActions.SAVE_MINDPATTERNS_STORE_OPTIONS,
    payload: {
      data
    }
  }),

};

export const mindPatternsStoreOptionsRequestActions = {
  failed: mindPatternsStoreOptionsActions.fetchMindPatternsOptionsDataFailed,
  success: mindPatternsStoreOptionsActions.fetchMindPatternsOptionsDataSuccess,
  pending: mindPatternsStoreOptionsActions.fetchMindPatternsOptionsDataPending
};
