import { Map } from 'immutable';

import { createMember } from './member';
import { membersListActions } from '../members-list';

export function membersReducer(state = new Map(), action) {
  const { payload } = action;

  switch (action.type) {
    case membersListActions.FETCH_MEMBERS_SUCCESS:      
      return state.withMutations( members => {
        payload.data.forEach( memberData => {
          members.set(`${memberData.id}`, createMember(memberData));
        });
      });
    default:
      return state;
  }
}
