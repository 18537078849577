import React, { Component } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Media, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faEnvelope, faStar, faPlus, faEllipsisV, faPencilAlt, faTimesCircle, faUser, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import imageMapResize from 'image-map-resizer'

import classNames from 'classnames';
import { List } from 'immutable';

import logo from '../../../assets/img/true.logo.circle.png'
import icon_members from '../../../assets/img/avatar.png';

import { QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL, QUADRANTS_PROCEDURAL, QUADRANTS_ALL } from '../../../core/constants'

import graphic_report from '../../../assets/img/graphic-report-sm.jpg'
import graphic_report_mask from '../../../assets/img/graphic-report-mask.png'
import icon_tt from '../../../assets/img/tt-sm-icon.svg';

import TalentsItem from './talents-item'
import TalentsListMembers from './talents-list-members'
import QuadrantsDetail from './quadrants-detail'
import ThinkingTalentsFullReport from './thinking-talents-full-report'


class ThinkingTalents extends Component {

  constructor(props) {
    super(props)

    this.state = {
      animationClassTabs: {
        enter: 'animated',
        enterActive: 'animated fadeIn',
        exit: 'animated',
        exitActive: 'animated fadeOut',
      },
      paddingTop: 0,
      paddingBottom: 0,
      show_modal: false,
      data_modal: null,
      show_type: null,
      isOpen: false,
      quadrant_id: null,
    }

    this.toggle = this.toggle.bind(this);
    this.updateSize = this.updateSize.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.toggleQuadrant = this.toggleQuadrant.bind(this);


    const img = new Image();

    img.src = graphic_report_mask;

  }

  componentDidMount() {
    imageMapResize()
    window.addEventListener('resize', this.updateSize);
    window.addEventListener('load', this.updateSize)
    setTimeout(() => {
      this.updateSize();
    }, 500)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
    window.removeEventListener('load', this.updateSize);
  }

  componentDidUpdate(oldProps, oldState) {

  }

  handleModal = (e, data, show_type) => {
    const { show_modal } = this.state
    const { member } = this.props

    this.setState({ show_modal: !show_modal, data_modal: data, show_type })
  }

  toggle = (name) => {

    this.setState({ show_modal: true, show_type: 'quadrant-detail', data_modal: name })
  }

  toggleQuadrant = (id) => {
    const { quadrant_id } = this.state

    if (quadrant_id == id) {
      this.setState({ isOpen: false, quadrant_id: null })
    }
    else {
      this.setState({ isOpen: true, quadrant_id: id })
    }


  }

  updateSize() {
    const { paddingBottom, paddingTop } = this.state
    //const top = document.getElementById('lists-top').clientHeight
    //const bottom = document.getElementById('lists-top').clientHeight
    if (this.image_bg) {
      const media = this.image_bg.clientHeight / 4;
      let new_paddingTop = 0;
      let new_paddingBottom = 0;

      if (media <= this.div_lists_top.clientHeight) {
        if (this.image_bg.clientWidth < 400)
          new_paddingTop = media + (this.div_lists_top.clientHeight / 2);
        else
          new_paddingTop = media - (this.div_lists_top.clientHeight / 2);
      }

      if (this.image_bg.clientWidth < 400)
        new_paddingBottom = media + (this.div_lists_bottom.clientHeight / 2);
      else
        new_paddingBottom = media - (this.div_lists_bottom.clientHeight / 2);

      this.setState({ paddingBottom: new_paddingBottom, paddingTop: new_paddingTop })
    }
  }


  render() {
    const { animationClassTabs, activeTab, paddingBottom, paddingTop, show_modal, data_modal, show_type, quadrant_id, isOpen } = this.state
    const { hanndleNextSection, member, quadrants, isMember, isLeader, team, enable_edit, team_report, questions, team_full_report, loadTeamsFullRepor } = this.props

    let value_analytic = null
    let value_innovative = null
    let value_relational = null
    let value_procedural = null
    let value_all = null

    let content_modal = null
    if (data_modal && show_type === 'members-list') {
      content_modal = <TalentsListMembers data={data_modal} />

    }
    else if (data_modal && show_type === 'quadrant-detail') {
      content_modal = <QuadrantsDetail
        items={questions.items}
        quadrant_name={data_modal}
        quadrants={quadrants}
        team_report={team_report}
      />
    }

    let talen_list = null
    let talen_list_detail = null

    if (team_report && quadrants.size) {

      talen_list_detail = team_report.thinking_talents_detail.map(item => {
        const quadrant = quadrants.find(quadrant => quadrant.id === item.quadrant_id);

        return <TalentsItem key={item.quadrant_id} handleModal={this.handleModal} data={item} quadrant={quadrant} toggle={this.toggleQuadrant} quadrant_id={quadrant_id} isOpen={isOpen} />

      })

      talen_list = team_report.thinking_talents_report.map(item => {

        const quadrant = quadrants.find(quadrant => quadrant.id === item.quadrant_id);

        if (quadrant && item.quadrant_name === QUADRANTS_ANALYTIC) {
          value_analytic = <p className="h4 pt-md-4 pl-4 pt-lg-0 pl-lg-5" style={{ color: quadrant.color }}><small className="font-weight-semibold" style={{ fontSize: '70%' }}>{item.percent}%</small></p>
        }

        if (quadrant && item.quadrant_name === QUADRANTS_INNOVATIVE) {
          value_innovative = <p className="h4 pt-md-4 pr-4 pt-lg-0 pr-lg-5 font-weight-semibold" style={{ color: quadrant.color }}><small className="font-weight-semibold" style={{ fontSize: '70%' }}>{item.percent}%</small></p>
        }

        if (quadrant && item.quadrant_name === QUADRANTS_RELATIONAL) {
          value_relational = <p className="h4 pb-md-4 pr-4 pb-lg-0 pr-lg-5 font-weight-semibold" style={{ color: quadrant.color }}><small className="font-weight-semibold" style={{ fontSize: '70%' }}>{item.percent}%</small></p>
        }

        if (quadrant && item.quadrant_name === QUADRANTS_PROCEDURAL) {
          value_procedural = <p className="h4 pb-md-4 pl-4 pb-lg-0 pl-lg-5 font-weight-semibold" style={{ color: quadrant.color }}><small className="font-weight-semibold" style={{ fontSize: '70%' }}>{item.percent}%</small></p>
        }

        if (quadrant && item.quadrant_name === QUADRANTS_ALL) {
          value_all = <p className="h4 font-weight-semibold" style={{ color: quadrant.color }}><small className="font-weight-semibold" style={{ fontSize: '70%' }}>{item.percent}%</small></p>
        }
        if (quadrant) {
          return <div key={item.quadrant_id} className={`item-talen col mb-2 ${item.quadrant_name.toLowerCase()}`}>
            <h6 className="text-reset mb-0">
              <i className="icon mr-2">
                <svg width="16" height="16"><circle cx="8" cy="8" r="8" fill={quadrant.color} /></svg>
              </i>{item.quadrant_name} &nbsp;<b>{item.percent}%</b>
            </h6>
          </div>

        }

      })
    }
    return (
      <>
        <ThinkingTalentsFullReport team={team} quadrants={quadrants} questions={questions} team_full_report={team_full_report} loadTeamsFullRepor={loadTeamsFullRepor} />
        <div className="bg-celeste py-2 px-3 rounded mb-4">
          <p className="mb-0">Tap on each quadrant to view information</p>
        </div>

        <div className="mb-4 px-3 py-2 d-flex align-items-center border rounded-lg d-md-none">
          <i className="mr-3"><img src={icon_tt} width="24"/></i>
          <p className="mb-0">Note that percentages only reflect team members who have completed each assessment</p>
        </div>

        <Row className="align-items-center">
          <Col lg={{ size: 5 }}>
            <div className="graphic-map graphic-map-sm mb-3 mx-auto">
              <div className="graphic-map-list overflow-hidden mx-auto" >
                <Row id="lists-top" className="d-flex list-top justify-content-between align-items-end position-relative">
                  <div onClick={() => this.toggle(QUADRANTS_ANALYTIC)} ref={e => this.div_lists_top = e} className="col-6 cursor-pointer align-items-md-end justify-content-md-start d-md-flex flex-md-column pt-md-4">{value_analytic}</div>
                  <Col xs={{ size: 6 }} onClick={() => this.toggle(QUADRANTS_INNOVATIVE)} className="cursor-pointer text-right align-items-md-start justify-content-md-start d-md-flex flex-md-column pt-md-4">{value_innovative}</Col>
                </Row>
                <div className="bg-map-dk mx-auto">
                  <div className="graphic-map-img mx-auto" style={{ width: '190px' }}>
                    <div className="mx-auto text-center graphic-list-all-talent">{value_all}</div>
                    <img ref={e => this.image_bg = e} src={graphic_report} useMap="#image_map_team" className="img-fluid" style={{ opacity: 0, minHeight: '100%' }} alt="" />
                    <map name="image_map_team">
                      <area onClick={() => this.toggle(QUADRANTS_ANALYTIC)} alt="Analytic" title="Analytic" shape="polygon" coords="1,2,96,0,95,22,57,33,29,62,20,94,0,94" />
                      <area onClick={() => this.toggle(QUADRANTS_INNOVATIVE)} alt="Innovative" title="Innovative" shape="polygon" coords="97,21,97,0,188,0,190,95,169,96,161,57,131,31" />
                      <area onClick={() => this.toggle(QUADRANTS_RELATIONAL)} alt="Relational" title="Relational" shape="polygon" coords="98,167,131,161,158,132,169,98,189,97,187,189,96,188" />
                      <area onClick={() => this.toggle(QUADRANTS_PROCEDURAL)} alt="Procedural" title="Procedural" shape="polygon" coords="1,95,20,94,29,128,61,162,96,168,95,189,0,189" />
                      <area onClick={() => this.toggle(QUADRANTS_ALL)} alt="All" title="All" shape="circle" coords="95,95,70" />
                    </map>
                  </div>
                </div>
                <Row id="lists-bottom" className="d-flex list-bottom justify-content-between align-items-start position-relative">
                  <div onClick={() => this.toggle(QUADRANTS_PROCEDURAL)} ref={e => this.div_lists_bottom = e} className="col-6 cursor-pointer align-items-md-end justify-content-md-end d-md-flex flex-md-column pb-md-4">{value_procedural}</div>
                  <Col xs={{ size: 6 }} onClick={() => this.toggle(QUADRANTS_RELATIONAL)} className="cursor-pointer text-right align-items-md-start justify-content-md-end d-md-flex flex-md-column pb-md-4">{value_relational}</Col>
                </Row>

              </div>
            </div>
          </Col>
          <Col lg={{ size: 5, offset: 1 }}>
            <div className="mb-5 px-3 py-2 d-none d-md-flex align-items-center border rounded-lg">
              <i className="mr-3"><img src={icon_tt} width="24"/></i>
              <p className="mb-0">Note that percentages only reflect team members who have completed each assessment</p>
            </div>
            <div className="list-talents row row-cols-2 text-left">{talen_list}</div>
          </Col>
        </Row>

        <hr />
        <p className="text-center text-reset h5">Thinking Talents</p>
        <div className="bg-celeste py-2 px-3 rounded mb-4">
          <p className="mb-0">Tap on each quadrant to view the talents</p>
        </div>

        <div className="list-talents-detail row">{talen_list_detail}</div>

        <Modal isOpen={show_modal} toggle={this.handleModal} className="modal-dialog-summary float-right">
          <ModalHeader toggle={this.handleModal}><span className="d-flex justify-content-center bd-highlight"><img src={logo} height="48" alt="True Advance" /></span></ModalHeader>
          <ModalBody>
            <div className="d-flex align-items-center mb-2">
              <img className="media-object rounded-circle mr-3" width="40" height="40" src={icon_members} alt={team.name} />
              <h2 className="text-center text-md-left text-reset text-capitalize mb-0">{team.name}</h2>
            </div>
            <span className="border border-info d-block mb-3"></span>
            {content_modal}
          </ModalBody>
        </Modal>
      </>
    )
  }
}

export default ThinkingTalents;
