import React from 'react'
import { Radio } from '../common'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse, Media, Progress, Table, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCircle, faSquareFull, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';


const ThinkingTalentsFullReportItem = (props) => {

  const { item, data, user, showShadowTalent, showDescriptionTalent, showMembers = true } = props;

  const info_team = () => {
    return <>
      {showDescriptionTalent && //light
        <p className="mb-0 mt-1 text-reset small">
            {item.light_talent}
        </p>
      }

      {showShadowTalent &&
        <p className="mb-0 mt-1 text-reset small">
            <em>{item.shadow_talent}</em>
        </p>
      }

      { (showMembers && data.members.length !== 0) &&
      	<div className="d-flex flex-wrap justify-content-center mt-1">
      	{ data.members.map((member, i) =>
          <p className={`${((data.members.length !== i + 1) || (data.members.length - 1 !== i)) ? "pr-1" : ""} tooltip-member-item mb-0 text-capitalize font-weight-semibold text-nowrap`} style={{ color: item.quadrant.color }}>
            {member.leader === 1 &&
              <span id={"tooltip-tl-" + item.id} className="mr-1">
                <FontAwesomeIcon icon={faStar} alt="team leader" />
              </span>
            }

            {member.first_name}
            {((data.members.length !== i + 1) || (data.members.length - 1 !== i)) ? ", " : ""}

          </p>
        )
      	}
      	</div>
      }

    </>;
  }


  return (
    <div className="talents-item text-center mb-2">
      <div className="rounded p-2">
      <i><img className="media-object" width="50" height="50" src={item.icon_invert} alt={item.name} /></i>
      <p key={'qitem_' + item.id} className="mb-0 text-reset text-uppercase small font-weight-semibold">{item.name}</p>
      <div className="team-member pt-0 justify-content-center flex-wrap">
        {info_team()}
      </div>
      </div>
    </div>
  )

}

const mapStateToProps = (state, props) => {
  const { user } = state;
  return {
    user
  };
};

const mapDispatchToProps = {
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThinkingTalentsFullReportItem);
