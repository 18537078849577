import { List, Record, Map } from 'immutable';

export const TeamsReport = new Record({
  id: null,
  isNew: true,
  isPending: false,
  error:null,
  update_at: new Date(),

  name: null,
  owner_id: null,
  mind_pattern: '',
  mind_pattern_first: null,
  mind_pattern_second: false,
  mind_pattern_third: false,
  mind_pattern_p1: 0,
  mind_pattern_p2: 0,
  mind_pattern_p3: 0,
  thinking_talents_report: new List(),
  thinking_talents_detail: new List()

});
