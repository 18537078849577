import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { videosActions } from '../../../core/videos';
import { ThumbnailVideo } from '../../components/videos/ThumbnailVideo';

export const Videos = () => {
    const dispatch = useDispatch();
    const { videos, user } = useSelector(state => state);
    const lastUserId = user.id;

    useEffect(() => {
        const isPending = videos.isPending || user.isPending;
        if(isPending) {
            
            const needLoadVideos = lastUserId !== user.id || !videos?.data.length;
            if(needLoadVideos) {
                user.id 
                    ? dispatch(videosActions.loadVideos()) 
                    : dispatch(videosActions.loadVideosLogin())
            }
        }
    }, [user.id])


    return (
        <>
            {videos && !videos.isPending && videos?.data.length > 0 &&
                <Row className="mt-4 row-cols-md-3 row-cols-1 justify-content-center">
                    {!videos.isPending && videos.data.map((video, index) => {
                        return <Col className="mb-3" key={index}>
                            <ThumbnailVideo video={video} index={index} videos={videos.data} />
                        </Col>
                    })
                    }
                </Row>
            }
        </>

    )
}