import { teamResumeActions } from './actions';
import { TeamResume } from './team-resume';


export function teamResumeReducer(state = new TeamResume(), { payload, type }) {
    switch (type) {
        case teamResumeActions.FETCH_TEAM_RESUME_SUCCESS:
            return state.merge({
                data: payload.data,
                isPending: false

            });
            
        case teamResumeActions.FETCH_TEAM_RESUME_FAILED:
            return state.merge({
                isPending: false,
                error: payload?.error?.data
            });
        
        case teamResumeActions.FETCH_TEAM_RESUME_PENDING:
            return state.set('isPending', true);

        case teamResumeActions.LOAD_TEAM_RESUME:
            return state.set('id', Number(payload.team_id) || payload.team_id);

        default:
            return state;
    }
}


