import React from 'react';
import { Link } from 'react-router-dom';
import { currentDate } from '../../../core/helper';

export const HomeAuthFooter = () => {
    return (

        <>
        <div className="text-center text-muted mx-auto" style={{ maxWidth: '400px'}}>
            <hr className="d-none d-md-block mb-0"/>
            <Link to="/contact-us" className="text-muted welcome-link py-3 pl-0 pl-md-3 pr-3 d-inline-block">
                Contact us
            </Link>
            |
            <a href="https://www.trueadvance.com/" target="blank" className="text-muted welcome-link p-3 d-inline-block">
                About True Advance
            </a>
            |
            {/* <Link to="/privacy" className="text-muted welcome-link py-3 pl-3 pr-0 pr-md-3 d-inline-block">Privacy</Link> */}
            <a href="https://www.trueadvance.com/privacy-policy" target="blank" className="text-muted welcome-link py-3 pl-3 pr-0 pr-md-3 d-inline-block">
                Privacy Policy
            </a>
            <hr className="my-0 d-none d-md-block"/>
            <p className="pt-2 px-4 text-uppercase text-primary">Copyright True Leadership Solutions LLC. {currentDate()}. All rights reserved.</p>
        </div>
        </>
    )
}
