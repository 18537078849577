import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { QuadrantItem } from './quadrant-item';
import { QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL, QUADRANTS_PROCEDURAL, QUADRANTS_ALL } from '../../../core/constants';
import styled from 'styled-components';
import { ThinkingTalentsReportTeamDetailsItem } from '../teams/thinking-talents-report-team-details-item';

const StyledQuadrant = styled.div`
  border: ${props => "1px solid " + props.quadrant_color} ;
  border-radius: 20px;

  .h3, .talents-item p {
    color: ${props => props.quadrant_color};
  }
`;



export const ThinkingTalentsReportTeamDetails = ({ showShadowTalent, talents }) => {
    const { quadrants } = useSelector(state => state)
    const array_quadrants = Array.from(quadrants.values());

    const getQuadrantColor = (name) => {
        if (quadrants) {
            let quadrant = quadrants.find(quadrant => quadrant.name === name)
            if (quadrant) return quadrant.color
        }
    }

    const getQuadrant = (name, talents = null) => {
        if (quadrants) {
            let quadrant = quadrants.find(quadrant => quadrant.name === name)
            if (quadrant) {
                if(talents) {
                    quadrant.items = talents.filter(talent => talent.quadrant_name === quadrant.name)
                }
                return quadrant
            }
        }
    }

    return (
        <>
            <div className="quadrants-list position-relative full-report mb-4">
                <Row noGutters id="lists-top" className="d-flex list-top align-content-start flex-wrap h-50 mb-2 no-gutters row">
                    {
                        array_quadrants.map(quadrant => {
                            return (quadrant.name === QUADRANTS_ANALYTIC || quadrant.name === QUADRANTS_INNOVATIVE) &&
                                <Col xs="6" className={`text-${quadrant.name === QUADRANTS_ANALYTIC ? "left" : "right"}`} key={quadrant.id}>
                                    <QuadrantItem
                                        talent={getQuadrant(quadrant.name, talents)}
                                        StyledQuadrant={StyledQuadrant}
                                        isShadowTalents={showShadowTalent}
                                    />
                                </Col>
                            }
                        )
                    }
                </Row>

                {

                        talents.find(talent => talent.quadrant_name === QUADRANTS_ALL) &&
                            <>
                                <div id="lists-all" className="position-relative" >
                                    <div style={{ marginTop: '-215px' }} className="graphic-list-all-talent text-center">
                                        <StyledQuadrant className="mx-auto d-inline-block bg-white pt-3" quadrant_color={getQuadrantColor(QUADRANTS_ALL)} key={QUADRANTS_ALL}>
                                            <p className="h3 mb-3">Talents In All Quadrants</p>
                                            <div className="d-flex flex-wrap justify-content-around report-inner-all">
                                                {
                                                    talents.map(item => item.quadrant_name === QUADRANTS_ALL && <ThinkingTalentsReportTeamDetailsItem item={item} color={getQuadrantColor(item.quadrant_name)} key={item.item_id} isShadowTalents={showShadowTalent} />)
                                                }
                                            </div> 
                                        </StyledQuadrant>
                                    </div>
                                </div>
                            </>
                        
                    
                }

                <Row noGutters id="lists-bottom" className="d-flex list-bottom align-content-end flex-wrap h-50 no-gutters row">
                    {
                        array_quadrants.map( quadrant => {
                            return (quadrant.name === QUADRANTS_PROCEDURAL || quadrant.name === QUADRANTS_RELATIONAL) &&
                                <Col xs="6" className={`text-${quadrant.name === QUADRANTS_PROCEDURAL ? "left" : "right"}`} key={quadrant.name} >
                                    <QuadrantItem
                                        talent={getQuadrant(quadrant.name, talents)}
                                        StyledQuadrant={StyledQuadrant}
                                        isShadowTalents={showShadowTalent}
                                    />
                                </Col>
                            }
                        )
                    }
                </Row>
            </div>
        </>
    )
}
