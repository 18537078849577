import React, { useState } from 'react';
import { Col, Row, Collapse, Fade, Button } from 'reactstrap';
import ReportTT from '../../pages/home/thinkingTalents/report-tt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCaretDown, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export const ResumeMember = (props) => {
    const { first_name, last_name, rol, email, mind_pattern, avatar, user } = props
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = () => { setCollapsed(!collapsed) }

    let mind_pattern_data = null;
    if (mind_pattern) {
        mind_pattern_data =
            <div className="d-flex">
                {mind_pattern.split("").map((mp, k) =>
                    <div key={`${k}_${mp}`} className={`rounded-circle mx-1 mind-pattern mind-pattern-md mind-pattern-${mp.toLowerCase()}`}>
                        <span style={{ minWidth: '30px', maxHeight: '30px' }}>{mp.toUpperCase()}</span>
                    </div>
                )}
            </div>
    }


    return (
        <>
            <div>
                <div className="position-relative">
                    <FontAwesomeIcon className="position-absolute mt-md-3 mr-md-3" size="lg" style={{ cursor: "pointer", top: "0px", right: "0px" }} onClick={toggleCollapsed} icon={collapsed ? faChevronDown : faChevronUp} color={`${collapsed ? '#49b4be' : '#ff7b00'}`} />
                    <Row className="mx-0" style={{ cursor: "pointer" }} onClick={toggleCollapsed}>
                        <Col md="3">
                            <div className="pt-md-3 d-flex">
                                <i className="d-inline mr-2"><img src={avatar} width="24" height="auto" /></i>
                                <div>
                                    <h5 className="d-md-block d-inline text-reset font-weight-semibold mb-1">{first_name + ' ' + last_name}</h5><span className="mx-1 align-top d-md-none"> {rol ? '|' : ''} </span>
                                    {rol && <p className="d-inline d-md-block mb-1">{rol}</p>}
                                    <a onClick={e => e.stopPropagation()} href={'mailto:' + email} target="_blank" className="d-block text-reset small">{email}</a>
                                </div>
                            </div>
                        </Col>
                        <Col md="6" className="mt-3 mb-2">
                            <Collapse isOpen={collapsed} className="fade">
                                <ReportTT version="small" collapsed={true} user_finded={user} />
                            </Collapse>
                            <Collapse isOpen={!collapsed} className="fade">
                                <ReportTT version="small" user_finded={user} />
                            </Collapse>
                        </Col>
                        <Col md="3">
                            <div className="mind-patterns-content d-flex mb-3 justify-content-center justify-content-md-end mt-md-4 pt-md-3 mr-md-3">{mind_pattern_data}</div>
                        </Col>
                    </Row>
                </div>
                <hr className="border-top mt-0 mb-md-0 mb-sm-2" />
            </div>
        </>
    )
}