import React, { useEffect, useState } from "react";
import { DocumentPreview } from "../common/documents-previews/DocumentPreview";
import { userDocumentsActions, getDocumentFileById } from "../../../core/user-documents";
import { useDispatch, useSelector } from "react-redux";
import { getURLPublicStorage, downloadFile as downloadFileDefault } from "../../../core/helper";

export const DocumentItem = ({ id, fileName, document, text, size, downloaded, date, type, attachedDocumentType, documentLink }) => {
    const dispatch = useDispatch()
    const { userDocuments } = useSelector(state => state)
    const [file, setFile] = useState(null)
    const [link, setLink] = useState(attachedDocumentType && documentLink ? documentLink : (getURLPublicStorage() + '/' + document.download_link))

    useEffect(() => {
        dispatch(userDocumentsActions.loadUserDocumentById(id))
    }, [])

    useEffect(() => {
        if(!file)
            setFile(getDocumentFileById(userDocuments.files, id))
    }, [userDocuments])

    const downloadFile = (file) => {
        if(!downloaded)
            dispatch(userDocumentsActions.setUserDocumentDownloaded(id))
        if(!attachedDocumentType)
            downloadFileDefault(file)
        else
            window.open(link, '_blank')
    }

    return (
        <>
            <div className="p-2">
                <div className="rounded-lg border border-on-hover p-2 documentItem cursor-pointer" onClick={() => downloadFile(attachedDocumentType ? link : file) }>
                    <div className="rounded-lg overflow-hidden">
                        {(file && link && type != null) && <DocumentPreview file={file} type={type} link={link} downloadFile={downloadFile} downloadable={false} />}
                    </div>
                    <div className="px-3 pt-3 pb-1">
                        {text && <>
                            <h2>{text}</h2>
                            <span className="border border-muted d-block my-2"></span>
                        </>}

                        {size != null && <span>{size}Mb</span>}

                        {date && <span className="float-right">{date}</span>}

                        {(size || date) && <span className={`border border-muted d-block ${size === null ? '' : 'my-2'}`} style={{ marginTop: size == null ? '2rem' : '', marginBottom: size == null ? '0.5rem' : ''}}></span>}

                        <p className="mb-0">
                            <span className="icon-download mr-2 icon-16 align-middle"></span>
                            <span className="text-uppercase">Download</span>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}