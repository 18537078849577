import { List , Map } from 'immutable';
import { membersListActions } from './actions';
import { MembersList } from './members-list';
import { DEFAULT_SESSION_ID } from '../constants';
import { membersListIdForFind } from './utils';

export const initialState = new Map({
  currentMembersListId: membersListIdForFind(DEFAULT_SESSION_ID),
  [membersListIdForFind(DEFAULT_SESSION_ID)]: new MembersList({id: DEFAULT_SESSION_ID, isNew: false})
});


export function membersListsReducer(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {

    case membersListActions.FETCH_MEMBERS_FAILED:
    case membersListActions.FETCH_MEMBERS_SUCCESS:
    case membersListActions.FETCH_MEMBERS_PENDING:
      return state.set(
        payload.membersListId,
        membersListReducer(state.get(payload.membersListId), action)
      );
    case membersListActions.FIND_MEMBERS_BY_ID:
    case membersListActions.FIND_MEMBERS:
    case membersListActions.SEARCH_MEMBERS:
      return state.merge({
        currentMembersListId: payload.membersListId,
        [payload.membersListId]: membersListReducer(state.get(payload.membersListId), action)
      });
    default:
      return state;
  }
}

export const membersListReducer = (state = new MembersList(),  {payload, type} ) => {

  switch (type) {
    case membersListActions.FETCH_MEMBERS_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case membersListActions.FETCH_MEMBERS_SUCCESS:    
      return state.withMutations( memberslist => {
        memberslist.merge({
          isNew: false,
          isPending: false,
          itemsId: mergeIds(memberslist.itemsId, payload.data),
        });
      });
    case membersListActions.FETCH_MEMBERS_PENDING:
      return state.set('isPending', true);
    case membersListActions.FIND_MEMBERS:
    case membersListActions.SEARCH_MEMBERS:
      return state.merge({
        id: payload.membersListId,
      });
    case membersListActions.FIND_MEMBERS_BY_ID:
      return state.merge({
        id: payload.membersListId,
        isNew:false,
        itemsId:new List([payload.membersListId])
      });
    default:
      return state;
  }
}

function mergeIds(itemsId, items) {
  let ids = itemsId.toJS();

  //orden asc
  /*
  items = items.sort(function (a, b) {
    return (a.order - b.order)
  })
  */

  let newIds = items.reduce((list, itemData) => {
    if (ids.indexOf(itemData.id) === -1) list.push(itemData.id);
    return list;
  }, []);

  return newIds.length ? new List(ids.concat(newIds)) : itemsId;
}
