import { call, fork, takeEvery } from 'redux-saga/effects';
import { sesionVerify } from '../api'
import { verifyCodeActions } from './actions';

export function* verifyCode({ payload }) {

    const { data, verifyId } = payload;
    yield call(sesionVerify, verifyId, data);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchVerifyCode() {
    yield takeEvery(verifyCodeActions.CREATE_VERIFY, verifyCode);
}

//=====================================
//  ROOT
//-------------------------------------

export const verifySagas = [
    fork(watchVerifyCode)
];