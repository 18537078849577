import React from 'react';
import { Col, Card } from 'reactstrap';
import ReportTT from './report-tt';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import intro_tt from '../../../../assets/img/Thinking-Talents.svg'

const ThinkingTalentsHome = ({ oneColumn }) => {

    const { user } = useSelector(state => state);

    return (
        <Col xl='12' className="mt-3">
            <Card className="p-4 pt-md-1 mb-4 mb-xl-0 ">
                <div className={`${oneColumn ? "px-md-5": ''}`}>
                    <div className="encabezado encabezado-icono">
                        <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                            <img src={intro_tt} alt="Thinking Talents" width="40" height="auto" />
                            <h6 className="mb-0 ml-3 title-underline">Thinking Talents</h6>
                        </div>
                        <span className="border border-info d-none d-md-block"></span>
                    </div>

                    {!user.talents || user.talents.length === 0 &&
                        (<>
                            <p className="my-4 mt-md-2">Discover the natural ways you approach challenges that give you energy rather than burn you out.</p>
                        </>)}

                    {user.talents && user.talents.length > 1 &&
                        (<>
                            <p className="my-4 mt-md-2">My Thinking Talents.</p>
                            <ReportTT version={`${oneColumn ? '': 'small'}`}/>
                        </>)
                    }

                </div>
                <Link
                    to='/thinking-talents'
                    className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">
                    {
                        (user.talents && user.talents.length > 1) ? (<><i className="icon-view-report mr-2"></i>View Results</>) : 'Let’s start!'
                    }
                </Link>
            </Card>
        </Col>
    )
}
const mapStateToProps = (state, props) => {
    const { user } = state;
    return { user };
};

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ThinkingTalentsHome);
