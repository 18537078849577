import React, { useEffect, useState } from 'react';
import { Col, Progress, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import {  useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { teamResumeActions } from '../../../../core/team-resume/actions';
import { ModalViewList } from './ModalViewList';
import icon_tt from '../../../../assets/img/tt-sm-icon.svg';
import icon_mp from '../../../../assets/img/mp-sm-icon.svg';
import icon_user_sm from '../../../../assets/img/user-sm-icon.svg';
import MemberDetail from '../member-detail';


export const renderList = (list, toggle) => {
    return list.map(member =>
        <div className="member-item cursor-pointer" key={member.id} onClick={() => toggle({ section: 'detail-member', member_id: member.id })}>
            <div className="d-flex justify-content-between px-4 py-2">
                <p className="mb-0"><i className="mr-3"><FontAwesomeIcon icon={faUser} /></i>{`${member.first_name} ${member.last_name}`}</p>
            </div>
        </div>)
}

export const TeamResume = ({ team, members, quadrants }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [showModal, setshowModal] = useState(false);
    const [typeModal, setTypeModal] = useState("");
    const { team_resume } = useSelector(state => state);
    const [current_section, setSection] = useState(false);
    const [current_member_id, setCurrentMember] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        dispatch(teamResumeActions.loadTeamResume(id))
    }, [])

    const toggle = ({ section = 'members-list', member_id = null, quadrant_name = null }) => {
        setIsOpen(!isOpen);
        setSection(section);
        setCurrentMember(member_id)
    }

    const count_members_assessed_mp = team_resume.data && team_resume.data.count_mp && team_resume.data.count_mp;
    const count_members_assessed_tt = team_resume.data && team_resume.data.count_tt && team_resume.data.count_tt;
    const total_count_members = team_resume.data && team_resume.data.count_members;
    const count_members_not_assessed_mp = total_count_members - count_members_assessed_mp;
    const count_members_not_assessed_tt = total_count_members - count_members_assessed_tt

    const handleModal = (name) => {
        setshowModal(!showModal)
        setTypeModal(name)
        setSection('members-list');
    }

    const team_member = members.find(item => item.user_id === current_member_id);
    


    let contentModal = null;
    let title = "";

    if (typeModal === 'thinking-talent-already-done') {
        title = 'People with the Thinking Talent already done.'
        contentModal = <div className="member-list">
            {
                renderList(team_resume.data && team_resume.data.members_tt && team_resume.data.members_tt, toggle)
            }
        </div>

    } else if (typeModal === 'thinking-talent-not-done') {
        title = 'People with the Thinking Talent not done.'
        contentModal = <div className="member-list">
            {
                renderList(team_resume.data && team_resume.data.members_no_tt && team_resume.data.members_no_tt, toggle)
            }
        </div>
    } else if (typeModal === "mind-patern-already-done") {
        title = 'People with the Mind Patern already done'
        contentModal = <div className="member-list">
            {
                renderList(team_resume.data && team_resume.data.members_mp && team_resume.data.members_mp, toggle)
            }
        </div>
    } else if (typeModal === "mind-pattern-not-done") {
        title = 'People with the Mind Pattern not done.'
        contentModal = <div className="member-list">
            {
                renderList(team_resume.data && team_resume.data.members_no_mp && team_resume.data.members_no_mp, toggle)
            }
        </div>
    }



    return (
        <>
            {!team_resume.isPending &&
                <Row className="pt-md-2">
                    <Col md={{ size: 6 }} className="mb-2 mb-md-4">
                        <div className="bg-celeste py-2 px-3 rounded mb-4">
                            <p className="mb-0">Team progress in completing Thinking Talents.</p>
                        </div>

                        <div className="pt-1 px-md-5">
                            <Progress value={team_resume.data && team_resume.data.progress_tt} barStyle={{ backgroundColor: "#ff7400" }} className="questionnaireProgress overflow-initial mb-2" barClassName="position-relative overflow-initial" >
                                <div className="text-right ml-n3 small" style={(team_resume.data && team_resume.data.progress_tt) == 0 ? { color: '#ff7400', right: '-17px' } : { color: '#ff7400', right: '-13px' }}>{team_resume.data && team_resume.data.progress_tt}%
                                    <FontAwesomeIcon icon={faCaretDown} className="d-block mx-auto" size="lg" color="#ff7400" /></div>
                            </Progress>

                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <div className="mr-2"><img src={icon_tt} width="24" /></div>
                                    <div className="pt-1">
                                        <p className="mb-1"><b>{count_members_assessed_tt}/{total_count_members}</b> People</p>
                                        {count_members_assessed_tt > 0 && <a className="cursor-pointer text-reset small font-weight-semibold" onClick={() => handleModal('thinking-talent-already-done')}>VIEW LIST</a>}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="pt-1">
                                        <p className="mb-1"><b>{count_members_not_assessed_tt}/{total_count_members}</b> People</p>
                                        {count_members_not_assessed_tt > 0 && <a className="cursor-pointer text-reset small font-weight-semibold" onClick={() => handleModal('thinking-talent-not-done')}>VIEW LIST</a>}
                                    </div>
                                    <div className="ml-2"><img src={icon_user_sm} width="24" /></div>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col md={{ size: 6 }} className="mb-2 mb-md-4">
                        <div className="bg-celeste py-2 px-3 rounded mb-4">
                            <p className="mb-0">Team progress in completing Mind Patterns.</p>
                        </div>
                        <div className="pt-1 px-md-5">
                            <Progress value={team_resume.data && team_resume.data.progress_mp} barStyle={{ backgroundColor: "#16B3BE" }} className="questionnaireProgress overflow-initial mb-2" barClassName="position-relative overflow-initial" >
                                <div className="text-right ml-n3 small" style={(team_resume.data && team_resume.data.progress_mp) == 0 ? { color: '#16B3BE', right: '-17px' } : { color: '#16B3BE', right: '-13px' }}>
                                    {team_resume.data && team_resume.data.progress_mp}%
                                    <FontAwesomeIcon icon={faCaretDown} className="d-block mx-auto" size="lg" color="#16B3BE" /></div>
                            </Progress>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex">
                                    <div className="mr-2"><img src={icon_mp} width="24" /></div>
                                    <div className="pt-1">
                                        <p className="mb-1"><b>{count_members_assessed_mp}/{total_count_members}</b> People</p>
                                        {count_members_assessed_mp > 0 && <a className="cursor-pointer text-reset small font-weight-semibold" onClick={() => handleModal('mind-patern-already-done')}>VIEW LIST</a>}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="pt-1">
                                        <p className="mb-1"><b>{count_members_not_assessed_mp}/{total_count_members}</b> People</p>
                                        {count_members_not_assessed_mp > 0 && <a className="cursor-pointer text-reset small font-weight-semibold" onClick={() => handleModal('mind-pattern-not-done')}>VIEW LIST</a>}
                                    </div>
                                    <div className="ml-2"><img src={icon_user_sm} width="24" /></div>
                                </div>
                            </div>

                        </div>
                    </Col>
                </Row>
            }
            <ModalViewList
                showModal={showModal}
                handleModal={handleModal}
                team={team}
                contentModal={ 
                    (current_section === "detail-member" && current_member_id)?
                    <MemberDetail
                        member={team_member.member}
                        isMember={true}
                        team={team}
                        quadrants={quadrants}
                        isLeader={team_member.leader}
                        hanndleNextSection={toggle}
                        
                    />
                    :
                    contentModal
                }
                title={title}
                handleClick={toggle}
            />
        </>
    )
}

