

export const teamResumeActions = {
    FETCH_TEAM_RESUME_FAILED: 'FETCH_TEAM_RESUME_FAILED',
    FETCH_TEAM_RESUME_SUCCESS: 'FETCH_TEAM_RESUME_SUCCESS',
    FETCH_TEAM_RESUME_PENDING: 'FETCH_TEAM_RESUME_PENDING',
    LOAD_TEAM_RESUME: 'LOAD_TEAM_RESUME',

    fetchTeamResumeFailed: (error, team_id) => ({
        type: teamResumeActions.FETCH_TEAM_RESUME_FAILED,
        payload: {
            data: error,
            team_id
        }
    }),

    fetchTeamResumeSuccess: (team_id, data) => ({
        type: teamResumeActions.FETCH_TEAM_RESUME_SUCCESS,
        payload: {
            data,
            team_id
        }
    }),

    fetchTeamResumePending: team_id => {
        return ({
            type: teamResumeActions.FETCH_TEAM_RESUME_PENDING,
            payload: {
                team_id
            }
        })
    },

    loadTeamResume: team_id => ({
        type: teamResumeActions.LOAD_TEAM_RESUME,
        payload: {
            team_id
        }
    })
};

export const teamResumeRequestActions = {
    failed: teamResumeActions.fetchTeamResumeFailed,
    success: teamResumeActions.fetchTeamResumeSuccess,
    pending: teamResumeActions.fetchTeamResumePending
};
