import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import { userReducer } from './users';
import { questionsListReducer } from './questions-list';
import { assessmentsListsReducer } from './assessments-list';
import { assessmentItemReducer } from './assessment-item';
import { teamsListsReducer } from './teams-list';
import { teamsReducer } from './teams';
import { membersListsReducer } from './members-list';
import { membersReducer } from './members';
import { quadrantsReducer } from './quadrants';
import { teamsMembersListsReducer } from './teams-members-list';
import { systemsReducer } from './system';
import { teamsReportListReducer } from './teams-report';
import { teamsFullReportListReducer } from './teams-full-report';
import { mindPatternsDatasReducer } from './mind-patterns';
import { registerReducer } from './register/reducer';
import { verifyCodeReducer } from './sesion-verify/reducer';
import { mindPatternsOptionStoreDataReducer } from './mind-patterns-storeOptions/reducer';
import { mindPatternsStoreMPReducer } from './mind-patterns-storemp/reducer';
import { systemCheckReducer } from './systemCheck/reducer';
import { forgotPasswordReducer } from './forgotPassword/reducer';
import { ContactReducer } from './contact/reducer';
import { fullReportMpReducer } from './full-report-mp/reducer';
import { userResumeReducer } from './thinking-talents-user-resume';
import { questionnaireResumeReducer } from './questionnaire-resume/reducer';
import { questionnaireItemsReducer } from './questionnaire-items';
import { questionnaireOptionStoreDataReducer } from './questionnaire-storeOptions/reducer';
import { videosReducer } from './videos';
import { pdfsReducer } from './pdfs/reducer';
import { teamResumeReducer } from './team-resume/reducer';
import { profileReducer } from './profile/reducer';
import { communityReducer } from './community-search/reducer';
import { trackingReducer } from './tracking/reducer';
import { userDocumentsReducer } from './user-documents/reducer';

export default combineReducers({
  session: sessionReducer,
  user: userReducer,
  questions: questionsListReducer,
  assessment: assessmentsListsReducer,
  assessment_items: assessmentItemReducer,
  teams_lists: teamsListsReducer,
  teams: teamsReducer,
  members_lists: membersListsReducer,
  members: membersReducer,
  quadrants: quadrantsReducer,
  teams_members_lists: teamsMembersListsReducer,
  system: systemsReducer,
  teams_reports: teamsReportListReducer,
  teams_full_reports: teamsFullReportListReducer,
  mind_patterns_data: mindPatternsDatasReducer,
  register: registerReducer,
  verifySesionCode: verifyCodeReducer,
  mind_patterns_store_data_options: mindPatternsOptionStoreDataReducer,
  mind_patterns_store_mp: mindPatternsStoreMPReducer,
  systemCheck: systemCheckReducer,
  forgotPassword: forgotPasswordReducer,
  contact: ContactReducer,
  full_report_mp: fullReportMpReducer,
  user_resume_TT: userResumeReducer,
  questionnaire_resume: questionnaireResumeReducer,
  questionnaire_list: questionnaireItemsReducer,
  questionnaire_store_data_options: questionnaireOptionStoreDataReducer,
  videos: videosReducer,
  pdf: pdfsReducer,
  team_resume: teamResumeReducer,
  profile : profileReducer,
  profile: profileReducer,
  community: communityReducer,
  tracking: trackingReducer,
  userDocuments: userDocumentsReducer
});
