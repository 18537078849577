import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';


class Disclaimers extends Component {
  
  
  render() {

    const { view } = this.props;

    return (
      <>
      {view === 'propiedad-intelectual' &&
        <div className="propiedad-intelectual text-center pt-4">
        <p className="mb-0 small text-muted">The intellectual property on Mind Patterns and Thinking Talents in My True Advance is drawn from source material from the books <strong>Collaborative Intelligence</strong> and <strong>Reconcilable Differences</strong> by Dawna Markova, Ph.D. and Angie McArthur, published by Spiegel & Grau, an imprint of Random House, a division of Random House, Inc. Copyright © 2015, 2017 by Dawna Markova and Angie McArthur. All right reserved.</p></div>
      }
      {view === 'copyright' &&
        <div className="propiedad-intelectual text-center pt-4">
        <p className="mb-0 small text-muted">This material is excerpted from the books <strong>Collaborative Intelligence</strong> and <strong>Reconcilable Differences</strong> by Dawna Markova and Angie McArthur. Copyright © 2015, 2017 by Dawna Markova and Angie McArthur. Reprinted by arrangement with Spiegel & Grau, an imprint of Random House, a division of Random House, Inc. All right reserved.</p></div>
      }
      </>
    );
  }
}

export default Disclaimers;
