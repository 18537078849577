import React, { Component } from 'react';
import { Row, Col, Collapse, Modal, ModalHeader, ModalBody, UncontrolledCollapse, Media } from 'reactstrap';
import styled from 'styled-components';
import imageMapResize from 'image-map-resizer'
import logo from '../../../assets/img/true.logo.circle.png';
import graphic_report from '../../../assets/img/graphic-report-sm.jpg';
import graphic_report_mask from '../../../assets/img/graphic-report-mask.png'
import { QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL, QUADRANTS_PROCEDURAL, QUADRANTS_ALL } from '../../../core/constants';
import { ThinkingTalentsReportUser } from './thinking-talents-report-user';
import { Disclaimers } from '../common';

const StyledQuadrant = styled.div`
  display: initial;

  > h5 {
    color: ${props => props.quadrant_color};
  }

  > ul li:before {
    color: ${props => props.quadrant_color};
  }
`;


class Report extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      current_item: null,
      paddingTop: 0,
      paddingBottom: 0,
      isOpen: false
    }

    this.toggle = this.toggle.bind(this);
    this.updateSize = this.updateSize.bind(this);

    // this.image_bg = new Image();

    const img = new Image();

    img.src = graphic_report_mask;
  }

  toggle(key) {

    const { quadrants } = this.props
    const { modal } = this.state

    const current_item = quadrants.find(item => item.name === key)

    this.setState({ modal: !modal, current_item })
  }

  toggleReport = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen })
  }


  updateSize() {
    const { paddingBottom, paddingTop } = this.state
    // const top = document.getElementById('lists-top').clientHeight
    // const bottom = document.getElementById('lists-top').clientHeight
    // let img = this.image_bg && this.image_bg.clientHeight;
    // const media = img / 4;
    // let new_paddingTop = 0;
    // let new_paddingBottom = 0;

    // if (media <= this.div_lists_top.clientHeight) {
    //   if (img< 400)
    //     new_paddingTop = media + (this.div_lists_top.clientHeight / 2);
    //   else
    //     new_paddingTop = media - (this.div_lists_top.clientHeight / 2);
    // }

    // if (img < 400)
    //   new_paddingBottom = media + (this.div_lists_bottom.clientHeight / 2);
    // else
    //   new_paddingBottom = media - (this.div_lists_bottom.clientHeight / 2);

    // this.setState({ paddingBottom: new_paddingBottom, paddingTop: new_paddingTop })

  }

  componentDidMount() {
    imageMapResize()
    window.addEventListener('resize', this.updateSize);
    window.addEventListener('load', this.updateSize)
    setTimeout(() => {
      this.updateSize();
    }, 500)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateSize);
    window.removeEventListener('load', this.updateSize);
  }



  render() {
    const { user, items, questions, quadrants, hanndleNextQuestion, view_mode } = this.props;
    const { modal, current_item, paddingBottom, paddingTop, isOpen } = this.state;

    const btn_text = 'Next';

    const content = quadrants.map((item, key) => {
      return item.name !== 'All' ?
      <div key={'q_' + key} className={'item-report mb-2 mb-md-4 col-md-6 ir-' + key}>
        <h6 className="text-reset text-uppercase mb-0"><i className="icon mr-2"><svg width="16" height="16"><circle cx="8" cy="8" r="8" fill={item.color} /></svg></i>{item.name}</h6>
        <div>{item.excerpt}</div>
      </div> : null
    })


    const items_talents = current_item ? items.map(s_item => {
      const item = questions.find(question => question.id === s_item.item_id);
      // console.log('response_items_by_groups.group_1')
      // console.log(item)
      return (item && item.quadrant.id === current_item.id) ? <div key={'i_' + item.id} className={"item talent-item mb-2 pb-2 border-bottom modal-quadrant-" + item.quadrant.name}>
        <div id={`toggler${item.id}`} className="cursor-pointer">
          <Media className="align-items-center">
            <Media left middle className="mr-3">
              <Media object src={item.icon} width="60" height="60" alt={item.name} style={{ backgroundColor: current_item.color }} />
            </Media>
            <Media body>
              <Media heading tag="h5" className="mb-0 text-reset">{item.name}</Media>
            </Media>
          </Media>
        </div>
        <UncontrolledCollapse toggler={`#toggler${item.id}`}>
          <StyledQuadrant quadrant_color={current_item.color} dangerouslySetInnerHTML={{ __html: item.result_description }} className="pt-2"></StyledQuadrant>
        </UncontrolledCollapse>
      </div> : null
    }) : null

    const content_modal = current_item ? <div className={"modal-quadrant-" + current_item.name}>
          <div className="d-flex">
            <div className="d-inline-block mx-auto mb-2">
              <img className="icon" width="130" height="130" src={current_item.icon} alt={current_item.name} />
            </div>
          </div>
          <h2 className="text-center text-reset text-capitalize mb-3">{current_item.name}</h2>
          <StyledQuadrant quadrant_color={current_item.color} className="text-left" dangerouslySetInnerHTML={{ __html: current_item.description }}></StyledQuadrant>
          <div class="bg-celeste p-3 rounded mb-4"><p class="mb-0">Tap the quadrants to view more information</p></div>
          <hr className="mt-1 mb-2" />
          {items_talents}
      </div> : null


    let list_items_analytic = [];
    let list_items_innovative = [];
    let list_items_relational = [];
    let list_items_procedural = [];
    let list_items_all = [];

    items.map(s_item => {
      const item = questions.find(question => question.id === s_item.item_id || question.id === s_item.id);
      if (item && item.quadrant.name === QUADRANTS_ANALYTIC) {
        list_items_analytic.push(<div><h6 key={'qitem_' + item.id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-md-row-reverse">
          <i className="icon mr-1 mr-md-0 ml-md-1">
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="5" fill={item.quadrant.color} />
            </svg>
          </i>
          <span className="text-nowrap" style={{ lineHeight: '22px'}}>{item.name}</span>
        </h6></div>
        )
      }
      if (item && item.quadrant.name === QUADRANTS_INNOVATIVE) {
        list_items_innovative.push(<div><h6 key={'qitem_' + item.id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-row-reverse flex-md-row">
          <i className="icon float-right ml-1 ml-md-0 mr-md-1">
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="5" fill={item.quadrant.color} />
            </svg>
          </i>
          <span className="text-nowrap" style={{ lineHeight: '22px'}}>{item.name}</span>
        </h6></div>)
      }
      if (item && item.quadrant.name === QUADRANTS_RELATIONAL) {
        list_items_relational.push(<div><h6 key={'qitem_' + item.id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-row-reverse flex-md-row">
          <i className="icon float-right ml-1 ml-md-0 mr-md-1">
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="5" fill={item.quadrant.color} />
            </svg>
          </i>
          <span className="text-nowrap" style={{ lineHeight: '22px' }}>{item.name}</span>
        </h6></div>)
      }
      if (item && item.quadrant.name === QUADRANTS_PROCEDURAL) {
        list_items_procedural.push(<div><h6 key={'qitem_' + item.id} className="text-reset text-capitalize mb-0 d-flex align-items-center flex-md-row-reverse">
          <i className="icon ml-2">
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="5" fill={item.quadrant.color} />
            </svg>
          </i>
          <span className="text-nowrap" style={{ lineHeight: '22px' }}>{item.name}</span>
        </h6></div>)
      }
      if (item && item.quadrant.name === QUADRANTS_ALL) {
        list_items_all.push(<div><h6 key={'qitem_' + item.id} className="text-reset text-capitalize mb-0 d-flex align-items-center undefined flex-md-row-reverse">
          <i className="icon mr-1 mr-md-0 ml-md-1">
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="5" fill={item.quadrant.color} />
            </svg>
          </i>
          <span className="text-nowrap" style={{ lineHeight: '22px'}}>{item.name}</span>
        </h6></div>)
      }
    })

    return (
        <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 py-3 d-flex flex-column">
          <div className="px-md-4">
            <h1 className="text-center text-md-left mb-4 d-md-none">Thinking Talents</h1>

            <div className="d-none d-sm-none d-md-block mb-md-3 encabezado">
              <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-strech text-encabezado">
                <h6 className="d-none d-sm-none d-md-block mb-0 title-underline text-nowrap mr-4">Thinking Talents</h6>
                <p className="mb-0">Your Thinking Talents are your innate ways of thinking. Thinking Talents are the specific ways of approaching challenges that energize your brain and come naturally to you. These are different from the skills you develop through education and training.  There are thirty-five Thinking Talents in all, and most adults naturally use 5-8. Not only do you lean into these talents, but when you think this way you feel energized.</p>
              </div>
              <span className="border border-info d-block"></span>
            </div>


          <Row className="align-items-center">
            <Col lg={{ size: 6 }}>
              <p className="h5 text-reset mb-4 mb-md-2">Here's how your Thinking Talents plot to Domains of Competence, which are four broad categories of thinking:</p>
            </Col>
            { !view_mode && 
                <Col lg={{ size:6 }} className="d-none d-lg-block">
                    <button className="toggler-quadrants btn text-uppercase btn-outline-primary btn-block-inline rounded-pill px-3 mb-2 float-right" onClick={() => this.toggleReport()}>MAP VIEW</button>
                </Col>
            }
          </Row>
          
          { !view_mode && 
            <Row className="d-none d-lg-block">
                <Col lg={{ size: 12 }}>
                <Collapse isOpen={isOpen}>
                    <ThinkingTalentsReportUser isOpen={isOpen} />
                </Collapse>
                </Col>
            </Row>
          }
          <Row>
            <Col lg={{ size: 12 }}>
              <hr className="d-none d-md-block"/>
              {content && <Row className="mb-4 mb-md-0">{content}</Row>}
              <div className="border border-info rounded py-3 px-4 mb-4 mb-md-0"><p className="mb-0">If you have talents in the center, they can be used in all four ways.<br/>Notice your dominant quadrant(s) and also where talents are absent or light.</p></div>
            </Col>

            <Col lg={{ size: 12 }}>
              <hr className="d-none d-md-block"/>
              <div className="bg-celeste p-3 rounded mb-4"><p className="text-reset text-left text-md-center mb-0">Tap the quadrants to view more information</p></div>

              <div className="graphic-map graphic-map-sm mb-3 mx-auto">
                <div className="graphic-map-list overflow-hidden mx-auto">
                  <Row id="lists-top" className="d-flex list-top justify-content-between align-items-end position-relative">
                    <div onClick={() => this.toggle(QUADRANTS_ANALYTIC)} ref={e => this.div_lists_top = e} className="col-6 cursor-pointer align-items-md-end d-md-flex justify-content-md-end"><div className="lists-item mx-0 row row-cols-lg-2 justify-content-lg-end pr-lg-3">{list_items_analytic}</div></div>

                    <Col xs={{ size: 6 }} className="cursor-pointer align-items-md-end d-md-flex" onClick={() => this.toggle(QUADRANTS_INNOVATIVE)}><div className="lists-item">{list_items_innovative}</div></Col>
                  </Row>

                  <div className="bg-map-dk mx-auto">
                    <div className="graphic-map-img mx-auto" style={{ width: '190px'}}>
                      <div className="mx-auto text-center graphic-list-all-talent"><div>{list_items_all}</div></div>
                      <img ref={e => this.image_bg = e} src={graphic_report} useMap="#image_map" className="img-fluid" style={{ opacity: 0, minHeight: '100%' }} />
                      <map name="image_map">
                        <area onClick={() => this.toggle(QUADRANTS_ANALYTIC)} alt="Analytic" title="Analytic" shape="polygon" coords="1,2,96,0,95,22,57,33,29,62,20,94,0,94" />
                        <area onClick={() => this.toggle(QUADRANTS_INNOVATIVE)} alt="Innovative" title="Innovative" shape="polygon" coords="97,21,97,0,188,0,190,95,169,96,161,57,131,31" />
                        <area onClick={() => this.toggle(QUADRANTS_RELATIONAL)} alt="Relational" title="Relational" shape="polygon" coords="98,167,131,161,158,132,169,98,189,97,187,189,96,188" />
                        <area onClick={() => this.toggle(QUADRANTS_PROCEDURAL)} alt="Procedural" title="Procedural" shape="polygon" coords="1,95,20,94,29,128,61,162,96,168,95,189,0,189" />
                        <area onClick={() => this.toggle(QUADRANTS_ALL)} alt="All" title="All" shape="circle" coords="95,95,70" />
                      </map>
                    </div>
                  </div>

                  <Row id="lists-bottom" className="d-flex list-bottom justify-content-between align-items-start position-relative">
                    <div onClick={() => this.toggle(QUADRANTS_PROCEDURAL)} ref={e => this.div_lists_bottom = e} className="col-6 cursor-pointer align-items-md-start d-md-flex justify-content-md-end"><div className="lists-item">{list_items_procedural}</div></div>

                    <Col onClick={() => this.toggle(QUADRANTS_RELATIONAL)} xs={{ size: 6 }} className="cursor-pointer align-items-md-start d-md-flex"><div className="lists-item">{list_items_relational}</div></Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
              { !view_mode &&
                <Col sm={{ size: 6 }} xs={{ size: 12 }}>
                    <button onClick={() => hanndleNextQuestion('result_1')} className="btn btn-md-sm btn-outline-primary text-uppercase rounded-pill d-flex px-md-3 justify-content-center align-items-center mx-auto"><i className="icon-retake mr-2"></i><span>REVIEW / REARRANGE</span></button>
                </Col>
              }


                {false &&
                  <Col sm={{ size: 6 }} xs={{ size: 12 }}>
                  <Row className="mt-3 mt-sm-0">
                    <Col lg={{ size: 6, offset: 3 }} sm={{ size: 12, offset: 0 }} xs={{ size: 6, offset: 3 }} >
                      <button className="btn btn-md-sm btn-primary text-uppercase btn-block rounded-pill">{btn_text}</button>
                    </Col>
                  </Row></Col>
                }
            </Row>
            <Disclaimers view="copyright" />
          </div>

          <Modal isOpen={modal} toggle={this.toggle} className="modal-dialog-summary float-right">
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            {content_modal}
          </ModalBody>
          </Modal>

        </div>)
  }
}

export default Report;
