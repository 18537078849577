import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';

import { appActions } from './core/app';
import history from './core/history';
import configureStore from './core/store';
import { api } from './core/api';
import ReactGA from 'react-ga';

import session from './core/session';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/index.css';
import App from './views/App';
import * as serviceWorker from './serviceWorker';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const store = configureStore();

store.dispatch(appActions.initApp({
  check_session: true
}));

//ReactGA.initialize(GA_TRACKING_ID);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history} basename={process.env.PUBLIC_URL}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);
