export const teamsListActions = {

  FETCH_TEAMS_FAILED: 'FETCH_TEAMS_FAILED',
  FETCH_TEAMS_SUCCESS: 'FETCH_TEAMS_SUCCESS',
  FETCH_TEAMS_PENDING: 'FETCH_TEAMS_PENDING',
  LOAD_TEAMS: 'LOAD_TEAMS',


  fetchTeamsFailed: error => ({
    type: teamsListActions.FETCH_TEAMS_FAILED,
    payload: error
  }),

  fetchTeamsSuccess: (teamsListId, data) => ({
    type: teamsListActions.FETCH_TEAMS_SUCCESS,
    payload: {
      data,
      teamsListId
    }
  }),

  fetchTeamsPending: teamsListId => ({
    type: teamsListActions.FETCH_TEAMS_PENDING,
    payload: {
      teamsListId
    }
  }),

  loadTeams: (grupo) => ({
    type: teamsListActions.LOAD_TEAMS,
    payload: {
      teamsListId: grupo
    }
  }),
};


export const teamsListRequestActions = {
  failed: teamsListActions.fetchTeamsFailed,
  success: teamsListActions.fetchTeamsSuccess,
  pending: teamsListActions.fetchTeamsPending
};
