import { createSelector } from 'reselect';

export function getTeamsFullReport(state) {
  return state.teams_full_reports;
}

export function getTeamsFullReportById(state, teamsFullReportId) {
  return getTeamsFullReport(state).get(teamsFullReportId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentTeamsFullReport = createSelector(
  getTeamsFullReport,
  teams_reports => teams_reports.get(teams_reports.get('currentTeamsFullReportId'))
)
