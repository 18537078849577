import React, { Component, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Media, Collapse, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, CustomInput } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components'

import { QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL, QUADRANTS_PROCEDURAL, QUADRANTS_ALL } from '../../../core/constants'
import ThinkingTalentsFullReportItem from './thinking-talents-full-report-item'
import { ThinkingTalentsReportTeamDetails } from './thinking-talents-report-team-details';

const StyledQuadrant = styled.div`
  border: ${props => "1px solid " + props.quadrant_color} ;
  border-radius: 20px;
  .h3 { color: ${props => props.quadrant_color};

  //.h6, .talents-item p { color: ${props => props.quadrant_color};
  }
`;

class ThinkingTalentsFullReport extends Component {

    constructor(props) {
        super(props)

        this.state = {
            animationClass: {
                enter: 'animated',
                enterActive: 'animated fadeIn',
                exit: 'animated',
                exitActive: 'animated fadeOut',
            },
            isOpen: false,
            marginTop: 0,
            paddingBottom: 0,
            paddingTop: 0,
            currentTypeReportTT: 'members',
            showShadowTalent: false,
            showMembers: true,
            showDescriptionTalent: false
        }

        this.toggle = this.toggle.bind(this);
        this.updateSize = this.updateSize.bind(this);
        this.handleCurrentsTypeReportTT = this.handleCurrentTypeReportTT.bind(this);
        this.handleFiltersReportTT = this.handleFiltersReportTT.bind(this);

    }

    componentDidUpdate(oldProps, oldState) {
        this.updateSize();
    }


    componentDidMount() {

        window.addEventListener('resize', this.updateSize);
        window.addEventListener('load', this.updateSize)
        setTimeout(() => {
            this.updateSize();
        }, 500)
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSize);
        window.removeEventListener('load', this.updateSize);
    }

    toggle = () => {
        const { isOpen } = this.state
        const { loadTeamsFullRepor, team } = this.props

        if (!isOpen) {
            loadTeamsFullRepor(team.id)
        }

        this.setState({ isOpen: !isOpen })

    }


    updateSize() {

        if (this.div_lists_all) {

            let paddingBottom = (this.div_lists_all.clientHeight / 2);
            if (paddingBottom)
                paddingBottom = `${paddingBottom}px`;

            if (this.state.paddingBottom !== paddingBottom)
                this.setState({ paddingBottom });

            let marginTop = (this.div_lists_all.clientHeight / 2);
            if (marginTop)
                marginTop = `-${marginTop}px`;

            if (this.state.marginTop !== marginTop)
                this.setState({ marginTop });

            let paddingTop = (this.div_lists_all.clientHeight / 2);
            if (paddingTop)
                paddingTop = `${paddingTop}px`;

            if (this.state.paddingTop !== paddingTop)
                this.setState({ paddingTop })

        };

    }

    handleCurrentTypeReportTT(type) {
        this.setState({
            currentTypeReportTT: type
        })
    }

    handleFiltersReportTT(checked, type) {
        switch (type) {
            case 'talents_descriptions': this.setState({showDescriptionTalent: checked}); break;
            case 'shadow_talents': this.setState({showShadowTalent: checked}); break;
            case 'members': this.setState({showMembers: checked}); break;
        }
        console.log(this.state, checked, type)
    }

    render() {
        const { animationClass, isOpen, marginTop, paddingBottom, paddingTop, currentTypeReportTT, showShadowTalent, showMembers, showDescriptionTalent } = this.state
        const { quadrants, team, team_full_report, questions } = this.props
        console.log(quadrants)
        let list_items_analytic = []
        let list_items_innovative = []
        let list_items_relational = []
        let list_items_procedural = []
        let list_items_all = []

        let color_analytic = null
        let color_innovative = null
        let color_relational = null
        let color_procedural = null
        let color_all = null

        let excerpt_analytic = null
        let excerpt_innovative = null
        let excerpt_relational = null
        let excerpt_procedural = null
        let excerpt_all = null

        quadrants.map(item => {
            if (item.name === QUADRANTS_ANALYTIC) {
                color_analytic = item.color
                excerpt_analytic = item.excerpt;
            }
            else if (item.name === QUADRANTS_INNOVATIVE) {
                color_innovative = item.color;
                excerpt_innovative = item.excerpt;
            }
            else if (item.name === QUADRANTS_RELATIONAL) {
                color_relational = item.color;
                excerpt_relational = item.excerpt;
            }
            else if (item.name === QUADRANTS_PROCEDURAL) {
                color_procedural = item.color;
                excerpt_procedural = item.excerpt;
            }
            else if (item.name === QUADRANTS_ALL) {
                color_all = item.color;
                excerpt_all = item.excerpt;
            }
        });

        if (team_full_report) {
            team_full_report.items.map((s_item, key) => {
                const item = questions.items.find(question => question.id === s_item.item_id);
                if (item && item.quadrant.name === QUADRANTS_ANALYTIC) {
                    list_items_analytic.push(<ThinkingTalentsFullReportItem key={key} item={item} data={s_item} showShadowTalent={showShadowTalent} showMembers={showMembers} showDescriptionTalent={showDescriptionTalent} />)
                }
                else if (item && item.quadrant.name === QUADRANTS_INNOVATIVE) {
                    list_items_innovative.push(<ThinkingTalentsFullReportItem key={key} item={item} data={s_item} showShadowTalent={showShadowTalent} showMembers={showMembers} showDescriptionTalent={showDescriptionTalent} />)
                }
                else if (item && item.quadrant.name === QUADRANTS_RELATIONAL) {
                    list_items_relational.push(<ThinkingTalentsFullReportItem key={key} item={item} data={s_item} showShadowTalent={showShadowTalent} showMembers={showMembers} showDescriptionTalent={showDescriptionTalent} />)
                }
                else if (item && item.quadrant.name === QUADRANTS_PROCEDURAL) {
                    list_items_procedural.push(<ThinkingTalentsFullReportItem key={key} item={item} data={s_item} showShadowTalent={showShadowTalent} showMembers={showMembers} showDescriptionTalent={showDescriptionTalent} />)
                }
                else if (item && item.quadrant.name === QUADRANTS_ALL) {
                    list_items_all.push(<ThinkingTalentsFullReportItem key={key} item={item} data={s_item} showShadowTalent={showShadowTalent} showMembers={showMembers} showDescriptionTalent={showDescriptionTalent} />)
                }
            })
        }

        return (
            <>
                <div className="d-lg-flex justify-content-lg-between align-items-lg-center pb-lg-3">
                    <p className="text-center text-lg-left text-reset h5 mb-lg-0 pl-lg-3 px-3">Team domains of competence</p>
                    <button className="d-none d-lg-block toggler-quadrants btn text-uppercase btn-block-inline rounded-pill btn-outline-primary px-lg-4" onClick={() => this.toggle()}>
                        MAP VIEW
                    </button>
                </div>

                <div className="d-none d-lg-block full-report">
                    <Collapse isOpen={isOpen}>
                        {/* <div className="d-lg-flex justify-content-lg-end mb-md-4">
                            <button className="btn text-uppercase btn-block-inline rounded-pill btn-outline-primary px-lg-4 mr-md-2" onClick={() => this.handleCurrentTypeReportTT('members')}>
                                Members
                            </button>
                            <button className="btn text-uppercase btn-block-inline rounded-pill btn-outline-primary px-lg-4 mr-md-2" onClick={() => this.handleCurrentTypeReportTT('talents')}>
                                Talents
                            </button>
                            <button className="btn text-uppercase btn-block-inline rounded-pill btn-outline-primary px-lg-4" onClick={() => this.handleCurrentTypeReportTT('shadow-talents')}>
                                Shadow Talents
                            </button>
                        </div> */}

                        <FormGroup>
                            <Row>
                                <CustomInput checked={showDescriptionTalent} onChange={({ target }) => { this.handleFiltersReportTT(target.checked, 'talents_descriptions'); }} bsSize="sm" className="styled-checkbox text-uppercase py-1" type="checkbox" label="Talent Descriptions" id="talents" value="talents" />
                                <CustomInput checked={showShadowTalent} onChange={({ target }) => { this.handleFiltersReportTT(target.checked, 'shadow_talents'); }} bsSize="sm" className="styled-checkbox text-uppercase py-1" type="checkbox" label={<em>Shadow Talents</em>} id="shadow_talents" value="shadow_talents" />
                                <CustomInput checked={showMembers} onChange={({ target }) => { this.handleFiltersReportTT(target.checked, 'members'); }} bsSize="sm" className="styled-checkbox text-uppercase py-1" type="checkbox" label="Members" id="members" value="members" />
                            </Row>
                        </FormGroup>

                        {currentTypeReportTT === 'members' && <>
                            <div className="team-member references text-right mb-2 pr-2">
                                <small className="mx-2" title="ola"><FontAwesomeIcon icon={faStar} alt="team leader" className="mr-1" />TEAM LEADER</small>
                                {/* <small className="mx-2" title="CURRENT USER"><FontAwesomeIcon icon={faCircle} alt="current user" className="mr-1" />CURRENT USER</small>
                                <small className="mx-2" title="TEAM MEMBER"><FontAwesomeIcon icon={faSquareFull} alt="team member" className="mr-1" />TEAM MEMBER</small> */}
                            </div>

                            <div className="quadrants-list position-relative full-report mb-4">
                                <Row noGutters id="lists-top" className="d-flex list-top align-content-start flex-wrap h-50 mb-2 no-gutters row">
                                    <Col xs="6" className="text-left">
                                        <StyledQuadrant ref={e => this.div_lists_top = e} quadrant_color={color_analytic} className="px-3 pt-3 mr-1 h-100 d-flex flex-column">
                                            <p className="h3 mt-0 mb-0">{QUADRANTS_ANALYTIC}</p>
                                            <p class="w-75 pr-5"><span className="mr-5 d-inline-block">{excerpt_analytic}</span></p>
                                            <div className="d-flex flex-wrap pr-5 mr-5 justify-content-around row-cols-3">
                                                {list_items_analytic}
                                            </div>
                                        </StyledQuadrant>
                                    </Col>
                                    <Col xs="6" className="text-right">
                                        <StyledQuadrant quadrant_color={color_innovative} className="px-3 pt-3 ml-1 h-100 d-flex flex-column" >
                                            <p className="h3 mt-0 mb-0">{QUADRANTS_INNOVATIVE}</p>
                                            <p className="w-75 pl-5 ml-auto">{excerpt_innovative}</p>
                                            <div className="d-flex flex-wrap pl-5 ml-5 justify-content-around row-cols-3">
                                                {list_items_innovative}
                                            </div>
                                        </StyledQuadrant>
                                    </Col>
                                </Row>
                                <div id="lists-all" className="position-relative">
                                    <div style={{ marginTop: marginTop }} className="graphic-list-all-talent text-center">
                                        <StyledQuadrant ref={e => this.div_lists_all = e} className="mx-auto d-inline-block bg-white pt-3" quadrant_color={color_all}>
                                            <div className="mb-3">
                                                <p className="h3 mt-0 mb-0">Talents In All Quadrants</p>
                                                <p className="mb-0">{excerpt_all}</p>
                                            </div>
                                            <div className="d-flex flex-wrap justify-content-around report-inner-all">
                                                {list_items_all}
                                            </div>
                                        </StyledQuadrant>
                                    </div>
                                </div>
                                <Row noGutters id="lists-bottom" className="d-flex list-bottom align-content-end flex-wrap h-50">
                                    <Col xs="6" className="text-left">
                                        <StyledQuadrant ref={e => this.div_lists_bottom = e} quadrant_color={color_procedural} className="px-3 pb-3 mr-1 h-100 d-flex flex-column align-items-start">
                                            <div className="d-flex flex-wrap pr-5 mr-5 justify-content-around flex-fill mt-3 row-cols-3">
                                                {list_items_procedural}
                                            </div>
                                            <div className="mt-auto w-100">
                                                <p className="h3 mt-0 mb-0">{QUADRANTS_PROCEDURAL}</p>
                                                <p className="mb-0 w-50">{excerpt_procedural}</p>
                                            </div>
                                        </StyledQuadrant>
                                    </Col>
                                    <Col xs="6" className="text-right">
                                        <StyledQuadrant quadrant_color={color_relational} className="px-3 pb-3 ml-1 h-100 align-items-end">
                                            <div className="d-flex flex-wrap pl-5 ml-5 justify-content-around mt-3 row-cols-3">
                                                {list_items_relational}
                                            </div>
                                            <div className="mt-auto w-100 text-right">
                                                <p className="h3 mt-0 mb-0">{QUADRANTS_RELATIONAL}</p>
                                                <p className="mb-0 w-50 ml-auto">{excerpt_relational}</p>
                                            </div>
                                        </StyledQuadrant>
                                    </Col>
                                </Row>
                            </div>
                        </>}

                        {/* {(currentTypeReportTT === 'talents' || currentTypeReportTT === 'shadow-talents') &&
                            <div className='mb-4'>
                                <ThinkingTalentsReportTeamDetails talents={team_full_report.items} showShadowTalent={currentTypeReportTT === 'shadow-talents'} />
                            </div>
                        } */}

                    </Collapse>
                </div>
            </>
        )
    }
}

export default ThinkingTalentsFullReport;
