import { mindPatternsStoreMPActions } from './actions';
import { MIND_PATTERNS_DATA_OPTIONS } from '../constants';
import { StoreMP } from './storeMP';

export function mindPatternsStoreMPReducer(state = new StoreMP(), { payload, type }) {
    switch (type) {
        case mindPatternsStoreMPActions.FETCH_MINDPATTERNS_STOREMP_FAILED:
            return state.merge({
                isPending: false,
                error: payload.error
            });

        case mindPatternsStoreMPActions.FETCH_MINDPATTERNS_STOREMP_SUCCESS:
            return state.withMutations(mindPatternsData => {
                mindPatternsData.merge({
                    id: MIND_PATTERNS_DATA_OPTIONS,
                    isNew: false,
                    isPending: false,
                    items: payload.data
                });
            });

        case mindPatternsStoreMPActions.FETCH_MINDPATTERNS_STOREMP_PENDING:
            return state.set('isPending', true);

        case mindPatternsStoreMPActions.SAVE_MINDPATTERNS_STORE_MP:
            return state.merge({
                items: payload.data,
                isNew: false,
                isPending: false,
            });
        default:
            return state;
    }
}
