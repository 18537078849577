import { call, fork, select, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  fetchMembersTeam,
  removeMemberTeam as sendRemoveMember,
  setLeaderMemberTeam as sendSetMember
} from '../api';

import { DEFAULT_SESSION_ID } from '../constants';

import { teamsMembersListActions } from './actions';
import { getTeamMembersListById } from './selectors';

export function* loadTeamMembersList({ payload }) {
  const { teamsMembersListId } = payload;

  // const teamMemberList = 
  yield select(getTeamMembersListById, teamsMembersListId);
  /*
  if (teamMemberList.isNew)
    yield call(fetchMembersTeam, teamsMembersListId);
  */
  if (teamsMembersListId !== DEFAULT_SESSION_ID)
  yield call(fetchMembersTeam, teamsMembersListId);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadTeamMembersList() {
  yield takeLatest(teamsMembersListActions.LOAD_TEAM_MEMBERS, loadTeamMembersList);
}

//=====================================
//  ROOT
//-------------------------------------

export const teamMembersListSagas = [
  fork(watchLoadTeamMembersList),
];
