import React, { useMemo } from "react";
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';

import { QuestionnaireProgress } from "../../pages/home/questionnaires/QuestionnaireProgress";

export const Assessment = ({ title, icon, completed, link, loading, questionnaires, toggle }) => {
    const uniqueQuestionnaire = questionnaires?.length === 1 ? questionnaires[0] : false;
    const numberCompletedQuestionnaires = useMemo(() => {
        return questionnaires?.filter(questionnaire => questionnaire.completion === 100).length;
    }, [questionnaires]);

    return (
        <div className="d-flex align-items-stretch flex-column h-100">
            <h3 className="mb-0 text-center text-petrol text-uppercase align-content-center h5">{title}</h3>

            <div className="w-100 d-flex justify-content-center align-items-center py-3 mb-auto" style={{ minHeight: '64px'}}>
                <img src={icon} alt={title} width="30" height="auto" />
                {(questionnaires?.length > 0 && !uniqueQuestionnaire) && (
                    <span className="bg-celeste-dark text-white rounded-pill ml-2 px-3 py-1">
                        {numberCompletedQuestionnaires} / {questionnaires?.length}
                    </span>
                )}
            </div>

            <div className="d-flex justify-content-center align-items-end mt-auto mb-3" style={{ minHeight: '64px'}}>
                {uniqueQuestionnaire && (
                    <div>
                        <QuestionnaireProgress questionnaire={uniqueQuestionnaire} showName={false} customTexts={['', '', 'View answers']} />
                    </div>
                )}

                {!questionnaires && link &&
                    <Link
                        to={link}
                        className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">
                        {completed ? (
                            <>
                                <i className="icon-view-report mr-2" ></i>View Results
                            </>
                        ) : (
                            "Let’s start!"
                        )}
                    </Link>
                }

                {!link && questionnaires?.length > 1 && (
                    <button className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto" onClick={toggle}>
                        {completed ? 'View Results' : 'Go'}
                    </button>
                )}
            </div>

            <div className="w-100 d-flex justify-content-center mt-auto">
                <FontAwesomeIcon icon={completed ? faCheckCircle : faCircle} size="3x" color="#78AFBA" style={{ opacity: completed ? 1 : .3 }} />
            </div>
        </div>
    )
}
