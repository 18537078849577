import { DEFAULT_SESSION_ID } from "../constants";

export const verifyCodeActions = {

    FETCH_VERIFYCODE_FAILED: 'FETCH_VERIFYCODE_FAILED',
    FETCH_VERIFYCODE_SUCCESS: 'FETCH_VERIFYCODE_SUCCESS',
    FETCH_VERIFYCODE_PENDING: 'FETCH_VERIFYCODE_PENDING',
    CREATE_VERIFY: 'CREATE_VERIFY',
    RESET_VERIFY: 'RESET_VERIFY',

    fetchVerifyCodeFailed: error => ({
        type: verifyCodeActions.FETCH_VERIFYCODE_FAILED,
        payload: { error }
    }),

    fetchVerifyCodeSuccess: (idVerify, data) => ({
        type: verifyCodeActions.FETCH_VERIFYCODE_SUCCESS,
        payload: {
            data,
            idVerify
        }
    }),

    fetchVerifyCodePending: idVerify => ({
        type: verifyCodeActions.FETCH_VERIFYCODE_PENDING,
        payload: {
            idVerify
        }
    }),

    createVerify: data => ({
        type: verifyCodeActions.CREATE_VERIFY,
        payload: {
            data,
            verifyId: DEFAULT_SESSION_ID
        }
    }),

    resetVerify: () => ({
        type: verifyCodeActions.RESET_VERIFY
    })
};


export const verifyCodeRequestActions = {
    failed: verifyCodeActions.fetchVerifyCodeFailed,
    success: verifyCodeActions.fetchVerifyCodeSuccess,
    pending: verifyCodeActions.fetchVerifyCodePending
};