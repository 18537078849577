import { Record } from 'immutable';

export const Quadrant = new Record({
  id: null,
  name: null,
  description: null,
  color: null,
  icon: null,
  excerpt: null
});

export function createQuadrant(data) {
  return new Quadrant({
    id: data.id,
    name: data.name,
    description: data.description,
    color: data.color,
    icon: data.icon,
    excerpt: data.excerpt
  });
}
