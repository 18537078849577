import React from 'react';
import { Col, FormGroup, Row, CustomInput } from 'reactstrap';
import { Optioncustom } from '../questions-review/OptionCustom';


export const OptionsList = ({
    currentQuestion,
    buildOptions,
    setOptionSelected,
    setValue,
    validText,
    allValid,
    optionCustomSelected
}) => {

    return (
        <>
            <Row className="row-cols-1 row-cols-md-2 pb-5 pb-md-0">
                {
                    currentQuestion.options && currentQuestion.options.map((option) =>
                        <Col key={option.id}>
                            {
                                currentQuestion && currentQuestion.type === 'chk' ?

                                    <FormGroup className="border-bottom py-3 mb-0">
                                        <CustomInput className="styled-checkbox d-flex align-items-center" type="checkbox" onChange={({ target }) => buildOptions(target.id, target.checked)} id={option.id} label={<><strong>{option.title}</strong> <i>{option.description}</i></>} />

                                        {optionCustomSelected(option) &&
                                            <Optioncustom
                                                option={option}
                                                setValue={setValue}
                                                validText={validText}
                                                allValid={allValid}
                                            />
                                        }

                                    </FormGroup> :
                                    <FormGroup className="border-bottom py-3 mb-0">
                                        <CustomInput className="styled-checkbox d-flex align-items-center" type="radio" onChange={({ target })=> setOptionSelected([{id: target.id }])} name="questionName" id={option.id} label={<><strong>{option.title}</strong> <i>{option.description}</i></>} />

                                        {optionCustomSelected(option) &&
                                            <Optioncustom
                                                option={option}
                                                setValue={setValue}
                                                validText={validText}
                                                allValid={allValid}
                                            />
                                        }
                                    </FormGroup>
                            }
                        </Col>
                    )
                }
            </Row>
        </>
    )
}
