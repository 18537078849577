import React from "react";
import Slider from "react-slick";

export const SimpleSliderItem = ({ oneColumn, command, command_link, command_text, icon, id, open_command_new_tab, text, title }) => {
    return (
      <div className={`${oneColumn ? "px-md-5" : 'px-md-3'}`}>
            <h3 className="mb-3 rubik font-weight-normal">{title}</h3>
            <div className="d-md-flex align-items-start">
                {icon && <div className="profile-avatar mb-2 mx-4 mt-2"><img src={icon} width="80" alt={"slider-icon"} /></div>}
                <div className="mt-2">
                    <div dangerouslySetInnerHTML={{ __html: text}} />
                    {command == true && command_link && command_text && <a className={`btn btn-outline-primary text-uppercase rounded-pill px-md-5`} href={command_link} target={open_command_new_tab ? '_blank' : ''} >{command_text}</a>}
                </div>
            </div>
        </div>
    );
}