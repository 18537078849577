import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, CardImg } from 'reactstrap';
import intro_tt from '../../../assets/img/ilustracion-start.png'
import ReactGA from 'react-ga';
import { Disclaimers } from '../common';
import { trackingActions } from '../../../core/tracking';

class Ready extends Component{

  handleClick = ( event ) => {
    const { hanndleNextQuestion } = this.props
    ReactGA.event({
        category: 'Thinking Talent',
        action: 'Creation started'
    })
    this.props.saveTrackingEvent({category: 'Thinking Talent', action: 'Creation started'})

    hanndleNextQuestion( 'questions' );
  }

  render() {
    return (
    <div className="bg-white my-md-4 rounded-lg pb-md-4 px-md-5 pb-md-5 pt-md-2 content-ready content-view">
    <div className="px-md-4">

        <div className="d-none d-sm-none d-md-block mb-md-5 encabezado">
          <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
             <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">Thinking Talents</h6>
          </div>
          <span className="border border-info d-block"></span>
        </div>

    <Row className="ready-ilustracion align-items-md-center">
      <Col md={{ size: 5 }}>
          <CardImg top src={intro_tt} alt="Thinking Talents" className="img-responsive" />
      </Col>
      <Col md={{ size: 7}}>
        <div className="mb-md-5 contenido-after-fixed pb-5 pb-md-0">
          <h1 className="mb-md-3 mb-5 text-center text-md-left d-md-none">Thinking Talents</h1>
          <h2 className="mb-md-3 mb-4 text-center text-md-left text-reset">Ready!</h2>
          <div className="pb-5 pb-md-0">
            <p className="font-weight-semibold">The quiz will describe 35 talents.<br/>Take your time answering Always, Sometimes or Never for each one.</p>
            <p>Your goal is to have <strong>5-8 Always</strong> when finished and there is no way you can be wrong.</p>
            <div className="bg-celeste p-3 rounded mb-5">
              <p className="mb-0"><strong>Remember:</strong> <br/>A <strong>talent</strong> is energizing and something you <strong>cannot help but do.</strong></p>
            </div>
            <h2 className="text-naranja text-center">Let's GO!</h2>
          </div>

        </div>
        <Row className="contenido-bottom-fixed-mb">
        <Col md={{ size:4, offset: 4}}>
          <button onClick={this.handleClick.bind(this)}className="btn btn-primary text-uppercase btn-block rounded-pill">Next</button>
        </Col>
        <Col md="12">
          <Disclaimers view="propiedad-intelectual" />
        </Col>
        </Row>
      </Col>
    </Row>
    </div>
    </div>
    )
  }
}

const mapStateToProps = (state, props) => {
    
  };
  
  const mapDispatchToProps = {
    saveTrackingEvent: trackingActions.saveTrackingEvent
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Ready);
