import { call, fork, takeLatest } from 'redux-saga/effects';
import { fetchFullReportMP } from '../api';
import { fullReportMpListActions } from './actions';


export function* loadFullReportListMp({ payload }) {
    const { fullReportIdMp } = payload;
    yield call(fetchFullReportMP, fullReportIdMp)

}

export function* watchLoadFullReportListMp() {
    yield takeLatest(fullReportMpListActions.LOAD_FULL_REPORT_MP, loadFullReportListMp);
}


export const fullReportListMpSagas = [
    fork(watchLoadFullReportListMp),
];