import { call, fork, take, takeEvery } from 'redux-saga/effects';
import { fetchQuestionnaireItems } from '../api';
import { questionnaireItemsActions } from './actions';

export function* questionnairelistLoad({ payload }) {
    const { id } = payload;
    yield call(fetchQuestionnaireItems, id)
}

//Watchers
export function* watchQuestionnairelistLoad() {
    yield takeEvery(questionnaireItemsActions.LOAD_QUESTIONNAIRE_ITEMS, questionnairelistLoad)
}

//Root
export const questionnaireItemsSaga = [
    fork(watchQuestionnairelistLoad),
]
