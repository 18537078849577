import { createSelector } from 'reselect';
import { getMembers } from '../members/selectors';

export function getTeamsMembersList(state) {
  return state.teams_members_lists;
}

export function getTeamMembersListById(state, teamsMembersListId) {
  return getTeamsMembersList(state).get(teamsMembersListId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentTeamMembersList = createSelector(
  getTeamsMembersList,
  team_member_lists => team_member_lists.get(team_member_lists.get('currentTeamMembersListId'))
);
