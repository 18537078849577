import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Media, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import Autosuggest from 'react-autosuggest';
import classNames from 'classnames';
import { List } from 'immutable';

import { membersListActions, getCurrentMembersList, getMembersForCurrentMembersList } from '../../../core/members-list'
import { getCurrentTeamMembersList } from '../../../core/teams-members-list'

import { Checkbox } from '../common'
import MemberItem from './member-item'

const themeSuggest = {
  container: 'react-autosuggest__container',
  input: 'form-control',
  suggestionsContainer: 'react-autosuggest__suggestions-container position-relative',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList: 'list-group list-group-flush',
  suggestion: 'react-autosuggest__suggestion list-group-item',
  suggestionHighlighted: 'react-autosuggest__suggestion--highlighted',
};

const renderSuggestion = (suggestion, data_suggestion) => {

  let text = `${suggestion.first_name} ${suggestion.last_name}`
  /*
  let re = new RegExp(data_suggestion.query, "g");
  text =  text.replace(re, `<b>${data_suggestion.query}</b>`)
  const queryCapitalized = data_suggestion.query.charAt(0).toUpperCase() + data_suggestion.query.slice(1)
  re = new RegExp(queryCapitalized, "g");
  text =  text.replace(re, `<b>${queryCapitalized}</b>`)
*/
  return (
    <span className="suggestion-item" dangerouslySetInnerHTML={{ __html: text }} ></span>
  );
}

const renderSuggestionsContainer = ({ containerProps, children, query }) => {
  return (
    <div {...containerProps}>
      {children}
    </div>
  );
}

class MemberSearch extends Component {

  constructor(props) {
    super(props)

    this.state = {
      filters: {
        first_name: '',
      },
      value_id: null,
      value: '',
      suggestions: []
    }

    this.cleanFilters = this.cleanFilters.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSuggest = this.handleSuggest.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)

    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this)
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this)
    this.getSuggestionValue = this.getSuggestionValue.bind(this)


  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  componentDidUpdate(oldProps, oldState) {

  }

  cleanFilters() {
    this.setState({
      filters: {
        first_name: '',
      }
    });
  }

  handleChange(event, { newValue }) {

    // console.log('handleChange', event, newValue)
    this.setState({
      value: newValue
    });
    /*
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const { filters } = this.state
        const new_filters = Object.assign({}, filters, {
          [name]: value
        })
    
        this.setState({
          filters:new_filters
        });
        */
  }

  handleKeyPress(event) {

    if (event.which == 13 || event.keyCode == 13) {
      event.preventDefault();
      const value = event.target.value.trim();
      this.setState({
        value_id: null
      });
      event.target.value && this.props.findMembers(event.target.value)
    }
  }

  handleSuggest({ value }) {
    console.log('handleSuggest', value)
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested({ value }) {
    const { findMembers, members, team_members } = this.props;

    if (value) {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;

      const new_suggestions = [];



      members.map(item => {
        if (!team_members.itemsId.contains(item.id) && ((item.first_name ? item.first_name.toLowerCase().indexOf(inputValue) !== -1 : false) ||
          (item.last_name ? item.last_name.toLowerCase().indexOf(inputValue) !== -1 : false) ||
          (item.first_name && item.last_name ? `${item.first_name.toLowerCase()} ${item.last_name.toLowerCase()}`.indexOf(inputValue) !== -1 : false)))
          new_suggestions.push(item);
      });

      this.setState({
        suggestions: new_suggestions
      });
    }
  }
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested() {

    this.setState({
      suggestions: []
    });

  }

  getSuggestionValue(suggestion) {
    const { findMembersById } = this.props
    /*
    this.setState({
      value_id: suggestion.id
    });
    */
    findMembersById(suggestion.id)
    return `${suggestion.first_name} ${suggestion.last_name}`;
  }

  render() {
    const { hanndleNextSection, members, members_selected, handleCheckboxChange, team_members, search_members_list } = this.props
    const { filters, value, suggestions, value_id } = this.state
    const props = {
      value,
      onChange: this.handleChange,
      onKeyPress: this.handleKeyPress,
      className: "form-control form-control-lg",
      placeholder: "Enter Name or Last Name"
    };

    let membmer_list = search_members_list.valueSeq().map(member => {

      if (!team_members.itemsId.contains(member.id))
        return <MemberItem key={member.id} member={member} isMember={false}
          members_selected={members_selected}
          handleCheckboxChange={handleCheckboxChange}
          hanndleNextSection={hanndleNextSection} />

      return null;
    });

    if (value_id) {
      const member = members.get(`${value_id}`)

      membmer_list = <MemberItem key={member.id} member={member} isMember={false}
        members_selected={members_selected}
        handleCheckboxChange={handleCheckboxChange}
        hanndleNextSection={hanndleNextSection} />

    }


    return (
      <>
        <Row>
          <Col xs={{ size: 10, offset: 1 }} md={{ size: 12, offset: 0 }}>
            <Form>
              <FormGroup row>
                <Label sm={2} htmlFor="inputEmail" className="text-uppercase pt-lg-3" style={{marginTop: '-1px'}}>Search Members</Label>
                <Col sm={10} lg={6}>
                <Autosuggest suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsUpdateRequested={this.handleSuggest}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  renderSuggestion={renderSuggestion}
                  renderSuggestionsContainer={renderSuggestionsContainer}
                  getSuggestionValue={this.getSuggestionValue}
                  inputProps={props}
                  theme={themeSuggest}
                />
                </Col>
              </FormGroup>
              <Row>
                <Col sm={{size: 10, offset: 2}} lg={{size: 6, offset: 2}} className="text-right text-lg-left">
                  <a href="#" className="px-3 text-reset" onClick={(e) => { e.preventDefault(); hanndleNextSection({ section: 'advanced-search' }) }}>Advanced Search</a>
                </Col>
              </Row>

            </Form>
          </Col>
        </Row>

        <div className="pt-3">
          <div className="bg-celeste p-3 rounded mb-4">
            <p className="mb-0">Tap a member to add to team</p>
          </div>
          <div className="text-right mb-3">
            <Checkbox handleCheckboxChange={handleCheckboxChange} select_option={members_selected} label="Select all" value="all" />
          </div>
          <div className="members-lists pb-5 row row-cols-lg-2 row-cols-1">
            {membmer_list}
          </div>
          <Row className="contenido-bottom-fixed-mb">
            <Col xs={{ size: 10, offset: 1 }} lg={{ size: 2, offset: 5}}>
              <button onClick={(e) => { e.preventDefault(); hanndleNextSection({ section: 'save-members' }) }} className="btn btn-primary text-uppercase btn-block rounded-pill">Finish</button>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { members } = state

  const search_members_list = getMembersForCurrentMembersList(state, props);
  const team_members = getCurrentTeamMembersList(state, props);

  return {
    members,
    team_members,
    search_members_list
  };
};

const mapDispatchToProps = {
  findMembers: membersListActions.findMembers,
  findMembersById: membersListActions.findMembersById,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberSearch);
