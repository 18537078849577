import { List , Map } from 'immutable';
import { teamsMembersListActions } from './actions';
import { TeamMembersList } from './team-members-list';
import { DEFAULT_SESSION_ID } from '../constants';


export const initialState = new Map({
  currentTeamMembersListId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new TeamMembersList({id: DEFAULT_SESSION_ID, isNew: false})
});


export function teamsMembersListsReducer(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {

    case teamsMembersListActions.FETCH_TEAM_MEMBERS_FAILED:
    case teamsMembersListActions.FETCH_TEAM_MEMBERS_SUCCESS:
    case teamsMembersListActions.FETCH_TEAM_MEMBERS_PENDING:
      return state.set(
        payload.teamsMembersListId,
        teamsMembersListReducer(state.get(payload.teamsMembersListId), action)
      );

    case teamsMembersListActions.LOAD_TEAM_MEMBERS:
      return state.merge({
        currentTeamMembersListId: payload.teamsMembersListId,
        [payload.teamsMembersListId]: teamsMembersListReducer(state.get(payload.teamsMembersListId), action)
      });
    default:
      return state;
  }
}

export const teamsMembersListReducer = (state = new TeamMembersList(),  {payload, type} ) => {

  switch (type) {
    case teamsMembersListActions.FETCH_TEAM_MEMBERS_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case teamsMembersListActions.FETCH_TEAM_MEMBERS_SUCCESS:
      return state.withMutations( teamsMemberslist => {
        teamsMemberslist.merge({
          isNew: false,
          isPending: false,
          items: new List(payload.data),
          itemsId: mergeIds(teamsMemberslist.itemsId, payload.data),
          update_at: new Date(),
        });
      });
    case teamsMembersListActions.FETCH_TEAM_MEMBERS_PENDING:
      return state.set('isPending', true);
    case teamsMembersListActions.LOAD_TEAM_MEMBERS:
      return state.merge({
        id: payload.teamsMembersListId,
      });
    default:
      return state;
  }
}

function mergeIds(itemsId, items) {
  let ids = itemsId.toJS();

  let newIds = items.reduce((list, itemData) => {
    if (ids.indexOf(itemData.user_id) === -1) list.push(itemData.user_id);
    return list;
  }, []);

  return newIds.length ? new List(ids.concat(newIds)) : itemsId;
}
