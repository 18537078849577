import { createSelector } from 'reselect';
import { getTeams } from '../teams/selectors';

export function getTeamsList(state) {
  return state.teams_lists;
}

export function getTeamsListById(state, teamsListId) {
  return getTeamsList(state).get(teamsListId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentTeamsList = createSelector(
  getTeamsList,
  teams_lists => teams_lists.get(teams_lists.get('currentTeamsListId'))
);

export const getTeamsForCurrentTeamsList = createSelector(
  getCurrentTeamsList,
  getTeams,
  ( teams_lists, teams) => {
    return teams_lists.itemsId.map( id => teams.get(`${id}`));
  }
);
