import React, { useState } from 'react';
import { Container, Row, Col, Media, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import LoginForm from './login-form';
import collaborative from '../../../assets/img/collaborative.svg';
import { HomeAuthFooter } from './HomeAuthFooter';
import check_primary from '../../../assets/img/blue-dot.svg'
import { Videos } from '../home/Videos';


export const HomeAuthPage = () => {
    const history = useHistory();
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const { videos } = useSelector(state => state);


    return (
        <div className="view overflow-hidden bg-white content-view-full w-100">
            <Container fluid={true}>
                <Row className="d-md-none">
                    <Col>
                        <div>
                            <Nav tabs>
                                <NavItem className="flex-fill text-center">
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' }, 'h6 mb-0')}
                                        onClick={() => { toggle('1'); }}
                                    >
                                        ORIENTATION
                                    </NavLink>
                                </NavItem>
                                <NavItem className="flex-fill text-center">
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' }, 'h6 mb-0')}
                                        onClick={() => { toggle('2'); }}
                                    >
                                        GET STARTED
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1" className="pt-3">
                                    <Row className="traditional-version">
                                        <Col sm="12">
                                            <div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={collaborative} width="90" className="mr-3" alt="collaborative" />
                                                    <h2 className="pt-2 rubik">Welcome</h2>
                                                </div>
                                                <p><b>An interactive tool for thinking well with those who think differently.</b></p>
                                                <p>The Collaborative Intelligence (CQ) Exploration Tool is a 20-minute discovery for improved communication, innovation, problem-solving and team dynamics.</p>
                                            </div>
                                            <Videos />
                                            <div>
                                                <p className="pt-2"><b>The initial assessment will help you discover:</b></p>
                                                <Media>
                                                    <Media left className="mr-2">
                                                        <Media object src={check_primary} width="20" />
                                                    </Media>
                                                    <Media body>
                                                        <p><b>Thinking Talents</b><br />The ways you think that make you unique.</p>
                                                    </Media>
                                                </Media>
                                                <Media>
                                                    <Media left className="mr-2">
                                                        <Media object src={check_primary} width="20" />
                                                    </Media>
                                                    <Media body>
                                                        <p><b>Mind Patterns</b><br />What your brain needs to excel at learning.</p>
                                                    </Media>
                                                </Media>
                                            </div>
                                        </Col>
                                    </Row>

                                </TabPane>
                                <TabPane tabId="2" className="pt-4">
                                    <Row>
                                        <Col sm="12">
                                            <div className="bg-white rounded pt-4 pb-1">
                                                <LoginForm history={history} />
                                            </div>
                                            <HomeAuthFooter />
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </div>
                    </Col>
                </Row>


                <Row className="d-none d-md-flex align-items-stretch">
                    <Col xs="7" className="bg-welcome-dk d-flex align-items-center">
                        <div className="traditional-version mt-5 pt-3 col-welcome-dk pl-xl-5 ml-auto">
                            <h3 className="mb-3 rubik">Collaborative<br />Intelligence (CQ) Inventory</h3>
                            <div className="d-flex align-items-start mb-3">
                                <img src={collaborative} width="100" className="mb-2 mr-3 mt-2" alt="Collaborative" />
                                <div className="mt-2">
                                    <p><b>An interactive tool for thinking well with those who think differently.</b><br />The Collaborative Intelligence (CQ) Exploration Tool is a 20-minute discovery for improved communication, innovation, problem-solving and team dynamics.</p>
                                </div>
                            </div>
                            {videos && videos.data && videos.data.length > 0 &&
                                <>
                                    <div className="encabezado mb-3">
                                        <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                            <h6 className="font-weight-normal d-none d-sm-none d-md-block mb-0 title-underline">Welcome Videos</h6>
                                        </div>
                                        <span className="border border-info d-none d-sm-none d-md-block"></span>
                                    </div>
                                    <Videos />
                                </>
                            }
                            <div className="encabezado mb-3">
                                <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                                    <h6 className="font-weight-normal d-none d-sm-none d-md-block mb-0 title-underline">The initial assessment will help you discover:</h6>
                                </div>
                                <span className="border border-info d-none d-sm-none d-md-block"></span>
                            </div>
                            <Row>
                                <Col xs="12">
                                    <Media>
                                        <Media left className="mr-2">
                                            <Media object src={check_primary} width="20" />
                                        </Media>
                                        <Media body>
                                            <p><b>Thinking Talents</b><br />The ways you think that make you unique.</p>
                                        </Media>
                                    </Media>
                                </Col>
                                <Col xs="12">
                                    <Media>
                                        <Media left className="mr-2">
                                            <Media object src={check_primary} width="20" />
                                        </Media>
                                        <Media body>
                                            <p><b>Mind Patterns</b><br />What your brain needs to excel at learning.</p>
                                        </Media>
                                    </Media>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                    <Col xs="5" className="bg-white col-login-form d-flex justify-content-start justify-content-xl-center flex-column">
                        <div className="mt-5 mx-auto pt-3 w-100">
                            <LoginForm history={history} />
                        </div>

                        <HomeAuthFooter />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
