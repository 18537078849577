import React, { useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Button, Container, Form, Input, Label, Col, Row, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { verifyCodeActions } from '../../../../core/sesion-verify/actions';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from '../../../components/loading-indicator';

const ConfirmRegister = ({ register, createVerify, verifySesionCode, resetVerify }) => {

    const [verificationCode, setVerificationCode] = useState('');
    const history = useHistory();
    const [disable, setDisabled] = useState(true);
    const [animationClass, setAnimationClass] = useState({
        appearActive: 'animated slideInRight',
        enter: 'animated',
        enterActive: 'animated slideInRight',
        exit: 'animated',
        exitActive: 'animated slideOutLeft',
    })

    useEffect(() => {
        verificationCode.length > 1 && register.id ?
            setDisabled(false) :
            setDisabled(true)
    }, [verificationCode])

    useEffect(() => {
        !verifySesionCode.isNew && verifySesionCode.success &&
            history.push('/welcome')

        return () => {
            resetVerify()
        }
    }, [verifySesionCode])

    const handleInputChange = async ({ target }) => {
        const { value } = target;
        setVerificationCode(value);
    }

    const onSubmitRegister = async (e) => {
        e.preventDefault();
        createVerify({ id: register.id, code: verificationCode })
    }

    return (
        <div className='view overflow-hidden bg-white'>
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='confirm' appear >
                    <div className="register-confirm-page">
                        <Container>
                            <div className="px-2 px-md-0 my-md-4">
                                <div className="content-view">
                                    <Row>
                                        <Col sm={{ size: 6, offset: 3 }}><h2 className="pl-3 pl-md-0">Register</h2></Col>
                                    </Row>
                                    <Form onSubmit={onSubmitRegister} className="form-login p-4 p-md-0 shadow rounded-lg bg-white">
                                        <Row>
                                            <Col sm={{ size: 6, offset: 3 }}>
                                                <FormGroup>
                                                    <Label for="code">Verification Code</Label>
                                                    <Input
                                                        type="text"
                                                        name="verificationCode"
                                                        id="code"
                                                        placeholder="Please enter your verification code"
                                                        value={verificationCode}
                                                        onChange={handleInputChange}
                                                    />
                                                </FormGroup>
                                                <Button color="primary" type="submit" disabled={disable} className="text-uppercase rounded-pill px-md-5 btn-block">Verify</Button>
                                            </Col>
                                        </Row>
                                    </Form>

                                    {
                                        !verifySesionCode.success &&
                                        <p>{verifySesionCode.error}</p>
                                    }
                                </div>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </TransitionGroup>

            {
                verifySesionCode.isPending &&
                <LoadingIndicator
                    className="text-center"
                    style={{ "marginTop": "15px", "display": "block" }}
                />
            }
        </div>
    )
}


const mapStateToProps = (state, props) => {
    const { register, verifySesionCode } = state;
    return {
        register,
        verifySesionCode
    };
};

const mapDispatchToProps = {
    createVerify: verifyCodeActions.createVerify,
    resetVerify: verifyCodeActions.resetVerify
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmRegister);

