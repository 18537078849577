import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import classNames from 'classnames';
import { List, Map } from 'immutable';

import logo from '../../../assets/img/true.logo.circle.png';

import LoadingIndicator from '../../components/loading-indicator'
import { MemberAdvancedSearch, MemberSearch, MemberDetail, WorkingWhithVaks, QuadrantsDetail } from '../../components/teams'
import { getCurrentTeam, teamActions } from '../../../core/teams'
import { membersListActions, getCurrentMembersList, getMembersForCurrentMembersList } from '../../../core/members-list'
import { questionsListActions } from '../../../core/questions-list'
import { getCurrentTeamMembersList, teamsMembersListActions } from '../../../core/teams-members-list'
import { teamsReportListActions } from '../../../core/teams-report'
import { DEFAULT_SESSION_ID } from '../../../core/constants'

import ReactGA from 'react-ga'
import { trackingActions } from '../../../core/tracking';

class EditPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      current_section: 'search',
      animationClass: {
        appearActive: 'animated slideInRight position-fixed',
        enter: 'animated',
        enterActive: 'animated slideInRight position-fixed',
        exit: 'animated',
        exitActive: 'animated slideOutLeft position-fixed',
      },
      filters: {
        first_name: '',
        last_name: '',
        email: '',
        function_value: '',
        thinking_talent_area: '',
        mind_pattern: '',
      },
      members_selected: new Set(),
      current_member_id: null,
      data_modal: null,
      show_modal: false,
      quadrant_name: null,


    }

    this.hanndleNextSection = this.hanndleNextSection.bind(this)
    this.toggleCheckbox = this.toggleCheckbox.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleModal = this.handleModal.bind(this)

  }


  componentWillMount() {
    const { team_id, team, loadTeam, loadTeamsReport, loadTeamMembers } = this.props;

    loadTeam(team_id);

    if (team_id !== DEFAULT_SESSION_ID && !team.name) {
      loadTeamsReport(team_id);
      loadTeamMembers(team_id);
    }

    this.props.loadQuestions()
    this.props.searchMembers({
      first_name: '',
      last_name: '',
      email: '',
      seniority_id: '',
      mind_pattern: ''
    });
  }

  handleModal() {

    const { show_modal } = this.state
    this.setState({ show_modal: !show_modal, data_modal: null })
  }

  hanndleNextSection({ section = 'search', filters = null, member_id = null, quadrant_name = null }) {
    const { members_selected } = this.state;
    const { team, searchMembers } = this.props;
    let data = {
      current_section: section,
      current_member_id: member_id,
      data_modal: null,
      show_modal: false,
      quadrant_name,
    }

    if (section === 'run-search') {
      data.current_section = 'search';
      searchMembers(filters)
      //do search
    }

    if (section === 'add-member') {
      data.current_section = 'search';
      data.current_member_id = null;

      if (!members_selected.has(member_id)) {
        members_selected.add(member_id);
        data.members_selected = members_selected;
      }
    }

    if (section === 'save-members') {
      data.current_section = 'search';
      this.props.addMembers({ items: members_selected, team_id: team.id });
      this.props.loadTeamsReport(this.props.team_id)
      this.props.history.push(`/teams/${team.id}`)
      ReactGA.event({
        category: 'Teams',
        action: 'Add members completed'
      })
      this.props.saveTrackingEvent({category: 'Teams',action: 'Add members completed'})
    }

    if (section === 'detail-member-vaks') {
      data.current_section = 'detail-member';
      data.data_modal = 'vaks';
      data.show_modal = true;
    }

    if (section === 'detail-member-talens') {
      data.current_section = 'detail-member';
      data.data_modal = 'talens';
      data.show_modal = true;
    }

    this.setState(data)
  }

  toggleCheckbox(member_id, name) {
    const { members_selected } = this.state
    const { members, team_members } = this.props

    if (members_selected.has(member_id)) {
      members_selected.delete(member_id);
      if (member_id === 'all') {
        members_selected.clear();
      }
      else if (members_selected.has('all')) {
        members_selected.delete('all')
      }
    }
    else {

      if (!team_members.itemsId.contains(member_id))
        members_selected.add(member_id);

      if (member_id === 'all') {
        //console.log('toggleCheckbox', members, members_selected, member_id, name )
        members.map(member => {
          if (!team_members.itemsId.contains(member.id))
            members_selected.add(member.id)

          return;
        })
      }
    }

    this.setState({ members_selected: members_selected })
  }

  handleBack(e) {
    e.preventDefault();
    const { current_section } = this.state;
    current_section === 'advanced-search' ?
      this.setState({ current_section: 'search' }) :
      this.props.history.goBack()
  }

  render() {
    const { animationClass, current_section, members_selected, current_member_id, show_modal, data_modal, quadrant_name } = this.state;
    const { displayLoadingIndicator, team, members, quadrants, questions, user } = this.props;

    if (user.checked && !user.id)
      return <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />;

    let content_modal = null
    let content = <MemberSearch
      key={members_selected.has('all') ? '1' : '0'}
      hanndleNextSection={this.hanndleNextSection}
      members={members}
      handleCheckboxChange={this.toggleCheckbox}
      members_selected={members_selected}
    />
    if (current_section === 'advanced-search')
      content = <MemberAdvancedSearch hanndleNextSection={this.hanndleNextSection} />
    else if (current_section === 'detail-member') {
      const member = members.find(item => item.id === current_member_id);
      content = <MemberDetail member={member} isMember={false} quadrants={quadrants} hanndleNextSection={this.hanndleNextSection} />

      if (data_modal === 'vaks') {
        content_modal = <WorkingWhithVaks member={member} />
      }
      if (data_modal === 'talens') {
        content_modal = <QuadrantsDetail questions={questions} items={member.talents}
          quadrant_name={quadrant_name} quadrants={quadrants} />
      }

    }

    return (
      <div className="view overflow-hidden">
        <TransitionGroup className="content">
          <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key={current_section} appear >
            <div className="w-100">
              <Container>
                <div className="bg-white my-md-4 rounded-lg pb-4 pb-md-5 px-md-5 pt-md-2 content-rearrenge content-view">
                  <Row className="px-md-4">
                    <Col md={{ size: 12 }}>
                      <div className="position-relative d-flex justify-content-between align-items-md-center">
                        <a href="#" onClick={e => this.handleBack(e)} className="icon-back"><FontAwesomeIcon icon={faArrowLeft} /></a>
                        <div className="d-none d-sm-none d-md-block mb-md-3 encabezado flex-fill">
                          <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                            <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">{team.name}</h6>
                          </div>
                          <span className="border border-info d-block"></span>
                        </div>
                        <h1 className="text-center text-md-left text-reset d-md-none">{team.name}</h1>
                      </div>
                      <div className="mb-md-4 rounded-lg pb-4 content-start content-view">
                        {content}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {displayLoadingIndicator && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />}
            </div>
          </CSSTransition>
        </TransitionGroup>

        <Modal isOpen={show_modal} toggle={this.handleModal} className="modal-dialog-summary float-right modal-lg">
          <ModalHeader toggle={this.handleModal}><span className="d-flex justify-content-center bd-highlight"><img src={logo} height="48" alt="True Advance" /></span></ModalHeader>
          <ModalBody>
            {content_modal}
          </ModalBody>
        </Modal>

      </div>
    );
  }
}
//export default ThinkingTalentsPage;
//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {

  const { session, quadrants, questions, user } = state
  const team = getCurrentTeam(state, props);
  const members_list = getCurrentMembersList(state, props);
  const members = getMembersForCurrentMembersList(state, props);
  const team_members = getCurrentTeamMembersList(state, props);
  const team_id = props.match.params.id ? props.match.params.id : DEFAULT_SESSION_ID;

  return {
    user,
    team_id,
    team,
    members,
    quadrants,
    team_members,
    questions,
    displayLoadingIndicator: team.isPending || members_list.isPending || false,
  };
};

const mapDispatchToProps = {
  searchMembers: membersListActions.searchMembers,
  loadQuestions: questionsListActions.loadQuestions,
  addMembers: teamActions.addMembers,
  loadTeamsReport: teamsReportListActions.loadTeamsReport,
  loadTeam: teamActions.loadTeam,
  loadTeamMembers: teamsMembersListActions.loadTeamMembers,
  saveTrackingEvent: trackingActions.saveTrackingEvent
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPage);
