
export const communityActions = {

  FETCH_COMMUNITY_FAILED: 'FETCH_COMMUNITY_FAILED',
  FETCH_COMMUNITY_SUCCESS: 'FETCH_COMMUNITY_SUCCESS',
  FETCH_COMMUNITY_PENDING: 'FETCH_COMMUNITY_PENDING',
  SEARCH_MEMBERS_BY_NAME: 'SEARCH_MEMBERS_BY_NAME',
  LAST_SEARCH: 'LAST_SEARCH',
  SEARCH_MEMBERS_BY_FILTERS: 'SEARCH_MEMBERS_BY_FILTERS',

  fetchCommunityFailed: error => ({
    type: communityActions.FETCH_COMMUNITY_FAILED,
    payload: error
  }),

  fetchCommunitySuccess: (id, data) => ({
    type: communityActions.FETCH_COMMUNITY_SUCCESS,
    payload: {
      data,
      id
    }
  }),

  fetchCommunityPending: () => ({
    type: communityActions.FETCH_COMMUNITY_PENDING,
  }),

  searchMembers: (search, page) => ({
      type: communityActions.SEARCH_MEMBERS_BY_NAME,
      payload: {
        search, 
        page
      },
  }),

  searchMemberByFilters: (filters, page) => ({
    type: communityActions.SEARCH_MEMBERS_BY_FILTERS,
    payload: {
        filters, 
        page
      },
  })
};


export const communityRequestActions = {
  failed: communityActions.fetchCommunityFailed,
  success: communityActions.fetchCommunitySuccess,
  pending: communityActions.fetchCommunityPending
};
