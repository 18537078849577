import { call, fork, select, takeLatest } from 'redux-saga/effects';

import { fetchReportTeam } from '../api';
import { teamsReportListActions } from './actions';

import { getTeamsReportById } from './selectors';

export function* loadTeamsReportList({payload}) {
  const { teamsReportId } = payload;
  const teamsReportList = yield select(getTeamsReportById, teamsReportId);

  //if (!teamsReportList || !teamsReportList.isPending)
    yield call(fetchReportTeam, teamsReportId);

}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadTeamsReportList() {
  yield takeLatest(teamsReportListActions.LOAD_TEAMS_REPORT, loadTeamsReportList);
}


//=====================================
//  ROOT
//-------------------------------------

export const teamsReportListSagas = [
  fork(watchLoadTeamsReportList),
];
