import { DEFAULT_SESSION_ID } from "../constants";

export const registerActions = {

    FETCH_REGISTER_FAILED: 'FETCH_REGISTER_FAILED',
    FETCH_REGISTER_SUCCESS: 'FETCH_REGISTER_SUCCESS',
    FETCH_REGISTER_PENDING: 'FETCH_REGISTER_PENDING',
    CREATE_USER: 'CREATE_USER',
    RESET_ERROR: "RESET_ERROR",


    fetchRegisterFailed: error => ({
        type: registerActions.FETCH_REGISTER_FAILED,
        payload: { error }
    }),

    fetchRegisterSuccess: (userId, data) => ({
        type: registerActions.FETCH_REGISTER_SUCCESS,
        payload: {
            data,
            userId
        }
    }),

    fetchRegisterPending: userId => ({
        type: registerActions.FETCH_REGISTER_PENDING,
        payload: {
            userId
        }
    }),

    createUser: data => ({
        type: registerActions.CREATE_USER,
        payload: {
            data,
            userId: DEFAULT_SESSION_ID
        }
    }),

    resetError: () => ({
        type: registerActions.RESET_ERROR
    })
};


export const registerRequestActions = {
    failed: registerActions.fetchRegisterFailed,
    success: registerActions.fetchRegisterSuccess,
    pending: registerActions.fetchRegisterPending
};
