import { call, fork, select, takeEvery } from 'redux-saga/effects';

import { fetchSystem } from '../api';

import { systemActions } from './actions';
import { getSystemById } from './selectors';

export function* loadSystem({payload}) {
  const { systemId } = payload;

  const system = yield select(getSystemById, systemId);

  if (system.isNew)
    yield call(fetchSystem, systemId);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadSystem() {
  yield takeEvery(systemActions.LOAD_SYSTEM, loadSystem);
}

//=====================================
//  ROOT
//-------------------------------------

export const systemSagas = [
  fork(watchLoadSystem),
];
