import React from 'react';
import { useSelector } from 'react-redux';

export const ThinkingTalentsReportUserItem = ({ item, color, isShadowTalents }) => {
    const { talents } = useSelector(state => state.user);

    const hasItemUser = talents.filter(talent => talent.items.find(i => i.name === item.item.name)).length > 0;

    const backgroundColorReferences = hasItemUser && item.item.energy_level === 1 ? "energy-level-always" :
        !hasItemUser && item.item.energy_level === 3 && "energy-level-never";

    return (
        <div className={`talents-item text-center mb-2`} >
            <div className={`rounded mx-1 p-2 ${backgroundColorReferences}`}>
            <i className="icon">
                <img src={item.item.icon_invert} className="media-object" width="50" height="50" alt={item.name}/>
            </i>

            <p className="mb-0 text-reset small"><span className="text-uppercase font-weight-semibold">{item.item.name}</span><br/>
             {!isShadowTalents ? item.item.light_talent : item.item.shadow_talent}
            </p>
            </div>
        </div>
    )
}
