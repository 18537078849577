import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import graphic_report from '../../../../assets/img/graphic-report-sm.jpg';
import {
    QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL,
    QUADRANTS_PROCEDURAL, QUADRANTS_ALL
} from '../../../../core/constants';
import { questionsListActions } from '../../../../core/questions-list';
import { ModalReportTT } from './ModalReportTT';
import { QuadrantItem } from './QuadrantItem';
import { QuadrantList } from './QuadrantList';
import { QuadrantListCollapsed } from './QuadrantListCollapsed';

export const ReportTT = ({ questions_list, loadQuestions, version, user_finded, collapsed = false }) => {
    const dispatch = useDispatch()
    const { questions } = useSelector(state => state)

    useEffect(() => {
        if (questions && !questions.isPending && !questions.id) dispatch(questionsListActions.loadQuestions())
    }, [])

    questions_list = useSelector(state => state.questions)

    const [modal, setModal] = useState(false);
    const [current_item, setCurrentItem] = useState(null);
    let image_bg = useRef();

    const { user } = useSelector(state => state);

    const [lengthItems, setLengthItems] = useState({
        Analytic: 0,
        Innovative: 0,
        Procedural: 0,
        Relational: 0,
        All: 0
    })

    useEffect(() => {
        if (!user_finded) {
            user.talents && user.talents.forEach(quadrant => {
                setValue(quadrant.quadrant, quadrant.items.length)
            })
        }
    }, [user.talents])

    useEffect(() => {
        if (user_finded) {
            user_finded.talents && user_finded.talents.forEach(quadrant => {
                setValue(quadrant.quadrant, quadrant.items.length)
            })
        }
    }, [user_finded])

    const setValue = (name, value) => {
        setLengthItems({
            ...lengthItems,
            [name]: value
        })
    }

    const toggle = (key) => {
        setModal(!modal)
        setCurrentItem(questions_list.quadrants.find(item => item.name === key))
    }

    return (
        <>
            <div className={`graphic-map graphic-map-sm mx-auto ${version ? ('version-' + version) : ''}`} style={{minHeight: `${collapsed ? '90px' : ''}`}}>
                <div className={`graphic-map-list overflow-hidden ${version ? 'mx-n3 mx-md-n2' : 'mx-auto'}`}>
                    {/* home */}
                    {!collapsed && <QuadrantList version={version} user={user_finded ? user_finded : user} toggle={toggle} image_bg={image_bg} />}
                    {/* comumnity */}
                    {collapsed && <QuadrantListCollapsed user={user_finded ? user_finded : user} />}
                </div>
                    {!collapsed &&
                    <ModalReportTT
                        modal={modal}
                        toggle={toggle}
                        current_item={current_item}
                        user={user_finded ? user_finded : user}
                    />}
            </div >
        </>
    )
}

export default ReportTT