import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card } from 'reactstrap';
import MindPatternsHome from './home/mindPatterns/mindPatternsHome';
import TeamsHome from './home/teams/teamsHome';
import ThinkingTalentsHome from './home/thinkingTalents/thinkingTalentsHome';
import { NewMemberDiscovery } from './home/questionnaires/NewMemberDiscovery';
import icon_search from '../../assets/img/search-community.svg'
import { SimpleSlider } from '../components/common/SimpleSlider';
import { BoostYourGrowth } from '../components/boost-your-growth/BoostYourGrowth';

export const HomeOneColumnPage = ({ layoutformat, oneColumn }) => {

    const { user } = useSelector(state => state);
    const systemCheck = useSelector(state => state?.systemCheck?.data?.data)
    const sliders = useSelector(state => state?.user?.account?.home_slides)
    const thinking_talents_on = systemCheck?.thinking_talents_on
    const mind_patterns_on = systemCheck?.mind_patterns_on
    const teams_on = systemCheck?.teams_on

    return (
        <Row className={`${layoutformat} justify-content-center`}>
            <>
                {sliders?.length > 0 &&
                    <Col xl="12" className="mt-3">
                        <Card className="p-4 mb-4 mb-xl-0">
                            <SimpleSlider dots={true} arrows={false} dotsClass="dots-on-home d-flex align-items-center justify-content-center pt-2 mb-n3" oneColumn={oneColumn} items={sliders} ></SimpleSlider>
                        </Card>
                    </Col>
                }

                {user.id && !user.isPending && user.account && user.account.community_enabled &&
                    <Card className="mx-3 py-1 mb-4 mb-xl-0 mt-3 w-100">
                        <a href="community" className="text-decoration-none">
                            <div className="encabezado encabezado-icono">
                                <div className="mb-0 pt-2 pb-1 d-flex align-items-center justify-content-center">
                                    <img src={icon_search} width="40" height="auto" alt="Thinking Talents" style={{ maxHeight: '40px' }} />
                                    <h5 className="mb-0 mx-3 font-weight-semibold text-black">Member Search</h5>
                                </div>
                            </div>
                        </a>
                    </Card>
                }

                {user.id && !user.isPending &&
                    <BoostYourGrowth />
                }

                {!user.isPending && thinking_talents_on && <ThinkingTalentsHome oneColumn={oneColumn} />}
                {mind_patterns_on && <MindPatternsHome oneColumn={oneColumn} />}

                {user.id && !user.isPending && user.account.questionnaires_count > 0 && <NewMemberDiscovery oneColumn={oneColumn} />}

                {
                    user.is_manager === 1 && teams_on &&
                    <TeamsHome oneColumn={oneColumn} />
                }
            </>
        </Row>
    )
}


