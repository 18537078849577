import React, { Component, useState } from 'react'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col, Form, FormGroup } from 'reactstrap';
import { NavLink as Link} from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";

import classNames from 'classnames';
import { List } from 'immutable';

import { SYSTEM_ID_MINDPATTERNS, SYSTEM_ID_SENIORITIES } from '../../../core/constants'
import { getSystemById, systemActions } from '../../../core/system'


class AdvancedSearch extends Component{

  constructor(props) {
    super(props)

    this.state = {
      filters: {
        first_name:'',
        last_name:'',
        email:'',
        seniority_id:'',
        quadrant_id: '',
        talent_id:'',
        mind_pattern:'',
      }
    }

    this.cleanFilters = this.cleanFilters.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)

  }

  componentWillMount() {
    this.props.loadSystem(SYSTEM_ID_MINDPATTERNS)
    this.props.loadSystem(SYSTEM_ID_SENIORITIES)
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  componentDidUpdate(oldProps,oldState)
  {

  }

  cleanFilters(){
    this.setState({
      filters: {
        first_name:'',
        last_name:'',
        email:'',
        seniority_id:'',
        quadrant_id: '',
        talent_id:'',
        mind_pattern:'',
      }
    });
  }

  handleInputChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked :  target.multiple ?  Array.from(target.selectedOptions, option => option.value) : target.value;
    const name = target.name;
    const { filters } = this.state
    const new_filters = Object.assign({}, filters, {
      [name]: value
    })

    this.setState({
      filters:new_filters
    });
  }

  render() {
    const { hanndleNextSection, mindpatterns, seniorities, quadrants, questions } = this.props
    const { filters } = this.state

    const list_seniorities = seniorities ? seniorities.items.map( item => <option key={item.id} value={item.id}>{item.name}</option>) : null;
    const list_mindpatterns = mindpatterns ? mindpatterns.items.map( item => <option key={item.id} value={item.id}>{item.name}</option>) : null;
    const list_quadrants = quadrants ? quadrants.valueSeq().map( item => <option key={item.id} value={item.id}>{item.name}</option>) : null;

    const list_questions = questions ? questions.items.map( item => {
        if (filters.quadrant_id && filters.quadrant_id !== `${item.quadrant.id}`)
          return null;

        return <option key={item.id} value={item.id}>{item.name}</option>
      }) : null;


    return (
      <>
        <div className="text-center"><p>Add Member</p></div>
        <Row noGutters>
          <Col xs={{size: 10, offset: 1}}>
            <Form>
            <Row form className="pb-md-5">
               <Col md={4}>
                <FormGroup>
                  <label htmlFor="first_name"><small>First Name</small></label>
                  <input type="text" name="first_name" onChange={this.handleInputChange} value={ filters.first_name } className="form-control" />
                </FormGroup>
              </Col>
              <Col md={4}>
              <FormGroup>
                <label htmlFor="last_name"><small>Last Name</small></label>
                <input type="text" name="last_name" onChange={this.handleInputChange} value={ filters.last_name } className="form-control" />
              </FormGroup>
              </Col>
              <Col md={4}>
              <FormGroup>
                <label htmlFor="email"><small>Email</small></label>
                <input type="text" name="email" onChange={this.handleInputChange} value={ filters.email } className="form-control" />
              </FormGroup>
              </Col>
              <Col md={4}>
              <FormGroup>
                <label htmlFor="seniority_id"><small>Role</small></label>
                <select name="seniority_id" onChange={this.handleInputChange} value={ filters.seniority_id }  className="form-control">
                <option value="">Select</option>
                {list_seniorities}
                </select>
              </FormGroup>
              </Col>
              <Col md={4}>
              <FormGroup>
                <label htmlFor="quadrant_id"><small>Thinking Talent</small></label>
                <select name="quadrant_id" onChange={this.handleInputChange} value={ filters.quadrant_id }  className="form-control">
                <option value="">Select</option>
                {list_quadrants}
                </select>
              </FormGroup>

              <FormGroup>
                <select name="talent_id" onChange={this.handleInputChange} value={ filters.talent_id }  className="form-control">
                <option value="all">All</option>
                {list_questions}
                </select>
              </FormGroup>

              </Col>
              <Col md={4}>
              <FormGroup>
                <label htmlFor="mind_pattern"><small>Mind Pattern</small></label>
                <select name="mind_pattern" onChange={this.handleInputChange} value={ filters.mind_pattern }  className="form-control">
                <option value="">Select</option>
                {list_mindpatterns}
                </select>
              </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={{size: 8, offset: 2}}>
              <div className="d-flex flex-md-column justify-content-between">
                <div className="flex-fill pr-3 pr-md-0 mb-md-4">
                  <button onClick={ (e) => { e.preventDefault(); this.cleanFilters() } } className="btn btn-outline-primary text-uppercase btn-block rounded-pill px-4">Clear</button>
                </div>
                <div className="flex-fill w-100">
                  <button onClick={ (e) => { e.preventDefault(); hanndleNextSection({ section:'run-search',filters}) } } className="btn btn-primary text-uppercase btn-block rounded-pill">Search</button>
                </div>
              </div>
              </Col>
            </Row>

          </Form>
          </Col>
          </Row>

      </>
    )
  }
}

const mapStateToProps = (state, props) => {

  const { quadrants, questions } = state
  const mindpatterns = getSystemById(state, SYSTEM_ID_MINDPATTERNS);
  const seniorities = getSystemById(state, SYSTEM_ID_SENIORITIES);

  return {
    mindpatterns,
    seniorities,
    quadrants,
    questions
  };
};

const mapDispatchToProps = {
  loadSystem: systemActions.loadSystem,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedSearch);
