import { List , Map } from 'immutable';
import { list_options } from '../constants';
import { userDocumentsActions } from './actions';
import { userDocuments } from './userDocuments';
import { userActions } from '../users';


export const userDocumentsReducer = (state = userDocuments, { payload, type }) => {
    switch (type) {
        case userDocumentsActions.FETCH_USER_DOCUMENTS_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload.error
            };

        case userDocumentsActions.FETCH_USER_DOCUMENTS_SUCCESS:
            return {
                ...state,
                isPending: false,
                files: (payload.data && payload.data.blob) ? setNewFile(payload.id, payload.data.blob, state.files, state.data) : state.files
            };
        case userDocumentsActions.FETCH_USER_DOCUMENTS_PENDING:
            return {
                ...state,
                isPending: true
            }
        case userActions.FETCH_USER_SUCCESS:
            return {
                ...state,
                data: payload.data.documents
            }
        default:
            return state;
    }
}

function setNewFile(id, blob, files, data) {
    let listFiles = files
    let index = files.findIndex(document => document.id === id)
    let index_data = data.findIndex(document => document.id === id)
    let newFile = new File([blob], data[index_data].file_name)

    if(index === -1)
        listFiles.push({id, file: newFile})
    else listFiles[index].file = newFile

    return listFiles
}