import { Map, List } from 'immutable';
import { teamActions } from './actions';
import { Team, createTeam } from './team';
import { DEFAULT_SESSION_ID } from '../constants';

import { teamsListActions } from '../teams-list';
import { teamsReportListActions } from '../teams-report';


export const initialState = new Map({
  currentTeamId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new Team({ id: DEFAULT_SESSION_ID, isNew: false })
});

export function teamsReducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {

    case teamsReportListActions.FETCH_TEAMS_REPORT_SUCCESS:

      return state.withMutations(teams => {
        teams.set(`${payload.data.id}`, createTeam(payload.data));
      });
    case teamsListActions.FETCH_TEAMS_SUCCESS:
      return state.withMutations(teams => {
        payload.data.forEach(teamData => {
          teams.set(`${teamData.id}`, createTeam(teamData));
        });
      });

    case teamActions.FETCH_TEAM_FAILED:
      if (payload.team_id)
        return state.set(
          payload.team_id,
          teamReducer(state.get(payload.team_id), action)
        );
      return state;
    case teamActions.FETCH_DEFAULT_TEAM_PENDING_FALSE:
    case teamActions.FETCH_TEAM_PENDING:
      return state.set(
        payload.team_id,
        teamReducer(state.get(payload.team_id), action)
      );
    case teamActions.CREATE_TEAM:
    case teamActions.UPDATE_TEAM:
    case teamActions.FETCH_TEAM_SUCCESS:
    case teamActions.LOAD_TEAM:
      return state.merge({
        currentTeamId: payload.team_id,
        [payload.team_id]: teamReducer(state.get(payload.team_id), action)
      });
    default:
      return state;
  }
}

export const teamReducer = (state = new Team(), { payload, type }) => {
  switch (type) {
    case teamActions.FETCH_TEAM_SUCCESS:

      if (!payload.data)
        return state;

      return state.withMutations(team => {
        team
          .merge({
            isNew: false,
            isPending: false,
            id: !team.id ? payload.data.id : team.id,
            name: payload.data.name,
            owner_id: payload.data.owner_id,
            members: payload.data.members,
            update_at: new Date(),
            error: null,
            mind_pattern: payload.data.mind_pattern,
            mind_pattern_first: payload.data.mind_pattern_first,
            mind_pattern_second: payload.data.mind_pattern_second,
            mind_pattern_third: payload.data.mind_pattern_third,
            mind_pattern_p1: payload.data.mind_pattern_p1,
            mind_pattern_p2: payload.data.mind_pattern_p2,
            mind_pattern_p3: payload.data.mind_pattern_p3,
          });
      });
    case teamActions.FETCH_TEAM_FAILED:
      return state.withMutations(team => {
        team.merge({
          isNew: false,
          isPending: false,
          update_at: new Date(),
          error: payload.data
        });
      });
    // return state.set('isPending', false);
    case teamActions.RESET_TEAM:
      return state.withMutations(team => {
        team.merge({
          id: null,
          isNew: true,
          isPending: false,
          name: null,
          owner_id: null,
          members: 0,
          update_at: new Date(),
          error: null,
          mind_pattern: '',
          mind_pattern_first: null,
          mind_pattern_second: false,
          mind_pattern_third: false,
          mind_pattern_p1: null,
          mind_pattern_p2: null,
          mind_pattern_p3: null
        });
      });
    case teamActions.FETCH_DEFAULT_TEAM_PENDING_FALSE:
      return state.set('isPending', false);
    case teamActions.FETCH_TEAM_PENDING:
      return state.set('isPending', true);
    case teamActions.CREATE_TEAM:
    case teamActions.UPDATE_TEAM:
    case teamActions.LOAD_TEAM:
      return state.set('id', Number(payload.team_id) || payload.team_id);
    default:
      return state;
  }
}
