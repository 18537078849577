export const teamsFullReportListActions = {

  FETCH_TEAMS_FULL_REPORT_FAILED: 'FETCH_TEAMS_FULL_REPORT_FAILED',
  FETCH_TEAMS_FULL_REPORT_SUCCESS: 'FETCH_TEAMS_FULL_REPORT_SUCCESS',
  FETCH_TEAMS_FULL_REPORT_PENDING: 'FETCH_TEAMS_FULL_REPORT_PENDING',
  LOAD_TEAMS_FULL_REPORT: 'LOAD_TEAMS_FULL_REPORT',


  fetchTeamsFullReporFailed: error => ({
    type: teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_FAILED,
    payload: error
  }),

  fetchTeamsFullReporSuccess: (teamsFullReportId, data) => ({
    type: teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_SUCCESS,
    payload: {
      data,
      teamsFullReportId
    }
  }),

  fetchTeamsFullReporPending: teamsFullReportId => ({
    type: teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_PENDING,
    payload: {
      teamsFullReportId
    }
  }),

  loadTeamsFullRepor: (team_id) => ({
    type: teamsFullReportListActions.LOAD_TEAMS_FULL_REPORT,
    payload: {
      teamsFullReportId: team_id
    }
  }),
};

export const teamsFullReportListRequestActions = {
  failed: teamsFullReportListActions.fetchTeamsFullReporFailed,
  success: teamsFullReportListActions.fetchTeamsFullReporSuccess,
  pending: teamsFullReportListActions.fetchTeamsFullReporPending
};
