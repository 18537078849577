export const userDocumentsActions = {

    FETCH_USER_DOCUMENTS_FAILED: 'FETCH_USER_DOCUMENTS_FAILED',
    FETCH_USER_DOCUMENTS_SUCCESS: 'FETCH_USER_DOCUMENTS_SUCCESS',
    FETCH_USER_DOCUMENTS_PENDING: 'FETCH_USER_DOCUMENTS_PENDING',
    LOAD_USER_DOCUMENTS: 'LOAD_USER_DOCUMENTS',
    LOAD_USER_DOCUMENT_BY_ID: 'LOAD_USER_DOCUMENT_BY_ID',
    SET_USER_DOCUMENT_DOWNLOADED: 'SET_USER_DOCUMENT_DOWNLOADED',

    fetchUserDocumentsFailed: error => ({
        type: userDocumentsActions.FETCH_USER_DOCUMENTS_FAILED,
        payload: error
    }),

    fetchUserDocumentsSuccess: (id, data) => ({
        type: userDocumentsActions.FETCH_USER_DOCUMENTS_SUCCESS,
        payload: {
            data,
            id
        }
    }),

    fetchUserDocumentsPending: () => ({
        type: userDocumentsActions.FETCH_USER_DOCUMENTS_PENDING,
    }),

    loadUserDocuments: () => ({
        type: userDocumentsActions.LOAD_USER_DOCUMENTS,
    }),

    loadUserDocumentById: (id) => ({
        type: userDocumentsActions.LOAD_USER_DOCUMENT_BY_ID,
        payload: {
            id
        }
    }),

    setUserDocumentDownloaded: (id) => ({
        type: userDocumentsActions.SET_USER_DOCUMENT_DOWNLOADED,
        payload: {
            id
        }
    })

};

export const userDocumentsRequestActions = {
    failed: userDocumentsActions.fetchUserDocumentsFailed,
    success: userDocumentsActions.fetchUserDocumentsSuccess,
    pending: userDocumentsActions.fetchUserDocumentsPending
};
