import { createSelector } from 'reselect';


export function getTeams(state) {
  return state.teams;
}

export function getTeamById(state, teamId) {  
  return getTeams(state).get(teamId);
}


//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentTeam = createSelector(
  getTeams,
  teams => teams.get(teams.get('currentTeamId'))
);
