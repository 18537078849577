export const systemCheckActions = {

    FETCH_SYSTEMCHECK_FAILED: 'FETCH_SYSTEMCHECK_FAILED',
    FETCH_SYSTEMCHECK_SUCCESS: 'FETCH_SYSTEMCHECK_SUCCESS',
    FETCH_SYSTEMCHECK_PENDING: 'FETCH_SYSTEMCHECK_PENDING',
    LOAD_SYSTEMCHECK: 'LOAD_SYSTEMCHECK',
    RESET_SYSTEMCHECK: 'RESET_SYSTEMCHECK',

  
    fetchSystemCheckFailed: error => ({
      type: systemCheckActions.FETCH_SYSTEMCHECK_FAILED,
      payload: error
    }),
  
    fetchSystemCheckSuccess: (systemId, data) => ({
      type: systemCheckActions.FETCH_SYSTEMCHECK_SUCCESS,
      payload: {
        data,
        systemId
      }
    }),
  
    fetchSystemCheckPending: systemId => ({
      type: systemCheckActions.FETCH_SYSTEMCHECK_PENDING,
      payload: {
        systemId
      }
    }),

    loadSystemCheck: (data) => ({
      type: systemCheckActions.LOAD_SYSTEMCHECK,
      payload: {
        data
      }
    }),
  };
  
  export const systemCheckRequestActions = {
    failed: systemCheckActions.fetchSystemCheckFailed,
    success: systemCheckActions.fetchSystemCheckSuccess,
    pending: systemCheckActions.fetchSystemCheckPending
  };
  