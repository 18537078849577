import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  state = {
      isChecked: false,
      isHover: false,
  }

  toggleCheckboxChange = () => {
      const { handleCheckboxChange, value, name } = this.props;

      this.setState(({ isChecked }) => ({
          isChecked: !isChecked,
      }));

      handleCheckboxChange(value, name);
  }



  render() {

    const { label, value, select_option } = this.props;
    const isChecked = select_option.has(value)

    return (
      <>
      <div className= {  `checkbox item-${value} ${(isChecked? 'active' :'')}` } >
        <label>
          <input type="checkbox"
            value={value}
            defaultChecked={isChecked}
            onChange={this.toggleCheckboxChange}
            autoComplete="off"
          />
          <span><i className="icon-check"></i></span>
          { label ? `${label}` : '' }
        </label>
      </div>
      </>
    );
  }
}

Checkbox.propTypes = {
  handleCheckboxChange: PropTypes.func.isRequired,
};

export default Checkbox;
