import React, { Component, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';
import { NavLink as Link} from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";

import classNames from 'classnames';
import { List } from 'immutable';


class Admin extends Component{

  constructor(props) {
    super(props)

    this.state = {
      activeTab: '1',
      old_group_item: null,
      new_group_item: null,

    }

    this.toggle = this.toggle.bind(this);
    this.moveToGropu = this.moveToGropu.bind(this);
    this.onExitTransition = this.onExitTransition.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    // console.log('the scroll things', event)
  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if ( activeTab !== tab )
      this.setState({ activeTab: tab })
  }

  moveToGropu = ( item_id,value ) => {
    const { response_list, hanndleResponse } = this.props
    const old_group_item = response_list.get(item_id)
    const new_group_item = { item_id,value }
    this.setState({ old_group_item, new_group_item })
    //console.log('moveToGropu', old_group_item, new_group_item)
  }

  onExitTransition = (e) =>{
    const { response_list, hanndleResponse } = this.props
    const { old_group_item, new_group_item } = this.state
    if (new_group_item){
      this.setState({ new_group_item:null, activeTab: new_group_item.value })
      hanndleResponse( new_group_item.item_id, new_group_item.value)
    }
    //console.log('onExitTransition',old_group_item, new_group_item, e)
  }

  componentDidUpdate(oldProps,oldState)
  {
    const { hanndleResponse } = this.props
    const { old_group_item, new_group_item } = this.state
    if (!new_group_item && oldState.new_group_item) {

      this.setState({ old_group_item:null })
    }
  }

  render() {

    const { activeTab } = this.state;

    return (
      <div className="bg-white my-md-4 rounded-lg pb-4 p-md-5 content-rearrenge content-view">
        <Row>
          <Col md={{ size: 12 }}>
          <a href="#" className="icon-quest"></a>
          <h1 className="px-4 px-md-4 mb-md-4 text-center text-md-left">Teams</h1>

          <Nav className="mb-2" tabs>
            <NavItem className="flex-xs-fill">
              <NavLink className={classNames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                My Teams
              </NavLink>
            </NavItem>
            <NavItem className="flex-xs-fill">
              <NavLink className={classNames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                 All Teams
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="pb-5">
            <TabPane tabId="1">
              <div className="header-group d-flex justify-content-between mb-md-4 pt-md-2">
                <Link to="/teams/create" className="btn text-uppercase btn-block rounded-pill btn-outline-primary">Create First Team</Link>
              </div>
              <div className="overflow-hidden">
                <TransitionGroup >

                </TransitionGroup>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="header-group d-flex justify-content-between mb-md-4 pt-md-2">
                <p>all</p>
              </div>
              <div className="overflow-hidden">
                <TransitionGroup>

                </TransitionGroup>
              </div>
             </TabPane>
          </TabContent>

          </Col>
        </Row>
      </div>
    )
  }
}

export default Admin;
