import { call, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchSystemCheck } from '../api'
import { systemCheckActions } from './actions';

export function* systemCheck({ payload }) {
    yield call(fetchSystemCheck, payload.systemId);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchSystemCheck() {
    yield takeLatest(systemCheckActions.LOAD_SYSTEMCHECK, systemCheck);
}

//=====================================
//  ROOT
//-------------------------------------

export const systemCheckSagas = [
    fork(watchSystemCheck)
];