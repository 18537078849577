import { call, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchMindPattersStoreMP } from '../api';
import { mindPatternsStoreMPActions } from './actions';

export function* saveMindPatternsStoreMP({ payload }) {

  yield call(fetchMindPattersStoreMP, payload.data);

}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchMindPatternsStoreMP() {
  yield takeLatest(mindPatternsStoreMPActions.SAVE_MINDPATTERNS_STORE_MP, saveMindPatternsStoreMP);
}

//=====================================
//  ROOT
//-------------------------------------

export const mindPatternsStoreMPSagas = [
  fork(watchMindPatternsStoreMP)
];
