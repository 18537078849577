import axios from 'axios';
import session from '../session';
import {
  HOST_DEV,
  API_KEY,
  API_SESSION,
  API_THINKINGTALENTS,
  API_TEAMS,
  API_MEMBERS,
  API_SYSTEM,
  API_MINDPATTERNS,
  API_QUESTIONNAIRE,
  API_BASE_URL_V,
  API_VIDEOS,
  API_COMMUNITY,
  API_USER,
  API_USER_PROFILE,
  API_USER_ACTIVITY,
  API_USER_DOCUMENTS,
  API_REPORTS
} from '../constants';
import { downloadPDF } from '../helper';
import ReactGA from 'react-ga';

export const api = {

  async fetchLogin({ email, password }) {
    let res = await dispatch({
      method: "post",
      url: `${API_SESSION}/login`,
      body: {
        email,
        password,
      }
    });
    if (res && res.success) {
        ReactGA.set({ userId: res.data.id });
        ReactGA.event({
            category: 'Login',
            action: 'Completed',
            value: email
        })
    } else {
        ReactGA.event({
            category: 'Login',
            action: 'Failed',
            value: email
        })
    }
    return res;
  },

  fetchLogout() {
    ReactGA.event({
      category: 'User',
      action: 'Logout'
    })
    return dispatch({
      url: `${API_SESSION}/logout`,
    });
  },

  async fetchRegister(user) {
    let res = await dispatch({
      method: "post",
      url: `${API_SESSION}/register`,
      body: {
        ...user
      }
    });
    if (res)
        ReactGA.event({
            category: 'Create account',
            action: 'Registration code sended'
        })
    return res;
  },

  async sesionVerify({ id, code }) {
    let res = await dispatch({
      method: "post",
      url: `${API_SESSION}/verify`,
      body: {
        id,
        code
      }
    });
    if(res) {
        if(!res.isNew && res.success) {
            ReactGA.event({
                category: 'Create account',
                action: 'User verified'
            })
        } else if(!res.success) {
            ReactGA.event({
                category: 'Create account',
                action: 'Verification failed'
            })
        }
    }
    return res;
  },

  fetchUser() {
    return dispatch({
      url: `${API_SESSION}/user`,
    });
  },

  fetchQuestions() {
    return dispatch({
      url: `${API_THINKINGTALENTS}/questions`,
    });
  },

  fetchAssessment() {
    return dispatch({
      url: `${API_THINKINGTALENTS}/assessment`,
    });
  },

  createAssessment() {
    return dispatch({
      method: "post",
      url: `${API_THINKINGTALENTS}/assessment`,
    });
  },

  storeAssessmen({ items }) {
    return dispatch({
      method: "post",
      url: `${API_THINKINGTALENTS}/assessment`,
      body: {
        items
      }
    });
  },

  fetchTeams(group) {
    return dispatch({
      url: `${API_TEAMS}/${group}`,
    });
  },

  createTeam(data) {
    return dispatch({
      method: "post",
      url: `${API_TEAMS}/create`,
      body: {
        ...data
      }
    });
  },

  updateTeam(data) {
    return dispatch({
      method: "post",
      url: `${API_TEAMS}/rename`,
      body: {
        ...data
      }
    });
  },

  findMembers(data) {
    return dispatch({
      method: "post",
      url: `${API_MEMBERS}/find`,
      body: {
        ...data
      }
    });
  },

  searchMembers(data) {
    return dispatch({
      method: "post",
      url: `${API_MEMBERS}/search`,
      body: {
        ...data
      }
    });
  },

  addMembersTeam(data) {
    return dispatch({
      method: "post",
      url: `${API_TEAMS}/addmembers`,
      body: {
        ...data
      }
    });
  },

  loadMembersTeam(team_id) {
    return dispatch({
      url: `${API_TEAMS}/members/${team_id}`,
    });
  },

  removeMemberTeam(data) {
    return dispatch({
      method: "post",
      url: `${API_TEAMS}/removemember`,
      body: {
        ...data
      }
    });
  },

  setLeaderMemberTeam(data) {
    return dispatch({
      method: "post",
      url: `${API_TEAMS}/leader`,
      body: {
        ...data
      }
    });
  },

  deleteTeam(team_id) {
    return dispatch({
      method: "post",
      url: `${API_TEAMS}/delete`,
      body: {
        id: team_id
      }
    });
  },

  fetchSystem(system_id) {
    return dispatch({
      url: `${API_SYSTEM}/${system_id}`,
    });
  },

  fetchSystemCheck() {
    return dispatch({
      url: `${API_SYSTEM}/account`,
    });
  },

  fetchMember(member_id) {
    return dispatch({
      url: `${API_MEMBERS}/get/${member_id}`,
    });
  },

  fetchReportTeam(team_id) {
    return dispatch({
      url: `${API_TEAMS}/reports/${team_id}`,
    });
  },

  fetchFullReportTeam(team_id) {
    return dispatch({
      url: `${API_TEAMS}/ttfullreport/${team_id}`,
    });
  },

  fetchFullReportMP(team_id) {
    return dispatch({
      url: `${API_TEAMS}/mpfullreport/${team_id}`,
    })
  },

  fetchMindPatters(key) {
    return dispatch({
      url: `${API_MINDPATTERNS}/${key}`,
    });
  },

  fetchMindPattersStoreOptions(items) {
    return dispatch({
      method: "post",
      url: `${API_MINDPATTERNS}/storeoptions`,
      body: {
        items
      }
    });
  },

  fetchMindPattersStoreMP(value) {
    return dispatch({
      method: "post",
      url: `${API_MINDPATTERNS}/storemp`,
      body: {
        value
      }
    });
  },

  fetchAllTeams() {
    return dispatch({
      url: `${API_TEAMS}/all`,
    });
  },

  fetchForgotPassword(email) {
    return dispatch({
      method: "post",
      url: `${API_SESSION}/reset-password`,
      body: {
        email
      }
    });
  },

  fetchChangePassword(newPassword) {
    return dispatch({
      method: "post",
      url: `${API_SESSION}/change-password`,
      body: {
        id: newPassword.id,
        code: newPassword.code,
        password: newPassword.password,
        confirm_password: newPassword.confirm_password
      }
    });
  },

  fetchContact(contact) {
    return dispatch({
      method: "post",
      url: `${API_SYSTEM}/contact`,
      body: {
        email: contact.email,
        name: contact.name,
        phone: contact.phone,
        comments: contact.comments
      }
    });
  },

  fetchPrivacy() {
    return dispatch({
      url: `${API_SYSTEM}/page/privacy`
    });
  },

  fetchCurrentUser() {
    return dispatch({
      url: `${API_SESSION}/current-user`
    });
  },

  async fetchFullPdf(userId) {
    const response = await dispatch({
      url: `${API_USER}/${userId}/full-pdf`,
      responseType: 'blob',
      includeHeaders: true
    });

    const contentDisposition = response.headers['content-disposition'];
    let filename;
  
    if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
      filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
    }
  
    downloadPDF(response.data, filename);
  
    return { data: { fullPDF: response.data }, success: true };
  },

  fetchUserResume() {
    return dispatch({
      url: `${API_THINKINGTALENTS}/user-resume`
    })
  },

  fetchQuestionnaireResume() {
    return dispatch({
      url: `${API_QUESTIONNAIRE}/resume`
    })
  },

  fetchQuestionnaireItems(id) {
    return dispatch({
      url: `${API_QUESTIONNAIRE}/${id}/items`
    })
  },

  fetchQuestionnaireStoreAnswerOptions({ questionnaireId, questionId, options }) {
    return dispatch({
      method: "post",
      url: `${API_QUESTIONNAIRE}/${questionnaireId}/save-answer/${questionId}`,
      body: {
        options,
      }
    })
  },

  fetchVideos() {
    return dispatch({
      url: `${API_VIDEOS}/home`
    })
  },

  fetchVideosLogin() {
    return dispatch({
      url: `${API_VIDEOS}/login`
    })
  },

  fetchTeamResume(team_id) {
    return dispatch({
      url: `${API_TEAMS}/resume/${team_id}`
    })
  },

  async fetchFullReportTTPDF() {
    const response = await dispatch({
        url: `${API_REPORTS}/tt-user`,
        responseType: 'blob',
        includeHeaders: true
      });
  
      const contentDisposition = response.headers['content-disposition'];
      let filename;
    
      if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
        filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
      }
    
      downloadPDF(response.data, filename);
  },

  async fetchFullReportMPPDF() {
    const response = await dispatch({
        url: `${API_REPORTS}/mp-user`,
        responseType: 'blob',
        includeHeaders: true
      });
  
      const contentDisposition = response.headers['content-disposition'];
      let filename;
    
      if (contentDisposition && contentDisposition.indexOf('filename=') !== -1) {
        filename = contentDisposition.split('filename=')[1].split(';')[0].replace(/"/g, '');
      }
    
      downloadPDF(response.data, filename);
  },

  async updateProfile(info_profile) {
    return dispatch({
      method: "post",
      url: `${API_USER}/profile`,
      body: info_profile
    })
  },

  updateProfilePicture(file){
    const data = new FormData();
    data.append("avatar", file);

    return dispatch({
        method: "post",
        url: `${API_USER_PROFILE}/picture`,
        body: data
    })
  },

  updateProfileStory(data){
    return dispatch({
        method: "post",
        url: `${API_USER_PROFILE}/story`,
        body: {
            profile_story: data
        }
    })
  },
  
  async fetchCommunityMembers({ search, page }) {
      let res = await dispatch({
        method: "post",
        url: `${API_COMMUNITY}/find/${page}`,
        body: {
            name: search,
        }
      })
      res.data.search = search
      return res
  },

  async fetchCommunityMembersByFilters({ filters, page }) {
    let res = await dispatch({
      method: "post",
      url: `${API_COMMUNITY}/search/${page}`,
      body: {
        name: filters && filters.name ? filters.name : '',
        talents: filters && filters.talents ? filters.talents : [],
        mind_patterns: filters && filters.mind_patterns ? filters.mind_patterns : [],
        seniorities: filters && filters.seniorities ? filters.seniorities : []
      }
    })
    res.data.search = filters
    return res
  },

  setAcceptTerms() {
    return dispatch({
        method: "post",
        url: `${API_USER}/accept-terms`,
      })
  },

  saveTrackingEvent(data) {
    return dispatch({
        method: "post",
        url: `${API_USER_ACTIVITY}/log`,
        body: {...data}
    })
  },

  checkPasswordAcceptedByPolitics(password) {
    return dispatch({
        method: "post",
        url: `${API_USER}/check-password`,
        body: {
            password
        }
    })
  },

  fetchUserDocuments() {
    return dispatch({
      url: `${API_USER_DOCUMENTS}`,
    })
  },

  async fetchUserDocumentById(id) {
    let res = await dispatch({
        url: `${API_USER_DOCUMENTS}/${id}`,
        responseType: 'blob'
    })
    return {data: {blob: res}, success: true}
  },

  setDocumentDownloaded(id) {
    return dispatch({
        method: "post",
        url: `${API_USER_DOCUMENTS}/${id}/downloaded`,
    })
  },

  fetchAssociatedAccounts(email) {console.log(email)
    return dispatch({
        url: `${API_USER}/${email}/accounts`,
    })
  }
}

export function requestUrl({ query, url }) {

  let params = []

  if (query) params.push(query);

  if (params.length) {
    url += url.indexOf('?') === -1 ? '?' : '&';
    url += params.join('&');
  }

  // SOLO para dev: siempre trabajar usuario de la cuenta demo
  if (window.location.hostname == "localhost") {
    url = HOST_DEV + url
  }

  return url;
}

export function dispatch(options) {

  let headers_config = {
    "Content-Type": "application/json;charset=utf-8",
    'Nonce-Key': API_KEY,
  }
  if(options.contentType) {
    headers_config["Content-Type"] = options.contentType;
  }

  let config = {
    method: options.method || "get",
    url: requestUrl(options),
    headers: headers_config,
    withCredentials: true
  }

  // alert(config.url);
  if (options.body) {
    config["data"] = options.body;
  }
  if (options.responseType) {
    config["responseType"] = options.responseType;
  }
  /*
  console.log('########### START AXIOS CONFIG ###############')
  console.log(config)
  console.log('########### END AXIOS CONFIG ###############')
  */
  return axios(config)
    .then(response => {
      /*
      console.log('########### START AXIOS RESPONSE ###############')
      console.log(response.data);
      console.log(response.status);
      console.log(response.statusText);
      console.log(response.headers);
      console.log(response.config);
      console.log('########### END AXIOS RESPONSE ###############')
      */
      if (options.includeHeaders) {
        return {
          data: response.data,
          headers: response.headers,
          status: response.status,
        };
      }

      return response.data;

    })
    .catch(error => {
      console.log('########### START AXIOS ERROR ###############')
      if (error.response) {

        // console.log(error.response.data);
        // error.response.status === 404 && options.history.push('/not-found')
        error.response.status === 404 && window.location.assign("/not-found");
        // error.response.status === 401 && window.location.assign("/welcome")

        // console.log(error.response.status);
        // console.log(error.response.headers);

        if (error.response.status === 500) {
          return error.response.data;
        }
        if (error.response.status === 401) {
          return error.response.data;
        }

      }
      console.log('########### END AXIOS ERROR ###############')
      return error;
    });
}
