import { call, fork, takeEvery } from 'redux-saga/effects';

import { saveTrackingEvent } from '../api';
import { trackingActions } from './actions';


export function* saveEvent({ payload }) {
  // const { category, action, firstTime } = payload
  yield call(saveTrackingEvent, {...payload} );
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchSaveEvent() {
  yield takeEvery(trackingActions.SAVE_EVENT, saveEvent);
}

//=====================================
//  ROOT
//-------------------------------------

export const trackingSagas = [
  fork(watchSaveEvent),
];
