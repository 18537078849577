import { all } from 'redux-saga/effects';
import { appSagas } from './app';
import { userSagas } from './users';
import { questionsListSagas } from './questions-list';
import { assessmentsListSagas } from './assessments-list';
import { teamsListSagas } from './teams-list';
import { teamSagas } from './teams';
import { membersListSagas } from './members-list';
import { teamMembersListSagas } from './teams-members-list';
import { systemSagas } from './system';
import { teamsReportListSagas } from './teams-report';
import { teamsFullReportListSagas } from './teams-full-report';
import { mindPatternsDataSagas } from './mind-patterns';
import { registerSagas } from './register/sagas';
import { verifySagas } from './sesion-verify/sagas';
import { mindPatternsStoreOptionsSagas } from './mind-patterns-storeOptions/sagas';
import { mindPatternsStoreMPSagas } from './mind-patterns-storemp/sagas';
import { systemCheckSagas } from './systemCheck/sagas';
import { forgotPasswordSagas } from './forgotPassword/sagas';
import { contactSagas } from './contact/sagas';
import { fullReportListMpSagas } from './full-report-mp/sagas';
import { userResumeSaga } from './thinking-talents-user-resume';
import { questionnaireResumeSaga } from './questionnaire-resume/sagas';
import { questionnaireItemsSaga } from './questionnaire-items';
import { questionnaireStoreOptionsSagas } from './questionnaire-storeOptions/sagas';
import { videosSagas } from './videos';
import { pdfsSagas } from './pdfs/sagas';
import { teamResumeSagas } from './team-resume/sagas';
import { profileSagas } from './profile/sagas';
import { communitySagas } from './community-search/sagas';
import { trackingSagas } from './tracking/sagas';
import { userDocumentsSagas } from './user-documents/sagas';

export default function* sagas() {
  yield all([
    ...appSagas,
    ...userSagas,
    ...questionsListSagas,
    ...assessmentsListSagas,
    ...teamsListSagas,
    ...teamSagas,
    ...membersListSagas,
    ...teamMembersListSagas,
    ...systemSagas,
    ...teamsReportListSagas,
    ...teamsFullReportListSagas,
    ...mindPatternsDataSagas,
    ...registerSagas,
    ...verifySagas,
    ...mindPatternsStoreOptionsSagas,
    ...mindPatternsStoreMPSagas,
    ...systemCheckSagas,
    ...forgotPasswordSagas,
    ...contactSagas,
    ...fullReportListMpSagas,
    ...userResumeSaga,
    ...questionnaireResumeSaga,
    ...questionnaireItemsSaga,
    ...questionnaireStoreOptionsSagas,
    ...videosSagas,
    ...pdfsSagas,
    ...teamResumeSagas,
    ...profileSagas,
    ...communitySagas,
    ...trackingSagas,
    ...userDocumentsSagas
  ]);
}
