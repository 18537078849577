import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchTeamResume } from '../api'
import { teamResumeActions } from './actions';


export function* loadTeamResume({ payload }) {
    const { team_id } = payload;

    yield call(fetchTeamResume, team_id);
}


export function* watchLoadTeamResume() {
    yield takeEvery(teamResumeActions.LOAD_TEAM_RESUME, loadTeamResume);
}


export const teamResumeSagas = [
    fork(watchLoadTeamResume)
];
