export const contactActions = {

  FETCH_CONTACT_FAILED: 'FETCH_CONTACT_FAILED',
  FETCH_CONTACT_SUCCESS: 'FETCH_CONTACT_SUCCESS',
  FETCH_CONTACT_PENDING: 'FETCH_CONTACT_PENDING',

  RESET_CONTACT: 'RESET_CONTACT',
  LOAD_CONTACT: 'LOAD_CONTACT',
  PAGE_PRIVACY: 'PAGE_PRIVACY',

  fetchContactFailed: error => ({
    type: contactActions.FETCH_CONTACT_FAILED,
    payload: error
  }),

  fetchContactSuccess: (systemId, data) => ({
    type: contactActions.FETCH_CONTACT_SUCCESS,
    payload: {
      data,
      systemId
    }
  }),

  fetchContactPending: systemId => ({
    type: contactActions.FETCH_CONTACT_PENDING,
    payload: {
      systemId
    }
  }),

  loadContact: (data) => ({
    type: contactActions.LOAD_CONTACT,
    payload: {
      data
    }
  }),

  pagePrivacy: (systemId) => ({
    type: contactActions.PAGE_PRIVACY,
    payload: {
      systemId
    }
  }),

  resetContact: (systemId) => ({
    type: contactActions.RESET_CONTACT,
    payload: {
      systemId
    }
  })
};

export const contactRequestActions = {
  failed: contactActions.fetchContactFailed,
  success: contactActions.fetchContactSuccess,
  pending: contactActions.fetchContactPending
};