import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Media, UncontrolledCollapse } from 'reactstrap';
import logo from '../../../../assets/img/true.logo.circle.png';
import styled from 'styled-components';

const StyledQuadrant = styled.div`
  display: initial;

  > h5 {
    color: ${props => props.quadrant_color};
  }

  > ul li:before {
    color: ${props => props.quadrant_color};
  }
`;

export const ModalReportTT = ({ modal, toggle, current_item, user }) => {
    return (
        <Modal isOpen={modal} toggle={toggle} className="modal-dialog modal-dialog-summary float-right">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                {
                    current_item && 
                    <div className={"modal-quadrant-" + current_item.name}>
                        <div className="d-flex">
                            <div className="d-inline-block mx-auto mb-2">
                                <img
                                    className="icon"
                                    width="130"
                                    height="130"
                                    src={current_item.icon}
                                    alt={current_item.name}
                                />
                            </div>
                        </div>
                        <h2 className="text-center text-reset text-capitalize mb-3">{current_item.name}</h2>


                        <StyledQuadrant
                            quadrant_color={current_item.color}
                            className="text-left"
                            dangerouslySetInnerHTML={{ __html: current_item.description }}
                        >
                        </StyledQuadrant>

                        <div className="bg-celeste p-3 rounded mb-4"><p className="text-left mb-md-0">Tap the quadrants to view more information</p></div>
                        <hr className="mt-1 mb-2" />
                        {
                            current_item &&
                            user.talents && user.talents.map(item =>
                                item.quadrant === current_item.name &&
                                item.items.map(s_item =>
                                    <div
                                        key={'i_' + s_item.id}
                                        className={"item talent-item mb-2 pb-2 border-bottom modal-quadrant-" + item.quadrant}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div id={`toggler${s_item.id}`}>
                                            <Media className="align-items-center">
                                                <Media left middle className="mr-3">
                                                    <Media object src={s_item.icon} width="60" height="60" alt={item.name} style={{ backgroundColor: current_item.color }} />
                                                </Media>
                                                <Media body>
                                                    <Media heading tag="h5" className="mb-0 text-reset">
                                                        {s_item.name}
                                                    </Media>
                                                </Media>
                                            </Media>
                                        </div>

                                        <UncontrolledCollapse toggler={`#toggler${s_item.id}`}>
                                            <StyledQuadrant quadrant_color={current_item.color}
                                                dangerouslySetInnerHTML={{ __html: s_item.result_description }} className="pt-2"></StyledQuadrant>
                                        </UncontrolledCollapse>
                                    </div>
                                )
                            )
                        }


                    </div>
                }
            </ModalBody>
        </Modal>
    )
}
