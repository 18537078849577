import { call, fork, takeEvery, take } from 'redux-saga/effects';
import { fetchContact, fetchPrivacy } from '../api';
import { contactActions } from './actions';

export function* sendContact({ payload }) {
    const { data } = payload;
    yield call(fetchContact, data);
}

export function* privacyPage() {
    yield call(fetchPrivacy);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchContact() {
    yield takeEvery(contactActions.LOAD_CONTACT, sendContact);
}

export function* watchPrivacyPage() {
    yield takeEvery(contactActions.PAGE_PRIVACY, privacyPage);
}

export function* watchResetContact() {
    yield take(contactActions.RESET_CONTACT);
}
//=====================================
//  ROOT
//-------------------------------------

export const contactSagas = [
    fork(watchContact),
    fork(watchPrivacyPage),
    fork(watchResetContact)
];