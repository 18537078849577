import { fork, take } from 'redux-saga/effects'
import { appActions } from './actions'
import history from '../history';
import session from '../session';
//=====================================
//  WATCHERS
//-------------------------------------


export function* watchInitApp() {
  while (true) {
    const { payload } = yield take(appActions.INIT_APP);

    if (payload.check_session){
      session.validateSession();
    }
  }
}


export function* watchNavigateTo() {
  while (true) {
    const { payload } = yield take(appActions.NAVIGATE_TO_PATH);

    if ( history.location.pathname !== payload.pathname )
      yield history.push(payload);

    window.scrollTo(0, 0)
    if (payload.pathname === "login"){
      window.location.reload()
    }
  }
}

//=====================================
//  ROOT
//-------------------------------------

export const appSagas = [
  fork(watchNavigateTo),
  fork(watchInitApp)
]
