import React, { Fragment, useEffect } from 'react';
import { Container, Card, Col, Row } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator';
import { DocumentsList } from '../../components/myDocuments/DocumentsList';
import { userDocumentsActions } from '../../../core/user-documents';

import icon_document from '../../../assets/img/icon-mydocuments.svg'

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const MyDocuments = () => {
    const dispatch = useDispatch()
    const { userDocuments } = useSelector(state => state);
    const { account } = useSelector(state => state.user);

    // useEffect(() => {
    //   dispatch(userDocumentsActions.loadUserDocuments()) ahora se toma de user
    // }, [])

    const documents =
    {
        data: [
            {
                id: 11,
                file_name: "RequerimientoParaProgramacion.pptx",
                user_id: 219,
                document: {download_link: "user-files/April2022/wM3BIB4qNPIF6t0krAjv.pptx", original_name: "RequerimientoParaProgramacion.pptx"},
                text: "pdf",
                size: 0.44,
                downloaded: 1,
                type: "pdf"
            },
            {
                id: 12,
                file_name: "RequerimientoParaProgramacion.pptx",
                user_id: 219,
                document: {download_link: "user-files/April2022/eBeuS70lJ8hKtIjJqSKV.pptx", original_name: "RequerimientoParaProgramacion.pptx"},
                text: "docx",
                size: 0.44,
                downloaded: 0,
                type: "docx"
            },
            {
                id: 12,
                file_name: "RequerimientoParaProgramacion.pptx",
                user_id: 219,
                document: {download_link: "user-files/April2022/eBeuS70lJ8hKtIjJqSKV.pptx", original_name: "RequerimientoParaProgramacion.pptx"},
                text: "pptx",
                size: 0.44,
                downloaded: 0,
                type: "pptx"
            },
            {
                id: 12,
                file_name: "RequerimientoParaProgramacion.pptx",
                user_id: 219,
                document: {download_link: "user-files/April2022/eBeuS70lJ8hKtIjJqSKV.pptx", original_name: "RequerimientoParaProgramacion.pptx"},
                text: "xlsx",
                size: 0.44,
                downloaded: 0,
                type: "xlsx"
            }
        ]
    }

    return (
        <div className='view overflow-hidden'>
            {
                <TransitionGroup className="content">
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} appear >
                        <div className="w-100">
                            <Container>
                                <div className="bg-white content-view my-md-4 shadow-md rounded-lg">
                                    <div className="py-4 border-md-bottom mb-md-3">
                                        <div className="d-flex align-items-md-center px-md-4 mx-md-2">
                                            <div className="text-md-right px-md-5 mr-2 mr-md-0">
                                                <img src={icon_document} width="46" height="auto" style={{ maxHeight: '46px' }} />
                                            </div>
                                            <div className="">
                                                <h3 className="mb-0"><small>My Documents</small></h3>
                                                {account && account.my_documents_text && <div dangerouslySetInnerHTML={{ __html: account.my_documents_text}}></div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mx-md-0 pb-md-4 row row-cols-1 row-cols-md-4 documentsList">
                                        <DocumentsList documents={userDocuments}></DocumentsList>
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            }
        </div>
    )
}