import { call, fork, takeEvery } from 'redux-saga/effects';

import { fetchUserDocuments, fetchUserDocumentById, setDocumentDownloaded } from '../api';
import { userDocumentsActions } from './actions';


export function* loadUserDocuments() {
    yield call(fetchUserDocuments);
}

export function* loadUserDocumentById({ payload }) {
    const { id } = payload
    yield call(fetchUserDocumentById, id);
}

export function* setUserDocumentDownloaded({ payload }) {
    const { id } = payload
    yield call(setDocumentDownloaded, id);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadUserDocuments() {
    yield takeEvery(userDocumentsActions.LOAD_USER_DOCUMENTS, loadUserDocuments);
}

export function* watchLoadUserDocumentById() {
    yield takeEvery(userDocumentsActions.LOAD_USER_DOCUMENT_BY_ID, loadUserDocumentById);
}

export function* watchSetUserDocumentDownloaded() {
    yield takeEvery(userDocumentsActions.SET_USER_DOCUMENT_DOWNLOADED, setUserDocumentDownloaded);
}

//=====================================
//  ROOT
//-------------------------------------

export const userDocumentsSagas = [
    fork(watchLoadUserDocuments),
    fork(watchLoadUserDocumentById),
    fork(watchSetUserDocumentDownloaded)
];
