import { trackingActions } from './actions';
import { tracking } from './tracking';


export const trackingReducer = (state = tracking, { payload, type }) => {

    switch (type) {
        case trackingActions.FETCH_PDF_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload.error
            };

        case trackingActions.FETCH_PDF_SUCCESS:
            return {
                ...state,
                isPending: false,
            };
        case trackingActions.FETCH_PDF_PENDING:
            return {
                ...state,
                isPending: true
            }
        default:
            return state;
    }
}
