import { List, Map } from 'immutable';
import { assessmentsListActions } from './actions';
import { AssessmentsList } from './assessments-list';
import { DEFAULT_SESSION_ID } from '../constants';

export const initialState = new Map({
  currentAssessmentListId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new AssessmentsList({ id: DEFAULT_SESSION_ID, isNew: false })
});

export const assessmentsListsReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case assessmentsListActions.FETCH_ASSESSMENTS_SUCCESS:
      const previouseAssessmentListId = state.get("currentAssessmentListId");
      //when we get a new list or after saving we get a new id then we need to update the isPending here
      state = state.merge({
        [previouseAssessmentListId]: state.get(previouseAssessmentListId.toString()).set('isPending', false)
      });
    case assessmentsListActions.FETCH_ASSESSMENTS_PENDING:
      const assessmentsId = payload?.data?.id || payload.assessmentsId || "";
      return state.merge({
        currentAssessmentListId: assessmentsId,
        [assessmentsId]: assessmentListReducer(state.get(assessmentsId.toString()), action)
      });

    case assessmentsListActions.FETCH_ASSESSMENTS_FAILED:
      return state.merge({
        [state.get("currentAssessmentListId")]: assessmentListReducer(state.get(state.get("currentAssessmentListId")), action)
      });

    default:
      return state;
  }
}

const assessmentListReducer = (state = new AssessmentsList(), { payload, type }) => {
  switch (type) {
    case assessmentsListActions.FETCH_ASSESSMENTS_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case assessmentsListActions.FETCH_ASSESSMENTS_SUCCESS:
      return state.withMutations(assessmentsList => {
        assessmentsList.merge({
          id: payload.data.id,
          isPending: false,
          isNew: false,
          assessment_id: payload.data.id,
          item_ids: mergeIds(assessmentsList.item_ids, payload.data.items),
        })
      });
    case assessmentsListActions.FETCH_ASSESSMENTS_PENDING:
      return state.withMutations(assessmentsList => {console.log(payload, assessmentsList)
        assessmentsList.merge({
          ...assessmentsList,
          isPending: true
        })
      });
  }
}

function mergeIds(itemsId, items) {
  let ids = itemsId;

  let newIds = items.reduce((list, itemData) => {
    if (ids.indexOf(itemData.id) === -1) list.push(itemData.item_id);
    return list;
  }, []);

  return newIds.length ? new List([...ids, ...newIds]) : ids;
}
