import React, { useEffect } from "react";
import { Button, Container, Col, Row, FormText} from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { userActions } from "../../../core/users";

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const AccountInformationSent = (props) => {
    const { history } = props
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
      if(!user.email) {
        history.replace('welcome')
      } else{
        user.error?.message === 'Unauthorized' && !user.isPending &&
            dispatch(userActions.fetchAssociatedAccounts(user.email))
      }
    }, [])
    

    return (
        <div className='view overflow-hidden bg-white'>
        <TransitionGroup className="content">
            <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='account-information' appear >
                <div className="account-information-sent-page">
                    <Container>
                        <div className="px-2 px-md-0 my-md-4">
                            <div className="content-view">
                                <Row>
                                    <Col sm={{ size: 6, offset: 3 }}>
                                        <h2 className="pl-3 pl-md-0">Account information sent</h2>
                                        <p className="text-reset mb-0"> 
                                            The account information was sent to {user?.email}. <br/>
                                            Please check your email and access to the account reported. <br/>
                                        </p>
                                        <p className="small">
                                            If you do not receive this information in your inbox within 5 minutes or less, please check your spam folder or contact us <Link to="/contact-us"> here</Link>.
                                        </p>
                                    </Col>
                                </Row>
                                    <Row>
                                        <Col sm={{ size: 6, offset: 3 }}>
                                            
                                            <Button
                                                color="primary"
                                                type="submit"
                                                className="text-uppercase rounded-pill px-md-5 btn-block"
                                                onClick={() => { history.push("/welcome") }}
                                            >
                                                CLOSE
                                            </Button>
                                        </Col>
                                    </Row>
                            </div>
                        </div>
                    </Container>
                </div>
            </CSSTransition>
        </TransitionGroup>
    </div>
    )
}