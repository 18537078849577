import { call, fork, select, takeLatest } from 'redux-saga/effects';

import { createAssessment, fetchAssessment, storeAssessmen, fetchCurrentUser } from '../api';
import { assessmentsListActions } from './actions';

import { getCurrentAssessmentsList } from './selectors';

export function* loadAssessments({payload}) {
  const { assessmentsId } = payload;
  yield call(fetchAssessment, assessmentsId);
}

export function* createAssessments({payload}) {
  const { assessmentsId } = payload;
  yield call(createAssessment, assessmentsId);
}

export function* saveAssessment({payload}) {
  const { assessmentItemId, data } = payload;
  let items = [];
  data.map( item => items.push(item) );
  yield call(storeAssessmen, assessmentItemId, {items});
  yield call(fetchCurrentUser);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadAssessments() {
  yield takeLatest(assessmentsListActions.LOAD_ASSESSMENTS, loadAssessments);
}

export function* watchCreateAssessments() {
  yield takeLatest(assessmentsListActions.CREATE_ASSESSMENTS, createAssessments);
}

export function* watchSaveAssessment() {
  yield takeLatest(assessmentsListActions.SAVE_ASSESSMENT, saveAssessment);
}


//=====================================
//  ROOT
//-------------------------------------

export const assessmentsListSagas = [
  fork(watchLoadAssessments),
  fork(watchCreateAssessments),
  fork(watchSaveAssessment),
];
