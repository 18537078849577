import { userResumeActions } from './actions';
import { UserResume } from './user-resume';

export const userResumeReducer = (state = new UserResume(), { payload, type }) => {
   
    switch (type) {
        case userResumeActions.FETCH_USER_RESUME_FAILED:
            return state.merge({
                isPending: false,
                error: payload?.error?.data
            });
        case userResumeActions.FETCH_USER_RESUME_SUCCESS:
            return state.merge({
                data: payload.data,
                isPending: false
            });
        case userResumeActions.FETCH_USER_RESUME_PENDING:
            return state.set('isPending', true);
        default:
            return state;
    }
}
