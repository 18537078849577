import { call, fork, select, takeLatest, takeEvery } from 'redux-saga/effects';

import { fetchTeams } from '../api';
import { teamsListActions } from './actions';

import { getCurrentTeamsList, getTeamsListById } from './selectors';

export function* loadTeamsList({payload}) {
  const { teamsListId } = payload;

  const teamsLis = yield select(getTeamsListById, teamsListId);
  // console.log(teamsLis)
  if (!teamsLis || !teamsLis.isPending)
    yield call(fetchTeams, teamsListId);

}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadTeamsList() {
  yield takeEvery(teamsListActions.LOAD_TEAMS, loadTeamsList);
}


//=====================================
//  ROOT
//-------------------------------------

export const teamsListSagas = [
  fork(watchLoadTeamsList),
];
