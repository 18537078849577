import React, { useState } from 'react'
import { Radio } from '../common'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse, Media, Progress, Table } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';


const TalentsItem  = (props) => {


  const { data, quadrant, handleModal, toggle, quadrant_id, isOpen } = props;

  //console.log('data',data, quadrant )

  const items = data.items.map( item => <tr key={ 'ti'+item.item_id} className="cursor-pointer" onClick={ (e) => handleModal(e, { ...item, quadrant_id: quadrant.id }, 'members-list') }>
    <th scope="row" className="text-uppercase"><small>{item.name}</small></th>
    <td className="">
      { !item.percent ? <small className="px-2">NO MEMBERS</small> : <Progress value={`${item.percent}`} className="rounded-0 bg-transparent"  barStyle={{ backgroundColor: quadrant.color }} />}
    </td>
  </tr>)
  const active = isOpen && quadrant_id === quadrant.id
 const active_class = active ? ' active' : '';

 let custom_style = {
   border: `2px solid ${quadrant.color}`
 }
 let icon = faAngleDown
 if (active){
   custom_style.color = '#ffffff';
   custom_style.backgroundColor = quadrant.color;
   icon = faAngleUp
 }

  return (
      <>
      <Col md="4" className={ `item talents-item clearfix mb-3 ${quadrant.name}` }>
        <div id={ `toggler${quadrant.name}` } onClick={ () => toggle(quadrant.id) } className={ `text-uppercase px-3 py-1 cursor-pointer option-select rounded-pill ${active_class}` } style={custom_style}>
          {quadrant.name} <i className="mr-2 h5 text-reset align-middle float-right"><FontAwesomeIcon icon={icon}/></i>
        </div>
        <Collapse isOpen={active}>
          <div className="bg-celeste p-2 mt-3 rounded text-center mb-2"><p className="mb-0">Tap on each talent to view its members</p></div>
          <Table size="sm" borderless responsive>
            <tbody>
              {items}
            </tbody>
          </Table>
        </Collapse>
      </Col>
      </>
    )

}

export default TalentsItem
