import React from 'react'
import { Row, Col } from 'reactstrap';

import { mind_pattern_sections } from '../../../core/constants'
import mind_ilustracion from '../../../assets/img/mind-ilustracion.png'
import { Disclaimers } from '../common';

import { useDispatch } from 'react-redux';
import { trackingActions } from '../../../core/tracking';

import ReactGA from 'react-ga';

const ReadyPartOne = (props) => {
  const dispatch = useDispatch()

  const { hanndleNext, retake } = props

    const eventGA = () => {
        !retake ?
        ReactGA.event({
            category: 'Mind Patterns',
            action: 'Creation started'
        })
        :
        ReactGA.event({
            category: 'Mind Patterns',
            action: 'Retake assessment'
        })
    }

    const ourTracking = () => {
        !retake ?
        dispatch(trackingActions.saveTrackingEvent({category: 'Mind Patterns', action: 'Creation started'}))
        :
        dispatch(trackingActions.saveTrackingEvent({category: 'Mind Patterns', action: 'Retake assessment'}))
    }

  return (
    <div className="bg-white my-md-4 rounded-lg pb-md-4 px-md-5 pb-md-5 pt-md-2 content-ready content-view">
    <div className="px-md-4">

        <div className="d-none d-sm-none d-md-block mb-md-5 encabezado">
          <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
             <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">Mind Patterns</h6>
          </div>
          <span className="border border-info d-block"></span>
        </div>

    <Row className="ready-mind align-items-md-start">
      <Col md="5">
        <div className="mind-ilustracion text-center pt-md-5 px-5 px-md-0">
          <h1 className="text-center d-md-none">Mind Patterns</h1>
          <img src={mind_ilustracion} className="img-fluid mb-4" />
        </div>
      </Col>

      <Col md={{ size: 7}}>
        <div className="mb-md-5 contenido-after-fixed pb-5 pb-md-0">
          <h1 className="mb-md-3 mb-5 text-center text-md-left d-md-none">Mind Patterns</h1>
          <h2 className="mb-md-3 mb-4 text-center text-md-left text-reset">Ready!</h2>
          <div className="pb-5 pb-md-0">
            <p>The following discovery consists of 14 questions, each with three choices.<br/>Take your time to select the answer that is most true for you.</p>
            <p className="mb-5">Just answer each question as truthfully as possible without thinking too hard about them.</p>
            <div className="bg-celeste p-3 rounded mb-5">
              <p className="mb-0"><strong>Remember:</strong> <br/>A <strong>talent</strong> is energizing and something you <strong>cannot help but do.</strong></p>
            </div>
            <h2 className="text-naranja text-center">Let's GO!</h2>
          </div>
        </div>
        <Row className="contenido-bottom-fixed-mb">
        <Col md={{ size:4, offset: 4}}>
          <button onClick={ () =>  {hanndleNext( mind_pattern_sections.ASSESSMENT ); eventGA(); ourTracking() }} className="btn btn-primary text-uppercase btn-block rounded-pill">Next</button>
        </Col>
        <Col md="12">
          <Disclaimers view="propiedad-intelectual" />
        </Col>
        </Row>
      </Col>
    </Row>
    </div>
    </div>
  )

}

export default ReadyPartOne;
