import React, { useState, useCallback, useMemo, useRef, useEffect } from "react";
import { Card, Row, Col, Collapse, Spinner, Button } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import { Assessment } from "./Assessment";
import { YourReport } from "./YourReport";
import icon_pie from '../../../assets/img/boost-your-growth/growth-pie.svg';
import graphic_report from '../../../assets/img/boost-your-growth/thinking-talent-sm.svg';
import icon_leadership from '../../../assets/img/boost-your-growth/leadership-questionars.svg';
import icon_mind_patterns from '../../../assets/img/boost-your-growth/mind-patterns-sm.svg';
import { QuestionnaireProgress } from "../../pages/home/questionnaires/QuestionnaireProgress";
import { userActions } from "../../../core/users";

export const BoostYourGrowth = () => {
    const dispatch = useDispatch();
    const sliderRef = useRef(null);
    const { user, questionnaire_resume } = useSelector(state => state);
    const { systemCheck } = useSelector(state => state);
    const account = systemCheck?.data?.data;
    const [showQuestionnaires, setShowQuestionnaires] = useState(false);
    const uniqueQuestionnaire = questionnaire_resume?.data?.length === 1;

    const toggleShowQuestionnaires = useCallback(() => {
        setShowQuestionnaires(prev => !prev);
    }, []);

    const allQuestionnairesCompleted = useMemo(() => {
        return questionnaire_resume.data?.every(q => q.completion === 100);
    }, [questionnaire_resume]);

    useEffect(() => {
        if(allQuestionnairesCompleted && !!user.talents?.length && !!user.mind_pattern && !user?.can_download_pdf) {
            dispatch(userActions.currentUser())
        }
    }, [allQuestionnairesCompleted])

    const assessments = useMemo(() => {
        return [
            { title: 'Thinking Talents', icon: graphic_report, completed: !!user.talents?.length, link: '/thinking-talents' },
            { title: 'Mind Patterns', icon: icon_mind_patterns, completed: !!user.mind_pattern, link: '/mind-patterns' },
            {
                title: uniqueQuestionnaire ? questionnaire_resume.data[0].name : 'Questionnaires',
                icon: icon_leadership,
                completed: allQuestionnairesCompleted,
                questionnaires: questionnaire_resume?.data,
                link: uniqueQuestionnaire ? `/questionnaire/${questionnaire_resume.data[0].id}` : '',
                loading: !questionnaire_resume?.data?.length && questionnaire_resume.isPending
            },
        ];
    }, [user, questionnaire_resume, uniqueQuestionnaire, allQuestionnairesCompleted]);

    const sliderSettingsRef = useRef({
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1
    });

    const items = useMemo(() => questionnaire_resume.data.map(questionnaire => (
        <div className="text-center px-3 d-flex flex-column justify-content-end" key={questionnaire.id}>
            <QuestionnaireProgress questionnaire={questionnaire}  customTexts={['', '', 'View answers']} />
            <div className="w-100 d-flex justify-content-center mt-2">
                <FontAwesomeIcon 
                    icon={questionnaire.completion === 100 ? faCheckCircle : faCircle} 
                    size="3x" 
                    color="#78AFBA" 
                    style={{ opacity: questionnaire.completion === 100 ? 1 : 0.3 }} 
                />
            </div>
        </div>
    )), [questionnaire_resume]);

    return (
        <Card className="px-4 mb-4 mx-3 mt-3 w-100">
            <div className="px-md-5">
                <div className="encabezado encabezado-icono">
                    <div className="mb-0 pt-2 pb-1 d-flex align-items-end">
                        <img src={icon_pie} width="24" height="auto" alt="Boost your growth" className="mb-2" />
                        <h2 className="mb-0 ml-3 title-underline">{account?.assessment_title}</h2>
                    </div>
                    <span className="border border-info d-none d-md-block"></span>
                </div>
                <div className="my-4">
                    <div dangerouslySetInnerHTML={{ __html: account?.assessment_presentation }}></div>

                    <hr />

                    <Row>
                        <Col sm="12" md="9">
                            <Row>
                                {assessments.map((assessment, index) => (
                                    <Col sm="12" md="4" className="mb-3" key={index}>
                                        {!assessment.loading ? (
                                            <Assessment
                                                title={assessment.title}
                                                icon={assessment.icon}
                                                completed={assessment.completed}
                                                link={assessment.link}
                                                questionnaires={assessment.questionnaires}
                                                loading={assessment.loading}
                                                toggle={toggleShowQuestionnaires}
                                            />
                                        ) : (
                                            <div className="d-flex justify-content-center align-content-end mt-5">
                                                <Spinner color="primary" style={{ height: '3rem', width: '3rem' }} />
                                            </div>
                                        )}
                                    </Col>
                                ))}

                                <Collapse isOpen={showQuestionnaires} className="container collapse-down">
                                    <Card className="pt-4 w-100 border-info">
                                        <div className="d-flex align-items-center mb-3 px-4">
                                            <img src={icon_leadership} alt='Questionnaires' width="40" height="auto" />
                                            <h2 className="mb-0 ml-3"> Questionnaires </h2>
                                        </div>

                                        {items.length > 0 && (
                                            <React.Fragment>
                                                <div className="px-2 pb-2">
                                                    <Slider 
                                                        className="slide-questionnaires"
                                                        ref={sliderRef} 
                                                        {...sliderSettingsRef.current} 
                                                        slidesToShow={
                                                            items.length < sliderSettingsRef.current.slidesToShow
                                                                ? items.length
                                                                : sliderSettingsRef.current.slidesToShow
                                                        }
                                                    >
                                                        {items}
                                                    </Slider>
                                                </div>

                                                {items.length > 3 && (
                                                    <>
                                                    <hr className="my-0" />

                                                    <div className="d-flex justify-content-center w-100 py-1">
                                                        <Button color="link" size="lg" className="mx-4  text-secondary" onClick={() => sliderRef.current.slickPrev()}>
                                                            <FontAwesomeIcon size="lg" icon={faChevronLeft} />
                                                        </Button>
                                                        <Button color="link" size="lg" className="mx-4 text-secondary" onClick={() => sliderRef.current.slickNext()}>
                                                            <FontAwesomeIcon size="lg" icon={faChevronRight} />
                                                        </Button>
                                                    </div>
                                                    </>
                                                ) }
                                            </React.Fragment>
                                        )}
                                    </Card>
                                </Collapse>
                            </Row>
                        </Col>

                        <Col sm="12" md="3">
                            <YourReport />
                        </Col>
                    </Row>
                </div>
            </div>
        </Card>
    );
};