import React, { useEffect, useState } from 'react';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Container, Row, Col } from 'reactstrap';
import { QuestionsList } from '../../../components/questionnaires/questions-review/QuestionsList';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { questionnaireItemsActions } from '../../../../core/questionnaire-items/actions';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const QuestionnaireReview = (params) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const questionnaire_list = useSelector(state => state.questionnaire_list.data);

    useEffect(() => {
        dispatch(questionnaireItemsActions.loadQuestionnaireItems(Number(id !== undefined ? id : params.id)));
    }, []);

    return (
        <>
            <div className="view overflow-hidden">
                <TransitionGroup className="content"></TransitionGroup>
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='questionnaire' appear>
                    <div className="h-100">
                        <Container>
                            <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 pb-md-5 pt-md-2 content-view">
                                {params?.id && params?.close && <div className="icons-funciones d-flex justify-content-end align-items-end">
                                    <a href="#" onClick={(e) => {e.preventDefault(); params.close()}} className="icon-close"></a>
                                </div>}
                                <div className="px-md-4">
                                    <div className="mb-md-4 mb-3 encabezado text-center text-md-left">
                                        <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-inline-flex d-md-flex align-items-center">
                                            <h6 className="mb-0 title-underline">{questionnaire_list?.questionnaire?.name}</h6>
                                        </div>
                                        <span className="d-none d-md-block border border-info"></span>
                                    </div>
                                    <QuestionsList
                                        questionnaire={questionnaire_list}
                                        text={questionnaire_list.questionnaire && questionnaire_list.questionnaire.detail}
                                        icon={questionnaire_list.questionnaire && questionnaire_list.questionnaire.icon} />
                                </div>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </div>
        </>
    )
}