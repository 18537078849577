import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchCommunityMembers, fetchCommunityMembersByFilters } from '../api';
import { communityActions } from './actions';

export function* searchMembers({ payload }) {
    yield call(fetchCommunityMembers, payload);
}

export function* searchMembersByFilters({ payload }) {
    yield call(fetchCommunityMembersByFilters, payload);
}

//Watchers
export function* watchSearchMembers() {
    yield takeEvery(communityActions.SEARCH_MEMBERS_BY_NAME, searchMembers)
}

export function* watchSearchMembersByFilters() {
    yield takeEvery(communityActions.SEARCH_MEMBERS_BY_FILTERS, searchMembersByFilters)
}

//Root
export const communitySagas = [
    fork(watchSearchMembers),
    fork(watchSearchMembersByFilters)
]
