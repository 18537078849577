import React, { Component } from 'react';
import { Media } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";

class MindPatternsMemberItem extends Component {

  render() {
    const { handleClick, member } = this.props;

    return (
      <div className="member-item" onClick={(e) => handleClick(e, member)}>
        <div className={`justify-content-lg-end flex-lg-row-reverse d-inline-flex align-items-center justify-content-between w-100 ${member.mind_pattern !== '' && 'border-top'} py-3 p-2 cursor-pointer`}>
          <p className="mb-0 text-truncate">
            {member.mind_pattern !== '' ? (
              <i className="mr-3">
                <FontAwesomeIcon icon={faUser} />
              </i>
            )
              : null
            }

            {member.mind_pattern !== '' ? ((member.first_name) ? `${member.first_name} ${member.last_name}` : member.email)
              : null
            }
          </p>

          <div className="d-flex mr-lg-5">
            {
              member.mind_pattern && member.mind_pattern.split("").map((mp, k) =>
                <div
                  key={`${member.id}-${mp}-${k}`}
                  className={`rounded-circle mx-1 mind-pattern mind-pattern-sm mind-pattern-${mp.toLowerCase()}`}
                >
                  {mp}
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default MindPatternsMemberItem;
