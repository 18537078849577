import { call, fork, takeEvery, takeLatest } from 'redux-saga/effects';

import { fetchVideos, fetchVideosLogin } from '../api';
import { videosActions } from './actions';


export function* loadVideos() {
  yield call(fetchVideos);
}

export function* loadVideosLogin() {
    yield call(fetchVideosLogin);
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchGetVideos() {
  yield takeEvery(videosActions.LOAD_VIDEOS, loadVideos);
}

export function* watchGetVideosLogin() {
    yield takeEvery(videosActions.LOAD_VIDEOS_LOGIN,loadVideosLogin)
}

//=====================================
//  ROOT
//-------------------------------------

export const videosSagas = [
  fork(watchGetVideos),
  fork(watchGetVideosLogin),
];
