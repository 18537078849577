import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Radio } from '../common'
import { Container, Row, Col, UncontrolledCollapse, Card, CardBody, Media } from 'reactstrap';
import { mind_pattern_sections } from '../../../core/constants'
import { Checkbox } from '../common'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { Disclaimers } from '../common';

const AssessmentPartTwo = (props) => {

  const { hanndleNext, question_key, questions, selected_options, current_step, hanndlePrev } = props;

  const [current_value, setCurrentValue] = useState(null);

  const toggle = (value) => {
    setCurrentValue(value)
  };

  let label_btn_next = 'Finish'
  let next = mind_pattern_sections.REPORT
  if (selected_options.size < 2) {
    next = mind_pattern_sections.ASSESSMENT_PART_TWO
    label_btn_next = 'Next'
  }

  const select_option_chbox = new Set([current_value])

  const items = questions.map(item => {
  
    const StyledCard = styled.div`
    > ul li:before {
      color: ${props => props.card_color};
    }
    `;

    const item_selected = selected_options.find(s_item => s_item === item.code)
    if (item_selected) {
      return null
    }

    if (current_step === 3 && current_value !== item.code) {
      toggle(item.code)
    }

    return <div key={item.id}>
      <Media className="py-1 py-md-2 align-items-center">
        <Media left middle className="mr-2 mr-md-4">
          <Checkbox handleCheckboxChange={toggle} select_option={select_option_chbox} label='' value={item.code} />
        </Media>
        <Media body>
          <div id={`toggler${item.id}`} className="text-uppercase cursor-pointer">
            {item.icon && <span className="ico mr-2"><img src={item.icon} alt={item.name} /></span>} {item.name}
          </div>
        </Media>
      </Media>
        <UncontrolledCollapse toggler={`#toggler${item.id}`} className={'list-mind-pattern-'+item.code.toLowerCase()}>
          <StyledCard card_color={item.color} className="styled-list" 
            dangerouslySetInnerHTML={{
              __html:
                current_step === 1 ? item.description_step1 :
                current_step === 2 ? item.description_step2 :
                item.description_step3
              }}
          >
          </StyledCard>
        </UncontrolledCollapse>
      <hr />
    </div>
  })
  return (
  <Row className="content-questions">
    <Col md={{ size: 8, offset: 2 }}>
      <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 py-3 content-view content-rearrenge d-flex flex-column">
          
          <div className="d-flex justify-content-between align-items-center header-questions mb-4 mb-md-5">
            <a className="cursor-pointer" onClick={() => hanndlePrev(mind_pattern_sections.ASSESSMENT_PART_TWO)} disabled={current_step <= 1}><FontAwesomeIcon icon={faArrowLeft} /></a>
            <div className="paginador">{current_step}<span className="text-muted">/{questions.size}</span></div>
            <a className="cursor-pointer" disabled={current_step === questions.size || !current_value}
              onClick={() => hanndleNext(next, null, current_value, mind_pattern_sections.ASSESSMENT_PART_TWO)}><FontAwesomeIcon icon={faArrowRight} /></a>
          </div>

          <Row className="contenido-after-fixed pb-2 pb-md-0">
            <Col md={{ size:10, offset: 1}}>
              <p className="h6 text-center pb-4">Read the characteristics of each card and select the one that most describes you</p>

              <div className="pb-5 pb-md-0">
              {items}
              </div>

            </Col>
          </Row>

          <Row className="contenido-bottom-fixed-mb mt-auto">
            <Col md={{ size: 6, offset: 3 }}>
              <button onClick={() => hanndleNext(next, null, current_value, mind_pattern_sections.ASSESSMENT_PART_TWO)}
                className={"btn text-uppercase btn-block rounded-pill " + (!current_value ? 'btn-outline-primary' : 'btn-primary')}
                disabled={!current_value}>{label_btn_next}</button>
            </Col>
            <Col md="12">
              <Disclaimers view="propiedad-intelectual" />
            </Col>
          </Row>
      </div>
    </Col>
  </Row>
  )

}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssessmentPartTwo)
