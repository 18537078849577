import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { currentDate } from '../../../core/helper';


export const FooterHomePage = () => {

    const [dateNow, setDateNow] = useState(null);

    useEffect(() => {
        setDateNow(currentDate());
    }, []);

    return (

        <footer className="text-muted d-md-flex mt-3 justify-content-between text-center text-md-left">
            <div>
                <Link to="/contact-us" className="text-muted welcome-link mr-md-5 d-block d-md-inline">Contact Us</Link>
                <a href="https://www.trueadvance.com/" target="_blank" className="text-muted welcome-link mr-md-5">About True Advance</a>

                {/* <Link to="/privacy" className="text-muted welcome-link mr-5">Privacy</Link> */}
                <a href="https://www.trueadvance.com/privacy-policy" className="text-muted welcome-link mr-md-5 d-block d-md-inline" target='_blank'>Privacy Policy</a>
                <a href="https://www.trueadvance.com/terms-of-use" className="text-muted welcome-link mr-md-5 d-block d-md-inline" target='_blank'>Terms of Use</a>
            </div>

            <p>Copyright True Leadership Solutions LLC. <br/> {dateNow}. All rights reserved.</p>
        </footer>

    )
}