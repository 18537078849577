import { call, fork, takeEvery } from 'redux-saga/effects';

import { fetchFullReportTTPDF, fetchFullReportMPPDF } from '../api';
import { pdfsActions } from './actions';


export function* getFullReportTT() {
    yield call(fetchFullReportTTPDF);
}

export function* getFullReportMP() {
    yield call(fetchFullReportMPPDF);
}


//=====================================
//  WATCHERS
//-------------------------------------

export function* watchGetFullReportTT() {
    yield takeEvery(pdfsActions.OPEN_FULL_REPORT_TT, getFullReportTT);
}

export function* watchGetFullReportMP() {
    yield takeEvery(pdfsActions.OPEN_FULL_REPORT_MP, getFullReportMP);
}


//=====================================
//  ROOT
//-------------------------------------

export const pdfsSagas = [
    fork(watchGetFullReportTT),
    fork(watchGetFullReportMP),
];
