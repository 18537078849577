import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Form, FormGroup, Label, Row, Col, Input, FormText, Button } from 'reactstrap';
import { profileActions } from '../../../core/profile/actions';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../../core/users';
import { ErrorPasswordPolitics } from '../common/ErrorPasswordPolitics';
import LoadingIndicator from '../loading-indicator/loading-indicator';
import { passwordStrengthChecker } from '../../../core/helper';

export const PopupBrokenPasswordPolicies = ({ isOpen, toggle }) => {
    const dispatch = useDispatch()

    const { user, profile } = useSelector(state => state);

    const errorPasswordPolitics = (profile.error && profile.error.description && profile.error.description.password_politics) || (user.pwd_politics_errors && user.pwd_politics_errors.password_politics)
    const [disabled, setDisabled] = useState(false)
    const [formErrors, setformErrors] = useState({
        password: '',
        confirmPassword: '',
    });
    const [inputValues, setInputValues] = useState({
        password: '',
        confirmPassword: ''
    });
    const strength = passwordStrengthChecker(inputValues.password)

    const onSubmitUpdatePassword = (e) => {
        e.preventDefault();
        if (formValid()) {
            let info_profile = {
                first_name: user.first_name,
                last_name: user.last_name,
                password: inputValues.password,
                confirm_password: inputValues.confirmPassword
            }
            dispatch(profileActions.updateProfile(info_profile));
        }
    }

    const formValid = () => {
        let valid = true;

        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        if(strength === 'Too weak!') valid = false

        return valid;
    };

    const handleInputChange = async ({ target }) => {
        const { name, value } = target;

        switch (name) {
            case "password":
                formErrors.password =
                    value.length < 8 ? "Minimum 8 characters required" : "";
                break;
            case "confirmPassword":
                let matchPass = inputValues.password === value ? "" : 'Passwords do not match';
                formErrors.confirmPassword = value.length < 8 ? "Minimum 8 characters required" : matchPass;
                break;
            default:
                break;
        }

        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues])

    useEffect(() => {
      if(profile.success && inputValues.password) {
          dispatch(userActions.checkPasswordAcceptedByPolitics(inputValues.password))
          toggle()
        }
    }, [profile.success]);
    
    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <h5 className='text-reset'>We are taking our commitment to your data seriously and are strengthening our security.</h5>
                <h5 className='text-reset'>We improved our password policies, please change your pass</h5>

                <Form onSubmit={onSubmitUpdatePassword} className="form-login p-3 p-md-0">
                    <Row className=''>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Input
                                    type="password"
                                    name="password"
                                    id="password"
                                    value={inputValues.password}
                                    onChange={handleInputChange}
                                    className="rounded-pill"
                                    required
                                />
                                <FormText>{formErrors.password}</FormText>
                                {inputValues.password && !formErrors.password && 
                                    <FormText>{ strength }</FormText>
                                }
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label for="confirm-pass">Confirm Password</Label>
                                <Input
                                    type="password"
                                    name="confirmPassword"
                                    id="confirm-pass"
                                    value={inputValues.confirmPassword}
                                    onChange={handleInputChange}
                                    className="rounded-pill"
                                    required
                                />
                                <FormText>{formErrors.confirmPassword}</FormText>
                            </FormGroup>
                        </Col>
                    </Row>

                    <div className="justify-content-center">
                        {errorPasswordPolitics && <ErrorPasswordPolitics errorPasswordPolitics={errorPasswordPolitics} />}
                        <center><Button color="primary" type="submit" disabled={disabled} className="text-uppercase rounded-pill px-md-5">CONFIRM</Button></center>
                    </div>
                </Form>
            </ModalBody>
            {profile.isPending && <LoadingIndicator/>}
        </Modal>
    )
}