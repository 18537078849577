import React, { Component } from 'react';
import { Container, Row, Col, CardImg } from 'reactstrap';
import intro_tt from '../../../assets/img/ilustracion-start.png'
import { Disclaimers } from '../common';

class Start extends Component {

  handleClick = (event) => {
    const { hanndleNextQuestion } = this.props
    hanndleNextQuestion('ready');
  }

  render() {
    return (
      <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 pb-md-5 pt-md-2 content-start content-view">
      <div className="px-md-4">

        <div className="d-none d-sm-none d-md-block mb-md-5 encabezado">
          <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
             <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">Thinking Talents</h6>
          </div>
          <span className="border border-info d-block"></span>
        </div>

        <Row className="start-ilustracion align-items-md-center">
          <Col md={{ size: 5 }}>
            <img top src={intro_tt} alt="Thinking Talents" className="img-fluid" />
          </Col>

          <Col md={{ size: 7 }}>
            <div className="mb-md-5 contenido-after-fixed">
              <h1 className="mb-md-5 text-center text-md-left d-md-none">Thinking Talents</h1>
              <div className="pb-5 pb-md-0">
                <p className="font-weight-semibold">Thinking Talents are the natural ways you approach a problem that actually give you energy.</p>
                <p>These are innate ways you have used to think about problems since you were young, and consequently, they have created deep pathways in your brain. Thinking Talents are different from skills, which can be learned, but can also be depleting when overused.</p>
                <p className="pb-3 pb-md-0">Research with over 2 million people shows that each of us have between 5-8 of these talents. When used together, they create your natural capacity for excellence.</p>
              </div>

            </div>
            <Row className="contenido-bottom-fixed-mb">
              <Col md={{ size: 4, offset: 4 }}>
                <button onClick={this.handleClick.bind(this)} className="btn btn-primary text-uppercase btn-block rounded-pill"
                >Next</button>
              </Col>
              <Col md="12">
              <Disclaimers view="propiedad-intelectual" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      </div>
    );
  }
}

export default Start;
