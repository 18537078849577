import React from "react";
import Slider from "react-slick";
import { SimpleSliderItem } from "./SimpleSliderItem";

export const SimpleSlider = ({ oneColumn, items, settings, dots, arrows, className, dotsClass }) => {
    var settings = {
        dots: dots,
        arrows: arrows,
        dotsClass: `slick-dots ${dotsClass ? dotsClass : ''}`,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        pauseOnHover: true,
        responsive: [
           {
             breakpoint: 768,
             settings: {
               infinite: true,
               vertical: false,
               slidesToShow: 1,
               slidesToScroll: 1,
               verticalSwiping: false,
             }
           }
         ],
         ...settings
      }

    return (
      <Slider {...settings} >
          { items?.map((item, index) => {
              return <SimpleSliderItem key={`slider-item-${index}`} oneColumn {...item} />
          })
          }
      </Slider>
    );
}