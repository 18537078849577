import React, { Component } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import classNames from 'classnames'
import { List } from 'immutable';
import styled from 'styled-components'

import logo from '../../../assets/img/true.logo.circle.png'

const StyledQuadrant = styled.div`
  display: initial;

  > h5 {
    color: ${props => props.quadrant_color};
  }

  > ul li:before {
    color: ${props => props.quadrant_color};
  }
`;

class Summary extends Component{
  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      current_item : null
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle (e, current_item) {
    e.preventDefault();
    const { modal } = this.state
      this.setState({ modal: !modal, current_item })
  }

  render() {
    const { valid, hanndleNextQuestion, items, questions } = this.props
    const { modal, current_item } = this.state

    const btn_text = 'Next'

    const offsetAngle = 360 / items.size;
    let cont = 0
    const content = items.map( s_item => {
      const rotateAngle = offsetAngle * cont;
      const item = questions.find( question => question.id === s_item.item_id );
      cont++;

      return item ? <div key={item.id} className={ 'item-summary is-'+item.id} style={{ transform: "rotate(" + rotateAngle + "deg) translate(0, -210%) rotate(-" + rotateAngle + "deg)" }}>
        <a href="#" onClick={ (e) => this.toggle( e, item ) } className="d-flex flex-column text-center text-reset text-decoration-none">
          <div className="icon position-relative">
            <img src={item.icon}/>
            <div className="icon-color" style={{backgroundColor:item.quadrant.color}}></div>
          </div>
          <p className="text-uppercase mb-0 icon-name text-nowrap"><small>{item.name}</small></p>
        </a>
      </div> : null
    })

    const content_modal = current_item ? <div className={"text-center px-md-5 modal-quadrant-"+current_item.quadrant.name} >

      <div className="d-flex">
      	<div className="d-inline-block mx-auto mb-2">
      		<img className="icon" width="130" height="130" src={current_item.icon} alt={ current_item.name } style={{backgroundColor: current_item.quadrant.color}}/>
	    </div>
      </div>
      <h2 className="text-center text-reset text-capitalize mb-3">{current_item.name}</h2>
      <StyledQuadrant quadrant_color={current_item.quadrant.color} className="text-left" dangerouslySetInnerHTML={{__html:current_item.result_description }}></StyledQuadrant>
      </div> : null

    return (
      <div className="bg-white my-md-4 rounded-lg pb-4 p-md-5 content-summary content-view">
        <Row className="no-gutters">
        <Col md={{size: 10, offset: 1}}>
        <Row>
          <Col xl={{ size: 4 }}>
            <h1 className="text-center text-md-left">Thinking Talents</h1>
            <p>Most talents fall into one of four quadrant: Analytic, Procedural, Relational, and Innovative.</p>
            <p className="h5 text-center text-md-left text-reset">TAP THE ICONS TO VIEW TALENT'S DETAIL</p>
          </Col>
          <Col xl={{ size: 8}}>
            <div className="box-list position-relative">
              <div className="list-items mx-auto">
                {content}
              </div>
            </div>
            <Row>
            <Col xl={{ size:6, offset:4 }}>
              <button onClick={ () => hanndleNextQuestion( 'report' ) } className="btn btn-primary text-uppercase btn-block rounded-pill">{btn_text}</button>
            </Col>
            </Row>
          </Col>
        </Row>
        </Col>
        </Row>

        <Modal isOpen={modal} toggle={this.toggle} className="modal-dialog-summary float-right">
          <ModalHeader toggle={this.toggle}></ModalHeader>
          <ModalBody>
            { content_modal }
          </ModalBody>
        </Modal>

      </div>
    )
  }
}

export default Summary;
