import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { ResumeMember } from './ResumeMember';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import avatar from '../../../assets/img/user-sm-icon.svg';
import { communityActions } from '../../../core/community-search/actions';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from '../loading-indicator';

export const CommunityResults = ({ filters, setFilters }) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.community)
    const { isPending } = useSelector(state => state.community)
    const [refresh, setRefresh] = useState(false)
    const toggleRefresh = () => setRefresh(!refresh)

    const goToPage = (page) => {
        // typeof (data.search) === 'string'
        //     ? dispatch(communityActions.searchMembers(data.search, page))
        //     : dispatch(communityActions.searchMemberByFilters(data.search ? data.search : {}, page))
        dispatch(communityActions.searchMemberByFilters(data.search ? data.search : {}, page))
    }

    const calculatePageNumber = (position) => {
        if (data && data.current_page) {
            let page = 1;
            switch (position) {
                case 1: data.current_page <= 3 ? page = position : data.current_page + 2 >= data.total_pages ? page = data.total_pages - 4 : page = data.current_page - 2; break;
                case 2: data.current_page <= 3 ? page = position : data.current_page + 2 >= data.total_pages ? page = data.total_pages - 3 : page = data.current_page - 1; break;
                case 3: data.current_page <= 3 ? page = position : data.current_page + 2 >= data.total_pages ? page = data.total_pages - 2 : page = data.current_page; break;
                case 4: data.current_page <= 3 ? page = position : data.current_page + 2 >= data.total_pages ? page = data.total_pages - 1 : page = data.current_page + 1; break;
                case 5: data.current_page <= 3 ? page = position : data.current_page + 2 >= data.total_pages ? page = data.total_pages - 0 : page = data.current_page + 2; break;
            }
            return data.total_pages > 5 ? page : position;
        }
    }

    const getMemberByFilters = () => {
        let formatFilters = {}
        formatFilters['name'] = data.search && data.search.name
        filters.forEach(filter => {
            if (formatFilters[filter.type])
                formatFilters[filter.type].push(filter && filter.type === 'mind_patterns' ? { mp: filter.mp } : { id: filter.id })
            else {
                formatFilters[filter.type] = []
                formatFilters[filter.type].push(filter && filter.type === 'mind_patterns' ? { mp: filter.mp } : { id: filter.id })
            }
        });
        dispatch(communityActions.searchMemberByFilters(formatFilters, 1))
    }

    const deleteFilter = (id, type) => {
        let filter = filters
        filter.splice(filter.map(function (item) {
            if(item.type === type) return item.id
        }).indexOf(id), 1)
        setFilters(filter)
        //toggleRefresh()
        getMemberByFilters()
    }

    return (
        <>
            <div className="ml-lg-5 d-flex flex-wrap align-items-center">
                {data && data.total_items > 0 && <p className="mb-0 mr-3 text-nowrap"><strong>{data.total_items}</strong> results</p>}
                <div className="d-flex flex-wrap results-items">
                    {filters && filters.map((filter, index) => {
                        return <Button key={index} onClick={() => deleteFilter(filter.id, filter.type)} size="sm" color="yellow" className="rounded-pill px-2 pr-4 text-left mr-3 my-2">{filter.name}</Button>
                    })
                    }
                </div>
            </div>

            <div className="p-3 bg-celeste border-top border-bottom d-none d-md-block">
                <Row>
                    <Col md="3"><h6 className="text-uppercase text-petrol font-weight-semibold small mb-0">Info Member</h6></Col>
                    <Col md="6"><h6 className="text-uppercase text-petrol font-weight-semibold small mb-0 text-center">Thinking talents</h6></Col>
                    <Col md="3"><h6 className="text-uppercase text-petrol font-weight-semibold small mb-0 text-center">Mind Patterns</h6></Col>
                </Row>
            </div>

            <hr className="border-top d-block d-md-none w-100 my-sm-2 my-md-0" />

            { data && !isPending && data.users && data.users.map(user => {
                    return <ResumeMember key={user.id} user={user} first_name={user.first_name} last_name={user.last_name} rol={user.seniority && user.seniority.name ? user.seniority.name : ''} email={user.email} avatar={user.avatar} mind_pattern={user.mind_pattern} />
                })
            }

            {data && data.current_page && data.total_pages !== null && data.search && data.total_pages > 1 &&
                <Pagination className="mt-auto d-none d-md-block pt-3" cssModule={{ 'pagination': 'pagination justify-content-center align-items-center' }}>
                    {/* <PaginationItem disabled={(data.current_page === 1)} ><PaginationLink className="text-nowrap" first onClick={() => goToPage(1)} children={[<><FontAwesomeIcon icon={faChevronLeft} /><FontAwesomeIcon icon={faChevronLeft} /></>]} /></PaginationItem> */}
                    <PaginationItem disabled={(data.current_page === 1)} ><PaginationLink previous onClick={() => goToPage(data.current_page - 1)} children={[<FontAwesomeIcon icon={faChevronLeft} />]} /></PaginationItem>

                    {data.current_page > 3 && data.total_pages > 5 && <>
                        <PaginationItem> <PaginationLink onClick={() => goToPage(1)}> {1} </PaginationLink></PaginationItem>
                        <PaginationItem> <PaginationLink > ... </PaginationLink></PaginationItem>
                    </>
                    }

                    {calculatePageNumber(1) <= data.total_pages && <PaginationItem disabled={calculatePageNumber(1) > data.total_pages} active={(data.current_page === calculatePageNumber(1))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(1))}>   {calculatePageNumber(1)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(2) <= data.total_pages && <PaginationItem disabled={calculatePageNumber(2) > data.total_pages} active={(data.current_page === calculatePageNumber(2))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(2))}>   {calculatePageNumber(2)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(3) <= data.total_pages && <PaginationItem disabled={calculatePageNumber(3) > data.total_pages} active={(data.current_page === calculatePageNumber(3))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(3))}>   {calculatePageNumber(3)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(4) <= data.total_pages && <PaginationItem disabled={calculatePageNumber(4) > data.total_pages} active={(data.current_page === calculatePageNumber(4))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(4))}>   {calculatePageNumber(4)}  </PaginationLink></PaginationItem>}
                    {calculatePageNumber(5) <= data.total_pages && <PaginationItem disabled={calculatePageNumber(5) > data.total_pages} active={(data.current_page === calculatePageNumber(5))}>  <PaginationLink onClick={() => goToPage(calculatePageNumber(5))}>   {calculatePageNumber(5)}  </PaginationLink></PaginationItem>}

                    {calculatePageNumber(5) + 1 <= data.total_pages && <>
                        <PaginationItem> <PaginationLink > ... </PaginationLink></PaginationItem>
                        <PaginationItem> <PaginationLink onClick={() => goToPage(data.total_pages)}> {data.total_pages} </PaginationLink></PaginationItem>
                    </>
                    }

                    <PaginationItem disabled={(data.current_page === data.total_pages)} ><PaginationLink next onClick={() => goToPage(data.current_page + 1)} children={[<FontAwesomeIcon icon={faChevronRight} />]} /></PaginationItem>
                    {/* <PaginationItem disabled={(data.current_page === data.total_pages)} ><PaginationLink className="text-nowrap" last onClick={() => goToPage(data.total_pages)} children={[<><FontAwesomeIcon icon={faChevronRight} /><FontAwesomeIcon icon={faChevronRight} /></>]} /></PaginationItem> */}
                </Pagination>
            }

            {isPending &&
                <LoadingIndicator />
            }

        </>
    )
}