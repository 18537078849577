
import { questionnaireStoreOptionsActions } from './actions';
import { QuestionnaireDataStoreOptions } from './questionnaireStoreOptions';

export function questionnaireOptionStoreDataReducer(state = new QuestionnaireDataStoreOptions(),
    { payload, type }) {

    switch (type) {
        case questionnaireStoreOptionsActions.FETCH_QUESTIONNAIRE_STOREOPTIONS_FAILED:
            return state.merge({
                isPending: false,
                error: payload.error
            });

        case questionnaireStoreOptionsActions.FETCH_QUESTIONNAIRE_STOREOPTIONS_SUCCESS:
            return state.withMutations(questionnaireData => {
                questionnaireData.merge({
                    isNew: false,
                    isPending: false,
                    data: payload.data
                });
            });

        case questionnaireStoreOptionsActions.FETCH_QUESTIONNAIRE_STOREOPTIONS_PENDING:
            return state.set('isPending', true);

        // case questionnaireStoreOptionsActions.SAVE_QUESTIONNAIRE_STOREOPTIONS:
        //     return state.merge({
        //         data: payload.data,
        //         isNew: false,
        //         isPending: false,
        //     });
        default:
            return state;
    }
}
