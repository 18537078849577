import React, { Component } from 'react'
import classNames from 'classnames'
import { Container, Row, Col, Card, CardBody, CardText } from 'reactstrap';
import check_ok from '../../../assets/img/check-ok.svg'
import iconCongratulations from '../../../assets/img/ico-congratulation.svg'
import iconAllDone from '../../../assets/img/ico-all-done.svg'
import { Disclaimers } from '../common';

class Result extends Component {


  render() {
    const { validations, hanndleNextQuestion, items, response_range, section, prevState } = this.props

    const btn_text = 'Next'

    const resultClass = classNames('content-result', {
      'error-validation': !validations.valid,
    });

    const styleResult = {
      backgroundColor: "#66b2bd",
    }

    const range_group_1 = response_range.get("1")
    const range_group_2 = response_range.get("2")
    const range_group_3 = response_range.get("3")

    let error_group_1 = null;
    let error_group_2 = null;
    let error_group_3 = null;

    if (!validations.group_1.valid && validations.group_1.error === 'min') {
      error_group_1 = <Card className="bg-light border-0">
        <CardBody className="py-2 px-3">
          <CardText>
            <small>The minimum number for talents for the "Always" bucket is {range_group_1.min}. In the next screen, please add the strongest talent(s) from your "Sometimes" bucket.</small>
          </CardText>
        </CardBody>
      </Card>
    }

    if (!validations.group_1.valid && validations.group_1.error === 'max') {
      error_group_1 = <Card className="bg-light border-0">
        <CardBody className="py-2 px-3">
          <CardText>
            <small>In the next screen, please select additional talents that aren't quite as strong and mark them as "Sometimes" to narrow the list down to your top {range_group_1.max}.</small>
          </CardText>
        </CardBody>
      </Card>
    }

    if (!validations.group_2.valid && validations.group_2.error === 'min') {
      error_group_2 = <Card className="bg-light border-0">
        <CardBody className="py-2 px-3">
          <CardText>
            <small>The minimum number for talents for the "Sometimes" bucket is {range_group_2.min}. In the next screen, please add the talent(s).</small>
          </CardText>
        </CardBody>
      </Card>
    }

    if (!validations.group_2.valid && validations.group_2.error === 'max') {
      error_group_2 = <Card className="bg-light border-0">
        <CardBody className="py-2 px-3">
          <CardText>
            <small>The maximum number for talents for the "Sometimes" bucket is {range_group_2.max}. In the next screen, please remove the talent (s) to your "Sometimes" bucket.</small>
          </CardText>
        </CardBody>
      </Card>
    }

    if (!validations.group_3.valid && validations.group_3.error === 'min') {
      error_group_3 = <Card className="bg-light border-0">
        <CardBody className="py-2 px-3">
          <CardText>
            <small>The minimum number for talents for the "Never" bucket is {range_group_3.min}. In the next screen, please add the talent(s).</small>
          </CardText>
        </CardBody>
      </Card>
    }

    if (!validations.group_3.valid && validations.group_3.error === 'max') {
      error_group_3 = <Card className="bg-light border-0">
        <CardBody className="py-2 px-3">
          <CardText>
            <small>The maximum number for talents for the "Never" bucket is {range_group_3.max}. In the next screen, please remove the talent (s) to your "Never" bucket.</small>
          </CardText>
        </CardBody>
      </Card>
    }

    const show_rearrenge = (section === 'result_1' || !validations.valid)

    return (
      <Row className={resultClass}>
        <Col md={{ size: 8, offset: 2 }}>
          <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 py-3 content-view d-flex flex-column">

            <div className="encabezado d-none d-md-block mb-4">
               <div className="d-flex justify-content-center">
                 <h6 className="title-underline mb-1">Thinking Talents</h6>
               </div>
               <span className="border border-info d-block"></span>
            </div>

            <Row className="mb-3 position-relative">
              <Col lg={{ size: 8, offset: 2 }}>
                <div>
                  <h1 className="text-center d-md-none">Thinking Talents</h1>

                  {validations.valid && !prevState && <h5 className="text-reset text-center mb-3">All Done!!</h5>}
                  <h5 className="text-center text-reset mb-4">Your Thinking Talent</h5>

                  <div className="d-flex">
                    {validations.valid && <div className="d-inline-block mx-auto mb-3"><img src={iconAllDone} height="120" alt="All Done" /></div>}
                    {!validations.valid && <div className="d-inline-block mx-auto mb-2"><img src={iconCongratulations} height="120" alt="Congratulations" /></div>}
                  </div>

                  {!prevState &&
                    <p className="text-center mb-4"><b>Congratulations!</b> You have identified the Thinking Talents that give you energy:</p>
                  }
                  {!validations.valid}
                </div>

                <hr/>

                <div className="d-flex justify-content-center">
                  <div className="px-2 px-md-4 text-center">
                    <p className="text-uppercase mb-2">Always</p>
                    <div style={styleResult} className={"icons-result mx-auto rounded-circle text-center h3 text-white mb-0" + (validations.group_1.valid ? '' : ' border-danger border')}>{items.group_1.size}</div>
                    {!validations.group_1.valid && <h6 className="text-danger"><small>{validations.group_1.message}</small></h6>}
                  </div>
                  <div className="px-2 px-md-4  text-center">
                    <p className="text-uppercase mb-2">Sometimes</p>
                    <div style={{ backgroundColor: '#dbce88' }} className={"icons-result mx-auto rounded-circle text-center h3 text-white mb-0" + (validations.group_2.valid ? '' : ' border-danger border')}>{items.group_2.size}</div>
                    {!validations.group_2.valid && <h6 className="text-danger"><small>{validations.group_2.message}</small></h6>}
                  </div>
                  <div className="px-2 px-md-4  text-center">
                    <p className="text-uppercase mb-2">Never</p>
                    <div style={{ backgroundColor: '#a3a3a3' }} className={"icons-result mx-auto rounded-circle text-center h3 text-white mb-0" + (validations.group_3.valid ? '' : ' border-danger border')}>{items.group_3.size}</div>
                    {!validations.group_3.valid && <h6 className="text-danger"><small>{validations.group_3.message}</small></h6>}
                  </div>
                </div>
                {validations.valid && <div className="mt-3 border border-info rounded text-center p-3 mx-lg-4"><p className="mb-0">On the next screen, you can review the talents you chose and rearrange them if you like.</p></div>}

                {error_group_1}
                {error_group_2}
                {error_group_3}

              </Col>
            </Row>

            <div className="text-center mt-auto">

              {show_rearrenge &&
                <Row>
                  <Col md={{ size: 4, offset: 4 }}>
                    <button onClick={() => hanndleNextQuestion('rearrenge')} className="btn btn-md-sm btn-primary text-uppercase btn-block rounded-pill">{btn_text}</button>
                  </Col>
                </Row>
              }
              {!show_rearrenge && <>
                <p className="h4 text-center text-reset">Tap Next to map your talents</p>
                <Row>
                  <Col md={{ size: 4, offset: 4 }}>
                    <button onClick={() => hanndleNextQuestion('summary')} className="btn btn-md-sm btn-primary text-uppercase btn-block rounded-pill">{btn_text}</button>
                  </Col>
                </Row>
              </>
              }
            </div>
            <Disclaimers view="propiedad-intelectual" />
          </div>
        </Col>
      </Row>
    )
  }
}

export default Result;
