


export const questionnaireItemsActions = {
    FETCH_QUESTIONNAIRE_ITEMS_FAILED: 'FETCH_QUESTIONNAIRE_ITEMS_FAILED',
    FETCH_QUESTIONNAIRE_ITEMS_SUCCESS: 'FETCH_QUESTIONNAIRE_ITEMS_SUCCESS',
    FETCH_QUESTIONNAIRE_ITEMS_PENDING: 'FETCH_QUESTIONNAIRE_ITEMS_PENDING',
    LOAD_QUESTIONNAIRE_ITEMS: 'LOAD_QUESTIONNAIRE_ITEMS',
    RESET_QUESTIONNAIRE_ITEMS: 'RESET_QUESTIONNAIRE_ITEMS',
    NEXT_QUESTION: 'NEXT_QUESTION',

    fetchQuestionnaireItemsSuccess: (id, data) => ({
        type: questionnaireItemsActions.FETCH_QUESTIONNAIRE_ITEMS_SUCCESS,
        payload: {
            id,
            data
        }
    }),

    fetchQuestionnaireItemsFailed: error => ({
        type: questionnaireItemsActions.FETCH_QUESTIONNAIRE_ITEMS_FAILED,
        payload: error
    }),

    fetchQuestionnaireItemsPending: () => ({
        type: questionnaireItemsActions.FETCH_QUESTIONNAIRE_ITEMS_PENDING,
    }),

    loadQuestionnaireItems: (id) => ({
        type: questionnaireItemsActions.LOAD_QUESTIONNAIRE_ITEMS,
        payload: {
            id
        }
    }),

    nextQuestionQuestionnaireItems : () => ({
        type: questionnaireItemsActions.NEXT_QUESTION
    })
};


export const questionnaireItemsRequestActions = {
    failed: questionnaireItemsActions.fetchQuestionnaireItemsFailed,
    success: questionnaireItemsActions.fetchQuestionnaireItemsSuccess,
    pending: questionnaireItemsActions.fetchQuestionnaireItemsPending
};
