import React from 'react';
import { Col, Row } from 'reactstrap';

export const Header = ({ icon, text }) => {

    return (
        <>
            <div className="border-bottom pb-3">
                <div className="d-flex align-items-center mb-3 mb-md-0" >
                    <div className="mr-4">
                        {icon && <img src={icon} alt="new_member_discovery" width="60" />}
                    </div>
                    <div className="d-flex" >
                        <div><h6 className="mb-0 text-reset">{text}</h6></div>
                    </div>
                </div>

            </div>
        </>
    )
}