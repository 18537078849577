import { DEFAULT_SESSION_ID } from '../constants';

export const teamActions = {

  FETCH_TEAM_FAILED: 'FETCH_TEAM_FAILED',
  FETCH_TEAM_SUCCESS: 'FETCH_TEAM_SUCCESS',
  FETCH_TEAM_PENDING: 'FETCH_TEAM_PENDING',

  LOAD_TEAM: 'LOAD_TEAM',
  CREATE_TEAM: 'CREATE_TEAM',
  UPDATE_TEAM: 'UPDATE_TEAM',
  ADD_MEMBERS_TEAM: 'ADD_MEMBERS_TEAM',
  RESET_TEAM: 'RESET_TEAM',

  REMOVE_TEAM_MEMBER: 'REMOVE_TEAM_MEMBER',
  SET_LEADER_TEAM_MEMBER: 'SET_LEADER_TEAM_MEMBER',

  DELETE_TEAM: 'DELETE_TEAM',

  FETCH_DEFAULT_TEAM_PENDING_FALSE: 'FETCH_DEFAULT_TEAM_PENDING_FALSE',

  fetchTeamFailed: (error, team_id) => ({
    type: teamActions.FETCH_TEAM_FAILED,
    payload: {
      data: error,
      team_id
    }
  }),

  fetchTeamSuccess: (team_id, data) => ({
    type: teamActions.FETCH_TEAM_SUCCESS,
    payload: {
      data,
      team_id: (team_id === DEFAULT_SESSION_ID && data.id) ? `${data.id}` : `${team_id}`
    }
  }),

  fetchTeamPending: team_id => {
    return ({
      type: teamActions.FETCH_TEAM_PENDING,
      payload: {
        team_id
      }
    })
  },

  fetchTeamDefaultPendingFalse: () => {
    return ({
      type: teamActions.FETCH_DEFAULT_TEAM_PENDING_FALSE,
      payload: {
        team_id: DEFAULT_SESSION_ID
      }
    })
  },

  loadTeam: team_id => ({
    type: teamActions.LOAD_TEAM,
    payload: {
      team_id: `${team_id}`
    }
  }),

  createTeam: team => ({
    type: teamActions.CREATE_TEAM,
    payload: {
      team,
      team_id: DEFAULT_SESSION_ID
    }
  }),

  updateTeam: team => ({
    type: teamActions.UPDATE_TEAM,
    payload: {
      team,
      team_id: `${team.id}`
    }
  }),

  addMembers: data => ({
    type: teamActions.ADD_MEMBERS_TEAM,
    payload: {
      ...data,
    }
  }),

  removeTeamMember: (team_id, member_id) => ({
    type: teamActions.REMOVE_TEAM_MEMBER,
    payload: {
      team_id: team_id,
      member_id
    }
  }),

  setLeaderTeamMember: (team_id, member_id) => ({
    type: teamActions.SET_LEADER_TEAM_MEMBER,
    payload: {
      team_id: team_id,
      member_id
    }
  }),

  deleteTeam: team_id => ({
    type: teamActions.DELETE_TEAM,
    payload: {
      team_id: `${team_id}`
    }
  }),

  resetTeam: team_id => ({
    type: teamActions.RESET_TEAM,
    payload: {
      team_id: `${team_id}`
    }
  }),


};

export const teamRequestActions = {
  failed: teamActions.fetchTeamFailed,
  success: teamActions.fetchTeamSuccess,
  pending: teamActions.fetchTeamPending
};
