import React from 'react';
import { useSelector } from 'react-redux';
import { getSystemById } from '../../../core/system';
import { SYSTEM_ID_SENIORITIES } from '../../../core/constants';
import { Row, Col, Modal, ModalHeader, ModalBody, FormGroup, CustomInput, Form, Button } from 'reactstrap';

export const ModalRoles = ({ open, toggle, handleInputChange, filters, getMemberByFilters, showApply = true }) => {
    const state = useSelector(state => state)
    const seniorities = getSystemById(state, SYSTEM_ID_SENIORITIES)

    return (
        <Modal isOpen={open} toggle={toggle} className="modal-dialog-summary float-right">
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <Row>
                    <Col xs="10"><h5 className="mb-0 mt-3 font-weight-semibold px-4">Roles</h5></Col>
                    <Col md="10"><span className="border border-info d-block mt-2"></span>
                        <div className="bg-celeste py-2 px-4 rounded my-2">
                            <p className="text-reset mb-0">Select roles and next press Apply botton</p>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md="10">
                        <Form onSubmit={e => { e.preventDefault() }}>
                            <FormGroup >
                                <div className="columns-md-2">
                                    {/* importante que los customInput tengan el id */}
                                    {seniorities && seniorities.items && seniorities.items.map((seniority, index) => {
                                        return <CustomInput key={'Modal-seniority-' + seniority.id} checked={filters && filters.find(filter => filter.type === 'seniorities' && filter.id === seniority.id)} onChange={({ target }) => handleInputChange(target, seniority.name, 'seniorities', null, true)} bsSize="sm" className="styled-checkbox pl-4 py-1" type="checkbox" label={seniority.name} value={seniority.id} id={'Modal-seniority-' + seniority.id} />
                                    })
                                    }
                                </div>
                            </FormGroup>
                            {showApply && <div className="text-center">
                                <Button className="mx-auto text-uppercase rounded-pill px-5" onClick={() => { toggle(); getMemberByFilters() }} outline color="primary">Apply</Button>
                            </div>
                            }
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}