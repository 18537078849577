import React, { Component, useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DEFAULT_SESSION_ID, SYSTEM_ID_SENIORITIES } from '../../../../core/constants';
import { registerActions } from '../../../../core/register/actions';
import { getSystemById, systemActions } from '../../../../core/system';
import { FormRegister } from './form-register';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Link } from 'react-router-dom';
import LoadingIndicator from '../../../components/loading-indicator';
import { passwordStrengthChecker } from '../../../../core/helper';

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

const RegisterPage = ({ seniorities, loadSystem, registerUser, register, resetError }) => {
    const history = useHistory();
    const list_seniorities = seniorities && seniorities.items;
    const [formErrors, setformErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        alternativeEmail: '',
        confirmAlternativeEmail: '',
        password: '',
        confirmPassword: '',
        senioritySelect: ''
    });
    const [inputValues, setInputValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        confirmEmail: '',
        alternativeEmail: '',
        confirmAlternativeEmail: '',
        password: '',
        confirmPassword: '',
        senioritySelect: '',
        termsAndPrivacy: false
    })

    useEffect(() => {
        loadSystem(SYSTEM_ID_SENIORITIES)

        return () => {
            resetError()
        }
    }, [])

    const handleInputChange = async ({ target }) => {
        let { name, value } = target;

        switch (name) {
            case "firstName":
                formErrors.firstName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;
            case "lastName":
                formErrors.lastName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                formErrors.confirmEmail = (inputValues.confirmEmail && inputValues.confirmEmail !== value)
                    ? "Emails do not match"
                    : ""
                break;
            case "confirmEmail":
                let matchEmail = inputValues.email === value ? '' : 'Emails do not match';
                formErrors.confirmEmail = emailRegex.test(value)
                    ? matchEmail
                    : "Invalid email address";
                break;
            case "alternativeEmail":
                if (value) {
                    formErrors.alternativeEmail = emailRegex.test(value)
                        ? ""
                        : "Invalid email address";
                    formErrors.confirmAlternativeEmail = (inputValues.confirmAlternativeEmail && inputValues.confirmAlternativeEmail !== value)
                        ? "Emails do not match"
                        : ""
                } else {
                    formErrors.alternativeEmail = ''
                    formErrors.confirmAlternativeEmail = ''
                }
                break;
            case "confirmAlternativeEmail":
                if (inputValues.alternativeEmail) {
                    let matchAlternativeEmail = inputValues.alternativeEmail === value ? '' : 'Emails do not match';
                    formErrors.confirmAlternativeEmail = emailRegex.test(value)
                        ? matchAlternativeEmail
                        : "Invalid email address";
                }
                break;
            case "password":
                let passwordStrength = passwordStrengthChecker(value) === 'Too weak!' ? 'Too weak!' : ''
                formErrors.password = value.length < 8 ? "Minimum 8 characters required" : passwordStrength;

                if(inputValues.confirmPassword) {
                    formErrors.confirmPassword = inputValues.confirmPassword === value ? '' : 'Passwords do not match';
                }
                break;
            case "confirmPassword":
                formErrors.confirmPassword = inputValues.password === value ? '' : 'Passwords do not match';
                break;
            case "termsAndPrivacy":
                value = target.checked
                break;
            default:
                break;
        }

        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    useEffect(() => {
        if(register?.error?.data) {

        }
    }, register.error)

    const onSubmitRegister = async (e) => {
        e.preventDefault();
        if (formValid()) {
            let newRegister = {
                email: inputValues.email,
                // alternative_email: inputValues.alternativeEmail,
                password: inputValues.password,
                first_name: inputValues.firstName,
                last_name: inputValues.lastName,
                seniority_id: inputValues.senioritySelect,
                accept_terms: inputValues.termsAndPrivacy
            }
            registerUser({ newRegister, history })
        }
    }

    const formValid = () => {
        let valid = true;
        let keysNotRequired = ['alternativeEmail', 'confirmAlternativeEmail']

        // validate form errors being empty
        // Object.values(formErrors).forEach(val => {
        //     val.length > 0 && (valid = false);
        // });

        // validate the form was filled out
        // Object.values(inputValues).forEach(val => {
        //     val === '' && (valid = false);
        // });

        // validate the form was filled out
        Object.keys(inputValues).forEach((key) => {
            if (!keysNotRequired.includes(key)) {
                inputValues[key] == '' && (valid = false);
            }
        })

        if (inputValues.alternativeEmail && !inputValues.confirmAlternativeEmail) valid = false

        return valid;
    };

    return (
        <div className='view overflow-hidden'>
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='register' appear>
                    <div className="register-page">
                        <Container>
                            <div className="px-2 px-md-0 my-md-4">
                                <div className="content-view">
                                    <h2 className="pl-3 pl-md-0 rubik">Register</h2>
                                    <FormRegister
                                        inputValues={inputValues}
                                        handleInputChange={handleInputChange}
                                        listSeniorities={list_seniorities}
                                        onSubmitRegister={onSubmitRegister}
                                        formErrors={formErrors}
                                        formValid={formValid}
                                    />
                                    {
                                        register.error.email &&
                                        <center style={{ marginTop: "40px" }}>
                                            {register.error.email[0]}
                                        </center>
                                    }

                                    {
                                        register.isPending && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />
                                    }

                                    <hr className="d-none d-md-block mt-4 mb-5" />
                                    <Link to={'/login'} className="btn btn-link text-reset text-uppercase rounded-pill px-md-5 btn-block d-md-none">LOGIN</Link>

                                </div>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </TransitionGroup>

        </div>
    )
}

const mapStateToProps = (state, props) => {
    const seniorities = getSystemById(state, SYSTEM_ID_SENIORITIES);
    const { register } = state;
    return {
        seniorities,
        register
    };
};

const mapDispatchToProps = {
    loadSystem: systemActions.loadSystem,
    registerUser: registerActions.createUser,
    resetError: registerActions.resetError
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterPage);
