import { call, fork, select, takeLatest } from 'redux-saga/effects';

import { findMembers, searchMembers } from '../api';

import { membersListActions } from './actions';

import { getCurrentMembersList, getMembersListById } from './selectors';

export function* searchMembersList({payload}) {
  const { membersListId, filters } = payload;

  yield call(searchMembers, membersListId, filters);

}

export function* findMembersList({payload}) {
  const { membersListId, name } = payload;

  const membersList = yield select(getMembersListById, membersListId);

  if (membersList && membersList.isNew ){
    yield call(findMembers, membersListId, { name });
  }

}

export function* findMembersById({payload}) {
  const { membersListId } = payload;

  const membersList = yield select(getMembersListById, membersListId);

}


//=====================================
//  WATCHERS
//-------------------------------------

export function* watchSearchMembersList() {
  yield takeLatest(membersListActions.SEARCH_MEMBERS, searchMembersList);
}

export function* watchFinMembersList() {
  yield takeLatest(membersListActions.FIND_MEMBERS, findMembersList);
}

export function* watchFinMembersById() {
  yield takeLatest(membersListActions.FIND_MEMBERS_BY_ID, findMembersById);
}



//=====================================
//  ROOT
//-------------------------------------

export const membersListSagas = [
  fork(watchSearchMembersList),
  fork(watchFinMembersList),
  fork(watchFinMembersById),

];
