import { createSelector } from 'reselect';

export function getQuestionsList(state) {
  return state.questions;
}

export function getQuestionsListById(state, questionsListId) {
  return getQuestionsList(state).get(questionsListId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentQuestionsList = createSelector(
  getQuestionsList,
  questions => questions.get(questions.get('id'))
);
