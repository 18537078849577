export const mindPatternsDataActions = {

  FETCH_MINDPATTERNS_FAILED: 'FETCH_MINDPATTERNS_FAILED',
  FETCH_MINDPATTERNS_SUCCESS: 'FETCH_MINDPATTERNS_SUCCESS',
  FETCH_MINDPATTERNS_PENDING: 'FETCH_MINDPATTERNS_PENDING',
  LOAD_MINDPATTERNS: 'LOAD_MINDPATTERNS',


  fetchMindPatternsDataFailed: error => ({
    type: mindPatternsDataActions.FETCH_MINDPATTERNS_FAILED,
    payload: {
      error
    }
  }),

  fetchMindPatternsDataSuccess: (mindPatternsDataId, data) => ({
    type: mindPatternsDataActions.FETCH_MINDPATTERNS_SUCCESS,
    payload: {
      data,
      mindPatternsDataId
    }
  }),

  fetchMindPatternsDataPending: mindPatternsDataId => ({
    type: mindPatternsDataActions.FETCH_MINDPATTERNS_PENDING,
    payload: {
      mindPatternsDataId
    }
  }),

  loadMindPatternsData: (mindPatternsDataId) => ({
    type: mindPatternsDataActions.LOAD_MINDPATTERNS,
    payload: {
      mindPatternsDataId
    }
  }),
};


export const mindPatternsDataRequestActions = {
  failed: mindPatternsDataActions.fetchMindPatternsDataFailed,
  success: mindPatternsDataActions.fetchMindPatternsDataSuccess,
  pending: mindPatternsDataActions.fetchMindPatternsDataPending
};
