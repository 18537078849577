import React, { useState, useRef } from 'react'
import ReactPlayer from 'react-player/youtube'
import { ModalVideo } from '../../components/videos/ModalVideo';

export const ThumbnailVideo = ({ video, index, videos, className, openable = true }) => {
    const [modalVideo, setModalVideo] = useState(false);
    const toggleModalVideo = () => setModalVideo(!modalVideo);

    return (
        <>
            <div className={`player-wrapper ${className}`}>
                <div className="player-overlay" style={{cursor: 'pointer'}} onClick={() => setModalVideo(true)}></div>
                <div className="embed-responsive embed-responsive-16by9 rounded-lg">
                <ReactPlayer className="embed-responsive-item" url={`http://www.youtube.com/embed/${video.code}`}
                    width='100%'
                    height='100%'
                    playing={false}
                    light
                    playIcon=''
                    muted
                    config={{
                        youtube: {
                            playerVars: { showinfo: 0, embedOptions: 0, disablekb: 1, frameborder: 0, rel: 0 }
                        },
                    }} />
                </div>
                {video.text &&
                    <p className="my-2 video-subtitle" dangerouslySetInnerHTML={{ __html: video.text}}></p>
                }
                {openable && <ModalVideo video={video} index={index} videos={videos} toggle={toggleModalVideo} open={modalVideo}></ModalVideo>}
            </div>
        </>
    );
}