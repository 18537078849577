import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Label, Col, Row, FormGroup, FormText } from 'reactstrap';

export const ContactForm = ({ formErrors, handleInputChange, inputValues, onSubmitRegister, formValid }) => {
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues])

    return (
        <>
            <Form
                onSubmit={onSubmitRegister}
                className="form-login p-4 p-md-0 shadow rounded-lg bg-white"
            >
                <Row>
                    <Col sm={{ size: 6, offset: 3 }}>
                        <FormGroup className="required mb-3">
                            <Label className="control-label" for="name">Name</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                value={inputValues.name}
                                onChange={handleInputChange}
                            />
                            <FormText>{formErrors.name}</FormText>
                        </FormGroup>
                        <FormGroup className="mb-3">
                            <Label for="phone">Phone</Label>
                            <Input
                                type="number"
                                name="phone"
                                id="phone"
                                value={inputValues.phone}
                                onChange={handleInputChange}
                            />
                            <FormText>{formErrors.phone}</FormText>
                        </FormGroup>
                        <FormGroup className="required mb-3">
                            <Label className="control-label" for="email">Email</Label>
                            <Input
                                type="text"
                                name="email"
                                id="email"
                                value={inputValues.email}
                                onChange={handleInputChange}
                            />
                            <FormText>{formErrors.email}</FormText>
                        </FormGroup>
                        <FormGroup className="required mb-3">
                            <Label className="control-label" for="comments">Comments</Label>
                            <Input
                                type="textarea"
                                name="comments"
                                id="comments"
                                style={{ height: 120 }}
                                value={inputValues.comments}
                                onChange={handleInputChange}
                            />
                            <FormText>{formErrors.comments}</FormText>
                        </FormGroup>
                        <Button
                            color="primary"
                            type="submit"
                            className="text-uppercase rounded-pill px-md-5 btn-block"
                            disabled={disabled}
                        >
                            Send
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
