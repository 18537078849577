import { List, Record, Map } from 'immutable';

export const TeamsFullReport = new Record({
  id: null,
  isNew: true,
  isPending: false,
  error:null,
  update_at: new Date(),
  items: new List()
});
