import React from 'react';

export const QuadrantItem = ({ item, quadrant, right, center, version, collapsed = false }) => {
    return (
        <>
            {collapsed &&
                <span className={`mb-0 ${right ? 'flex-row-reverse' : ''}`} id={quadrant.quadrant}>
                    <i className={`icon ${center ? 'mr-1' : right ? 'ml-1' : 'mr-1'}`}>
                        <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill={quadrant.color} /></svg>
                    </i>
                </span>
            }
            {!collapsed &&
                <div>
                    {version === 'small' &&
                        <h6 className={`text-reset text-capitalize mb-0 d-flex align-items-center ${right ? 'flex-row-reverse' : ''}`} id={quadrant.quadrant} style={{ fontWeight: '500' }}>
                            <i className={`icon ${right ? 'ml-1' : 'mr-1'}`}>
                                <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill={quadrant.color} /></svg>
                            </i>
                            <span className="text-nowrap" style={{ lineHeight: '19px', paddingTop: '3px' }}>{item.name}</span>
                        </h6>
                    }
                    {version != 'small' &&
                        <h6 className={`text-reset text-capitalize mb-0 d-flex align-items-center ${right ? 'flex-row-reverse flex-md-row' : 'flex-md-row-reverse'}`} id={quadrant.quadrant} style={{ fontWeight: '500' }}>
                            <i className={`icon ${right ? 'ml-1 ml-md-0 mr-md-1' : 'mr-1 mr-md-0 ml-md-1'}`}>
                                <svg width="10" height="10"><circle cx="5" cy="5" r="5" fill={quadrant.color} /></svg>
                            </i>
                            <span className="text-nowrap" style={{ lineHeight: '19px', paddingTop: '3px' }}>{item.name}</span>
                        </h6>
                    }
                </div>
            }
        </>
    )
}
