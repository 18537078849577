import React, { Component } from 'react'
import { Row, Col } from 'reactstrap';

import { mind_pattern_sections } from '../../../core/constants'
import mind_ilustracion from '../../../assets/img/mind-ilustracion.png'

import { Disclaimers } from '../common';

const ReadyPartTwo = (props) => {

  const { hanndleNext } = props;

  return (
    <div className="bg-white my-md-4 rounded-lg pb-md-4 px-md-5 pb-md-5 pt-md-2 content-ready content-view">
    <div className="px-md-4">
        <div className="d-none d-sm-none d-md-block mb-md-5 encabezado">
          <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
             <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">Mind Patterns</h6>
          </div>
          <span className="border border-info d-block"></span>
        </div>

    <Row className="ready-mind align-items-md-start">
      <Col md="5">
        <div className="mind-ilustracion text-center pt-md-5 px-5 px-md-0">
          <h1 className="text-center d-md-none">Mind Patterns</h1>
          <img src={mind_ilustracion} className="img-fluid mb-4" />
        </div>
      </Col>
      <Col md="7">
        <div className="mb-md-5 contenido-after-fixed pb-5 pb-md-0">
          <h1 className="mb-md-3 mb-5 text-center text-md-left d-md-none">Mind Patterns</h1>
          <h2 className="mb-md-3 mb-4 text-center text-md-left text-reset">Ready!</h2>
          <div className="pb-5 pb-md-0">
            <p>In the following section choose one of the avaliable cards that seems most true for you. Then, proceed to the next step.</p>
            <p className="mb-5">Just answer each question as truthfully as possible without thinking too hard about them.</p>
            <h2 className="text-naranja text-center">Let's GO!</h2>
          </div>
        </div>
        <Row className="contenido-bottom-fixed-mb">
        <Col md={{ size:4, offset: 4}}>
          <button onClick={ () => hanndleNext( mind_pattern_sections.ASSESSMENT_PART_TWO ) } className="btn btn-primary text-uppercase btn-block rounded-pill">Next</button>
        </Col>
        <Col md="12">
          <Disclaimers view="propiedad-intelectual" />
        </Col>
        </Row>
      </Col>
    </Row>
    </div>
  </div>
  )
}

export default ReadyPartTwo;
