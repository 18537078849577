import { videosActions } from './actions';
import { videos } from './videos';


export const videosReducer = (state = videos, { payload, type }) => {

    switch (type) {
        case videosActions.FETCH_VIDEOS_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload?.error?.data
            };

        case videosActions.FETCH_VIDEOS_SUCCESS:
            return {
                ...state,
                isPending: false,
                data: payload?.data,
            };
        case videosActions.FETCH_VIDEOS_PENDING:
            return {
                ...state,
                isPending: true
            }

        default:
            return state;
    }
}
