import React, { useEffect, useState } from 'react';
import { Button, Container, Form, FormGroup, FormFeedback, Input, Label, FormText, Row, Col, CustomInput } from 'reactstrap';
import { ErrorPasswordPolitics } from '../../../components/common/ErrorPasswordPolitics';
import { useSelector } from 'react-redux';

import { passwordStrengthChecker } from '../../../../core/helper';

export const FormRegister = ({ inputValues, handleInputChange, listSeniorities, onSubmitRegister, formErrors, formValid }) => {
    const { register } = useSelector(state => state);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues])

    const parseError = (error) => {
        const errorEmail = error?.data?.email?.length && error?.data?.email[0]
        const errorMessage = errorEmail || error?.message

        switch(errorMessage) {
            case 'The email already exists in MyTrueAdvance. Please contact us here or write us here.':
                return <span>
                        This email is connected to an existing account. Please 
                        <a target="_blank" href="/front/welcome"> login </a>
                        or email
                        <a href={'mailto:info@mytrueadvance.com'} target="_blank"> info@mytrueadvance.com </a> if you have any issues. 
                    </span>
            default:
                return <span> {errorMessage} </span>;
        }
    }

    return (
        <>
            <Form onSubmit={onSubmitRegister} className="form-login p-3 p-md-0">
                <Row>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="first_name">First Name</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="first_name"
                                value={inputValues.firstName}
                                onChange={handleInputChange}
                                valid={formErrors.firstName === ''}
                                invalid={formErrors.firstName === '' ? null : true}
                                className="rounded-pill"
                            />
                            <FormFeedback>{formErrors.firstName}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="last_name">Last Name</Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="last_name"
                                value={inputValues.lastName}
                                onChange={handleInputChange}
                                valid={formErrors.lastName === ''}
                                invalid={formErrors.lastName === '' ? null : true}
                                className="rounded-pill"
                            />
                            <FormFeedback>{formErrors.lastName}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="email">Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                value={inputValues.email}
                                onChange={handleInputChange}
                                valid={formErrors.email === ''}
                                invalid={formErrors.email === '' ? null : true}
                                className="rounded-pill"
                            />
                            <FormFeedback>{formErrors.email}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="confirm-email">Confirm Email</Label>
                            <Input
                                type="email"
                                name="confirmEmail"
                                id="confirm-email"
                                value={inputValues.confirmEmail}
                                onChange={handleInputChange}
                                valid={formErrors.confirmEmail === ''}
                                invalid={formErrors.confirmEmail === '' ? null : true}
                                className="rounded-pill"
                            />
                            <FormFeedback>{formErrors.confirmEmail}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                {/* <Row>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="alternative-email">Alternative Email</Label>
                            <Input
                                type="email"
                                name="alternativeEmail"
                                id="alternative-email"
                                value={inputValues.alternativeEmail}
                                onChange={handleInputChange}
                                className="rounded-lg"
                                valid={(formErrors.alternativeEmail !== '' || inputValues.alternativeEmail === '') ? null : true}
                                invalid={formErrors.alternativeEmail === '' ? null : true}
                            />
                            <FormFeedback>{formErrors.alternativeEmail}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="confirm-alternative-email">Confirm Alternative Email</Label>
                            <Input
                                type="email"
                                name="confirmAlternativeEmail"
                                id="confirm-alternative-email"
                                value={inputValues.confirmAlternativeEmail}
                                onChange={handleInputChange}
                                valid={(formErrors.confirmAlternativeEmail !== '' || inputValues.confirmAlternativeEmail === '') ? null : true}
                                invalid={formErrors.confirmAlternativeEmail === '' ? null : true}
                                className="rounded-lg"
                            />
                            <FormFeedback>{formErrors.confirmAlternativeEmail}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row> */}

                <Row>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={inputValues.password}
                                onChange={handleInputChange}
                                valid={formErrors.password === ''}
                                invalid={formErrors.password === '' ? null : true}
                                className="rounded-pill"
                            />
                            <FormFeedback>{formErrors.password}</FormFeedback>
                            {inputValues.password && !formErrors.password &&
                                <FormText className='ml-3'>{passwordStrengthChecker(inputValues.password)}</FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="confirm-pass">Confirm Password</Label>
                            <Input
                                type="password"
                                name="confirmPassword"
                                id="confirm-pass"
                                value={inputValues.confirmPassword}
                                onChange={handleInputChange}
                                valid={formErrors.confirmPassword === ''}
                                invalid={formErrors.confirmPassword === '' ? null : true}
                                className="rounded-pill"
                            />
                            <FormFeedback>{formErrors.confirmPassword}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label className='control-label' for="select">Seniority</Label>
                            <select className="form-control form-control-sm rounded-pill" onChange={handleInputChange} name="senioritySelect">
                                <option>Select your option</option>
                                {
                                    listSeniorities &&
                                    listSeniorities.map(item =>
                                        <option
                                            key={item.id}
                                            value={item.id}>
                                            {item.name}
                                        </option>
                                    )
                                }
                            </select>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup className='required'>
                            <Label></Label>
                            <CustomInput className="styled-checkbox pl-0 mt-1" type="checkbox" id="termsAndPrivacy" name='termsAndPrivacy' onChange={handleInputChange} label={<Label className='control-label' for='termsAndPrivacy' >I agree to True Advance's <a href="https://www.trueadvance.com/terms-of-use" target='_blank'>Terms of Use</a> and <a href="https://www.trueadvance.com/privacy-policy" target='_blank'>Privacy Policy</a></Label>} />
                        </FormGroup>
                    </Col>
                </Row>

                {register.error !== 0 && register.error.description && register.error.description.password_politics && register.error.description.password_politics && <ErrorPasswordPolitics errorPasswordPolitics={register.error.description.password_politics} />}

                <FormGroup>
                    <Input
                        invalid={parseError(register?.error) ? true : false}
                        style={{ display: 'none' }}
                    />
                    <FormFeedback>{register?.error ? parseError(register?.error) : ''}</FormFeedback>
                </FormGroup>
                <Button color="primary" type="submit" disabled={disabled} className="text-uppercase rounded-pill px-md-5 btn-block">Register</Button>
            </Form>
        </>
    )
}
