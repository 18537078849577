import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Input, Label, Col, Row, FormGroup, Alert, FormText } from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { forgotPasswordActions } from '../../../../core/forgotPassword/actions';
import LoadingIndicator from '../../../components/loading-indicator';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

const ForgotPassword = ({ sendforgotPassword, resetState, forgotPassword }) => {
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [title, setTitle] = useState('Trouble with logging in?');

    useEffect(() => {
        setDisabled(email.length === 0);
        forgotPassword.success && forgotPassword.data && history.push('/reset-password');
    }, [email, forgotPassword])

    useEffect(() => {
        if(!forgotPassword.success) {
            resetState();
        }
    }, [])

    const handleInputChange = async ({ target }) => {
        const { value } = target;
        setEmail(value);
    }

    const onSubmitForgot = (e) => {
        e.preventDefault();
        sendforgotPassword({ email })
        console.log(forgotPassword.success)
    }
    console.log(forgotPassword.toJS())

    const getErrorRequest = (forgotPassword) => {
        var error = <FormText></FormText>
        if (!forgotPassword.success && forgotPassword.type === 'Not Found') {
            if (forgotPassword.belong_to_any_account) {
                error = <FormText>
                            This user does not belong to this Account. <br/>
                            <Link to="/account-information-sent"> Please click here </Link> to recieve an email with the correct account information. <br/>
                            If you experience any issues <Link to="/contact-us"> contact us here. </Link>
                        </FormText>
            }
            else {
                error = <FormText>
                            This email address does not exist in our system. <br/>
                            Please check your email or create a new account. <br/>
                            If you experience any issues <Link to="/contact-us"> contact us here. </Link>
                        </FormText>
            }
        }

        return error;
    }

    useEffect(() => {
        if(history.location.state) 
            setTitle(history.location.state.title_error)
    }, [])

    return (
        <>
            {
                forgotPassword.isPending &&
                <LoadingIndicator />
            }

            <div className='view overflow-hidden bg-white'>
                <TransitionGroup className="content">
                    <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='confirm' appear >
                        <div className="register-confirm-page">
                            <Container>
                                <div className="px-2 px-md-0 my-md-4">
                                    <div className="content-view">
                                        <Row>
                                            <Col sm={{ size: 6, offset: 3 }}>
                                                <h2 className="pl-3 pl-md-0"> <b>{title}</b> </h2>
                                                <h2 className="pl-3 pl-md-0">Please enter your email address, we'll send you a verification code.</h2>
                                            </Col>
                                        </Row>
                                        <Form
                                            className="form-login p-4 p-md-0 shadow rounded-lg bg-white"
                                            onSubmit={onSubmitForgot}
                                        >
                                            <Row>
                                                <Col sm={{ size: 6, offset: 3 }}>
                                                    <FormGroup>
                                                        <Label for="email">Email</Label>
                                                        <Input
                                                            type="text"
                                                            name="email"
                                                            id="code"
                                                            placeholder="Please enter your email"
                                                            value={email}
                                                            onChange={handleInputChange}
                                                        />
                                                        {getErrorRequest(forgotPassword)}                                                 
                                                    </FormGroup>
                                                    <Button
                                                        color="primary"
                                                        type="submit"
                                                        className="text-uppercase rounded-pill px-md-5 btn-block"
                                                        disabled={disabled}
                                                    >
                                                        NEXT
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>

                                        {/* {
                                            !forgotPassword.success && forgotPassword.type === 'Not Found' &&
                                            <center className="mt-4">
                                                <Alert color={'danger'}>
                                                    {forgotPassword.message}
                                                </Alert>
                                            </center>
                                        } */}
                                    </div>
                                </div>
                            </Container>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        </>
    )
}

const mapStateToProps = (state, props) => {
    const { forgotPassword } = state;
    return {
        forgotPassword
    };
};

const mapDispatchToProps = {
    sendforgotPassword: forgotPasswordActions.forgotResetPassword,
    resetState: forgotPasswordActions.resetState
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);
