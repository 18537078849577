import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardImg, Media } from 'reactstrap';
import {connect, useSelector} from 'react-redux';
import icon_members from '../../../../assets/img/avatar.png';
import { NavLink as Link } from 'react-router-dom';
import { teamsListActions, getCurrentTeamsList, getTeamsForCurrentTeamsList } from '../../../../core/teams-list';
import intro_team from '../../../../assets/img/Teams.svg'


const TeamsHome = ({teams,teams_list,loadTeams,oneColumn}) => {

  useEffect(()=>{
    loadTeams('all')
  },[])

  const { systemCheck } = useSelector(state => state);
  const account = systemCheck.data.data;
  const { user } = useSelector(state => state);

  return (
    <Col xl='12' className='mt-3'>
      <Card className="p-4 pt-md-1 mb-4 mb-xl-0 ">
        <div className={`${oneColumn ? "px-md-5": ''}`}>
          <div className="encabezado encabezado-icono">
            <div className="pt-2 pb-1 d-flex align-items-end">
              <img src={intro_team} alt="Teams" width="40" height="auto" className="thumb-team mx-auto mx-md-0"/>
              <h6 className="mb-0 ml-3 title-underline">Teams</h6>
            </div>
            <span className="border border-info d-none d-md-block"></span>
          </div>
      
          { !teams.size && <p className="my-4 mt-md-2">Start using these tools to increase collaboration with your team</p> }

          <Row className={`my-4 mt-md-2 row-cols-1 ${oneColumn ? "row-cols-md-2": ''}`}>
          {
            teams.size > 0 &&

            teams.filter((team, i) => i <= 2).map(item =>
              <Col key={item.id} className="team-item">
                <Link to={`/teams/${item.id}`} className="media text-reset text-decoration-none border-bottom p-2 align-items-center">
                  <Media left className="pr-3">
                    <img className="media-object rounded-circle" width="48" height="48" src={icon_members} alt={item.name}/>
                  </Media>
                <Media body>
                  <Media heading tag="h5" className="text-reset mb-0">{item.name}</Media>
                  <p className="line-height-normal mb-0">
                    <small className={(!item.members) ? 'text-danger' : ''}>{item.members} members</small>
                  </p>
                </Media>
              </Link>
            </Col>)

          }
          </Row>
        </div>

        <Link to='/teams' className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">
          {teams.size > 0 ? (<><i className="icon-view-report mr-2"></i>View All Teams</>) : 'Let’s start!'}
        </Link>
      </Card>
    </Col>
  )
}

const mapStateToProps = (state, props) => {
  const teams_list = getCurrentTeamsList(state, props);

  return {
    user: state.user,
    teams_list: teams_list,
    teams: getTeamsForCurrentTeamsList(state, props),
    displayLoadingIndicator: teams_list && teams_list.isPending || false,
  };
};

const mapDispatchToProps = {
  loadTeams: teamsListActions.loadTeams,
}

export default connect(mapStateToProps, mapDispatchToProps)(TeamsHome);
