import { forgotPasswordActions } from './actions';
import { ForgotPassword } from './forgotPassword';

export const forgotPasswordReducer = (state = new ForgotPassword(), { payload, type }) => {
    switch (type) {
        case forgotPasswordActions.FETCH_FORGOTPWD_FAILED:
            const { error } = payload;
            return state.merge({
                success: error.success,
                message: error.message,
                isPending: false,
                code: error.code,
                type: error.type,
                belong_to_any_account: error.belong_to_any_account,
                error_description: error.response && error.response.data && error.response.data.message
            });
        case forgotPasswordActions.FETCH_FORGOTPWD_SUCCESS:
            return state.merge({
                success: true,
                message: 'successfully',
                isPending: false,
                data: payload.data,
                code: payload.code,
                type: payload.type,
                belong_to_any_account: true,
                reset: payload.data ? false : true
            });
        case forgotPasswordActions.FETCH_FORGOTPWD_PENDING:
            return state.set('isPending', true);
        
        case forgotPasswordActions.RESET_STATE:
            return new ForgotPassword();
        default:
            return state;
    }
}