import React from 'react';
import { Col, Container, Row } from 'reactstrap';

export const ResetPasswordDone = () => {
    return (
        <Container>
            <div className="px-2 px-md-0 my-md-4 view overflow-hidden">
                <div className="content-view">
                    <Row>
                        <Col sm={{ size: 6, offset: 3 }}>
                            Your password has successfully changed. Please click <a href="/front/welcome">here</a> to login.
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}
