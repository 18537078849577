import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { getAssessmentsItems } from '../assessment-item/selectors';

export function getAssessmentsList(state) {
  return state.assessment;
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentAssessmentsList = createSelector(
  getAssessmentsList,
  assessment => assessment.get(assessment.get('currentAssessmentListId').toString())
);


export const getAssessmentForCurrentAssessmentsList = createSelector(
  getCurrentAssessmentsList,
  getAssessmentsItems,
  (assessment_lists, assessment_items) => {
    let assessmentItems = [];

    if (assessment_lists.item_ids) {
      assessmentItems = Map();
      assessment_lists.item_ids.forEach(id => {
        assessmentItems = assessmentItems.set(id, assessment_items.get(id));
      });
    }

    return assessmentItems;
  }
);
