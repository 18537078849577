import { List , Map } from 'immutable';
import { teamsFullReportListActions } from './actions';
import { TeamsFullReport } from './teams-full-report';
import { DEFAULT_SESSION_ID } from '../constants';

export const initialState = new Map({
  currentTeamsFullReportId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new TeamsFullReport({id: DEFAULT_SESSION_ID, isNew: false})
});


export function teamsFullReportListReducer(state = initialState, action) {

  const { type, payload } = action;
  switch (type) {
    case teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_FAILED:
    case teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_SUCCESS:
    case teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_PENDING:
    console.log('--->',type, payload)
      return state.set(
        payload.teamsFullReportId,
        teamsFullReportReducer(state.get(payload.teamsFullReportId), action)
      );
    case teamsFullReportListActions.LOAD_TEAMS_FULL_REPORT:
      return state.merge({
        currentTeamsFullReportId: payload.teamsFullReportId,
        [payload.teamsFullReportId]: teamsFullReportReducer(state.get(payload.teamsFullReportId), action)
      });
    default:
      return state;
  }
}

export const teamsFullReportReducer = (state = new TeamsFullReport(),  {payload, type} ) => {
  const { data } = payload;

  switch (type) {
    case teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_SUCCESS:
      return state.withMutations( teamsreport => {
        teamsreport.merge({
          isNew: false,
          isPending: false,
          error: null,
          update_at: new Date(),
          items: new List(data)
        });
      });
    case teamsFullReportListActions.FETCH_TEAMS_FULL_REPORT_PENDING:
      return state.set('isPending', true);
    case teamsFullReportListActions.LOAD_TEAMS_FULL_REPORT:
      return state.merge({
        id: payload.teamsFullReportId,
      });
    default:
      return state;
  }
}
