import { call, fork, takeEvery } from 'redux-saga/effects';
import { updateProfile, updateProfilePicture, updateProfileStory } from '../api';
import { profileActions } from './actions';

export function* sendUpdatedData({ payload }) {
    const { data } = payload;
    console.log(data, "data sagas")
    yield call(updateProfile, data);
}

export function* sendUpdatedPicture({ payload }) {
    const { file } = payload;
    yield call(updateProfilePicture, file);
}

export function* sendUpdatedStory({ payload }) {
    const { data } = payload;
    yield call(updateProfileStory, data);
}

//===================================== 
// WATCHERS
//-------------------------------------

export function* watchSendUpdatedData() {
    yield takeEvery(profileActions.UPDATE_PROFILE, sendUpdatedData);
}

export function* watchSendUpdatedPicture() {
    yield takeEvery(profileActions.UPDATE_PICTURE, sendUpdatedPicture);
}

export function* watchSendUpdatedStory() {
    yield takeEvery(profileActions.UPDATE_STORY, sendUpdatedStory);
}


//=====================================
//  ROOT
//-------------------------------------

export const profileSagas = [
    fork(watchSendUpdatedData),
    fork(watchSendUpdatedPicture),
    fork(watchSendUpdatedStory),
];