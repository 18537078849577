import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentItem } from './DocumentItem';
import { getDocumentExtension, getDateStringFromTimestamp } from '../../../core/helper';

export const DocumentsList = ({ documents }) => {

    return (
        <>
            {
                documents && documents.data && documents.data.map((document, index) => {

                    return (
                        <Fragment key={document.id}>
                            <div className={`${index}`}>
                                <DocumentItem
                                    key={document.id}
                                    id={document.id}
                                    fileName={document.file_name}
                                    document={JSON.parse(document.document)[0]}
                                    attachedDocumentType={document.attached_document_type}
                                    documentLink={document.document_link}
                                    text={document.text}
                                    size={document.size}
                                    downloaded={document.downloaded}
                                    type={document.type ? document.type : getDocumentExtension(document.file_name)}
                                    date={document.created_at ? getDateStringFromTimestamp(document.created_at) : ''}
                                />
                            </div>

                            {(index +1) % 4 === 0 && index < (documents.data.length -1) && <hr className="mx-auto d-none d-md-block my-1"/> }

                        </Fragment>
                    )
                })

            }
        </>

    )
}