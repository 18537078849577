import { mindPatternsStoreOptionsActions } from './actions';
import { MIND_PATTERNS_DATA_OPTIONS } from '../constants';
import { DataStoreOptions } from './storeOptions';

export function mindPatternsOptionStoreDataReducer(state = new DataStoreOptions(), { payload, type }) {
    switch (type) {
        case mindPatternsStoreOptionsActions.FETCH_MINDPATTERNS_STOREOPTIONS_FAILED:
            return state.merge({
                isPending: false,
                error: payload.error
            });

        case mindPatternsStoreOptionsActions.FETCH_MINDPATTERNS_STOREOPTIONS_SUCCESS:
            return state.withMutations(mindPatternsData => {
                mindPatternsData.merge({
                    id: MIND_PATTERNS_DATA_OPTIONS,
                    isNew: false,
                    isPending: false,
                    items: payload.data
                });
            });

        case mindPatternsStoreOptionsActions.FETCH_MINDPATTERNS_STOREOPTIONS_PENDING:
            return state.set('isPending', true);

        case mindPatternsStoreOptionsActions.SAVE_MINDPATTERNS_STORE_OPTIONS:
            return state.merge({
                items: payload.data,
                isNew: false,
                isPending: false,
            });
        default:
            return state;
    }
}
