import React from 'react';
import { QuadrantItem } from './QuadrantItem';
import { Row, Col, Container } from 'reactstrap';
import {
    QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL,
    QUADRANTS_PROCEDURAL, QUADRANTS_ALL
} from '../../../../core/constants';
import graphic_report from '../../../../assets/img/graphic-report-sm.jpg';
import { useSelector } from 'react-redux';

export const QuadrantList = ({ toggle, image_bg, version, user }) => {

    return (
        <>
            <div id="lists-top" className="d-flex list-top justify-content-between align-items-end row position-relative">
                <div className={`col-6 cursor-pointer ${version != 'small' ? 'align-items-md-end d-md-flex justify-content-md-end' : ''}`} onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_ANALYTIC)}}>
                    <div className={`lists-item mx-0 ${version != 'small' ? 'row row-cols-lg-2 justify-content-lg-end pr-lg-3' : ''}`}>
                        {
                            user.talents &&
                            user.talents.map(quadrant =>
                                quadrant.quadrant === 'Analytic' &&
                                quadrant.items.map(item =>
                                    <QuadrantItem
                                        key={'_qitem_' + item.id}
                                        item={item}
                                        quadrant={quadrant}
                                        version={version}
                                    />
                                )
                            )
                        }
                    </div>
                </div>
                <Col xs={{ size: 6 }} className={`cursor-pointer ${version != 'small' ? 'align-items-md-end d-md-flex' : ''}`} onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_INNOVATIVE)}}>
                    <div className="lists-item">
                        {
                            user.talents &&
                            user.talents.map(quadrant =>
                                quadrant.quadrant === 'Innovative' &&
                                quadrant.items.map(item =>
                                    <QuadrantItem
                                        key={'qitem_' + item.id}
                                        item={item}
                                        quadrant={quadrant}
                                        right={'float-right'}
                                        version={version}
                                    />
                                )
                            )
                        }
                    </div>
                </Col>
            </div>

            <div className="bg-map-dk mx-auto">
                <div className="graphic-map-img mx-auto" style={{ width: '190px' }}>
                    <div className="mx-auto text-center graphic-list-all-talent">
                        <div>
                            {
                                user.talents &&
                                user.talents.map(quadrant =>
                                    quadrant.quadrant === 'All' && quadrant.items.map(item =>
                                        <QuadrantItem
                                            key={'qitem_' + item.id}
                                            item={item}
                                            quadrant={quadrant}
                                        />
                                    )
                                )
                            }
                        </div>
                    </div>
                    <img ref={e => image_bg = e} src={graphic_report} useMap={`#image_map_${user.id}`} id={user.id} className="img-fluid" style={{ opacity: 0 }} />
                    <map name={`image_map_${user.id}`} id={user.id}>
                        <area onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_ANALYTIC)}} alt="Analytic" title="Analytic" coords="1,2,96,0,95,22,57,33,29,62,20,94,0,94" shape="poly" />
                        <area onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_INNOVATIVE)}} alt="Innovative" title="Innovative" coords="97,21,97,0,188,0,190,95,169,96,161,57,131,31" shape="poly" />
                        <area onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_RELATIONAL)}} title="Relational" coords="98,167,131,161,158,132,169,98,189,97,187,189,96,188" shape="poly" />
                        <area onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_PROCEDURAL)}} alt="Procedural" title="Procedural" coords="1,95,20,94,29,128,61,162,96,168,95,189,0,189" shape="poly" />
                        <area onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_ALL)}} alt="All" title="All" coords="95,95,70" shape="circle" />
                    </map>

                </div>
            </div>

            <div id="lists-bottom" className="d-flex list-bottom justify-content-between align-items-start row position-relative">
                <div className={`col-6 cursor-pointer ${version != 'small' ? 'align-items-md-start d-md-flex justify-content-md-end' : ''}`} onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_PROCEDURAL)}}>
                    <div className="lists-item">
                        {
                            user.talents &&
                            user.talents.map(quadrant =>
                                quadrant.quadrant === 'Procedural' &&
                                quadrant.items.map(item =>
                                    <QuadrantItem
                                        key={'qitem_' + item.id}
                                        item={item}
                                        quadrant={quadrant}
                                        version={version}
                                    />
                                )
                            )
                        }
                    </div>
                </div>
                <Col xs={{ size: 6 }} className={`cursor-pointer ${version != 'small' ? 'align-items-md-start d-md-flex' : ''}`} onClick={(e) => {e.stopPropagation();toggle(QUADRANTS_RELATIONAL)}}>
                    <div className="lists-item">
                        {
                            user.talents &&
                            user.talents.map(quadrant =>
                                quadrant.quadrant === 'Relational' &&
                                quadrant.items.map(item =>
                                    <QuadrantItem
                                        key={'qitem_' + item.id}
                                        item={item}
                                        quadrant={quadrant}
                                        right={'float-right'}
                                        version={version}
                                    />
                                )
                            )
                        }
                    </div>
                </Col>
            </div>

        </>
    )
}
