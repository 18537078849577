import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchChangePassword, fetchForgotPassword } from '../api';
import { forgotPasswordActions } from './actions';

export function* ForgotPwd({ payload }) {
    const { data, userId } = payload;

    yield call(fetchForgotPassword, userId, data.email);
}

export function* ChangePwd({ payload }) {
    const { data, userId } = payload;
    yield call(fetchChangePassword, userId, data);
}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchForgotPwd() {
    yield takeEvery(forgotPasswordActions.FORGOT_PASSWORD, ForgotPwd);
}

export function* watchChangePwd() {
    yield takeEvery(forgotPasswordActions.CHANGE_PASSWORD, ChangePwd);
}



//=====================================
//  ROOT
//-------------------------------------

export const forgotPasswordSagas = [
    fork(watchForgotPwd),
    fork(watchChangePwd)
];