import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import logo from '../../../../assets/img/true.logo.circle.png'
import icon_members from '../../../../assets//img/avatar.png';


export const ModalViewList = ({ showModal, handleModal, team, contentModal, title }) => {


    return (
        <>
            <Modal isOpen={showModal} toggle={handleModal} className="modal-dialog-summary float-right">
                <ModalHeader toggle={handleModal}><span className="d-flex justify-content-center bd-highlight"><img src={logo} height="48" alt="True Advance" /></span></ModalHeader>
                <ModalBody>
                    <div className="member-item cursor-pointer">
                        <div className="d-flex align-items-center mb-2" >
                            <img className="media-object rounded-circle mr-3" width="40" height="40" src={icon_members} alt={team.name} />
                            <h2 className="text-center text-md-left text-reset text-capitalize mb-0">{team.name}</h2>
                        </div>
                    </div>
                    <span className="border border-info d-block mb-3"></span>
                    <div className={`item talents-item clearfix mb-3`}>
                        <div className="px-4 py-1 option-select rounded-pill mb-3">{title}</div>
                        <div className="bg-celeste py-2 px-4 mt-3 rounded text-left mb-2">
                            <p className="mb-0">Tap a member to view their profile</p>
                        </div>
                        <hr className="mb-0" />
                        {contentModal}
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}
