import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchQuestionnaireResume } from '../api';
import { questionnaireResumeActions } from './actions';

export function* questionnaireResume() {
    yield call(fetchQuestionnaireResume)
}

//Watchers
export function* watchQuestionnaireResume() {
    yield takeEvery(questionnaireResumeActions.LOAD_QUESTIONNAIRE_RESUME, questionnaireResume)
}

//Root
export const questionnaireResumeSaga = [
    fork(watchQuestionnaireResume)
]
