export function getDocuments(state) {
    return state.userDocuments;
}

export function getDocumentFileById(files, documentId) {
    let index = -1

    if (files) {
        index = files.findIndex(file => file.id === documentId)

        if (index !== -1) {
            return files[index].file
        }
    }
    return null
}