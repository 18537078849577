import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Radio } from '../common'
import { Container, Row, Col } from 'reactstrap';

class QuestionItem extends Component{

  render() {
    const { data, hanndleResponse, select_option } = this.props;

    return (
      <>
        <Row className="item question-item">
          <Col md={{ size: 8, offset: 2 }}>
          <h1 className="text-center text-reset">{ data.name }</h1>
          <h5 className="mb-3 text-center">{data.question}</h5>
          <p>{data.description}</p>
          </Col>
        </Row>
        <Row className="mt-auto mx-auto">
          <div className="question-item-nav">
            <p className="h4 text-center text-reset">Gives me Energy</p>
            <div className="d-flex justify-content-center">
              <div className="btn-group btn-group-toggle">
               <Radio handleRadioChange={hanndleResponse} item_id={data.id} group="gives_me_energy_" select_option={select_option} label="ALWAYS" value="1" />
               <Radio handleRadioChange={hanndleResponse} item_id={data.id} group="gives_me_energy_" select_option={select_option} label="SOMETIMES" value="2" />
               <Radio handleRadioChange={hanndleResponse} item_id={data.id} group="gives_me_energy_" select_option={select_option} label="NEVER" value="3" />
              </div>
            </div>
          </div>
          </Row>
      </>
    )
  }
}

const mapStateToProps = (state, props) => {
  return { };
};

const mapDispatchToProps = {
};

export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionItem)
