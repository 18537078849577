import React from 'react';
import { Row, Col } from 'reactstrap';

import { mind_pattern_sections } from '../../../core/constants'
import mind_ilustracion from '../../../assets/img/mind-ilustracion.png'
import auditory from '../../../assets/img/talking-hearing.png'
import visual from '../../../assets/img/writing-reading.png'
import kinesthetic from '../../../assets/img/hand-on.png'
import { Disclaimers } from '../common';

const Start = (props) => {

  const { hanndleNext, user } = props;

  return (
    <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 pb-md-5 pt-md-2 content-start content-view">
      <div className="px-md-4">

        <div className="d-none d-sm-none d-md-block mb-md-5 encabezado">
          <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
            <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">Mind Patterns</h6>
          </div>
          <span className="border border-info d-block"></span>
        </div>

        <Row className="start-mind align-items-md-start">
          <Col md="5">
            <div className="mind-ilustracion text-center pt-md-5 px-5 px-md-0">
              <h1 className="text-center d-md-none">Mind Patterns</h1>
              <img src={mind_ilustracion} className="img-fluid mb-4" alt="" />
            </div>
          </Col>

          <Col md="7">
            <div className="mb-md-5 pb-4 pb-md-0 contenido-after-fixed">
              <div className="pb-5 pb-md-0">
                <p className="h5 text-reset mb-3 text-center text-md-left">Recognizing your Mind Pattern</p>
                <p>You may have heard people describe themselves as either "visual," "auditory" or "hands-on" learners, but this is an oversimplification; they're describing how they trigger their focus or how they concentrate. However, your ability to focus is only one part of how you effectively think, learn and communicate: In total, there are three different states of attention: </p>
                <p>Focus (concentration), Sorting (decision-making) and Open (imagination).</p>
                <p className="mb-3">  Each of these states of attention is catalyzed by a different input or channel — either auditory, kinesthetic, and visual.</p>

                <div className="d-flex align-items-center justify-content-between mb-3 px-md-5">
                  <div className="text-center item-mind-patterns item-mind-patterns-a">
                    <p className="line-height-normal mb-2 color-mind-pattern-a"><b>AUDITORY</b></p>
                    <img src={auditory} alt="" />
                  </div>
                  <div className="text-center item-mind-patterns item-mind-patterns-v">
                    <p className="line-height-normal mb-2 color-mind-pattern-v"><b>VISUAL</b></p>
                    <img src={visual} alt="" />
                  </div>
                  <div className="text-center item-mind-patterns item-mind-patterns-k">
                    <p className="line-height-normal mb-2 color-mind-pattern-k"><b>KINESTHETIC</b></p>
                    <img src={kinesthetic} alt="" />
                  </div>
                </div>

                <div className="bg-celeste p-3 rounded mb-5">
                  <p className="line-height-normal mb-md-0">Matching these three inputs with the three states of attention produces your unique Mind Pattern, which this assessment will help you determine. Following the assessment, you'll understand the characteristics of your Mind Pattern and its associated strengths and challenges.</p>
                </div>

                {user.assessment_access === 2 && !user.talents &&
                  <p className="line-height-normal">The MyTru is designed for you to access the discoveries sequentially. Please complete the Thinking Talents discovery first, then you can access the Mind Pattern discovery.</p>
                }

              </div>
            </div>

            <Row className="contenido-bottom-fixed-mb">
              <Col md={{ size: 4, offset: 4 }}>
                {user.assessment_access === 2 && !user.talents ? null :
                  <button
                    onClick={() => hanndleNext(mind_pattern_sections.READY)}
                    className="btn btn-primary text-uppercase btn-block rounded-pill"
                  >NEXT</button>
                }
              </Col>
              <Col md="12">
              <Disclaimers view="propiedad-intelectual" />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>)
}

export default Start;
