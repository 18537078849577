import { contactActions } from './actions';
import { Contact } from './contact';

export const ContactReducer = (state = new Contact(), { payload, type }) => {
    switch (type) {
        case contactActions.FETCH_CONTACT_FAILED:
            const { error } = payload;
            return state.merge({
                success: false,
                message: payload.message,
                isPending: false
            });
        case contactActions.FETCH_CONTACT_SUCCESS:
            return state.merge({
                success: true,
                message: payload.message,
                isPending: false,
                data: payload.data
            });
        case contactActions.FETCH_CONTACT_PENDING:
            return state.set('isPending', true);
        case contactActions.RESET_CONTACT:
            return state.merge({
                success: false,
                message: null,
                isPending: false,
                data: null
            });
        default:
            return state;
    }
}