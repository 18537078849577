import React, { Component, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col } from 'reactstrap';

import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";

import classNames from 'classnames';
import { List } from 'immutable';
import RearrengeItem from './rearrenge-item'

import { Disclaimers } from '../common';

class Rearrenge extends Component{

  constructor(props) {
    super(props)

    this.state = {
      activeTab: '1',
      old_group_item: null,
      new_group_item: null,

    }

    this.toggle = this.toggle.bind(this);
    this.moveToGropu = this.moveToGropu.bind(this);
    this.onExitTransition = this.onExitTransition.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    // console.log('the scroll things', event)
  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if ( activeTab !== tab )
      this.setState({ activeTab: tab })
  }

  moveToGropu = ( item_id,value ) => {
    // console.log('moveToGropu')

    const { response_list, hanndleResponse } = this.props
    const old_group_item = response_list.get(item_id)
    const new_group_item = { item_id,value }
    this.setState({ old_group_item, new_group_item })
    //console.log('moveToGropu', old_group_item, new_group_item)
  }

  onExitTransition = (e) =>{
    const { response_list, hanndleResponse } = this.props
    const { old_group_item, new_group_item } = this.state
    if (new_group_item){
      this.setState({ new_group_item:null })
      hanndleResponse( new_group_item.item_id, new_group_item.value)
    }
    //console.log('onExitTransition',old_group_item, new_group_item, e)
  }

  componentDidUpdate(oldProps,oldState)
  {
    const { hanndleResponse } = this.props
    const { old_group_item, new_group_item } = this.state
    if (!new_group_item && oldState.new_group_item) {

      this.setState({ old_group_item:null })
    }
  }

  render() {
    const { hanndleNextQuestion,  items, response_range, questions, hanndleResponse, validations } = this.props
    const { activeTab, old_group_item } = this.state

    const grupo_1_style = {
      backgroundColor: '#66b2bd'
    }
    const grupo_2_style = {
      backgroundColor: '#e4daa6'
    }
    const grupo_3_style = {
      backgroundColor: '#c8c8c8'
    }

    const animationClass = {
      appearActive: 'animated slideInRight',
      enter: 'animated slideDown',
      enterActive: 'animated slideInRight',
      exit: 'animated slideUp',
      exitActive: 'animated slideOutLeft',
      appear: 'animated',
    }

    const range_group_1 = response_range.get("1")
    const range_group_2 = response_range.get("2")
    const range_group_3 = response_range.get("3")


    const grupo_1_items = items.group_1.map( item => {

      if (old_group_item && old_group_item.item_id === item.item_id && old_group_item.value === "1" ) return null

      return <CSSTransition onExited={this.onExitTransition} classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key={'1_' + item.item_id} >
        <RearrengeItem data={item} custom_style={ grupo_1_style } questions={questions} hanndleResponse={this.moveToGropu} />
      </CSSTransition>
    })

    const grupo_2_items = items.group_2.map( item => {

      if (old_group_item && old_group_item.item_id === item.item_id && old_group_item.value === "2" ) return null

      return <CSSTransition onExited={this.onExitTransition} classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key={'2_' + item.item_id} >
        <RearrengeItem data={item} custom_style={ grupo_2_style } questions={questions} hanndleResponse={this.moveToGropu} />
      </CSSTransition>
    })

    const grupo_3_items = items.group_3.map( item => {

      if (old_group_item && old_group_item.item_id === item.item_id && old_group_item.value === "3" ) return null

      return <CSSTransition onExited={this.onExitTransition} classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key={'3_' + item.item_id} >
        <RearrengeItem data={item} custom_style={ grupo_3_style } questions={questions} hanndleResponse={this.moveToGropu} />
        </CSSTransition>
    })


    let error_group_1 = null;
    let error_group_2 = null;
    let error_group_3 = null;

    if (!validations.group_1.valid && validations.group_1.error === 'min' ) {
      error_group_1 = <p className="text-danger line-height-normal">
          <small>The minimum number of talents for the "Always" energy level is { range_group_1.min }. Please add 1 talent. Tap on the "Sometimes" tab to add talents.</small>
      </p>
    }

    if (!validations.group_1.valid && validations.group_1.error === 'max' ) {
      error_group_1 =  <p className="text-danger line-height-normal">
        <small>Please select additional talents that aren't quite as strong and mark them as "Sometimes" to narrow the list down to your top { range_group_1.max }.</small>
      </p>
    }

    if (!validations.group_2.valid && validations.group_2.error === 'min' ) {
      error_group_2 = <p className="text-danger line-height-normal">
          <small>The minimum number of talents for the "Sometimes" energy level is { range_group_2.min }. Please add 1 talent. Tap on the "Always" or "Never" tab to add talents.</small>
      </p>
    }

    if (!validations.group_2.valid && validations.group_2.error === 'max' ) {
      error_group_2 =  <p className="text-danger line-height-normal mb-2">
        <small>The maximum number for talents for the "Sometimes" bucket is { range_group_2.max }. Please remove the talent (s) to your "Sometimes" bucket.</small>
      </p>
    }

    if (!validations.group_3.valid && validations.group_3.error === 'min' ) {
      error_group_3 = <p className="text-danger line-height-normal">
          <small>The minimum number of talents for the "Never" energy level is { range_group_3.min }. Please add 1 talent. Tap on the "Sometimes" tab to add talents.</small>
      </p>
    }

    if (!validations.group_3.valid && validations.group_3.error === 'max' ) {
      error_group_3 =  <p className="text-danger line-height-normal">
        <small>The maximum number for talents for the "Never" bucket is { range_group_3.max }. Please remove the talent (s) to your "Never" bucket.</small>
      </p>
    }



    return (
      <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 pb-md-5 pt-md-2 content-rearrenge content-view">
        <Row className="contenido-after-fixed">
          <Col md={{ size: 12 }}>
          <h1 className="mb-md-4 text-center text-md-left d-md-none">Thinking Talents</h1>

          <div className="d-none d-sm-none d-md-block mb-md-3 encabezado">
              <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center">
                 <h6 className="d-none d-sm-none d-md-block mb-0 title-underline">Thinking Talents</h6>
              </div>
              <span className="border border-info d-block"></span>
          </div>

          <p className="text-center text-md-left h5 text-reset mb-4">Tap on an item to change your answer</p>

          <Nav className="d-md-none d-flex justify-content-between mb-3" tabs>
            <NavItem>
              <NavLink className={classNames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                     ALWAYS <span className={ !validations.group_1.valid ? "text-danger" : ''}>({ items.group_1.size })</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classNames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                     SOMETIMES <span className={ !validations.group_2.valid ? "text-danger" : ''}>({ items.group_2.size })</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classNames({ active: activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                     NEVER <span className={ !validations.group_3.valid ? "text-danger" : ''}>({ items.group_3.size })</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="row pb-5">
            <TabPane className="col-sm-12 col-md-4 d-md-inline-block" tabId="1">
              <div className="header-group mb-4 pt-md-2">
                <h6 className="text-dark d-none d-md-block"><i className="dot" style={grupo_1_style}></i>ALWAYS <span className={ !validations.group_1.valid ? "text-danger" : ''}>({ items.group_1.size })</span></h6>
                {error_group_1}
              </div>
              <div className="overflow-hidden">
                <TransitionGroup >
                {grupo_1_items}
                </TransitionGroup>
              </div>
            </TabPane>
            <TabPane className="col col-sm-12 col-md-4 d-md-inline-block border-right border-left" tabId="2">
              <div className="header-group mb-md-4 pt-md-2">
                <h6 className="text-dark d-none d-md-block"><i className="dot" style={grupo_2_style}></i>SOMETIMES  <span className={ !validations.group_2.valid ? "text-danger" : ''}>({ items.group_2.size })</span></h6>
                {error_group_2}
              </div>
              <div className="overflow-hidden">
                <TransitionGroup>
                  {grupo_2_items}
                </TransitionGroup>
              </div>
             </TabPane>
             <TabPane className="col col-sm-12 col-md-4 d-md-inline-block" tabId="3">
              <div className="header-group mb-md-4 pt-md-2">
                <h6 className="text-dark d-none d-md-block"><i className="dot" style={grupo_3_style}></i>NEVER <span className={ !validations.group_3.valid ? "text-danger" : ''}>({ items.group_3.size })</span></h6>
                {error_group_3}
              </div>
              <div className="overflow-hidden">
                <TransitionGroup>
                {grupo_3_items}
                </TransitionGroup>
                </div>
              </TabPane>
          </TabContent>

          </Col>
        </Row>
          <Row className="contenido-bottom-fixed-mb">
          <Col md={{ size:4, offset: 4}}>
            <button onClick={ () => hanndleNextQuestion('result', validations.valid) } className={"btn text-uppercase btn-block rounded-pill" + ( (!validations.valid) ? ' btn-outline-primary' : ' btn-primary' )}>Finish sorting</button>
          </Col>
          <Col md="12">
          <Disclaimers view="propiedad-intelectual" />
          </Col>
          </Row>
      </div>
    )
  }
}

export default Rearrenge;
