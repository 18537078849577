import { List, Record, Map } from 'immutable';

export const TeamsList = new Record({
  id: null,
  isNew: true,
  isPending: false,
  itemsId: new List(),
  error:null,
  update_at: new Date(),
});
