import { TimelineMax as Timeline, Power1 } from 'gsap';
import moment from 'moment';
import { STORAGE_DEV_PUBLIC, STORAGE_PROD_PUBLIC, HOST_DEV } from './constants';

const getDefaultTimeline = (node, delay) => {
    console.log(node, delay);
    const timeline = new Timeline({ paused: true });
    const content = node.querySelector('.content');
    const contentInner = node.querySelector('.content--inner');

    timeline
        .from(node, 0.3, { display: 'none', autoAlpha: 0, delay, ease: Power1.easeIn })
        .from(content, 0.15, { autoAlpha: 0, y: 25, ease: Power1.easeInOut })
        .from(contentInner, 0.15, { autoAlpha: 0, delay: 0.15, ease: Power1.easeIn });

    return timeline;
}

const getHomeTimeline = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    const texts = node.querySelectorAll('h1 > div');

    timeline
        .from(node, 0, { display: 'none', autoAlpha: 0, delay })
        .staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125);

    return timeline;
}

export const play = (pathname, node, appears) => {
    const delay = appears ? 0 : 0.5;
    let timeline

    if (pathname === '/')
        timeline = getHomeTimeline(node, delay);
    else
        timeline = getDefaultTimeline(node, delay);

    window.loadPromise.then(() => requestAnimationFrame(() => timeline.play()))
}

export const exit = (node) => {
    const timeline = new Timeline({ paused: true });

    timeline.to(node, 0.15, { autoAlpha: 0, ease: Power1.easeOut });
    timeline.play();
}

export const getElementWidth = (elem) => {
    if (elem)
        return elem.getBoundingClientRect().width || elem.offsetWidth || 0;
    return 0
}

export const getElementHeight = (elem) => {
    if (elem)
        return elem.getBoundingClientRect().height || elem.offsetHeight || 0;
    return 0
}

export const uniqueId = () => {
    return Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
}

export const isTouchDevice = () => {
    return 'ontouchstart' in document.documentElement;
}

export const sortNumber = (a, b) => {
    return a - b;
}

export const getScreenWidth = () => {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
}

export const text_truncate = (str = "", length, ending) => {
    if (!length) {
        length = 96;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
}

export const getToArrayData = (items_set) => {
    let list_items_array = [];
    items_set.forEach(value => {
        list_items_array.push(value);
    });

    return list_items_array;
}

export const closest = (el, fn) => {
    while (el) {
        if (fn(el)) return el;
        el = el.parentNode;
    }
}

export const isTouchEvent = (event) => {
    return (
        (event.touches && event.touches.length) ||
        (event.changedTouches && event.changedTouches.length)
    );
}

export const getPosition = (event) => {
    if (event.touches && event.touches.length) {
        return {
            x: event.touches[0].pageX,
            y: event.touches[0].pageY,
        };
    } else if (event.changedTouches && event.changedTouches.length) {
        return {
            x: event.changedTouches[0].pageX,
            y: event.changedTouches[0].pageY,
        };
    } else {
        return {
            x: event.pageX,
            y: event.pageY,
        };
    }
}

export const addHttp = url => (url.indexOf('://') === -1) ? 'http://' + url : url;

/*
  lat1, lon1: The Latitude and Longitude of point 1 (in decimal degrees)
  lat2, lon2: The Latitude and Longitude of point 2 (in decimal degrees)
  unit: The unit of measurement in which to calculate the results where:
    'M' is statute miles (default)
    'K' is kilometers
    'N' is nautical miles
*/
export const distance = (lat1, lon1, lat2, lon2, unit) => {
    var radlat1 = Math.PI * lat1 / 180
    var radlat2 = Math.PI * lat2 / 180
    var radlon1 = Math.PI * lon1 / 180
    var radlon2 = Math.PI * lon2 / 180
    var theta = lon1 - lon2
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    if (unit === "K") { dist = dist * 1.609344 }
    if (unit === "N") { dist = dist * 0.8684 }
    return dist
}

// Pasos de producción de ECMA-262, Edición 6, 22.1.2.1
// Referencia: https://people.mozilla.org/~jorendorff/es6-draft.html#sec-array.from
if (!Array.from) {
    Array.from = (function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
            return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
        };
        var toInteger = function (value) {
            var number = Number(value);
            if (isNaN(number)) { return 0; }
            if (number === 0 || !isFinite(number)) { return number; }
            return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
            var len = toInteger(value);
            return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // La propiedad length del método from es 1.
        return function from(arrayLike/*, mapFn, thisArg */) {
            // 1. Deje a C ser el este valor.
            var C = this;

            // 2. Deje que los elementos sean ToObject(arrayLike).
            var items = Object(arrayLike);

            // 3. Retornar IfAbrupt(items).
            if (arrayLike == null) {
                throw new TypeError("Array.from requiere un objeto array-like - not null or undefined");
            }

            // 4. Si mapfn no está definida, entonces deja que sea false.
            var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
            var T;
            if (typeof mapFn !== 'undefined') {
                // 5. si no
                // 5. a If IsCallable(mapfn) es false, lanza una excepción TypeError.
                if (!isCallable(mapFn)) {
                    throw new TypeError('Array.from: si hay mapFn, el segundo argumento debe ser una función');
                }

                // 5. b. Si thisArg se suministró, deje que T sea thisArg; si no, deje que T esté indefinido.
                if (arguments.length > 2) {
                    T = arguments[2];
                }
            }

            // 10. Let lenValue be Get(items, "length").
            // 11. Let len be ToLength(lenValue).
            var len = toLength(items.length);

            // 13. If IsConstructor(C) is true, then
            // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
            // 14. a. Else, Let A be ArrayCreate(len).
            var A = isCallable(C) ? Object(new C(len)) : new Array(len);

            // 16. Let k be 0.
            var k = 0;
            // 17. Repeat, while k < len… (also steps a - h)
            var kValue;
            while (k < len) {
                kValue = items[k];
                if (mapFn) {
                    A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                } else {
                    A[k] = kValue;
                }
                k += 1;
            }
            // 18. Let putStatus be Put(A, "length", len, true).
            A.length = len;
            // 20. Return A.
            return A;
        };
    }());
}

export function openPDF(pdf) {
    // const file = new Blob(
    //     [pdf],
    //     { type: 'application/pdf' });
    // const fileURL = URL.createObjectURL(file);
    window.open(pdf);
}

export function downloadPDF(pdf, name = 'file') {
    const file = new Blob(
        [pdf],
        { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    var link = document.createElement('a');
    link.href = fileURL;
    link.download = name + '.pdf'
    link.click();
}

export function downloadFile(file) {
    const link = document.createElement('a')
    const url = URL.createObjectURL(file)

    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}

export function customDistanceToMouse(pt, mousePos, markerProps) {
    const K_SCALE_NORMAL = 0.65;

    const K_MARKER_HEIGHT = 60;
    // marker is more tall at top, so calc distance to some point at marker top
    const K_MARKER_WEIGHT_PT = K_MARKER_HEIGHT * 0.7;
    // distance to markers depends on scale so hover on big markers is more probable
    const scale = markerProps.scale;
    const x = pt.x;
    const y = pt.y - K_MARKER_WEIGHT_PT * scale;

    const scaleNormalized = Math.min(scale / K_SCALE_NORMAL, 1);
    const K_MIN_DIST_MIN_KOEF = 0.6;

    const distKoef = 1 + scaleNormalized * (K_MIN_DIST_MIN_KOEF - 1);
    return distKoef * Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
}

export const currentDate = () => {
    const now = moment().format('gggg');
    return now
}

export function fitTextMax100(text) {
    if (text.length > 100) {
        return text.slice(0, 100) + '...'
    } else {
        return text
    }
}

export function passwordStrengthChecker(password) {
    let parameters = {
        minPasswordLength: 8,
        baseScore: 0,
        score: 0,
        bonus: {
            excess: 3,
            upper: 4,
            numbers: 5,
            symbols: 5,
            combo: 0,
            flatLower: 0,
            flatNumber: 0
        }
    }

    if (password.length >= parameters.minPasswordLength) {
        parameters.baseScore = 50;
        let caracters = analyzePassword(password, parameters);
        parameters.score = calcPasswordComplexity(caracters, parameters)
    } else {
        parameters.score = 0;
    }

    return (parameters.score < 50 || onlyNumbers(password))
        ? 'Too weak!'
        : ((parameters.score > 100) ? 'Strong!' : 'Medium!');
}

export function analyzePassword(password, parameters) {
    let num = Object.create({
        excess: 0,
        upper: 0,
        numbers: 0,
        symbols: 0
    })

    for (let i = 0; i < password.length; i++) {
        if (password[i].match(/[A-Z]/g)) { num.upper++; }
        if (password[i].match(/[0-9]/g)) { num.numbers++; }
        if (password[i].match(/(.*[!,@,#,$,%,^,&,*,?,_,~])/)) { num.symbols++; }
    }

    num.excess = password.length - parameters.minPasswordLength;

    if (num.upper && num.numbers && num.symbols) {
        parameters.bonus.combo = 25;
    }

    else if ((num.upper && num.numbers) || (num.upper && num.symbols) || (num.numbers && num.symbols)) {
        parameters.bonus.combo = 15;
    }

    if (password.match(/^[\sa-z]+$/)) {
        parameters.bonus.flatLower = -15;
    }

    if (password.match(/^[\s0-9]+$/)) {
        parameters.bonus.flatNumber = -35;
    }

    return num;
}

export function calcPasswordComplexity(caracters, parameters) {
    return parameters.score = parameters.baseScore
        + (caracters.excess * parameters.bonus.excess)
        + (caracters.upper * parameters.bonus.upper)
        + (caracters.numbers * parameters.bonus.numbers)
        + (caracters.symbols * parameters.bonus.symbols)
        + parameters.bonus.combo
        + parameters.bonus.flatLower
        + parameters.bonus.flatNumber;
}

export function onlyNumbers(str) {
    return /^[0-9]+$/.test(str)
}

export function getDateStringFromTimestamp(timestamp) {
    return new Date(timestamp).toDateString().split(' ').slice(1).join(' ')
}

export function getDocumentExtension(documentName) {
    let type = ''
    if (documentName)
        documentName.includes('.doc') ? type = 'doc' :
            documentName.includes('.ppt') ? type = 'ppt' :
                documentName.includes('.pdf') ? type = 'pdf' :
                    documentName.includes('.xls') ? type = 'xls' : type = 'not accepted type'

    return type
}

export function getURLPublicStorage() {
    var url = ''
    if (window.location.hostname == "localhost") {
        url = HOST_DEV + '/' + STORAGE_DEV_PUBLIC;
    } else {
        url = window.location.host + '/' + STORAGE_PROD_PUBLIC
    }

    return url
}

export function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export function convertToSlug(str) {
    return str.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

export function convertCamelCase(str) {
    return str
        .replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

export function generateArrayOfYears(numOfYears = 50) {
    var max = new Date().getFullYear()
    var min = max - numOfYears
    var years = []

    for (var i = max; i >= min; i--) {
        years.push(i)
    }
    return years
}

export function generateArrayOfMonth() {
    var months = []

    for (var i = 1; i <= 12; i++) {
        let month = i
        if (month < 10)
            month = String(month).padStart(2, '0')
        months.push(month)
    }

    return months
}

export function getArrayOfDays(date) {
    let daysInMonth = date.getDate();
    var days = []

    for (var i = 0; i < daysInMonth; i++) {
        let day = i + 1
        if (day < 10)
            day = String(day).padStart(2, '0')
        days.push(day)
    }

    return days
}

export function getContentTag(string, tag) {
    let tag_start = `<${tag}>`
    let tag_end = `</${tag}>`
    let index_start, index_end

    index_start = string.indexOf(tag_start) + tag_start.length
    index_end = string.indexOf(tag_end)

    if (index_start == -1 || index_end == -1) {
        return
    }

    return string.substring(index_start, index_end)
}