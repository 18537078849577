export const trackingActions = {

  TRACKING_FAILED: 'TRACKING_FAILED',
  TRACKING_SUCCESS: 'TRACKING_SUCCESS',
  TRACKING_PENDING: 'TRACKING_PENDING',
  SAVE_EVENT: 'SAVE_EVENT',

  fetchTrackingFailed: error => ({
    type: trackingActions.TRACKING_FAILED,
    payload: error
  }),

  fetchTrackingSuccess: (id, data) => ({
    type: trackingActions.TRACKING_SUCCESS,
    payload: {
        data,
        id
    }
  }),

  fetchTrackingPending: () => ({
    type: trackingActions.TRACKING_PENDING,
  }),

  saveTrackingEvent: (event) => ({
    type: trackingActions.SAVE_EVENT,
    payload: event
  })

};

export const trackingRequestActions = {
  failed: trackingActions.fetchTrackingFailed,
  success: trackingActions.fetchTrackingSuccess,
  pending: trackingActions.fetchTrackingPending
};
