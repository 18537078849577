import React, { Component } from 'react'


class NotFoundPage extends Component {
  render() {
    return (
      <div className="container">
        <div className="main pt-md-5 pt-lg-0 container main-form">
          <div className="d-flex align-items-center justify-content-center text-center">
            <div className="error-template">
              <h1>404</h1>
              <div className="error-details">
                Sorry, an error has occurred, Requested page not found!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFoundPage;
