import React from 'react';
import { ThinkingTalentsReportUserItem } from './thinking-talents-report-user-item';


export const QuadrantItem = ({ talent, StyledQuadrant, isShadowTalents}) => {

    const { quadrant } = talent;
    //console.log(quadrant)
    return (
        <StyledQuadrant quadrant_color={quadrant.color} 
            className={`${(quadrant.name === 'Analytic' || quadrant.name === 'Procedural') ? "align-items-start" : "align-items-end"} ${(quadrant.name === 'Analytic' || quadrant.name === 'Procedural') ? "mr-1" : "ml-1"} 
            p-3 h-100 d-flex flex-column`}> {(quadrant.name === 'Analytic' || quadrant.name === 'Innovative') ? <><p className="h3 mb-0">{quadrant.name}</p><p className="w-50">{quadrant.excerpt}</p> </>: null }
            <div className={`d-flex flex-wrap ${(quadrant.name === 'Analytic' || quadrant.name === 'Procedural') ? "pr-5 mr-5" : "pl-5 ml-5"} ${(quadrant.name === 'Procedural') ? "flex-fill" : ""} justify-content-around row-cols-3`}>
                {
                    quadrant.items.map(item => <ThinkingTalentsReportUserItem item={item} color={quadrant.color} key={item.item.id} isShadowTalents={isShadowTalents}/>)
                }
            </div>
            {
                (quadrant.name === 'Procedural' || quadrant.name === 'Relational') ? 
                <>
                <p className="h3 mb-0">{quadrant.name}</p>
                <p className="mb-0 w-50">{quadrant.excerpt}</p> 
                </>
                : null
            }

        </StyledQuadrant>
    )
}
