import { communityActions } from './actions';
import { community } from './community';

export const communityReducer = (state = community, { payload, type }) => {

    switch (type) {
        case communityActions.FETCH_COMMUNITY_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload?.error?.data
            };

        case communityActions.FETCH_COMMUNITY_SUCCESS:
            return {
                ...state,
                isPending: false,
                data: payload.data,
            };

        case communityActions.FETCH_COMMUNITY_PENDING:
            return {
                ...state,
                isPending: true
            };

        default:
            return state;
    }
}
