export const systemActions = {

  FETCH_SYSTEM_FAILED: 'FETCH_SYSTEM_FAILED',
  FETCH_SYSTEM_SUCCESS: 'FETCH_SYSTEM_SUCCESS',
  FETCH_SYSTEM_PENDING: 'FETCH_SYSTEM_PENDING',

  LOAD_SYSTEM: 'LOAD_SYSTEM',

  fetchSystemFailed: error => ({
    type: systemActions.FETCH_SYSTEM_FAILED,
    payload: error
  }),

  fetchSystemSuccess: (systemId, data) => ({
    type: systemActions.FETCH_SYSTEM_SUCCESS,
    payload: {
      data,
      systemId
    }
  }),

  fetchSystemPending: systemId => ({
    type: systemActions.FETCH_SYSTEM_PENDING,
    payload: {
      systemId
    }
  }),

  loadSystem: (systemId) => ({
    type: systemActions.LOAD_SYSTEM,
    payload: {
      systemId
    }
  }),
};

export const systemRequestActions = {
  failed: systemActions.fetchSystemFailed,
  success: systemActions.fetchSystemSuccess,
  pending: systemActions.fetchSystemPending
};
