import { DEFAULT_SESSION_ID } from '../constants';

import { membersListIdForFind, membersListIdForSearch } from './utils';

export const membersListActions = {

  FETCH_MEMBERS_FAILED: 'FETCH_MEMBERS_FAILED',
  FETCH_MEMBERS_SUCCESS: 'FETCH_MEMBERS_SUCCESS',
  FETCH_MEMBERS_PENDING: 'FETCH_MEMBERS_PENDING',

  SEARCH_MEMBERS: 'SEARCH_MEMBERS',
  FIND_MEMBERS:'FIND_MEMBERS',
  FIND_MEMBERS_BY_ID:'FIND_MEMBERS_BY_ID',

  fetchMembersFailed: error => ({
    type: membersListActions.FETCH_MEMBERS_FAILED,
    payload: error
  }),

  fetchMembersSuccess: (membersListId, data) => ({
    type: membersListActions.FETCH_MEMBERS_SUCCESS,
    payload: {
      data,
      membersListId
    }
  }),

  fetchMembersPending: membersListId => ({
    type: membersListActions.FETCH_MEMBERS_PENDING,
    payload: {
      membersListId
    }
  }),

  findMembers: (name) => ({
    type: membersListActions.FIND_MEMBERS,
    payload: {
      membersListId: membersListIdForFind(name),
      name
    }
  }),

  searchMembers: (filters) => ({
    type: membersListActions.SEARCH_MEMBERS,
    payload: {
      membersListId: membersListIdForSearch(filters),
      filters
    }
  }),

  findMembersById: (membersListId) => ({
    type: membersListActions.FIND_MEMBERS_BY_ID,
    payload: {
      membersListId:  `${membersListId}`,
    }
  }),
};


export const membersListRequestActions = {
  failed: membersListActions.fetchMembersFailed,
  success: membersListActions.fetchMembersSuccess,
  pending: membersListActions.fetchMembersPending
};
