import React, { Component } from 'react';
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';
import { userActions } from '../../../core/users';
import { NavLink } from 'react-router-dom';
import iconlogout from '../../../assets/img/icon-menu-logout.svg';


class UserMenu extends Component {

  logoutUser = () => {
    const { logout, history } = this.props;

    logout({ history });
  }

  render() {
    const { user, profile, systemCheck } = this.props;

    return (
      <Nav className="navbar-right" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret className="user-profile p-0 rounded-pill">
            <span className="avatar mr-md-3">
              <img src={user.avatar} className="rounded-circle sm-avatar" alt="Jhon Doe"/>
            </span>
            <span className="username mr-2 d-sm-none d-none d-md-inline-block">{profile.data.first_name ? profile.data.first_name : user && user.first_name}</span>
          </DropdownToggle>
          <DropdownMenu className="user-menu-items p-0" right>
            <div className="misc"></div>
            <div className="profile-img pt-3 px-3 pb-2 bg-cian rounded-top">
              <div className="profile-body px-3 text-center">
                <div className="mb-2"><img src={user.avatar} className="rounded-circle md-avatar mx-auto" alt="Jhon Doe"/></div>
                <p className="text-reset mb-0">{profile.data.first_name ? profile.data.first_name : user && user.first_name} {profile.data.last_name ? profile.data.last_name : user && user.last_name}</p>
              </div>
            </div>
            <DropdownItem tag="a" href="/front/profile" className="text-uppercase">
              My Profile
            </DropdownItem>
            {user.documents && user.documents.length > 0 &&
            <DropdownItem tag="a" href="/front/my-documents" className="text-uppercase">
              My Documents
            </DropdownItem>
            }
            <DropdownItem divider className="m-0" />
            <DropdownItem tag="a" onClick={() => this.logoutUser()} className="text-uppercase py-3 cursor-pointer"><i className="mr-2"><img src={iconlogout}/></i><span>Logout</span></DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    );
  }
}
//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {
  const { profile, systemCheck } = state;
  return {
    profile,
    systemCheck
  }
}

const mapDispatchToProps = {
  logout: userActions.logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserMenu);
