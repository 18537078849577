import { createSelector } from 'reselect';

export function getSystem(state) {
  return state.system;
}

export function getSystemById(state, systemId) {
  return getSystem(state).get(systemId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentSystem = createSelector(
  getSystem,
  system => system.get(system.get('currentSystemId'))
);
