import React from 'react';
import { QuadrantItem } from './QuadrantItem';
import { Row, Col } from 'reactstrap';
import graphic_report from '../../../../assets/img/graphic-report-collapsed-sm.jpg';
import { useSelector } from 'react-redux';

export const QuadrantListCollapsed = ({ user }) => {
    const { quadrants } = useSelector(state => state)

    const getQuadrantColor = (name) => {
        if (quadrants) {
            let quadrant = quadrants.find(quadrant => quadrant.name === name)
            if (quadrant) return quadrant.color
        }
    }

    return (
        <>
            <Row className="px-0 justify-content-center">
                <Col className="px-0 mr-1">
                    <div style={{ display: 'block', height: '50px' }} className={`cursor-pointer`}>
                        <div className="ml-sm-3">
                            <h6 className="mb-0 text-right text-uppercase font-weight-semibold" style={{ color: getQuadrantColor('Analytic') }}>Analytic</h6>
                        </div>
                        <div className={`lists-item mx-0 float-right`}>
                            {
                                user.talents &&
                                user.talents.map(quadrant =>
                                    quadrant.quadrant === 'Analytic' &&
                                    quadrant.items.map(item =>
                                        <QuadrantItem
                                            key={'qitem_' + item.id}
                                            item={item}
                                            collapsed={true}
                                            quadrant={quadrant}
                                        />
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div style={{ display: 'inline' }} className={`cursor-pointer`}>
                        <div className="ml-sm-3">
                            <h6 className="mb-0 text-right text-uppercase font-weight-semibold" style={{ color: getQuadrantColor('Procedural') }}>Procedural</h6>
                        </div>
                        <div className={`lists-item mx-0 float-right`}>
                            {
                                user.talents &&
                                user.talents.map(quadrant =>
                                    quadrant.quadrant === 'Procedural' &&
                                    quadrant.items.map(item =>
                                        <QuadrantItem
                                            key={'qitem_' + item.id}
                                            item={item}
                                            collapsed={true}
                                            quadrant={quadrant}
                                        />
                                    )
                                )
                            }
                        </div>
                    </div>
                </Col>

                <div className="bg-map-dk">
                    <div className="graphic-map-img mx-auto" style={{ width: '75px' }}>
                        <div className="mx-auto text-center graphic-list-all-talent-collapsed">
                            <div>
                                <h6 className="text-center mb-0 text-uppercase font-weight-semibold" style={{ color: getQuadrantColor('All') }}>All</h6>
                            </div>
                            <div className={`lists-item`}>
                                {
                                    user.talents &&
                                    user.talents.map(quadrant =>
                                        quadrant.quadrant === 'All' && quadrant.items.map(item =>
                                            <QuadrantItem
                                                key={'qitem_' + item.id}
                                                item={item}
                                                collapsed={true}
                                                quadrant={quadrant}
                                                center={true}
                                            />
                                        )
                                    )
                                }
                            </div>
                        </div>
                        <img src={graphic_report} id={user.id} className="img-fluid" />
                    </div>
                </div>

                <Col className="px-0 ml-1">
                    <div style={{ display: 'inline-block', height: '50px' }} className={`cursor-pointer `}>
                        <div>
                            <h6 className="text-right mb-0 text-uppercase font-weight-semibold" style={{ color: getQuadrantColor('Innovative') }}>Innovative</h6>
                        </div>
                        <div className={`lists-item`}>
                            {
                                user.talents &&
                                user.talents.map(quadrant =>
                                    quadrant.quadrant === 'Innovative' &&
                                    quadrant.items.map(item =>
                                        <QuadrantItem
                                            key={'qitem_' + item.id}
                                            item={item}
                                            collapsed={true}
                                            quadrant={quadrant}
                                        />
                                    )
                                )
                            }
                        </div>
                    </div>

                    <div className={`cursor-pointer`}>
                        <div>
                            <h6 className="mb-0 text-uppercase font-weight-semibold" style={{ color: getQuadrantColor('Relational') }}>Relational</h6>
                        </div>
                        <div className={`lists-item`}>
                            {
                                user.talents &&
                                user.talents.map(quadrant =>
                                    quadrant.quadrant === 'Relational' &&
                                    quadrant.items.map(item =>
                                        <QuadrantItem
                                            key={'qitem_' + item.id}
                                            item={item}
                                            collapsed={true}
                                            quadrant={quadrant}
                                        />
                                    )
                                )
                            }
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}
