
export const userResumeActions = {

    FETCH_USER_RESUME_FAILED: 'FETCH_USER_RESUME_FAILED',
    FETCH_USER_RESUME_SUCCESS: 'FETCH_USER_RESUME_SUCCESS',
    FETCH_USER_RESUME_PENDING: 'FETCH_USER_RESUME_PENDING',
    LOAD_USER_RESUME: 'LOAD_USER_RESUME',


    fetchUserResumeFailed: error => ({
        type: userResumeActions.FETCH_USER_RESUME_FAILED,
        payload: error
    }),

    fetchUserResumeSuccess: (id, data) => ({
        type: userResumeActions.FETCH_USER_RESUME_SUCCESS,
        payload: { id, data }
    }),

    fetchUserResumePending: () => ({
        type: userResumeActions.FETCH_USER_RESUME_PENDING,
    }),

    loadUserResume: () => ({
        type: userResumeActions.LOAD_USER_RESUME,
    })
};


export const userResumeRequestActions = {
    failed: userResumeActions.fetchUserResumeFailed,
    success: userResumeActions.fetchUserResumeSuccess,
    pending: userResumeActions.fetchUserResumePending
};
