import React, { useEffect, useState } from 'react';
import { Alert, CardImg, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { OptionsList } from './OptionsList';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { questionnaireItemsActions } from '../../../../core/questionnaire-items/actions';
import { questionnaireStoreOptionsActions } from '../../../../core/questionnaire-storeOptions/actions';
import LoadingIndicator from '../../loading-indicator/loading-indicator';
import Select from 'react-select';
import { Optioncustom } from '../questions-review/OptionCustom';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

export const Question = ({ handleNextSection }) => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { questionnaire_list } = useSelector(state => state);
    const [sectionActive, setSectionActive] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [optionSelected, setOptionSelected] = useState([]);
    const [disabled, setDisabled] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [allValid, setAllValid] = useState(true);


    useEffect(() => {
        let isMounted = true;

        if (isMounted) {
            dispatch(questionnaireItemsActions.loadQuestionnaireItems(Number(id)));
        }

        return () => {
            isMounted = false;
        }
    }, []);


    useEffect(() => {
        if (questionnaire_list.data?.items?.length > 0) {
            setSectionActive(questionnaire_list.data.items[0].id);
        }
    }, [])

    const questionItem = () => {
        setCurrentQuestion(questionnaire_list.data.items && questionnaire_list.data.items.find(question => sectionActive === question.id));
    }

    useEffect(() => {
        questionItem();
    }, [sectionActive, questionnaire_list])

    const alertCondition = () => {
        if ((currentQuestion && currentQuestion.type === 'chk') || (currentQuestion && currentQuestion.type === 'opt')) {
            return <>
                {currentQuestion && currentQuestion.type === 'chk' ?
                    currentQuestion.help_text : currentQuestion.help_text}
            </>
        } else {
            return null;
        }
    }

    const positionElement = (question) => {
        return questionnaire_list.data.items.findIndex(
            item => item.id === question.id
        ) + 1;
    }

    const buildOptions = (optionId, checked) => {
        if (checked) {
            setOptionSelected([
                ...optionSelected,
                {
                    id: optionId,
                    checked
                }
            ])
        } else {
            setOptionSelected(
                optionSelected.filter(option => option.id !== optionId)
            )
        }
    }


    const nextQuestion = (finish) => {
        if (areAllValid()) {
            dispatch(questionnaireStoreOptionsActions.saveQuestionnaireStoreOptions({ questionnaireId: id, questionId: currentQuestion.id, options: optionSelected }));
            finish ? handleNextSection('finish') : setSectionActive(questionnaire_list.data.items[positionElement(currentQuestion)].id);
            setDisabled(true);
            setOptionSelected([])
            setShowAlert(false)
            dispatch(questionnaireItemsActions.nextQuestionQuestionnaireItems());
            window.scrollTo(0, 0);
        } else {
            setAllValid(false);
        }
    }

    useEffect(() => {
        disabledBtnContinue()
    }, [optionSelected]);


    const disabledBtnContinue = () => {
        var valid = true;

        if (currentQuestion && currentQuestion.minimum && currentQuestion.maximum) {
            if (optionSelected.length > currentQuestion.maximum || optionSelected.length < currentQuestion.minimum) {
                valid = false;
            } else {
                valid = true;
            }

            setDisabled(!valid);
            
            if(optionSelected.length)
                setShowAlert(!valid);
        }
    }

    const transformItemsIntoNameValue = (list) => {
        return list && list.map((element) => {
            return { value: element.id, label: element.title };
        });
    };

    const newList = transformItemsIntoNameValue(currentQuestion && currentQuestion.options && currentQuestion.options);


    const onChangeSelectedOption = (selectedOption) => {
        setOptionSelected([{ id: selectedOption.value }])
    }

    const optionCustomSelected = (opt) => {
        let value = false
        if (opt.custom_enable) {
            optionSelected.forEach(option => {
                if (option.id.toString() === opt.id.toString()) {
                    value = true
                }
            });
        }
        return value
    }

    const setValue = (target) => {
        optionSelected.forEach(option => {
            if (target.id.toString() === option.id.toString()) {
                option.custom_value = target.value;
            }
            if (areAllValid()) {
                setAllValid(true);
            }
        });
    }

    const validText = (optionId, mandatory) => {
        let valid = true;
        if (mandatory) {
            optionSelected.forEach(opt => {
                if (optionId.toString() === opt.id.toString()) {
                    if (!opt.custom_value || opt.custom_value === undefined || opt.custom_value == '') {
                        valid = false;
                    }
                }
            });
        }
        return valid
    }

    const areAllValid = () => {
        let boolean = true;
        currentQuestion.options.forEach(option => {
            optionSelected.forEach(opt => {
                if (option.custom_mandatory && option.id.toString() === opt.id.toString()) {
                    if (!validText(option.id, option.custom_mandatory)) {
                        boolean = false
                    }
                }
            });
        });
        return boolean;
    }


    return (
        <>
            <TransitionGroup >
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key={`${sectionActive}`} appear>
                    <div className="h-100">
                        <div className="bg-white my-md-4 rounded-lg pb-md-4 px-md-5 pb-md-5 pt-md-3 content-view">
                            <div className="px-md-4 pb-5 pb-md-0">
                                <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 flex-row-reverse d-md-flex align-items-center justify-content-between">
                                    <div className="paginador text-center text-md-right">{questionnaire_list.starts_from}<span className="text-muted"> /{questionnaire_list.data && questionnaire_list.data.total_items}</span></div>
                                    <div className="d-flex align-items-center">
                                        <div className="rounded-circle question-member bg-celeste text-center mr-3">{questionnaire_list.starts_from}</div>
                                        <h5 className="mb-0 text-uppercase">{currentQuestion && currentQuestion.title}</h5>
                                    </div>
                                </div>
                                <p className="mt-2 mb-3 h4 text-reset"><small>{currentQuestion && currentQuestion.subtitle}</small></p>
                                {
                                    alertCondition() && <Alert color="primary" className="text-md-center font-weight-bold">{alertCondition()}</Alert>
                                }
                                {
                                    (currentQuestion && (currentQuestion.type === "opt" || currentQuestion.type === "chk")) &&
                                    <OptionsList
                                        currentQuestion={currentQuestion}
                                        buildOptions={buildOptions}
                                        setOptionSelected={setOptionSelected}
                                        setValue={setValue}
                                        validText={validText}
                                        allValid={allValid}
                                        optionCustomSelected={optionCustomSelected}
                                    />
                                }

                                {
                                    (currentQuestion && (currentQuestion.type === "sel")) &&
                                    <div className="select-years-content ml-5 pl-1 pt-3 ml-md-0 pl-md-0 pt-md-0">
                                        <Select options={newList} defaultValue={onChangeSelectedOption}
                                            onChange={onChangeSelectedOption} className="form-control-select select-years d-md-inline-block" classNamePrefix="form-control" placeholder={currentQuestion && currentQuestion.help_text && currentQuestion.help_text} />

                                        {currentQuestion.options && currentQuestion.options.map((option) =>
                                            <div key={option.id}>
                                                {
                                                    optionCustomSelected(option) &&
                                                    <Optioncustom
                                                        option={option}
                                                        setValue={setValue}
                                                        validText={validText}
                                                        allValid={allValid}
                                                        setOptionSelected={setOptionSelected}
                                                    />
                                                }
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>

                            <div className={`text-danger text-center mt-3 ${(disabled && showAlert) ? 'visible' : 'invisible'}`}><p className="mb-0">{currentQuestion && currentQuestion.validation_message}</p></div>

                            {
                                currentQuestion && positionElement(currentQuestion) < questionnaire_list.data.items.length ?
                                    <Row className="contenido-bottom-fixed-mb">
                                        <Col md={{ size: 2, offset: 5 }}>
                                            <button
                                                className="btn btn-primary text-uppercase btn-block rounded-pill mt-3"
                                                onClick={() => nextQuestion(false)}
                                                disabled={disabled}
                                            >
                                                Continue
                                            </button>
                                        </Col>
                                    </Row>

                                    :
                                    <Row className="contenido-bottom-fixed-mb">
                                        <Col md={{ size: 2, offset: 5 }}>
                                            <button
                                                className="btn btn-primary text-uppercase btn-block rounded-pill mt-3"
                                                onClick={() => nextQuestion(true)}
                                                disabled={disabled}
                                            >
                                                Continue
                                            </button>
                                        </Col>
                                    </Row>
                            }
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>

            {
                questionnaire_list.isPending && <LoadingIndicator className="text-center" />
            }
        </>
    )
}