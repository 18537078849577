//=========================================================
//  CONSTANTS
//---------------------------------------------------------
export const APP_NAME = 'smart-navigator-app'

// export const HOST_DEV = "http://localhost:8080";
export const HOST_DEV = "https://assessment.mytrueadvance.review";
// export const HOST_DEV = "https://jordan.mytrueadvance.review";
// export const HOST_DEV = "http://demo.ci.dcoworks.com";
// export const HOST_DEV = "http://demo.ci-qa.dcoworks.com";
// export const HOST_DEV = "http://exxon.ci.dcoworks.com";
// export const HOST_DEV = "http://space-x.ci.dcoworks.com";
// export const HOST_DEV = "http://space-x.ci-qa.dcoworks.com/";
// export const HOST_DEV = "http://cocacola.ci.dcoworks.com";

//=====================================
//  API
//-------------------------------------
 
export const API_KEY = 'YzrTyq8ac_4gxLSn831S-cs3C9ynmjVYJLi2'

//export const API_BASE_URL = window.location.hostname == "localhost" ? '/api' : '/back/public/api'
export const API_BASE_URL = '/back/public/api'
export const API_BASE_URL_V = `${API_BASE_URL}/v1`

export const API_SESSION = `${API_BASE_URL_V}/session`
export const API_THINKINGTALENTS = `${API_BASE_URL_V}/thinkingtalents`
export const API_TEAMS = `${API_BASE_URL_V}/teams`
export const API_MEMBERS = `${API_BASE_URL_V}/members`
export const API_SYSTEM = `${API_BASE_URL_V}/system`
export const API_MINDPATTERNS = `${API_BASE_URL_V}/mindpatterns`
export const API_QUESTIONNAIRE = `${API_BASE_URL_V}/questionnaire`
export const API_VIDEOS = `${API_BASE_URL_V}/videos`
export const API_USER = `${API_BASE_URL_V}/user`
export const API_USER_PROFILE = `${API_USER}/profile`
export const API_USER_DOCUMENTS = `${API_USER}/documents`
export const API_USER_ACTIVITY = `${API_BASE_URL_V}/user-activity`
export const API_COMMUNITY = `${API_BASE_URL_V}/community`
export const API_REPORTS = `${API_BASE_URL_V}/reports`


//=====================================
//  STORAGE
//-------------------------------------

export const STORAGE = '/back/storage'
export const STORAGE_PROD = `${STORAGE}/app`                // ** PRODUCTION //app_prod
export const STORAGE_PROD_PUBLIC = `${STORAGE_PROD}/public` // ** PRODUCTION
export const STORAGE_DEV = `${STORAGE}/app`                 // ** DEV
export const STORAGE_DEV_PUBLIC = `${STORAGE_DEV}/public`   // ** DEV

export const SYSTEM_ID_MINDPATTERNS = 'mindpatterns';
export const SYSTEM_ID_SENIORITIES = 'seniorities';
export const SYSTEM_ID_LOGIN_POPUPS = 'login-popups';

export const DEFAULT_SESSION_ID = "smart-navigator-session"

export const QUADRANTS_ANALYTIC = 'Analytic'
export const QUADRANTS_INNOVATIVE = 'Innovative'
export const QUADRANTS_RELATIONAL = 'Relational'
export const QUADRANTS_PROCEDURAL = 'Procedural'
export const QUADRANTS_ALL = 'All'

export const MIND_PATTERNS_DATA_AREAS = 'areas';
export const MIND_PATTERNS_DATA_OPTIONS = 'options';
export const MIND_PATTERNS_DATA_REPORTS = 'reports';

export const list_options = [
  'ALLWAYS', 'SOMETIMES', 'NEVER'
]

export const mind_pattern_sections = {
    START: "START",
    READY: "READY",
    ASSESSMENT: "ASSESSMENT",
    READY_PART_TWO: "READY_PART_TWO",
    ASSESSMENT_PART_TWO: "ASSESSMENT_PART_TWO",
    REPORT_COMPLETE: "REPORT_COMPLETE",
    REPORT: "REPORT"
}