import { Map } from 'immutable';
import { createAssessmentItem } from './assessmentItem';
import { assessmentsListActions } from '../assessments-list';


export function assessmentItemReducer(state = new Map(), action) {
  const { payload } = action;

  switch (action.type) {
    case assessmentsListActions.FETCH_ASSESSMENTS_SUCCESS:
      return state.withMutations(assessment => {
        payload.data.items.forEach(itemData => {
          assessment.set(itemData.item_id, createAssessmentItem(itemData));
        });
      });

    default:
      return state;
  }
}
