import React from "react";
import { XLSXPreview } from "../documents-previews/XLSXPreview";
import FileViewer from "react-file-viewer";
import { downloadFile as downloadFileDefault } from "../../../../core/helper"
import link_variant from "../../../../assets/img/link-variant.svg"

export const DocumentPreview = ({ type, file, link, downloadable = true, downloadFile = downloadFileDefault }) => {
    console.log(type)
    return (
        <>
            {(type === 'pdf') &&
                <div className="unselectable cursor-pointer" onClick={() => { downloadable && downloadFile(file) }}>
                    <div className="container-doc">
                        <FileViewer
                            fileType='pdf'
                            filePath={URL.createObjectURL(file)}
                        />
                    </div>
                </div>
            }

            {type === 'ppt' &&
                <div className="cursor-pointer" onClick={() => { downloadable && downloadFile(file) }} >
                    <div className="not-clickable embed-responsive embed-responsive-doc-prev">
                        <iframe id="idcw"
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${link}`}
                            className="embed-responsive-item"
                        ></iframe>
                    </div>
                </div>
            }

            {type === 'doc' &&
                <div className="cursor-pointer" onClick={() => { downloadable && downloadFile(file) }} >
                    <div className="not-clickable embed-responsive embed-responsive-doc-prev">
                        <iframe id="idcw"
                            src={`https://view.officeapps.live.com/op/embed.aspx?src=${link}`}
                            className="embed-responsive-item"
                        ></iframe>
                    </div>
                </div>
            }

            {type === 'xls' &&
                <div className="unselectable cursor-pointer" onClick={() => { downloadable && downloadFile(file) }}>
                    <XLSXPreview file={file} downloadable />
                </div>
            }

            {type === '' && 
                <div className="cursor-pointer embed-responsive embed-responsive-doc-prev" height="175px" onClick={() => { downloadable && window.open(link, '_blank') }}>
                    <img src={link_variant} className="embed-responsive-item" width="150px" alt="link document" />
                </div>
            }

        </>
    );
}