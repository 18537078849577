import { call, fork, select, takeEvery } from 'redux-saga/effects';

import {
  createTeam as sendCreate,
  updateTeam as sendUpdate,
  addMembersTeam as sendMembers,
  removeMemberTeam as sendRemoveMember,
  setLeaderMemberTeam as sendSetMember,
  deleteTeam as sendDeleteTeam,
} from '../api'

import { teamActions } from './actions';
import { getTeamById } from './selectors';

export function* loadTeam({ payload }) {

  const { team_id } = payload;
  
  yield select(getTeamById, team_id);

  /*
    if ( team && team.isNew ) {
      yield call(fetchTeam, team.id);
    }
    */
}

export function* createTeam({ payload }) {

  const { team, team_id } = payload;
  yield call(sendCreate, team_id, team);

}

export function* updateTeam({ payload }) {

  const { team, team_id } = payload;
  yield call(sendUpdate, team_id, team);

}

export function* addMembersTeam({ payload }) {

  const { items, team_id } = payload;
  let items_data = [];
  items.forEach(value => {
    if (value != 'all')
      items_data.push({ id: value });
  });

  yield call(sendMembers, team_id, { team_id, items: items_data });

}

export function* removeMemberTeam({ payload }) {

  const { member_id, team_id } = payload;

  yield call(sendRemoveMember, team_id, { team_id, user_id: member_id });

}

export function* setLeaderMemberTeam({ payload }) {

  const { member_id, team_id } = payload;

  yield call(sendSetMember, team_id, { team_id, user_id: member_id });
}

export function* deleteTeam({ payload }) {

  const { team_id } = payload;
  yield call(sendDeleteTeam, team_id);

}



//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadTeam() {
  yield takeEvery(teamActions.LOAD_TEAM, loadTeam);
}

export function* watchCreateTeam() {
  yield takeEvery(teamActions.CREATE_TEAM, createTeam);
}

export function* watchUpdateTeam() {
  yield takeEvery(teamActions.UPDATE_TEAM, updateTeam);
}

export function* watchAddMembersTeam() {
  yield takeEvery(teamActions.ADD_MEMBERS_TEAM, addMembersTeam);
}

export function* watchRemoveMemberTeam() {
  yield takeEvery(teamActions.REMOVE_TEAM_MEMBER, removeMemberTeam);
}

export function* watchSetLeaderMemberTeam() {
  yield takeEvery(teamActions.SET_LEADER_TEAM_MEMBER, setLeaderMemberTeam);
}

export function* watchDeleteTeam() {
  yield takeEvery(teamActions.DELETE_TEAM, deleteTeam);
}





//=====================================
//  ROOT
//-------------------------------------

export const teamSagas = [
  fork(watchLoadTeam),
  fork(watchCreateTeam),
  fork(watchUpdateTeam),
  fork(watchAddMembersTeam),
  fork(watchRemoveMemberTeam),
  fork(watchSetLeaderMemberTeam),
  fork(watchDeleteTeam),

];
