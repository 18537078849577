import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { QuadrantItem } from './quadrant-item';
import { QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL, QUADRANTS_PROCEDURAL, QUADRANTS_ALL } from '../../../core/constants';
import styled from 'styled-components';
import { ThinkingTalentsReportUserItem } from './thinking-talents-report-user-item';
import { userResumeActions } from '../../../core/thinking-talents-user-resume/actions';
import { pdfsActions } from '../../../core/pdfs';
import LoadingIndicator from '../loading-indicator';

const StyledQuadrant = styled.div`
  border: ${props => "1px solid " + props.quadrant_color} ;
  border-radius: 20px;

  .h3, .talents-item p {
    color: ${props => props.quadrant_color};
  }
`;

export const ThinkingTalentsReportUser = ({ isOpen }) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.user_resume_TT);
    const { user } = useSelector(state => state);
    const [isShadowTalents, setIsShadowTalents] = useState(false);
    const [listTop, setListTop] = useState(0);
    let reportBox = useRef();
    let quadrantAll = useRef();
    const { pdf } = useSelector(state => state);

    useEffect(() => {
        dispatch(userResumeActions.loadUserResume())
    }, [])

    const getFullReportTT = () => {
        dispatch(pdfsActions.loadFullReportTT())
    }

    // useEffect(() => {
    //     // const lis = document.getElementById("lists-top").clientHeight
    //     // console.log(lis)

    //     console.log(reportBox.current.clientHeight)

    // }, [])

    const handleChange = ({ target }) => {
        setIsShadowTalents(target.checked)
    }



    // const updateSize = () => {
    //     if (reportBox.current.clientHeight && quadrantAll.current.clientHeight && isOpen) {
    //         setListTop((reportBox.current.clientHeight / 2) - (quadrantAll.current.clientHeight / 2) )
    //          console.log("updateSize")
    //         }
    //         isOpen && console.log(quadrantAll.current.clientHeight)
    // }

    // console.log(listTop)
    return (
        <>
            <div className="container px-0">
                <p className="h5 d-none"> {user.first_name}’s Thinking Talents</p>
                <hr />

                <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="d-flex">
                        <p className="text-uppercase mb-0 mr-3">
                            <i className="icon mr-2">
                                <svg width="16" height="16" className="mt-n1">
                                    <circle cx="8" cy="8" r="8" fill="#D8F1F8" />
                                </svg>
                            </i><span>Always</span>
                        </p>
                        <p className="text-uppercase mb-0 mx-3">
                            <i className="icon mr-2">
                                <svg width="16" height="16" className="mt-n1">
                                    <circle cx="8" cy="8" r="8" fill="#d5d5d5" />
                                </svg>
                            </i><span>Never</span>
                        </p>
                    </div>

                    <div className="d-flex">
                        <div className="btn btn-outline-primary text-uppercase rounded-pill px-md-2 btn-shadow py-md-0">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch1"
                                    checked={isShadowTalents} onChange={handleChange} />
                                <label className="custom-control-label cursor-pointer" htmlFor="customSwitch1">Shadow Talents</label>
                            </div>
                        </div>
                        { user && user.access_pdf_tt &&
                            <div className="ml-2">
                                <button onClick={() => getFullReportTT()} className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto">
                                    DOWNLOAD THINKING TALENT REPORT
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="quadrants-list position-relative mx-n5 full-report" ref={reportBox}>
                <Row noGutters id="lists-top" className="d-flex list-top align-content-start flex-wrap h-50 mb-2">
                    {
                        data.map(talent => {
                            if (talent.quadrant.name === QUADRANTS_ANALYTIC || talent.quadrant.name === QUADRANTS_INNOVATIVE) {
                                return <Col xs="6" className={`text-${talent.quadrant.name === QUADRANTS_ANALYTIC ? "left" : "right"}`} key={talent.quadrant.id}>
                                    <QuadrantItem
                                        talent={talent}
                                        StyledQuadrant={StyledQuadrant}
                                        isShadowTalents={isShadowTalents}
                                    />
                                </Col>
                            }
                        })
                    }
                </Row>

                {
                    data.map(talent => {
                        if (talent.quadrant.name === QUADRANTS_ALL) {
                            return <>
                                <div id="lists-all" className="position-relative" >
                                    <div style={{ marginTop: '-215px' }} className="graphic-list-all-talent text-center">
                                        <StyledQuadrant className="mx-auto d-inline-block bg-white pt-3" quadrant_color={talent.quadrant.color} key={talent.quadrant.id}>
                                            <p className="h3 mb-3">Talents In All Quadrants</p>
                                            <div className="d-flex flex-wrap justify-content-around report-inner-all">
                                                {
                                                    talent.quadrant.items.map(item => <ThinkingTalentsReportUserItem item={item} color={talent.quadrant.color} key={item.item.id} isShadowTalents={isShadowTalents} />)
                                                }
                                            </div>
                                        </StyledQuadrant>
                                    </div>
                                </div>
                            </>
                        }
                    })
                }

                <Row noGutters id="lists-bottom" className="d-flex list-bottom align-content-end flex-wrap h-50">
                    {
                        data.map((talent) => {
                            if (talent.quadrant.name === QUADRANTS_PROCEDURAL ||
                                talent.quadrant.name === QUADRANTS_RELATIONAL) {
                                return <Col xs="6" className={`text-${talent.quadrant.name === QUADRANTS_PROCEDURAL ? "left" : "right"}`} key={talent.quadrant.id} >
                                    <QuadrantItem
                                        talent={talent}
                                        StyledQuadrant={StyledQuadrant}
                                        isShadowTalents={isShadowTalents}
                                    />
                                </Col>
                            }
                        })
                    }
                </Row>

            </div>
            {
                pdf.isPending &&
                <LoadingIndicator />
            }
        </>
    )
}
