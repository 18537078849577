import { List, Record, Map } from 'immutable';

export const QuestionsList = new Record({
  id: null,
  isNew: true,
  isPending: false,
  answers: new Map(),
  range: new Map(),
  items: new List(),
  quadrants: new List()
});


export function createSuggest(data) {
  return new QuestionsList({
    asset_id : data.asset_id,
    items : new List(data.items) || new List(),
  });
}
