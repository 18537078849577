import { systemCheckActions } from "./actions";
import { SistemCheck } from "./systemCheck";


export function systemCheckReducer(state = new SistemCheck(), action) {
    const { type, payload } = action;

    switch (type) {
        case systemCheckActions.FETCH_SYSTEMCHECK_FAILED:
            return state.merge({
                isPending: false,
                error: payload,
            });
        case systemCheckActions.FETCH_SYSTEMCHECK_SUCCESS:
            return state.merge({
                id: payload.data.id,
                success: true,
                data: payload,
                isPending: false,
            });
        case systemCheckActions.FETCH_SYSTEMCHECK_PENDING:
            return state.set('isPending', true);

        default:
            return state;
    }
}