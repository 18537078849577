import { List, Record, Map } from 'immutable';

export const AssessmentsList = new Record({
  id: null,
  isNew: true,
  isPending: false,
  item_ids: new List(),
  assessment_id: null,
  error: null
});
