import { profileActions } from './actions';
import { Profile } from './profile';

export const profileReducer = (state = new Profile(), { payload, type }) => {
    switch (type) {
        case profileActions.FETCH_PROFILE_FAILED:
            payload.description = payload.response && payload.response.data && payload.response.data.message
            return state.merge({
                success: false,
                isPending: false,
                error: payload
            });
        case profileActions.FETCH_PROFILE_SUCCESS:
            return state.merge({
                success: true,
                isPending: false,
                data: payload.data
            });
        case profileActions.FETCH_PROFILE_PENDING:
            return state.set('isPending', true);
        default:
            return state;
    }
}