import { questionnaireResumeActions } from './actions';
import { QuestionnaireResume } from './questionnaire-resume';

export const questionnaireResumeReducer = (state = new QuestionnaireResume(), { payload, type }) => {
   
    switch (type) {
        case questionnaireResumeActions.FETCH_QUESTIONNAIRE_RESUME_FAILED:
            return state.merge({
                isPending: false,
                error: payload?.error?.data
            });
        case questionnaireResumeActions.FETCH_QUESTIONNAIRE_RESUME_SUCCESS:
            return state.merge({
                data: payload.data,
                isPending: false
            });
        case questionnaireResumeActions.FETCH_QUESTIONNAIRE_RESUME_PENDING:
            return state.set('isPending', true);

        case questionnaireResumeActions.CLEAN_QUESTIONNAIRE_RESUME:
            return new QuestionnaireResume();
        default:
            return state;
    }
}
