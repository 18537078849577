import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Form, Label, Input } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { teamActions, getCurrentTeam, getTeamById } from '../../../core/teams'
import { DEFAULT_SESSION_ID } from '../../../core/constants'
import { teamsMembersListActions } from '../../../core/teams-members-list'
import { teamsReportListActions, getTeamsReportById } from '../../../core/teams-report'
import LoadingIndicator from '../../components/loading-indicator'

import ReactGA from 'react-ga'
import { trackingActions } from '../../../core/tracking';

class EditPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      animationClass: {
        appearActive: 'animated slideInRight position-fixed',
        enter: 'animated',
        enterActive: 'animated slideInRight position-fixed',
        exit: 'animated',
        exitActive: 'animated slideOutLeft position-fixed',
      },
      // team: this.props.history.location.pathname !== '/teams/create' ? props.team.toJS() : {},
      team: this.props.team ? props.team.toJS() : {},
      current_action: null,
      validation: {
        message: null,
        valid: true
      }
    }
    // && this.props.history.location.pathname !== '/teams/create' ?
    this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBack = this.handleBack.bind(this);


  }

  componentWillMount() {
    const { team_id, team, loadTeam, loadTeamsReport } = this.props;
    loadTeam(team_id);
    if (team_id !== DEFAULT_SESSION_ID && !team.name) {
      loadTeamsReport(team_id);
    }
    if (this.props.history.location.pathname === '/teams/create') {
      this.setState({
        team: this.props.team_report.toJS(),
      });
    }
  }

  componentWillUpdate(nextProps, nextState) {
    //console.log(nextProps, nextState)
  }

  componentDidUpdate(oldProps, oldState) {
    const { team, team_report } = this.props;
    const { current_action } = this.state;
    if (oldProps.team_report && oldProps.team_report.isNew && oldProps.team_report.isPending && !team_report.isPending) {
      this.setState({
        team: team_report.toJS(),
      });
    }

    if (oldProps.team.update_at != team.update_at) {

      if (team.error) {
        let validation = {
          message: team.error.message,
          valid: false
        }
        if (team.error.code === 'TEA0004') {
          validation = {
            message: 'There is already a team with that name.',
            valid: false
          }
        }

        this.setState({
          validation
        });
      }
      else {
        if (current_action === 'create') {
          this.props.fetchTeamDefaultPendingFalse()
          this.props.loadTeamMembers(DEFAULT_SESSION_ID)
          // this.props.history.push(`/teams/${team.id}/add-member`);
          this.setState({
            team: team_report.toJS(),
          });
          this.props.history.push(`/teams/${team.id}`);
        }
        else if (current_action) {
          this.props.history.push(`/teams/${team.id}`);
        }
      }
    }
  }

  handleInputChange({ target }) {

    const value = target.type === 'checkbox' ? target.checked : target.value.trim();
    const name = target.name;
    const { team } = this.state;
    const _team = Object.assign({}, team, {
      [name]: value
    })

    this.setState({
      team: _team,
      validation: {
        message: null,
        valid: true
      }
    });
  }

  handleClick({ action = 'save' }) {
    //createTeam
    const { createTeam, updateTeam, loadTeamMembers, history } = this.props
    const { team } = this.state

    if (!team.name || !team.name.length) {
      this.setState({
        validation: {
          message: 'Please enter a team name.',
          valid: false
        }
      });
      return;
    }
    if (action === 'save') {
      if (team.id === DEFAULT_SESSION_ID) {
        createTeam(team);
        this.setState({
          current_action: 'create'
        });
        //loadTeamMembers(DEFAULT_SESSION_ID)
        //this.props.history.push('/teams/add-member');
        ReactGA.event({
            category: 'Teams',
            action: 'Creation completed'
        })
        this.props.saveTrackingEvent({category: 'Teams',action: 'Creation completed'})
      }
      else {
        updateTeam(team);
        this.setState({
          current_action: 'update'
        });

        //this.props.history.push('/teams/'+team.id);
      }
    }
    else {
      history.goBack()
    }

  }

  handleBack(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  render() {

    const { animationClass, team, validation, current_action } = this.state;

    const { displayLoadingIndicator, team_report, user } = this.props;

    if (user.checked && !user.id)
      return null;

    const btn_label = (team.id === DEFAULT_SESSION_ID) ? 'Next' : 'Confirm';
    const btn_cancel = (team.id !== DEFAULT_SESSION_ID) &&
      <button
        onClick={(e) => { e.preventDefault(); this.handleClick({ action: 'cancel' }) }}
        className="btn btn-outline-primary text-uppercase btn-block rounded-pill"
      >
        Cancel
      </button>;

    const msg_error = (!validation.valid) &&
      <p className="line-height-normal mb-0">
        <small className='text-danger'>
          {validation.message}
        </small>
      </p>;

    // console.log(team);
    // console.log(this.props.team.toJS())
    // console.log(team_report.toJS())
    return (
      <div className="view overflow-hidden">
        <TransitionGroup className="content">
          <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='teams' appear >
            <div className="w-100">
              <Container>
                <div className="bg-white my-md-4 rounded-lg pb-4 pb-md-5 px-md-5 pt-md-2 content-rearrenge content-view">
                  <Row className="px-md-4">
                    <Col md={{ size: 12 }}>
                      <a href="#" className="icon-quest"></a>
                      <a href="#" onClick={e => this.handleBack(e)} className="icon-back">
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </a>
                      <h1 className="mx-4 mx-md-0 mb-md-4 text-center text-md-left">
                        {(team.id === DEFAULT_SESSION_ID) ? 'Add Team' : 'Rename Team'}
                      </h1>
                      <Row>
                        <Col xs={{ size: 10, offset: 1 }} md={{ size: 6, offset: 3 }}>
                          <div className="bg-white my-md-4 rounded-lg pb-4 p-md-5 content-start content-view">
                            <form>
                              <div className="form-group">
                                <label htmlFor="inputEmail"><small>Team Name</small></label>
                                <Input type="text" name="name" maxLength="30" onChange={this.handleInputChange} defaultValue={team.name} className="form-control" placeholder="Enter Team Name" bsSize="lg" />
                                {msg_error}
                              </div>
                              <button onClick={(e) => { e.preventDefault(); this.handleClick({ action: 'save' }) }} className="btn btn-primary text-uppercase btn-block rounded-pill">{btn_label}</button>
                              {btn_cancel}

                            </form>
                          </div>
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                </div>
              </Container>
              {
                displayLoadingIndicator &&
                <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />
              }
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}
//export default ThinkingTalentsPage;
//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {
  //console.log('mapStateToProps', state, props, props.match.params.id)
  // const { session } = state

  const team_id = props.match.params.id ? props.match.params.id : DEFAULT_SESSION_ID;
  const team = getCurrentTeam(state, props);
  const team_report = getTeamsReportById(state, team_id);
  return {
    user: state.user,
    team_id,
    team,
    team_report,
    displayLoadingIndicator: (team ? team.isPending : false) || (team_report ? team_report.isPending : false) || false,
  };
};

const mapDispatchToProps = {
  createTeam: teamActions.createTeam,
  updateTeam: teamActions.updateTeam,
  loadTeam: teamActions.loadTeam,
  loadTeamMembers: teamsMembersListActions.loadTeamMembers,
  fetchTeamDefaultPendingFalse: teamActions.fetchTeamDefaultPendingFalse,
  loadTeamsReport: teamsReportListActions.loadTeamsReport,
  resetTeam: teamActions.resetTeam,
  saveTrackingEvent: trackingActions.saveTrackingEvent
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPage);
