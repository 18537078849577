import React, { useEffect, useState, Fragment } from 'react';
import { Button, Col, Form, FormGroup, FormText, Input, Label, Row, FormFeedback, CustomInput, Modal, ModalBody, ModalHeader } from 'reactstrap';

import { generateArrayOfYears } from '../../../core/helper';
import { ConfirmCurrentPasswordModal } from './ConfirmCurrentPasswordModal';
import { useSelector } from 'react-redux';

export const EditForm = ({
    inputValues,
    handleInputChange,
    onSubmitUpdateInfo,
    formErrors,
    formValid,
    strength,
    lists = []
}) => {
    const [openConfirmCurrentPasswordModal, setOpenConfirmCurrentPasswordModal] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const toggleConfirmCurrentPasswordModal = () => {
        setOpenConfirmCurrentPasswordModal(!openConfirmCurrentPasswordModal);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (inputValues.email) {
            toggleConfirmCurrentPasswordModal();
            return;
        }

        onSubmitUpdateInfo();
    }

    const onConfirmCurrentPassword = () => {
        onSubmitUpdateInfo();
    }

    useEffect(() => {
        setDisabled(!formValid());
    }, [inputValues, formErrors])

    return (
        <>
            <Form onSubmit={onSubmit} className="form-login p-3 p-md-0">
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="first_name">First Name</Label>
                            <Input
                                type="text"
                                name="firstName"
                                id="first_name"
                                value={inputValues.firstName}
                                onChange={handleInputChange}
                                valid={formErrors.firstName === ''}
                                className="rounded-pill"

                            />
                            <FormText>{formErrors.firstName}</FormText>
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="last_name">Last Name</Label>
                            <Input
                                type="text"
                                name="lastName"
                                id="last_name"
                                value={inputValues.lastName}
                                onChange={handleInputChange}
                                valid={formErrors.lastName === ''}
                                // defaultValue={user.last_name}
                                className="rounded-pill"
                            />
                            <FormText>{formErrors.lastName}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label className='control-label' for="email">Personal Email</Label>
                            <Input
                                type="email"
                                name="email"
                                id="email"
                                value={inputValues.email}
                                onChange={handleInputChange}
                                className="rounded-lg"
                                valid={(formErrors.email !== '' || inputValues.email === '') ? null : true}
                            />
                            <FormText>{formErrors.email}</FormText>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label className='control-label' for="confirm-email">Confirm Personal Email</Label>
                            <Input
                                type="email"
                                name="confirmEmail"
                                id="confirm-email"
                                value={inputValues.confirmEmail}
                                onChange={handleInputChange}
                                valid={(formErrors.confirmEmail !== '' || inputValues.confirmEmail === '') ? null : true}
                                className="rounded-lg"
                            />
                            <FormText>{formErrors.confirmEmail}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                value={inputValues.password}
                                onChange={handleInputChange}
                                className="rounded-pill"
                            />
                            <FormText>{formErrors.password}</FormText>
                            {inputValues.password && !formErrors.password &&
                                <FormText>{strength}</FormText>
                            }
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <Label for="confirm-pass">Confirm Password</Label>
                            <Input
                                type="password"
                                name="confirmPassword"
                                id="confirm-pass"
                                value={inputValues.confirmPassword}
                                onChange={handleInputChange}
                                className="rounded-pill"
                            />
                            <FormText>{formErrors.confirmPassword}</FormText>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {lists?.seniorities?.size > 0 &&
                            <FormGroup>
                                <Label for="select">Seniority</Label>
                                <Input
                                    type="select"
                                    className="form-control rounded-lg"
                                    onChange={handleInputChange}
                                    name="seniority"
                                    invalid={formErrors.seniority === '' ? null : true}
                                    value={inputValues.seniority}
                                >
                                    <option value="">Select your option</option>
                                    {
                                        lists?.seniorities &&
                                        lists?.seniorities.map(item =>
                                            <option
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </option>
                                        )
                                    }
                                </Input>
                                <FormFeedback>{formErrors.currentCareerLevel}</FormFeedback>
                            </FormGroup>
                        }
                    </Col>
                </Row>

                <div className="justify-content-center">
                    <center><Button color="primary" type="submit" disabled={disabled} className="text-uppercase rounded-pill px-md-5">CONFIRM</Button></center>
                </div>

                <ConfirmCurrentPasswordModal isOpen={openConfirmCurrentPasswordModal} toggle={toggleConfirmCurrentPasswordModal} setCurrentPassword={(value) => handleInputChange({ target: { name: 'currentPassword', value } })} error={inputValues.currentPassword ? formErrors.currentPassword : null} onAccept={onConfirmCurrentPassword} />
            </Form>
        </>
    )
}
