import React, { Component, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Media, Modal, Collapse, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper, faCompactDisc, faAssistiveListeningSystems } from '@fortawesome/free-solid-svg-icons';

import { List } from 'immutable';

import logo from '../../../assets/img/true.logo.circle.png'
import icon_mp from '../../../assets/img/mp-sm-icon.svg';

import MindPatternsMemberItem from './mind-patterns-member-item'
import WorkingWhithVaks from './working-whith-vaks'

import writing_reading from '../../../assets/img/writing-reading.png'
import talking_hearing from '../../../assets/img/talking-hearing.png'
import hand_on from '../../../assets/img/hand-on.png'
import { MindPatternsFullReport } from './mind-patterns-full-report';


class MindPatterns extends Component {

  constructor(props) {
    super(props)

    this.state = {
      animationClassTabs: {
        enter: 'animated',
        enterActive: 'animated fadeIn',
        exit: 'animated',
        exitActive: 'animated fadeOut',
      },
      isOpen: false,
      paddingTop: 0,
      paddingBottom: 0,
      show_modal: false,
      order_members: '',
      current_member: null

    }

    this.toggle = this.toggle.bind(this);
    this.handleOrder = this.handleOrder.bind(this);

    this.handleModal = this.handleModal.bind(this);
    this.handleClick = this.handleClick.bind(this);


  }

  handleModal = (e, member) => {
    const { show_modal } = this.state

    this.setState({ show_modal: !show_modal, current_member: member })
  }

  handleOrder = (order) => {
    const { order_members } = this.state
    if (order_members !== order)
      this.setState({ order_members: order })
  }

  toggle = (tab) => {
    const { activeTab, isOpen } = this.state
    if (activeTab !== tab)
      this.setState({ activeTab: tab })
  }


  toggle = () => {
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen })
  }


  handleClick() {
    const { team } = this.props
    this.props.history.push({
      pathname: '/teams-working-whith-vaks',
      state: { activeTab: 'mind-patterns', team_id: team.id }
    })
    //this.props.history.push('/teams-working-whith-vaks');
  }

  render() {
    const { animationClassTabs, activeTab, paddingBottom, paddingTop, show_modal, order_members, current_member, isOpen } = this.state
    const { hanndleNextSection, members, quadrants, isMember, isLeader, team, enable_edit, team_report } = this.props;

    if (!team_report) {
      return null;
    }
    const items = members.sort((a, b) => {
      const a_order = a.member.mind_pattern && a.member.mind_pattern.replace(order_members, "1").split('')[0]
      const b_order = b.member.mind_pattern && b.member.mind_pattern.replace(order_members, "1").split('')[0]
      return a_order && a_order.localeCompare(b_order, 'en', { sensitivity: 'base' });
    })

    let content_modal = current_member ? <WorkingWhithVaks member={current_member} /> : null

    const members_list = items.valueSeq().map(team_member =>
      <MindPatternsMemberItem
        key={`mp-${team_member.team_id}-${team_member.user_id}`}
        member={team_member.member}
        handleClick={this.handleModal}
      />
    )


    const mind_patterns = team_report.mind_pattern.split("").map((mp, k) => {

      let title = null;
      let icon = mp

      console.log(mp);
      if (mp === 'V') {
        title = <><b>WRITING<br />READING</b><br />(VISUAL)</>
        icon = writing_reading
      }

      if (mp === 'A') {
        title = <><b>TALKING<br />HEARING</b><br />(AUDITORY)</>
        icon = talking_hearing
      }

      if (mp === 'K') {
        title = <><b>HANDS-ON</b><br />(KINESTHETIC)</>
        icon = hand_on
      }

      return <div key={mp + k} className={`text-center cursor-pointer item-mind-patterns item-mind-patterns-${mp.toLowerCase()}`} onClick={() => this.handleOrder(mp)}>
        <p className={`line-height-normal mb-2 small color-mind-pattern-${mp.toLowerCase()}`}>{title}</p>
        <img src={icon} className="mb-2" />
        <h5 className="text-reset pl-2">{team_report[`mind_pattern_p${(k + 1)}`]}%</h5>
      </div>

    })

    return (
      <>
        <div className="d-lg-flex justify-content-lg-between align-items-lg-center pb-lg-3">
          <p className="text-center text-lg-left text-reset h5 mb-lg-0 pl-lg-3">Maximazing team communication</p>
          <button className="d-none d-lg-block toggler-quadrants btn text-uppercase btn-block-inline rounded-pill btn-outline-primary px-lg-4" onClick={() => this.toggle()}>
            MAP VIEW
          </button>
        </div>
        <Collapse isOpen={isOpen}>
          <MindPatternsFullReport />
        </Collapse>
        <div className="bg-celeste py-2 px-3 rounded mb-4">
          <p className="mb-0">Tap on area to order members</p>
        </div>

        <div className="mb-4 px-3 py-2 d-flex align-items-center border rounded-lg d-md-none">
          <i className="mr-3"><img src={icon_mp} width="24"/></i>
          <p className="mb-0">Note that percentages only reflect team members who have completed each assessment</p>
        </div>

        <Row noGutters className="mind-patterns-tab align-items-center">
          <Col md={{ size: 4, offset: 2 }}>
            <div className="d-flex align-items-center justify-content-between">
              {team_report.mind_pattern ? mind_patterns
                :
                <>
                  <div className={`text-center cursor-pointer item-mind-patterns item-mind-patterns-a`}>
                    <p className={`line-height-normal mb-2 small color-mind-pattern-a`}>
                      <><b>TALKING<br />HEARING</b><br />(AUDITORY)</>
                    </p>
                    <img src={talking_hearing} className="mb-2" />
                    <h5 className="text-reset pl-2">0%</h5>
                  </div>

                  <div className={`text-center cursor-pointer item-mind-patterns item-mind-patterns-k`}>
                    <p className={`line-height-normal mb-2 small color-mind-pattern-k`}>
                      <><b>HANDS-ON</b><br />(KINESTHETIC)</>
                    </p>
                    <img src={hand_on} className="mb-2" />
                    <h5 className="text-reset pl-2">0%</h5>
                  </div>

                  <div className={`text-center cursor-pointer item-mind-patterns item-mind-patterns-v`}>
                    <p className={`line-height-normal mb-2 small color-mind-pattern-v`}>
                      <><b>WRITING<br />READING</b><br />(VISUAL)</>
                    </p>
                    <img src={writing_reading} className="mb-2" />
                    <h5 className="text-reset pl-2">0%</h5>
                  </div>
                </>
              }
            </div>
          </Col>
          <Col lg={{ size: 5 , offset: 1}} className="d-none d-lg-block">
            <div className="mt-3 px-3 py-2 d-flex align-items-center border rounded-lg">
              <i className="mr-3"><img src={icon_mp} width="24"/></i>
              <p className="mb-0">Note that percentages only reflect team members who have completed each assessment</p>
            </div>
          </Col>
        </Row>
        <hr />

        <div className="bg-celeste py-2 px-3 rounded mb-4">
          <p className="mb-0">Tap on each member to view how to work with their unique mind pattern</p>
        </div>
        <div className="members-list columns-md-2">
          {members_list}
        </div>

        <Modal isOpen={show_modal} toggle={this.handleModal} className="modal-dialog-summary float-right">
          <ModalHeader toggle={this.handleModal}><span className="d-flex justify-content-center bd-highlight"><img src={logo} height="48" alt="True Advance" /></span></ModalHeader>
          <ModalBody>
            {content_modal}
          </ModalBody>
        </Modal>

      </>
    )
  }
}

export default MindPatterns;
