import { List, Record } from 'immutable';

export const TeamMembersList = new Record({
  id: null,
  isNew: true,
  isPending: false,
  items: new List(),
  itemsId: new List(),
  error:null,
  created_at: new Date(),
  update_at: new Date(),
});
