import React, { Component, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col, Media, UncontrolledTooltip } from 'reactstrap';
import { NavLink as Link} from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import classNames from 'classnames';
import { List } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { QUADRANTS_ANALYTIC, QUADRANTS_INNOVATIVE, QUADRANTS_RELATIONAL, QUADRANTS_PROCEDURAL, QUADRANTS_ALL } from '../../../core/constants'

import graphic_report from '../../../assets/img/graphic-report.jpg'
import graphic_report_mask from '../../../assets/img/graphic-report-mask.png'
import { Checkbox } from '../common'

class MemberItem extends Component{

  render() {
    const { hanndleNextSection, member, isMember, handleCheckboxChange, members_selected, isLeader } = this.props

    const chebox_conent = isMember ? <div className="text-center flex-fill">
        <div className={ (isLeader ? 'active ':'') + 'icon-star' }>
          <span id={"Tooltip-" + member.id}><FontAwesomeIcon icon={faStar}/></span>
          { isLeader ? <UncontrolledTooltip placement="bottom" target={"Tooltip-" + member.id} trigger="click">TEAM LEADER</UncontrolledTooltip> : null }
        </div>
      </div> : '';


    return (
      <div className="member-item col">
        <div className="d-flex align-items-center text-reset text-decoration-none border-top py-3 p-2 cursor-pointer"  >
          {!isMember ? <div className="text-center pr-3"><Checkbox handleCheckboxChange={handleCheckboxChange} select_option={members_selected} label="" value={member.id} /></div> : ''}
          <div className="d-flex align-items-center text-reset text-decoration-none flex-fill" onClick={ ()=> hanndleNextSection({ section:'detail-member', member_id: member.id, isMember })  }>
          <div className="pr-3">
            <img className="media-object" width="40" height="40" src={member.avatar} alt={ member.first_name } />
          </div>
          <div className="pr-3 w-100">
            <p className="h5 text-reset mb-1 text-truncate text-nowrap">{ (member.first_name) ? `${member.first_name} ${member.last_name}` : member.username  }</p>
           <p className="text-reset line-height-normal mb-0">{ member.seniority ? member.seniority.name : '' }</p>
          </div>
          </div>
          {isMember ? <div className="text-center px-3">
            <div className={ (isLeader ? 'active ':'') + 'icon-star' }>
              <span id={"Tooltip-" + member.id}><FontAwesomeIcon icon={faStar}/></span>
              { isLeader ? <UncontrolledTooltip placement="bottom" target={"Tooltip-" + member.id} trigger="click">TEAM LEADER</UncontrolledTooltip> : null }
            </div>
          </div> : ''}
        </div>
      </div>
    )
  }
}

export default MemberItem;
