import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { mind_pattern_sections } from '../../../core/constants'
import writing_reading from '../../../assets/img/writing-reading-black.png';
import talking_hearing from '../../../assets/img/talking-hearing-black.png';
import hand_on from '../../../assets/img/hand-on-black.png';
import { Disclaimers } from '../common';
import { pdfsActions } from '../../../core/pdfs';
import LoadingIndicator from '../loading-indicator';

const Report = (props) => {
    console.log(props)

    const { hanndleNext, reports, selected_options, mind_pattern, setRetake, viewMode } = props;
    const dispatch = useDispatch();
    const { pdf } = useSelector(state => state);
    const { user } = useSelector(state => state);

    const eventGA = () => {
        setRetake(true)
    }

    const code = mind_pattern ? mind_pattern : selected_options.toJS().join("");
    const report = reports.find(item => item.code === code);

    if (!report) {
        return (<><h3> report not found </h3></>)
    }

    const list_report = reports.map(item => {
        if (item.code === code)
            return null;

        const mind_patterns = item.code.split("").map((mp, k) => <div key={item.id + '_' + mp}
            className={`d-inline-block rounded-circle mx-2 mind-pattern mind-pattern-sm mind-pattern-${mp.toLowerCase()}`}><span>{mp}</span></div>)

        return <div key={item.id} className="d-flex justify-content-between border-top py-2 pr-2 cursor-pointer view-report" onClick={() => hanndleNext(mind_pattern_sections.REPORT_COMPLETE, item.id)}>
            <div>{mind_patterns}</div>
            <div className="btn btn-link align-items-center p-0 d-flex text-reset"><i className="icon-view-report mr-2"></i>VIEW REPORT</div>
        </div>
    })

    const mind_patterns = report.code.split("").map((mp, k) => {

        let title = null;
        let icon = mp
        let header = '';
        let color = '';
        if (mp === 'V') {
            title = 'VISUAL'
            icon = writing_reading
            color = '#7D9E94';
        }

        if (mp === 'A') {
            title = 'AUDITORY'
            icon = talking_hearing
            color = '#DBCE88';

        }

        if (mp === 'K') {
            title = 'KINESTHETIC'
            icon = hand_on
            color = '#8BDBF2'
        }

        let desc = 'TRIGGERS CONCENTRATION'
        if (k === 1) {
            desc = 'TRIGGERS SORTING'
        }
        else if (k === 2) {
            desc = 'TRIGGERS IMAGINATION'
        }

        return <div key={mp + k} className={`px-md-4 text-center item-mind-patterns item-mind-patterns-${mp.toLowerCase()}`}>
            <div className={`mx-auto mb-2 rounded-circle mind-pattern mind-pattern-lg mind-pattern-${mp.toLowerCase()}`} style={{ backgroundColor: color }}>{mp}</div>
            <img src={icon} className="mb-2" alt="" />
            <p className="mb-0">{title}</p>
            <p className="line-height-normal"><small>{desc}</small></p>
        </div>
    })


    const getFullReportMP = () => {
        dispatch(pdfsActions.loadFullReportMP())
    }

    return (
        <Fragment>
            <Row className="content-result mind-pattern-report">
                <Col lg={{ size: 8, offset: 2 }}>
                    <div className={`bg-white my-md-4 rounded-lg pb-4 px-md-5 py-3 d-flex flex-column ${ viewMode ? '' : 'content-view'}`}>
                        <div class="encabezado d-none d-md-block mb-4">
                            <div class="d-flex align-items-strech text-encabezado">
                                <h6 class="title-underline mb-1 text-nowrap mr-4">Mind Patterns</h6>
                                <p className="mb-0">Your Mind Pattern is the unique way that you process and respond to information. Mind Patterns can also help you intentionally shift from focused to generative thinking  and help you design a working environment and develop new habits that evoke your best thinking. When working with others, understanding our Mind Pattern can help us dissolve some of the immediate ways we misread and therefore misjudge or miscommunicate with others</p>
                            </div>
                            <span class="border border-info d-block"></span>
                        </div>

                        <Row className="position-relative">
                            <Col lg={{ size: 10, offset: 1 }}>
                                <h1 className="text-center d-md-none">Mind Patterns</h1>
                                <h5 className="text-reset text-center mb-3">Your mind pattern</h5>
                                <div className="d-flex justify-content-center">{mind_patterns}</div>
                            </Col>
                        </Row>

                        {!viewMode &&
                            <div className="d-flex justify-content-center align-content-center">
                                <div>
                                    <button onClick={() => hanndleNext(mind_pattern_sections.REPORT_COMPLETE, report.id)} className="btn btn-outline-primary text-uppercase rounded-pill d-flex px-md-4 mx-auto mt-3"><i className="icon-view-report mr-2"></i>View Report</button>
                                </div>
                                {user && user.access_pdf_mp &&
                                    <div className="ml-2">
                                        <button onClick={() => getFullReportMP()} className="btn btn-outline-primary text-uppercase rounded-pill d-inline-flex px-md-4 mx-auto mt-3">
                                            DOWNLOAD MIND PATTERN REPORT
                                        </button>
                                    </div>
                                }
                            </div>
                        }

                        <hr className="w-100" />

                        {!viewMode &&
                            <Row className="mb-3 position-relative">
                                <Col lg={{ size: 10, offset: 1 }}>
                                    <h5 className="text-reset text-center mb-4">The other mind patterns</h5>
                                    <p className="text-uppercase h6">SET MIND PATTERN MANUALLY</p>
                                    <p>If you think that this summary does not accurately represent you, you have the option to set your Mind Pattern manually</p>
                                    <p className="h6 pb-3">You will find the button to change your Mind Pattern at the bottom of each one of these reports:</p>
                                    <div className="default-gap mb-4">{list_report}</div>
                                </Col>
                            </Row>
                        }
                        
                        {!viewMode &&
                            <div>
                                <button onClick={() => { hanndleNext(mind_pattern_sections.START); eventGA() }} className="btn btn-outline-primary text-uppercase rounded-pill d-flex px-md-4 mx-auto"><i className="icon-retake mr-2"></i>Retake assessment</button>
                            </div>
                        }
                        <Disclaimers view="copyright" />
                    </div>
                </Col>
            </Row>
            {
                pdf.isPending &&
                <LoadingIndicator />
            }
        </Fragment>
    )

}

export default Report;
