export const mindPatternsStoreMPActions = {

    FETCH_MINDPATTERNS_STOREMP_FAILED: 'FETCH_MINDPATTERNS_STOREMP_FAILED',
    FETCH_MINDPATTERNS_STOREMP_SUCCESS: 'FETCH_MINDPATTERNS_STOREMP_SUCCESS',
    FETCH_MINDPATTERNS_STOREMP_PENDING: 'FETCH_MINDPATTERNS_STOREMP_PENDING',
    SAVE_MINDPATTERNS_STORE_MP: 'SAVE_MINDPATTERNS_STORE_MP',
  
  
    fetchMindPatternsMPFailed: error => ({
      type: mindPatternsStoreMPActions.FETCH_MINDPATTERNS_STOREMP_FAILED,
      payload: {
        error
      }
    }),
  
    fetchMindPatternsMPSuccess: (mindPatternsDataId, data) => ({
      type: mindPatternsStoreMPActions.FETCH_MINDPATTERNS_STOREMP_SUCCESS,
      payload: {
        data,
        mindPatternsDataId
      }
    }),
  
    fetchMindPatternsMPPending: mindPatternsDataId => ({
      type: mindPatternsStoreMPActions.FETCH_MINDPATTERNS_STOREMP_PENDING,
      payload: {
        mindPatternsDataId
      }
    }),
  
  
    mindPatternsSaveStoreMp: data => ({
      type: mindPatternsStoreMPActions.SAVE_MINDPATTERNS_STORE_MP,
      payload: {
        data
      }
    }),
  
  };
  
  export const mindPatternsStoreMPRequestActions = {
    failed: mindPatternsStoreMPActions.fetchMindPatternsMPFailed,
    success: mindPatternsStoreMPActions.fetchMindPatternsMPSuccess,
    pending: mindPatternsStoreMPActions.fetchMindPatternsMPPending
  };
  