import { createSelector } from 'reselect';


export function getUser(state) {
  return state.user;
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentUser = createSelector(
  getUser,
  user => user.get(user.get('email'))
);
