export const questionsListActions = {

  FETCH_QUESTIONS_FAILED: 'FETCH_QUESTIONS_FAILED',
  FETCH_QUESTIONS_SUCCESS: 'FETCH_QUESTIONS_SUCCESS',
  FETCH_QUESTIONS_PENDING: 'FETCH_QUESTIONS_PENDING',
  LOAD_QUESTIONS: 'LOAD_QUESTIONS',


  fetchQuestionsFailed: error => ({
    type: questionsListActions.FETCH_QUESTIONS_FAILED,
    payload: error
  }),

  fetchQuestionsSuccess: (questionsListId, data) => ({
    type: questionsListActions.FETCH_QUESTIONS_SUCCESS,
    payload: {
      data,
      questionsListId
    }
  }),

  fetchQuestionsPending: questionsListId => ({
    type: questionsListActions.FETCH_QUESTIONS_PENDING,
    payload: {
      questionsListId
    }
  }),

  loadQuestions: () => ({
    type: questionsListActions.LOAD_QUESTIONS,
    payload: {
      questionsListId: 'QUESTIONS_LIST'
    }
  }),
};


export const questionsListRequestActions = {
  failed: questionsListActions.fetchQuestionsFailed,
  success: questionsListActions.fetchQuestionsSuccess,
  pending: questionsListActions.fetchQuestionsPending
};
