import React from 'react';

export const AccountNotFound = () => {
    return (
        <div>
            <center style={{ marginTop: '150px' }}>
                <h1>
                    Account Not Found (404)
                </h1>
            </center>
        </div>
    )
}
