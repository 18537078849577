import React, { useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { fullReportMpListActions } from '../../../core/full-report-mp/actions';
import writing_reading from '../../../assets/img/writing-reading.png';
import talking_hearing from '../../../assets/img/talking-hearing.png';
import hand_on from '../../../assets/img/hand-on.png';

export const MindPatternsFullReport = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { full_report_mp } = useSelector(state => state);
    const { name: teamName, items } = full_report_mp.items;


    useEffect(() => {
        dispatch(fullReportMpListActions.loadFullReportMp(id))
    }, [id])

    const cardList = Object.keys(items);

    const listUsersMp = (pattern) => {
        return items[pattern]
    }

    const staticTexts = (position) => {
        switch (position) {
            case 0:
                return "FOCUS"

            case 1:
                return "SORT"
            case 2:
                return "OPEN"
            default:
                return "FOCUS"
        }
    }

    return (
        <Row className="mind-patterns-report-team">
            {
                cardList.map((card, i) =>
                    <Col md="6" key={card + i} className="mb-4">
                        <div className="border rounded">
                            <Row>
                                <Col md="7">
                                    <div className="d-flex text-center text-align-center justify-content-around px-2 py-3">
                                        {
                                            card.split("").map((mp, k) => {

                                                if (mp === "K") {
                                                    return <div key={mp + k} className={`text-center item-mind-patterns item-mind-patterns-${mp.toLowerCase()}`}>
                                                        <p key={mp + k} className={`mb-2 color-mind-pattern-${mp.toLowerCase()}`}><small><b>KINESTHETIC</b><br />{staticTexts(k)}</small></p>
                                                        <img src={hand_on} alt="kinesthetic" className="mb-2" style={{ width: 60, height: 60 }} />
                                                        <h5 className="text-reset mb-0">{mp}</h5>
                                                    </div>
                                                }

                                                if (mp === "V") {
                                                    return <div key={mp + k} className={`text-center item-mind-patterns item-mind-patterns-${mp.toLowerCase()}`}>
                                                        <p key={mp + k} className={`mb-2 color-mind-pattern-${mp.toLowerCase()}`}><small><b>VISUAL</b><br />{staticTexts(k)}</small></p>
                                                        <img src={writing_reading} className="mb-2" style={{ width: 60, height: 60 }} alt="writin_reading" />
                                                        <h5 className="text-reset mb-0">{mp}</h5>
                                                    </div>
                                                }

                                                if (mp === "A") {
                                                    return <div key={mp + k} className={`text-center item-mind-patterns item-mind-patterns-${mp.toLowerCase()}`}>
                                                        <p key={mp + k} className={`mb-2 color-mind-pattern-${mp.toLowerCase()}`}><small><b>AUDITORY</b><br />{staticTexts(k)}</small></p>
                                                        <img src={talking_hearing} className="mb-2" style={{ width: 60, height: 60 }} alt="talking_hearing" />
                                                        <h5 className="text-reset mb-0">{mp}</h5>
                                                    </div>
                                                }
                                            })
                                        }
                                    </div>
                                </Col>

                                <Col md="5" className="border-left my-3">
                                    <div className="px-2">
                                        <p className="text-uppercase small line-height-normal">Team members with this mind pattern:</p>
                                        {
                                            listUsersMp(card).map(user => {
                                                return <p key={user.id} className="small line-height-normal font-weight-bold mb-1">{user.first_name} {user.last_name}</p>
                                            })
                                        }
                                    </div>

                                </Col>
                            </Row>
                        </div>
                    </Col>
                )
            }

        </Row>
    )
}
