import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Container, Col, Row, Alert } from 'reactstrap';
import { contactActions } from '../../../core/contact/actions';
import { ContactForm } from '../../components/contact/ContactForm';
import { connect } from 'react-redux';
import LoadingIndicator from '../../components/loading-indicator/loading-indicator';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

const ContactUs = ({ contact, sendContact, resetContact, user, systemCheck }) => {
    const history = useHistory();
    const [formErrors, setformErrors] = useState({
        name: '',
        phone: '',
        email: '',
        comments: ''
    });
    const [inputValues, setInputValues] = useState({
        name: '',
        phone: '',
        email: '',
        comments: ''
    })

    useEffect(() => {
        return () => {
            resetContact();
        }
    }, [])

    const handleInputChange = async ({ target }) => {
        const { name, value } = target;

        switch (name) {
            case "name":
                formErrors.name =
                    value.length < 3 ? "Minimum 3 characters required" : "";
                break;
            case "phone":
                formErrors.phone =
                    value.length < 3 ? "Minimum 3 characters required" : "";
                break;
            case "email":
                formErrors.email = emailRegex.test(value)
                    ? ""
                    : "Invalid email address";
                break;
            case "comments":
                formErrors.comments =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;
            default:
                break;
        }

        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    const onSubmitRegister = async (e) => {
        e.preventDefault();
        if (formValid()) {
            sendContact(inputValues)
            console.log(inputValues)
        }
    }

    const formValid = () => {
        let valid = true;
        let keysNotRequired = ['phone']

        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        // validate the form was filled out
        Object.keys(inputValues).forEach((key) => {
            if (!keysNotRequired.includes(key)) {
                inputValues[key] == '' && (valid = false);
            }
        })

        return valid;
    };

    return (
        <div className='view overflow-hidden bg-white'>
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='confirm' appear >
                    <div className="register-confirm-page">
                        <Container>
                            <div className="px-2 px-md-0 my-md-4">
                            {!systemCheck.isPending && !user.isPending &&<div className="content-view">
                                    <Row className="mb-5">
                                        <Col sm={{ size: 1 }}>
                                            <span
                                                onClick={() => history.goBack()}
                                                style={{
                                                    color: '#66b2bd',
                                                    cursor:"pointer"
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft} />
                                            </span>
                                        </Col>
                                        <Col sm={{ size: 6, offset: 4 }}><h2 className="pl-3 pl-md-0">Contact us</h2></Col>
                                    </Row>

                                    <div>
                                    {
                                        !contact.success ?
                                            <ContactForm
                                                formErrors={formErrors}
                                                handleInputChange={handleInputChange}
                                                inputValues={inputValues}
                                                onSubmitRegister={onSubmitRegister}
                                                formValid={formValid}
                                            />
                                            :
                                            <Alert color="info">
                                                <center>
                                                    Your message has been sent.
                                                </center>
                                            </Alert>
                                    }
                                    </div>
                                </div>}
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            {
                contact.isPending || systemCheck.isPending || user.isPending &&
                <LoadingIndicator />
            }
        </div>
    )
}
//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {
    const { contact, user, systemCheck } = state;
    return {
        contact,
        user,
        systemCheck
    };
};

const mapDispatchToProps = {
    sendContact: contactActions.loadContact,
    resetContact: contactActions.resetContact
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContactUs);
