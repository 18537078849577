import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchQuestionnaireStoreAnswerOptions } from '../api';
import { questionnaireStoreOptionsActions } from './actions';

export function* saveQuestionnaireDataStoreOptions({ payload }) {
  const { questionnaireId, questionId, options } = payload;

  yield call(fetchQuestionnaireStoreAnswerOptions, { questionnaireId, questionId, options });
}


export function* watchQuestionnaireStoreOptions() {
  yield takeEvery(questionnaireStoreOptionsActions.SAVE_QUESTIONNAIRE_STOREOPTIONS, saveQuestionnaireDataStoreOptions);
}

export const questionnaireStoreOptionsSagas = [
  fork(watchQuestionnaireStoreOptions),
];
