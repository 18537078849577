import { call, fork, select, takeLatest } from 'redux-saga/effects';

import { fetchFullReportTeam } from '../api';
import { teamsFullReportListActions } from './actions';

import { getTeamsFullReportById } from './selectors';

export function* loadTeamsFullReportList({payload}) {
  const { teamsFullReportId } = payload;
  const teamsFullReportList = yield select(getTeamsFullReportById, teamsFullReportId);

    yield call(fetchFullReportTeam, teamsFullReportId);

}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadTeamsFullReportList() {
  yield takeLatest(teamsFullReportListActions.LOAD_TEAMS_FULL_REPORT, loadTeamsFullReportList);
}


//=====================================
//  ROOT
//-------------------------------------

export const teamsFullReportListSagas = [
  fork(watchLoadTeamsFullReportList),
];
