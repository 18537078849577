import React, { Component } from 'react'
import { connect } from 'react-redux'

import { List, Map } from 'immutable';
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { Container, Row, Col } from 'reactstrap';

import {
  Start,
  AssessmentPartOne,
  AssessmentPartTwo,
  ReadyPartOne,
  ReadyPartTwo,
  ReportComplete,
  Report
} from '../components/mind-pattern'

import quest_ilustracion from '../../assets/img/iconos-01.png'
import LoadingIndicator from '../components/loading-indicator'

import { MIND_PATTERNS_DATA_AREAS, MIND_PATTERNS_DATA_OPTIONS, MIND_PATTERNS_DATA_REPORTS, mind_pattern_sections } from '../../core/constants'
import { mindPatternsDataActions, getMindPatternsDataById } from '../../core/mind-patterns';
import { mindPatternsStoreOptionsActions } from '../../core/mind-patterns-storeOptions/actions';
import { mindPatternsStoreMPActions } from '../../core/mind-patterns-storemp/actions';
import { userActions } from '../../core/users';
import { trackingActions } from '../../core/tracking';

import ReactGA from 'react-ga';

class MindPatternPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      current_step: 0,
      current_section: mind_pattern_sections.START,
      next_step: false,
      direction: 'right',
      animationClass: {
        appearActive: 'animated slideInRight position-fixed',
        enter: 'animated',
        enterActive: 'animated slideInRight position-fixed',
        exit: 'animated',
        exitActive: 'animated slideOutLeft position-fixed',
      },
      response_list: new Map(),
      response_questions_two: new List(),
      mind_pattern_id: null,
      retake: false, 
    }

    this.storeOptions = [];
    this.hanndleNext = this.hanndleNext.bind(this);
    this.hanndlePrev = this.hanndlePrev.bind(this);
    this.hanndleQuestionResponse = this.hanndleQuestionResponse.bind(this);
  }

  setRetake = (value) => {
    this.setState({ retake: value })
  }

  componentDidMount = () => {
    this.props.loadMindPatternsData(MIND_PATTERNS_DATA_REPORTS)
    if (this.props.user.mind_pattern) {
      this.setState({ current_section: mind_pattern_sections.REPORT })
    }

    let history_state = this.props?.history?.location?.state
   
    if(history_state) {
        if(history_state.hanndleNext) {
            this.hanndleNext(history_state?.hanndleNext?.section, history_state?.hanndleNext?.id)
        }
        if(history_state?.setRetake && history_state?.setRetake !== undefined) {
            this.setRetake(history_state?.setRetake?.value)
        }
    
        // this.props.history.location.state = null;
    
    }
    
  }


  hanndleQuestionResponse(next, data, value, response_type) {
    const { response_list, current_step, response_items_by_groups, response_questions_two, storeOptions } = this.state;
    const { questions_list, assessment, saveAssessment, saveMindPatternsStoreOptions, saveMindPatternsStoreMP } = this.props;



    if (response_type === mind_pattern_sections.ASSESSMENT) {

      const item = { number: data.id, value }
      const new_response_list = response_list.set(data.id, item)
      this.setState({ response_list: new_response_list });

      this.storeOptions.push(item);
      if (next === 'READY_PART_TWO') {
        saveMindPatternsStoreOptions(this.storeOptions)
      }
    }


    if (response_type === mind_pattern_sections.ASSESSMENT_PART_TWO) {

      const new_response_questions_two = response_questions_two.push(value)
      this.setState({ response_questions_two: new_response_questions_two });

      if (next === mind_pattern_sections.REPORT) {
        // console.log(new_response_questions_two.toJSON().join(''))
        saveMindPatternsStoreMP(new_response_questions_two.toJSON().join(''))
        this.props.currentUser()
        //GA
        !this.state.retake ?
        ReactGA.event({
            category: 'Mind Patterns',
            action: 'Creation completed'
        })
        :
        ReactGA.event({
            category: 'Mind Patterns',
            action: 'Retake assessment completed'
        })

        //our tracking
        !this.state.retake ?
        this.props.saveTrackingEvent({category: 'Mind Patterns',action: 'Creation completed'})
        :
        this.props.saveTrackingEvent({category: 'Mind Patterns',action: 'Retake assessment completed'})

      }

    }

    this.hanndleNext(next);

  }

  hanndleNext(section, id = null) {

    const animationClass = {
      appearActive: 'animated slideInRight position-fixed',
      enter: 'animated',
      enterActive: 'animated slideInRight position-fixed',
      exit: 'animated',
      exitActive: 'animated slideOutLeft position-fixed',
    }
    let current_step = this.state.current_step;

    if (section === mind_pattern_sections.READY_PART_TWO || section === mind_pattern_sections.REPORT) {
      current_step = 0;
    }

    this.setState({ direction: 'right', next_step: true, current_section: section, animationClass, current_step, mind_pattern_id: id });

    if(this.props.fixSection) {
        this.props.history.push({
            pathname: '/mind-patterns',
            state: {
                hanndleNext: { section, id },
                setRetake: { section: section === mind_pattern_sections.START }
            }
        })
    }

  }

  hanndlePrev(section) {
    const { current_section, response_questions_two } = this.state

    const animationClass = {
      appearActive: 'animated slideInLeft position-fixed',
      enter: 'animated',
      enterActive: 'animated slideInLeft position-fixed',
      exit: 'animated',
      exitActive: 'animated slideOutRight position-fixed',
    }


    const new_response_questions_two = response_questions_two.pop()

    this.setState({ direction: 'left', next_step: true, current_section: section, animationClass, response_questions_two: new_response_questions_two });

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.current_section === 'REPORT_COMPLETE' && this.state.current_section === 'REPORT') {
        this.props.loadMindPatternsData(MIND_PATTERNS_DATA_REPORTS)
      }

    // console.log(prevState.current_section );
    // console.log(this.state.current_section);
    if (prevState.current_section ==='ASSESSMENT_PART_TWO' && this.state.current_section === 'REPORT' ) {
      this.props.currentUser()
    }

  }


  componentWillUpdate(nextProps, nextState) {
    const { direction, next_step, current_step, current_section, validations } = nextState;
    const { questions_list, user } = this.props;

    if (current_section === mind_pattern_sections.ASSESSMENT || current_section === mind_pattern_sections.ASSESSMENT_PART_TWO) {

      if (direction === 'right' && next_step) {
        this.setState({ current_step: current_step + 1, next_step: false })
      }
      else if (direction === 'left' && next_step) {
        this.setState({ current_step: current_step - 1, next_step: false })
      }
    }

    if (current_section === mind_pattern_sections.READY && next_step) {
      this.props.loadMindPatternsData(MIND_PATTERNS_DATA_OPTIONS)
      this.setState({ next_step: false })
    }

    if (current_section === mind_pattern_sections.READY_PART_TWO && next_step) {
      this.props.loadMindPatternsData(MIND_PATTERNS_DATA_AREAS)
      this.setState({ next_step: false })
    }

    if (nextProps.mindPatternsStoreOptions.items.result === 1 && nextState.current_section !== mind_pattern_sections.REPORT && nextState.current_section !== mind_pattern_sections.REPORT_COMPLETE) {
      this.setState({ current_section: mind_pattern_sections.REPORT })
    }

  }

  render() {

    const { current_step, direction, animationClass, response_list,
      response_questions_two, current_section, result_valid,
      response_items_by_groups, validations, mind_pattern_id } = this.state
    const { displayLoadingIndicator, questions_list, user, options, areas, reports, mindPatternsStoreOptions, saveMindPatternsStoreMP,mindPatternsStoreMP, fixSection, viewMode, animation } = this.props;

    if (displayLoadingIndicator || (user.checked && !user.id))
      return <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />;

    let content = null;

    if (current_section === mind_pattern_sections.START) {
      content = <Start hanndleNext={this.hanndleNext} user={user} />
    }
    else if (current_section === mind_pattern_sections.READY) {
      content = <ReadyPartOne hanndleNext={this.hanndleNext} retake={this.state.retake} />
    }
    else if (current_step && current_section === mind_pattern_sections.ASSESSMENT) {
      content = <AssessmentPartOne hanndleNext={this.hanndleQuestionResponse} questions={options.items} current_step={current_step} />
    }
    else if (current_section === mind_pattern_sections.READY_PART_TWO) {
      content = <ReadyPartTwo hanndleNext={this.hanndleNext} />
    }
    else if (current_section === mind_pattern_sections.ASSESSMENT_PART_TWO) {
      content = <AssessmentPartTwo hanndleNext={this.hanndleQuestionResponse} hanndlePrev={this.hanndlePrev}
        questions={areas.items} current_step={current_step} selected_options={response_questions_two} />
    }
    else if (current_section === mind_pattern_sections.REPORT_COMPLETE) {
      content = <ReportComplete hanndleNext={this.hanndleNext} mind_pattern_id={mind_pattern_id} />
      // reports={reports.items}
    }
    else if (current_section === mind_pattern_sections.REPORT && mindPatternsStoreOptions.items.result === 1) {
      content =
        <Report hanndleNext={this.hanndleNext} reports={reports.items} selected_options={this.storeOptions}
          mind_pattern={mindPatternsStoreOptions.items.user.mind_pattern} />
    }
    else if (current_section === mind_pattern_sections.REPORT && user.mind_pattern) {
      content = <Report hanndleNext={this.hanndleNext} reports={reports.items} mind_pattern={user.mind_pattern} setRetake={this.setRetake} viewMode={viewMode} />
    }
    else if (current_section === mind_pattern_sections.REPORT) {
      content = <Report hanndleNext={this.hanndleNext} reports={reports.items} selected_options={response_questions_two} setRetake={this.setRetake} />
    }

    return (
      <div className="view overflow-hidden">
        <TransitionGroup className="content">
          <CSSTransition classNames={!fixSection && animation && animationClass} timeout={{ enter: 1000, exit: 1000 }} key={current_section + '_' + current_step} appear >
            <div className="w-100">
              <Container>
                {content}
              </Container>
              {displayLoadingIndicator && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {
  const { user, mind_patterns_store_mp, mind_patterns_store_data_options } = state;
  const areas = getMindPatternsDataById(state, MIND_PATTERNS_DATA_AREAS)
  const options = getMindPatternsDataById(state, MIND_PATTERNS_DATA_OPTIONS)
  const reports = getMindPatternsDataById(state, MIND_PATTERNS_DATA_REPORTS)

  return {
    user: props.user || user,
    viewMode: props.user ? true : false,
    areas,
    options,
    reports,
    displayLoadingIndicator: user.isPending || (areas ? areas.isPending : false) || (options ? options.isPending : false) || (reports ? reports.isPending : false),
    mindPatternsStoreOptions: mind_patterns_store_data_options,
    mindPatternsStoreMP: mind_patterns_store_mp,
    animation: props.animation !== undefined ? props.animation : true
  };
};

const mapDispatchToProps = {
  loadMindPatternsData: mindPatternsDataActions.loadMindPatternsData,
  saveMindPatternsStoreOptions: mindPatternsStoreOptionsActions.mindPatternsSaveStoreOptions,
  saveMindPatternsStoreMP: mindPatternsStoreMPActions.mindPatternsSaveStoreMp,
  currentUser: userActions.currentUser,
  saveTrackingEvent: trackingActions.saveTrackingEvent
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MindPatternPage);
