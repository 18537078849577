export const teamsMembersListActions = {

  FETCH_TEAM_MEMBERS_FAILED: 'FETCH_TEAM_MEMBERS_FAILED',
  FETCH_TEAM_MEMBERS_SUCCESS: 'FETCH_TEAM_MEMBERS_SUCCESS',
  FETCH_TEAM_MEMBERS_PENDING: 'FETCH_TEAM_MEMBERS_PENDING',

  LOAD_TEAM_MEMBERS: 'LOAD_TEAM_MEMBERS',
  
  fetchTeamMembersFailed: error => ({
    type: teamsMembersListActions.FETCH_TEAM_MEMBERS_FAILED,
    payload: error
  }),

  fetchTeamMembersSuccess: (teamsMembersListId, data) => ({
    type: teamsMembersListActions.FETCH_TEAM_MEMBERS_SUCCESS,
    payload: {
      data,
      teamsMembersListId
    }
  }),

  fetchTeamMembersPending: teamsMembersListId => ({
    type: teamsMembersListActions.FETCH_TEAM_MEMBERS_PENDING,
    payload: {
      teamsMembersListId
    }
  }),

  loadTeamMembers: (team_id) => ({
    type: teamsMembersListActions.LOAD_TEAM_MEMBERS,
    payload: {
      teamsMembersListId: team_id
    }
  }),

};

export const teamsMembersListRequestActions = {
  failed: teamsMembersListActions.fetchTeamMembersFailed,
  success: teamsMembersListActions.fetchTeamMembersSuccess,
  pending: teamsMembersListActions.fetchTeamMembersPending
};
