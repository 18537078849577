import React from 'react';

export const ErrorPasswordPolitics = ({ errorPasswordPolitics }) => {
    return (
        <>{errorPasswordPolitics &&
            <div className="d-block">
                <p>{errorPasswordPolitics.title_error}:</p>
                <ul>
                    {errorPasswordPolitics.politics && errorPasswordPolitics.politics.map((item, index) => {
                        return <li key={index} style={{ color: errorPasswordPolitics.unfulfilled.find(element => element === item) ? 'red' : '' }}> {item} </li>
                    })
                    }
                </ul>
            </div>
        }</>
    )
}