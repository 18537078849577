import React, { Component } from 'react'
import { Media } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from "@fortawesome/free-solid-svg-icons";


class TalentsListMemberItem extends Component {

  render() {
    const { handleClick, member } = this.props;
    return (
      <div className="member-item cursor-pointer" onClick={() => handleClick({ section: 'detail-member', member_id: member.id })}>
        <div className="d-flex justify-content-between px-4 py-2">
          <p className="mb-0"><i className="mr-3"><FontAwesomeIcon icon={faUser} /></i>{`${member.first_name} ${member.last_name}`}</p>
        </div>
      </div>
    )
  }
}

export default TalentsListMemberItem;
