import React, { Component, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink,  Row, Col, Media,  Modal, ModalHeader, ModalBody, ModalFooter, Button  } from 'reactstrap';
import { NavLink as Link} from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPaper, faCompactDisc, faAssistiveListeningSystems } from '@fortawesome/free-solid-svg-icons';

import writing_reading from '../../../assets/img/writing-reading.png'
import talking_hearing from '../../../assets/img/talking-hearing.png'
import hand_on from '../../../assets/img/hand-on.png'

class WorkingWhithVaks extends Component{

  render() {

    const { member } = this.props;

    const mind_patterns = member.mind_pattern.split("").map( (mp, k) => {

      let title = mp
      let icon = null

      if ( mp === 'V'){
        icon = writing_reading
      }

      if ( mp === 'A'){
        icon = talking_hearing
      }

      if ( mp === 'K'){
        icon = hand_on
      }

      return <div key={mp + k} className={`text-center item-mind-patterns contnet-mind-pattern-${mp.toLowerCase()}`}>
        <h3 className={`line-height-normal mb-0 color-mind-pattern-${mp.toLowerCase()}`}>{title}</h3>
        <img src={icon} className="mb-2"/>
      </div>
    })

    return (
      <>
      <h3 className="text-center text-reset">Working with {member.mind_pattern}s</h3>

      <Row noGutters className="mind-patterns-tab mb-4">
        <Col md={{size: 10, offset: 1}} className="mb-3">
          <div className="d-flex align-items-center justify-content-between">
            {mind_patterns}
          </div>
        </Col>
        <Col md={{ size: 10, offset: 1}}>
          <p>{member.mind_pattern_info}</p>
        </Col>
      </Row>
      
      </>
    )
  }
}

export default WorkingWhithVaks;
