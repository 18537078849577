import { call, fork, takeEvery } from 'redux-saga/effects';
import { fetchUserResume } from '../api';
import { userResumeActions } from './actions';

export function* userResume() {
    yield call(fetchUserResume)
}

//Watchers
export function* watchUserResume() {
    yield takeEvery(userResumeActions.LOAD_USER_RESUME, userResume)
}

//Root
export const userResumeSaga = [
    fork(watchUserResume)
]
