import { createSelector } from 'reselect';
import { getMembers } from '../members/selectors';

export function getMembersList(state) {
  return state.members_lists;
}

export function getMembersListById(state, memberListId) {
  return getMembersList(state).get(memberListId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentMembersList = createSelector(
  getMembersList,
  member_lists => member_lists.get(member_lists.get('currentMembersListId'))
);

export const getMembersForCurrentMembersList = createSelector(
  getCurrentMembersList,
  getMembers,
  ( members_lists, members) => {
    return members_lists.itemsId.map( id => members.get(`${id}`));
  }
);
