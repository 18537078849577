import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse, Media,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { List } from 'immutable';
import logo from '../../../assets/img/true.logo.circle.png'

import TalentsListMemberItem from './talents-list-member-item'

import { getCurrentTeam } from '../../../core/teams'
import { getCurrentTeamMembersList } from '../../../core/teams-members-list'

import MemberDetail from './member-detail'
import WorkingWhithVaks from './working-whith-vaks'
import QuadrantsDetail from './quadrants-detail'

const TalentsListMembers = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [current_section, setSection] = useState(false);
  const [current_member_id, setCurrentMember] = useState(false);
  const [quadrant_name, setQuadrantName] = useState(null);

  const { data, custom_style, handleModal, quadrants, questions, members, user, team } = props;

  const toggle = ({ section = 'members-list', member_id = null, quadrant_name = null }) => {

    setIsOpen(!isOpen);
    setSection(section);
    setCurrentMember(member_id)
    setQuadrantName(quadrant_name)
    // console.log('handleClick', section, member_id)
  }

  if (!questions)
    return null;

  const quadrant = quadrants.find(quadrant => quadrant.id === data.quadrant_id);

  const question = questions.items.find(question => question.id === data.item_id);


  const list_members = data.members.map(member => <TalentsListMemberItem
    key={`tm-${member.id}`}
    member={member}
    handleClick={toggle} />)



  if (current_section === 'detail-member' && current_member_id) {
    const team_member = members.find(item => item.user_id === current_member_id);
    return (
      <MemberDetail
        member={team_member.member}
        isMember={true}
        enable_edit={(user && user.id === team.owner_id)}
        team={team}
        isLeader={team_member.leader}
        quadrants={quadrants}
        hanndleNextSection={toggle}
      />
    )

  }
  else if (current_section === 'detail-member-vaks' && current_member_id) {

    const team_member = members.find(item => item.user_id === current_member_id);
    return (<WorkingWhithVaks member={team_member.member} />)

  }
  else if (current_section === 'detail-member-talens' && current_member_id && quadrant_name) {

    const team_member = members.find(item => item.user_id === current_member_id);

    
    return <QuadrantsDetail questions={questions} items={team_member.member.talents} quadrant_name={quadrant_name} quadrants={quadrants} />

  }

  else return (
    <>
      <div className={`item talents-item clearfix mb-3 ${data.name}`}>
        <div className="px-4 py-1 option-select rounded-pill text-uppercase mb-3" style={{ backgroundColor: quadrant.color, color: '#fff' }}>{quadrant.name}</div>
        <Media className="align-items-center">
          <Media left middle className="mr-4 ml-2">
            <img className="media-object" width="60" height="60" src={question.icon} alt={question.name} style={{ backgroundColor: quadrant.color }} />
          </Media>
          <Media body>
            <Media heading tag="p" className="mb-0 mt-1">{question.name}</Media>
          </Media>
        </Media>
        <div className="bg-celeste py-2 px-4 mt-3 rounded text-left mb-2">
          <p className="mb-0">Tap a member to view their profile</p>
        </div>
        <hr className="mb-0" />
        <div className="member-list">{list_members}</div>
        <hr className="mt-0" />
        <div className="px-4" dangerouslySetInnerHTML={{ __html: data.team_report }}></div>
      </div>
    </>
  )

}

const mapStateToProps = (state, props) => {

  const { session, quadrants, questions } = state

  const team = getCurrentTeam(state, props);
  const team_members_list = getCurrentTeamMembersList(state, props);

  return {
    user: session.user,
    quadrants,
    questions,
    team_members_list,
    team,
    members: team_members_list ? team_members_list.items : new List(),
  }

}

const mapDispatchToProps = {

}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TalentsListMembers);
