import { pdfsActions } from './actions';
import { pdfs } from './pdfs';


export const pdfsReducer = (state = pdfs, { payload, type }) => {

    switch (type) {
        case pdfsActions.FETCH_PDF_FAILED:
            return {
                ...state,
                isPending: false,
                error: payload.error
            };

        case pdfsActions.FETCH_PDF_SUCCESS:
            return {
                ...state,
                isPending: false,
            };
        case pdfsActions.FETCH_PDF_PENDING:
            return {
                ...state,
                isPending: true
            }
        default:
            return state;
    }
}
