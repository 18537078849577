import { call, fork, select, takeEvery } from 'redux-saga/effects';

import { fetchMindPatters } from '../api';
import { mindPatternsDataActions } from './actions';

export function* loadMindPatternsData({payload}) {
  const { mindPatternsDataId } = payload;

  yield call(fetchMindPatters, mindPatternsDataId);

}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchMindPatternsData() {
  yield takeEvery(mindPatternsDataActions.LOAD_MINDPATTERNS, loadMindPatternsData);
}

//=====================================
//  ROOT
//-------------------------------------

export const mindPatternsDataSagas = [
  fork(watchMindPatternsData),
];
