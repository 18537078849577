export const videosActions = {

  FETCH_VIDEOS_FAILED: 'FETCH_VIDEOS_FAILED',
  FETCH_VIDEOS_SUCCESS: 'FETCH_VIDEOS_SUCCESS',
  FETCH_VIDEOS_PENDING: 'FETCH_VIDEOS_PENDING',
  LOAD_VIDEOS: 'LOAD_VIDEOS',
  LOAD_VIDEOS_LOGIN: 'LOAD_VIDEOS_LOGIN',

  fetchVideosFailed: error => ({
    type: videosActions.FETCH_VIDEOS_FAILED,
    payload: error
  }),

  fetchVideosSuccess: (id, data) => ({
    type: videosActions.FETCH_VIDEOS_SUCCESS,
    payload: {
      data,
      id
    }
  }),

  fetchVideosPending: () => ({
    type: videosActions.FETCH_VIDEOS_PENDING,
  }),

  loadVideos: () => ({
    type: videosActions.LOAD_VIDEOS,
  }),

  loadVideosLogin: () => ({
    type: videosActions.LOAD_VIDEOS_LOGIN,
  }),

};

export const videosRequestActions = {
  failed: videosActions.fetchVideosFailed,
  success: videosActions.fetchVideosSuccess,
  pending: videosActions.fetchVideosPending
};
