import React, { useEffect } from 'react';
import { Modal, ModalBody, Form, FormGroup, CustomInput, Label } from 'reactstrap';
import { userActions } from '../../../core/users';
import { useDispatch } from 'react-redux';

export const PopupAcceptTerms = ({ isOpen, toggle }) => {
    const dispatch = useDispatch()

    const acceptTerms = () => {
        dispatch(userActions.acceptTerms())
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalBody>
                <Form className='mt-3'>
                    <h5 className='text-center text-reset'>To continue using My True Advance, please agree to True Advance's updated Terms of Use and Privacy Policy</h5>
                    <FormGroup>
                        <CustomInput className="pl-0 ml-4 styled-checkbox d-flex align-items-center" type="checkbox" id="termsAndPrivacy" onChange={() => acceptTerms()} label={<Label for='termsAndPrivacy' >I agree to True Advance's <a href="https://www.trueadvance.com/terms-of-use" target='_blank'>Terms of Use</a> and <a href="https://www.trueadvance.com/privacy-policy" target='_blank'>Privacy Policy</a></Label>} />
                    </FormGroup>
                </Form>
            </ModalBody>
        </Modal>
    )
}