import React, { Fragment } from 'react';
import { Header } from './Header';
import { Question } from './Question'
import { useSelector } from 'react-redux';
import LoadingIndicator from '../../loading-indicator/loading-indicator';

export const QuestionsList = ({ questionnaire, text, icon }) => {
    const { questionnaire_list } = useSelector(state => state);
    return (
        <>{ questionnaire_list && !questionnaire_list.isPending &&
            <Fragment>
            <Header icon={icon} text={text} />
            {
                questionnaire.answered_items && questionnaire.answered_items.map((question, index) =>

                    <div key={question.id} className="border-bottom py-3">
                        <Question
                            question={question}
                            index={index}
                            questionnaie_id={questionnaire.questionnaire.id}
                            questionnaire={questionnaire.questionnaire}
                        />
                    </div>
                )
            }
            </Fragment>
            }
            {
                questionnaire_list && questionnaire_list.isPending &&
                <LoadingIndicator/>
            }
        </>
    )
}
