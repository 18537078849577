import { fullReportMpListActions } from './actions';
import { FullReportMp } from './full-report-mp';


export function fullReportMpReducer(state = new FullReportMp(), {payload, type}) {
    switch (type) {
        case fullReportMpListActions.FETCH_FULL_REPORT_MP_FAILED:
            return state.merge({
                isPending: false,
                error: payload.error
            })

        case fullReportMpListActions.FETCH_FULL_REPORT_MP_SUCCESS:
            return state.merge({
                isPending: false,
                error: null,
                items: payload.data
            })

        case fullReportMpListActions.FETCH_FULL_REPORT_MP_PENDING:
            return state.set('isPending', true);

        case fullReportMpListActions.LOAD_FULL_REPORT_MP:
            return state.merge({
                id: payload.fullReportIdMp,
              });

        default:
            return state
    }
    
}
