import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Radio extends Component {

  toggleChange = ( event ) => {

    const { handleRadioChange, item_id } = this.props
    handleRadioChange(item_id, event.target.value);
  }

  render() {
    const { label, value, item_id, select_option, group } = this.props
    let isChecked = select_option === value

    return (
      <label className={  `btn btn-outline-primary text-uppercase rounded-pill ${( isChecked ? 'active' : '' ) }` }>
        <input type="radio" name={ `${group}${item_id}` } onChange={this.toggleChange} checked={ isChecked } value={value}  />
        <b>{label}</b>
      </label>
    );
  }
}

Radio.propTypes = {
  label: PropTypes.string,
  handleRadioChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default Radio;
