import { call, fork, select, takeLatest } from 'redux-saga/effects';

import { fetchQuestions } from '../api';
import { questionsListActions } from './actions';

import { getCurrentQuestionsList } from './selectors';

export function* loadQuestionsList({payload}) {
  const { questionsListId } = payload;

  yield call(fetchQuestions, questionsListId);

}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchLoadQuestionsList() {
  yield takeLatest(questionsListActions.LOAD_QUESTIONS, loadQuestionsList);
}


//=====================================
//  ROOT
//-------------------------------------

export const questionsListSagas = [
  fork(watchLoadQuestionsList),
];
