import { List } from 'immutable';
import { call, fork, takeEvery } from 'redux-saga/effects';

import { fetchMindPattersStoreOptions } from '../api';
import { mindPatternsStoreOptionsActions } from './actions';

export function* saveMindPatternsDataStoreOptions({ payload }) {

  yield call(fetchMindPattersStoreOptions, payload.data);

}
//=====================================
//  WATCHERS
//-------------------------------------

export function* watchMindPatternsStoreOptions() {
  yield takeEvery(mindPatternsStoreOptionsActions.SAVE_MINDPATTERNS_STORE_OPTIONS, saveMindPatternsDataStoreOptions);
}

//=====================================
//  ROOT
//-------------------------------------

export const mindPatternsStoreOptionsSagas = [
  fork(watchMindPatternsStoreOptions),
];
