import { questionnaireItemsActions } from './actions';
import { QuestionnaireItems } from './questionnaire-items';

export const questionnaireItemsReducer = (state = new QuestionnaireItems(), { payload, type }) => {

    switch (type) {
        case questionnaireItemsActions.FETCH_QUESTIONNAIRE_ITEMS_FAILED:
            return state.merge({
                isPending: false,
                error: payload?.error?.data
            });
        case questionnaireItemsActions.FETCH_QUESTIONNAIRE_ITEMS_SUCCESS:
            return state.merge({
                data: payload.data,
                isPending: false,
                starts_from: payload.data.starts_from
            });
        case questionnaireItemsActions.FETCH_QUESTIONNAIRE_ITEMS_PENDING:
            return state.set('isPending', true);

        // case questionnaireItemsActions.LOAD_QUESTIONNAIRE_ITEMS:
        //     console.log(payload.id);
        //     return state.merge({
        //         id: payload.id,
        //     });

        case questionnaireItemsActions.NEXT_QUESTION:
            return state.merge({
                starts_from: state.starts_from + 1
            })

        default:
            return state;
    }
}
