export const fullReportMpListActions = {

    FETCH_FULL_REPORT_MP_FAILED: 'FETCH_FULL_REPORT_MP_FAILED',
    FETCH_FULL_REPORT_MP_SUCCESS: 'FETCH_FULL_REPORT_MP_SUCCESS',
    FETCH_FULL_REPORT_MP_PENDING: 'FETCH_FULL_REPORT_MP_PENDING',
    LOAD_FULL_REPORT_MP: 'LOAD_FULL_REPORT_MP',
  
  
    fetchFullReportMpFailed: error => ({
      type: fullReportMpListActions.FETCH_FULL_REPORT_MP_FAILED,
      payload: error
    }),
  
    fetchFullReportMpSuccess: (fullReportIdMp, data) => ({
      type: fullReportMpListActions.FETCH_FULL_REPORT_MP_SUCCESS,
      payload: {
        data,
        fullReportIdMp
      }
    }),
  
    fetchFullReportMpPending: fullReportIdMp => ({
      type: fullReportMpListActions.FETCH_FULL_REPORT_MP_PENDING,
      payload: {
        fullReportIdMp
      }
    }),
  
    loadFullReportMp: (team_id) => ({
      type: fullReportMpListActions.LOAD_FULL_REPORT_MP,
      payload: {
        fullReportIdMp: team_id
      }
    }),
  };
  
  export const fullReportMpListRequestActions = {
    failed: fullReportMpListActions.fetchFullReportMpFailed,
    success: fullReportMpListActions.fetchFullReportMpSuccess,
    pending: fullReportMpListActions.fetchFullReportMpPending
  };
  