const SPACE_SPLITTER = /\s+/;

export function membersListIdForFind(query) {
  query = (!query) ? '' : query.trim().split(SPACE_SPLITTER).join(' ');
  return `s${query}`.toLowerCase();
}

export function membersListIdForSearch(filters) {
	let query = "";
  Object.keys(filters).map(function(key) {
  		query+= "_" + key +'_'+filters[key];
		return key;
	});  
	return `s${query}`.toLowerCase();
}
