export const teamsReportListActions = {

  FETCH_TEAMS_REPORT_FAILED: 'FETCH_TEAMS_REPORT_FAILED',
  FETCH_TEAMS_REPORT_SUCCESS: 'FETCH_TEAMS_REPORT_SUCCESS',
  FETCH_TEAMS_REPORT_PENDING: 'FETCH_TEAMS_REPORT_PENDING',
  LOAD_TEAMS_REPORT: 'LOAD_TEAMS_REPORT',


  fetchTeamsReportFailed: error => ({
    type: teamsReportListActions.FETCH_TEAMS_REPORT_FAILED,
    payload: error
  }),

  fetchTeamsReportSuccess: (teamsReportId, data) => ({
    type: teamsReportListActions.FETCH_TEAMS_REPORT_SUCCESS,
    payload: {
      data,
      teamsReportId
    }
  }),

  fetchTeamsReportPending: teamsReportId => ({
    type: teamsReportListActions.FETCH_TEAMS_REPORT_PENDING,
    payload: {
      teamsReportId
    }
  }),

  loadTeamsReport: (team_id) => ({
    type: teamsReportListActions.LOAD_TEAMS_REPORT,
    payload: {
      teamsReportId: team_id
    }
  }),
};

export const teamsReportListRequestActions = {
  failed: teamsReportListActions.fetchTeamsReportFailed,
  success: teamsReportListActions.fetchTeamsReportSuccess,
  pending: teamsReportListActions.fetchTeamsReportPending
};
