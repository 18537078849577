import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledCollapse, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom'
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPencilAlt, faTimesCircle, faUser, faArrowLeft, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { List, Map } from 'immutable';

import logo from '../../../assets/img/true.logo.circle.png'

import { membersListActions, getCurrentMembersList, getMembersForCurrentMembersList } from '../../../core/members-list'
import { teamActions, getCurrentTeam } from '../../../core/teams'
import { DEFAULT_SESSION_ID } from '../../../core/constants'
import { teamsMembersListActions, getCurrentTeamMembersList } from '../../../core/teams-members-list'
import { teamsReportListActions, getTeamsReportById } from '../../../core/teams-report'

import { teamsFullReportListActions, getCurrentTeamsFullReport } from '../../../core/teams-full-report'

import { questionsListActions } from '../../../core/questions-list'

import LoadingIndicator from '../../components/loading-indicator'
import { MemberItem, MemberDetail, ThinkingTalents, MindPatterns, WorkingWhithVaks, QuadrantsDetail } from '../../components/teams'
import { TeamResume } from '../../components/teams/teamResume/TeamResume';

class DetailPage extends Component {

  constructor(props) {
    super(props)



    this.state = {
      activeTab: props.activeTab ? props.activeTab : 'members',
      animationClass: {
        appearActive: 'animated slideInRight position-fixed',
        enter: 'animated',
        enterActive: 'animated slideInRight position-fixed',
        exit: 'animated',
        exitActive: 'animated slideOutLeft position-fixed',
      },
      animationClassTabs: {
        enter: 'animated',
        enterActive: 'animated fadeIn',
        exit: 'animated',
        exitActive: 'animated fadeOut',
      },
      current_section: 'members-list',
      current_member_id: '',
      show_modal_delete: false,
      show_modal: false,
      data_modal: null,
      quadrant_name: null,
    }

    this.toggle = this.toggle.bind(this)
    this.hanndleNextSection = this.hanndleNextSection.bind(this)
    this.handleModal = this.handleModal.bind(this)
    this.handleModalDelete = this.handleModalDelete.bind(this)
    this.handleBack = this.handleBack.bind(this)

  }

  componentWillMount() {
    const { team_id, loadTeam, loadTeamMembers, loadTeamsReport, loadQuestions, history } = this.props;
    loadQuestions();
    loadTeam(team_id);
    loadTeamMembers(team_id);
    loadTeamsReport(team_id);
  }

  componentWillUpdate(nextProps, nextState) {
    const { loadTeamMembers, team, team_id } = this.props;
    //console.log(nextProps.team, this.props.team)
    if (nextProps.team.update_at.getTime() !== team.update_at.getTime()) {
      loadTeamMembers(team_id);
    }
  }

  componentDidUpdate(oldProps, oldState) {

  }

  hanndleNextSection({ section = 'members-list', member_id = null, quadrant_name = null }) {

    const { team_id, removeTeamMember, setLeaderTeamMember, loadTeamMembers } = this.props
    let data = {
      current_section: section,
      current_member_id: member_id,
      quadrant_name: quadrant_name,
      data_modal: null,
      show_modal: false
    }

    if (section === 'detail-member-vaks') {
      data.current_section = 'detail-member';
      data.data_modal = 'vaks';
      data.show_modal = true;
    }

    if (section === 'detail-member-talens') {
      data.current_section = 'detail-member';
      data.data_modal = 'talens';
      data.show_modal = true;
    }



    this.setState(data)
  }

  handleModalDelete = (e, confirm) => {
    const { show_modal_delete } = this.state
    const { team_id } = this.props

    if (confirm && show_modal_delete) {
      this.props.deleteTeam(team_id)
      this.props.history.push('/teams');
    }

    this.setState({ show_modal_delete: !show_modal_delete })

  }

  handleModal = (e) => {
    const { show_modal } = this.state

    this.setState({ show_modal: !show_modal, data_modal: null })

  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      // console.log(activeTab, tab)
      this.setState({ activeTab: tab })
    }
  }

  handleBack(e) {
    e.preventDefault();
    const { team, history } = this.props;
    const { current_section } = this.state;

    if (current_section === 'detail-member') {
      this.setState({ current_section: 'members-list' })
    } else {
      history.push('/teams');
    }
  }

  render() {

    const { animationClass, activeTab, animationClassTabs, current_section, current_member_id, show_modal, show_modal_delete, data_modal, quadrant_name } = this.state;

    const { displayLoadingIndicator, team, members, quadrants, team_members_list, user, team_id, team_report, questions, team_full_report, loadTeamsFullRepor, match } = this.props;

    // console.log('match.paramas', match);

    if (!team || (user.checked && !user.id))
      return <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />;

    let content_modal = null;

    const enable_edit = (user && user.id === team.owner_id);

    const member_list = members.map((team_member, i) => {
      return <MemberItem key={`${team_member.user_id}-${i}`} member={team_member.member} isMember={true} isLeader={team_member.leader}
        hanndleNextSection={this.hanndleNextSection} />
    })
    const btn_add_member = (enable_edit) ? <div className="header-group d-flex justify-content-between pt-md-2 mb-3 mb-md-4"><Link to={`/teams/${team.id}/add-member`} className="btn text-uppercase rounded-pill btn-outline-primary mx-auto px-md-3">
      <i className="mr-3 h5 text-reset align-middle"><FontAwesomeIcon icon={faUser} /></i><span className="h6 font-weight-normal text-reset align-middle">Add members</span>
    </Link></div> : null;

    const btns_rename_delete = (enable_edit && current_section === 'members-list') ? <UncontrolledCollapse toggler="#group-edit-toggler" className="d-md-block group-toggler shadow">
      <Link to={`/teams/${team.id}/edit`} className="text-reset mr-md-3 d-block d-md-inline"><i className="mr-2"><FontAwesomeIcon icon={faPencilAlt} /></i><small>RENAME</small></Link>
      <a href="#" onClick={(e) => { e.preventDefault(); this.handleModalDelete() }} className="text-reset d-block d-md-inline"><i className="mr-2"><FontAwesomeIcon icon={faTimesCircle} /></i><small>DELETE</small></a>
    </UncontrolledCollapse> : null;

    let content = <>
     
        <TeamResume team={team} members={members}  quadrants={quadrants}/>
      <Nav className="mb-3 mb-md-4 text-center text-uppercase nav-xs-justified align-items-end nav-tabs-alt" tabs>
        <NavItem>
          <NavLink className={classNames({ active: activeTab === 'thinking-talents', 'px-md-4': true })} onClick={() => { this.toggle('thinking-talents'); }}>
            Thinking Talents
          </NavLink>
        </NavItem>
        <NavItem className="">
          <NavLink className={classNames({ active: activeTab === 'mind-patterns', 'px-md-4': true })} onClick={() => { this.toggle('mind-patterns'); }}>
            Mind Patterns
          </NavLink>
        </NavItem>
        <NavItem className="">
          <NavLink className={classNames({ active: activeTab === 'members', 'px-md-4': true })} onClick={() => { this.toggle('members'); }}>
            Members
          </NavLink>
        </NavItem>
      </Nav>

      <div className="content-tabs" style={{ overflow: 'hidden' }}>
        <SwitchTransition>
          <CSSTransition classNames={animationClassTabs} timeout={{ enter: 1000, exit: 300 }} key={activeTab} >
            <TabContent activeTab={activeTab}>
              <TabPane tabId="members">
                {btn_add_member}
                <div className="bg-celeste p-3 rounded mb-4">
                  <p className="mb-0">Tap a member to view their profile or remove them from this team.</p>
                </div>
                <div className="members-lists row row-cols-lg-2 row-cols-1">
                  {member_list}
                </div>
              </TabPane>
              <TabPane tabId="thinking-talents">
                <ThinkingTalents
                  team={team}
                  quadrants={quadrants}
                  team_full_report={team_full_report}
                  loadTeamsFullRepor={loadTeamsFullRepor}
                  questions={questions}
                  team_report={team_report}
                  members={members}
                  history={this.props.history}
                />
              </TabPane>
              <TabPane tabId="mind-patterns">
                <MindPatterns team={team} members={members} team_report={team_report} history={this.props.history} />
              </TabPane>
            </TabContent>
          </CSSTransition>
        </SwitchTransition>
      </div>

    </>

    if (current_section === 'detail-member') {
      const team_member = members.find(item => item.user_id === current_member_id);
      content = <MemberDetail
        member={team_member.member}
        isMember={true}
        enable_edit={enable_edit}
        team={team}
        isLeader={team_member.leader}
        quadrants={quadrants}
        hanndleNextSection={this.hanndleNextSection}
        params={match.params}
      />

      if (data_modal === 'vaks') {
        content_modal = <WorkingWhithVaks member={team_member.member} />
      }
      if (data_modal === 'talens') {
          content_modal = <QuadrantsDetail questions={questions} items={team_member.member.talents} member_id={team_member.member.id}
          quadrant_name={quadrant_name} quadrants={quadrants} team_report={team_report} />
        }
    }

    return (
      <div className="view overflow-hidden">
        <TransitionGroup className="content">
          <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key={current_section} appear >
            <div className="w-100">
              <Container>
                <div className="bg-white my-md-4 rounded-lg pb-4 pb-md-5 px-md-5 pt-md-2 content-rearrenge content-view">
                  <Row className="px-md-4">
                    <Col md={{ size: 12 }}>
                      <div className="position-relative d-flex justify-content-between align-items-md-center">
                        <a href="#" onClick={e => this.handleBack(e)} className="icon-back"><FontAwesomeIcon icon={faArrowLeft} /></a>
                        <div className="d-none d-sm-none d-md-block mb-md-3 encabezado flex-fill"><div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center"><h6 className="d-none d-sm-none d-md-block mb-0 title-underline">{team.name}</h6></div><span className="border border-info d-block"></span></div>
                        <h1 className="mx-4 mx-md-0 mb-md-4 text-center text-md-left text-reset d-md-none">{team.name}</h1>
                        <div className="group-edit pt-3">
                          <a className="d-md-none btn-circle-link" id="group-edit-toggler"><i><FontAwesomeIcon icon={faEllipsisV} /></i></a>
                          {btns_rename_delete}
                        </div>
                      </div>
                      {content}
                    </Col>
                  </Row>
                </div>
              </Container>

              {displayLoadingIndicator && <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />}
            </div>
          </CSSTransition>
        </TransitionGroup>

        <Modal isOpen={show_modal_delete} toggle={this.handleModalDelete} className="pop-up-confirm">
          <ModalBody className="text-center p-4 p-md-5">
            <p>Are you sure you want to DELETE this team?</p>
            <div className="d-flex justify-content-center">
              <Button color="btn text-uppercase rounded-pill btn-outline-primary mr-2 px-4" onClick={this.handleModalDelete}><i className="mr-2 h5 text-reset align-middle"><FontAwesomeIcon icon={faTimesCircle} /></i><span className="h6 font-weight-normal text-reset align-middle">No</span></Button>{' '}
              <Button color="btn text-uppercase rounded-pill btn-outline-primary ml-2 px-4" onClick={(e) => this.handleModalDelete(e, true)}><i className="mr-2 h5 text-reset align-middle"><FontAwesomeIcon icon={faCheckCircle} /></i><span className="h6 font-weight-normal text-reset align-middle">Yes</span></Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={show_modal} toggle={this.handleModal} className="modal-dialog-summary float-right modal-lg">
          <ModalHeader toggle={this.handleModal}><span className="d-flex justify-content-center bd-highlight"><img src={logo} height="48" alt="True Advance" /></span></ModalHeader>
          <ModalBody>
            {content_modal}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}
//export default ThinkingTalentsPage;
//=====================================
//  CONNECT
//-------------------------------------

const mapStateToProps = (state, props) => {

  const { quadrants, questions } = state

  const activeTab = (props.history.location.state && props.history.location.state.activeTab) ? props.history.location.state.activeTab : null

  const team_id = props.match.params.id ? props.match.params.id : DEFAULT_SESSION_ID;
  const team = getCurrentTeam(state, props);
  const team_report = getTeamsReportById(state, team_id);
  const team_full_report = getCurrentTeamsFullReport(state, props);
  const team_members_list = getCurrentTeamMembersList(state, props);

  // console.log('mapStateToProps->team_full_report', team_id)

  return {
    user: state.user,
    team_id,
    team,
    quadrants,
    team_members_list,
    activeTab,
    team_report,
    questions,
    team_full_report,
    members: team_members_list ? team_members_list.items : new List(),
    displayLoadingIndicator: (team ? team.isPending : false) || (team_members_list ? team_members_list.isPending : false) || (team_full_report ? team_full_report.isPending : false) || (team_report ? team_report.isPending : false) || false,
  };
};

const mapDispatchToProps = {
  loadTeam: teamActions.loadTeam,
  loadTeamMembers: teamsMembersListActions.loadTeamMembers,
  removeTeamMember: teamActions.removeTeamMember,
  setLeaderTeamMember: teamActions.setLeaderTeamMember,
  deleteTeam: teamActions.deleteTeam,
  loadTeamsReport: teamsReportListActions.loadTeamsReport,
  loadQuestions: questionsListActions.loadQuestions,
  loadTeamsFullRepor: teamsFullReportListActions.loadTeamsFullRepor,
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailPage);
