import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { questionnaireItemsActions } from '../../../core/questionnaire-items/actions';
import LoadingIndicator from '../loading-indicator/loading-indicator';
import icons from '../../../assets/img/Growth.svg'
import ReactGA from 'react-ga'
import { trackingActions } from '../../../core/tracking';

export const StartSection = ({ questionnaire_list, typeQuestionnaire, text, handleNextSection, icon }) => {
    const dispatch = useDispatch();

    function createMarkup() {
        return { __html: text };
    }

    const eventGA = () => {
        ReactGA.event({
            category: 'Questionnaires',
            action: `${typeQuestionnaire} started`
        })
    }

    const ourTracking = () => {
        dispatch(trackingActions.saveTrackingEvent({category: 'Questionnaires', action: `${typeQuestionnaire} started`}))
    }

    return (
        <>
            <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 pb-md-5 pt-md-2 content-view">
                {questionnaire_list && !questionnaire_list?.isPending &&
                <div className="px-md-4">
                    <div className="mb-md-5 encabezado text-center text-md-left">
                        <div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-inline-flex d-md-flex align-items-center">
                            <h6 className="mb-0 title-underline">{questionnaire_list?.data?.questionnaire?.name}</h6>
                        </div>
                        <span className="d-none d-md-block border border-info"></span>
                    </div>

                    <Row className="align-items-md-start">
                        <Col md={{ size: 5 }}>
                            <div className="px-4 px-md-0 mt-n4 mt-md-0 text-center">
                                <div className="bg-questionnaire-icon mx-auto">
                                    { icon && 
                                    <img src={icon} alt="new_member_discovery" className="img-fluid mx-auto" width="118" />
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col md={{ size: 7 }}>
                            <div className="mb-md-5 pb-4 pb-md-0">
                                {<div dangerouslySetInnerHTML={createMarkup()} />}
                            </div>
                            {
                                questionnaire_list.starts_from > 1 ?
                                    <Row className="contenido-bottom-fixed-mb">
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <button onClick={() => handleNextSection(questionnaire_list.data.questionnaire?.completion == 100 ? 'finish' : 'question')} className="btn btn-primary text-uppercase btn-block rounded-pill mt-3">Continue</button>
                                        </Col>
                                    </Row>
                                    :
                                    <Row className="contenido-bottom-fixed-mb">
                                        <Col md={{ size: 4, offset: 4 }}>
                                            <h2 className="text-naranja text-center">Let's GO!</h2>
                                            <button onClick={() => {handleNextSection('question'); eventGA(); ourTracking()}} className="btn btn-primary text-uppercase btn-block rounded-pill mt-3">Start</button>
                                        </Col>
                                    </Row>
                            }
                        </Col>
                    </Row>
                </div>}
            </div>

            {
                questionnaire_list?.isPending && <LoadingIndicator className="text-center" />
            }
        </>
    )
}
