import { call, fork, takeEvery, take } from 'redux-saga/effects';
import { fetchRegister } from '../api'
import { registerActions } from './actions';

let history = null;

export function* createRegister({ payload }) {
    const { data, userId } = payload;
    history = data.history;
    yield call(fetchRegister, userId, data.newRegister);
    // data.history.push('/confirm-register')
}

//=====================================
//  WATCHERS
//-------------------------------------

export function* watchCreateRegister() {
    yield takeEvery(registerActions.CREATE_USER, createRegister);
}

export function* watchRegisterSucces() {
    while (true) {
        yield take(registerActions.FETCH_REGISTER_SUCCESS);
        history.push('/confirm-register')
    }
}
//=====================================
//  ROOT
//-------------------------------------

export const registerSagas = [
    fork(watchCreateRegister),
    fork(watchRegisterSucces)
];