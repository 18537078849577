import { registerActions } from './actions';
import { Register } from './register';

export const registerReducer = (state = new Register(), { payload, type }) => {
    switch (type) {
        case registerActions.FETCH_REGISTER_FAILED:
            payload.error.description = payload.error.response && payload.error.response.data && payload.error.response.data.message
            return state.merge({
                isPending: false,
                error: payload.error
            });
        case registerActions.FETCH_REGISTER_SUCCESS:
            return state.merge({
                id: payload.data.id,
                error: 0,
                data: payload.data,
                isPending: false,
                isNew: false
            });
        case registerActions.FETCH_REGISTER_PENDING:
            return state.set('isPending', true);

        case registerActions.RESET_ERROR:
            return state.set('error', 0);
        default:
            return state;
    }
}
