import { List , Map } from 'immutable';
import { teamsListActions } from './actions';
import { TeamsList } from './teams-list';
import { DEFAULT_SESSION_ID } from '../constants';

import { teamActions } from '../teams';

export const initialState = new Map({
  currentTeamsListId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new TeamsList({id: DEFAULT_SESSION_ID, isNew: false})
});


export function teamsListsReducer(state = initialState, action) {

  const { type, payload } = action;
  switch (type) {
    case teamActions.FETCH_TEAM_FAILED:
    case teamActions.FETCH_TEAM_SUCCESS:
    case teamActions.DELETE_TEAM:
      return state.set(
        state.get('currentTeamsListId'),
        teamsListReducer(state.get(state.get('currentTeamsListId')), action)
      );
    case teamsListActions.FETCH_TEAMS_FAILED:
    case teamsListActions.FETCH_TEAMS_SUCCESS:
    case teamsListActions.FETCH_TEAMS_PENDING:
      return state.set(
        payload.teamsListId,
        teamsListReducer(state.get(payload.teamsListId), action)
      );

    case teamsListActions.LOAD_TEAMS:
      return state.merge({
        currentTeamsListId: payload.teamsListId,
        [payload.teamsListId]: teamsListReducer(state.get(payload.teamsListId), action)
      });
    default:
      return state;
  }
}

export const teamsListReducer = (state = new TeamsList(),  {payload, type} ) => {

  switch (type) {
    case teamActions.FETCH_TEAM_FAILED:
    case teamActions.FETCH_TEAM_SUCCESS:
      return state.set('isPending', false);
    case teamActions.DELETE_TEAM:
      return state.withMutations(teamslist => {
          teamslist
          .merge({
           isPending: true,
           itemsId: teamslist.itemsId.filter( team_id => `${team_id}` !== `${payload.team_id}` )
        })
       });
    case teamsListActions.FETCH_TEAMS_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case teamsListActions.FETCH_TEAMS_SUCCESS:
      return state.withMutations( teamslist => {
        teamslist.merge({
          isNew: false,
          isPending: false,
          itemsId: mergeIds(teamslist.itemsId, payload.data),
          update_at: new Date(),
        });
      });
    case teamsListActions.FETCH_TEAMS_PENDING:
      return state.set('isPending', true);
    case teamsListActions.LOAD_TEAMS:
      return state.merge({
        id: payload.teamsListId,
      });
    default:
      return state;
  }
}

function mergeIds(itemsId, items) {
  let ids = itemsId.toJS();

  //orden asc
  /*
  items = items.sort(function (a, b) {
    return (a.order - b.order)
  })
  */

  let newIds = items.reduce((list, itemData) => {
    if (ids.indexOf(itemData.id) === -1) list.push(itemData.id);
    return list;
  }, []);

  return newIds.length ? new List(ids.concat(newIds)) : itemsId;
}
