import { Map } from 'immutable';

import { createQuadrant } from './quadrant';
import { questionsListActions } from '../questions-list';

export function quadrantsReducer(state = new Map(), action) {
  const { payload } = action;

  switch (action.type) {
    case questionsListActions.FETCH_QUESTIONS_SUCCESS:
      return state.withMutations( quadrants => {
        payload.data.quadrants.forEach( quadrantData => {
          quadrants.set(`${quadrantData.id}`, createQuadrant(quadrantData));
        });
      });
    default:
      return state;
  }
}
