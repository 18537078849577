import { Record } from 'immutable';

export const AssessmentItem = new Record({
  id: null,
  custom_id: null,
  assessment_id: null,
  item_id: null,
  value: null,
  updated_at: null,
  created_at: null,
});

export function createAssessmentItem(data) {
  return new AssessmentItem({
    id: data.id,
    custom_id: data.id,
    assessment_id: data.assessment_id,
    item_id: data.item_id,
    value:  `${data.value}`,
    updated_at: data.updated_at,
    created_at: data.created_at
  });
}
