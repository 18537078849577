import { List , Map } from 'immutable';
import { systemActions } from './actions';
import { System } from './system';
import { DEFAULT_SESSION_ID,  SYSTEM_ID_MINDPATTERNS} from '../constants';

export const initialState = new Map({
  currentSystemId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new System({id: DEFAULT_SESSION_ID, isNew: false})
});


export function systemsReducer(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {

    case systemActions.FETCH_SYSTEM_FAILED:
    case systemActions.FETCH_SYSTEM_SUCCESS:
    case systemActions.FETCH_SYSTEM_PENDING:
      return state.set(
        payload.systemId,
        systemReducer(state.get(payload.systemId), action)
      );

    case systemActions.LOAD_SYSTEM:
      return state.merge({
        currentSystemId: payload.systemId,
        [payload.systemId]: systemReducer(state.get(payload.systemId), action)
      });
    default:
      return state;
  }
}

export const systemReducer = (state = new System(),  {payload, type} ) => {

  switch (type) {
    case systemActions.FETCH_SYSTEM_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case systemActions.FETCH_SYSTEM_SUCCESS:
      return state.withMutations( system => {

        let new_items = new List();

        if (payload.systemId === SYSTEM_ID_MINDPATTERNS ){
          let data_list = [];
          Object.keys(payload.data).map(function(key) {
              data_list.push({
                id: key,
                name: payload.data[key]
              });
        		return key;
        	});
          new_items = new List(data_list)
        }
        else{
          new_items = new List(payload.data)
        }

        system.merge({
          isNew: false,
          isPending: false,
          items: new_items,
        });
      });
    case systemActions.FETCH_SYSTEM_PENDING:
      return state.set('isPending', true);
    case systemActions.LOAD_SYSTEM:
      return state.merge({
        id: payload.systemId,
      });
    default:
      return state;
  }
}
