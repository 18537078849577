import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Collapse, Media } from 'reactstrap';
import styled from 'styled-components'

import { Radio } from '../common'
import TalentsListMemberItem from './talents-list-member-item'

const StyledQuadrant = styled.div`
  display: initial;

  > h5 {
    color: ${props => props.quadrant_color};
  }

  > ul li:before {
    color: ${props => props.quadrant_color};
  }
`;

const QuadrantsDetail = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [telant_id, setTalentId] = useState(null);

  const toggle = (id) => {

    if (telant_id == id) {
      setIsOpen(false)
      setTalentId(null)
    }
    else {
      setIsOpen(true)
      setTalentId(id)
    }
  };

  const { quadrant_name, items, quadrants, questions, team_report, member_id = null } = props;

  const quadrant = quadrants.find(quadrant => quadrant.name === quadrant_name)

  const find_member = (members) => {
    let found_member = false;
    members.map(member => {
        if(member.id === member_id) found_member = true
    })
    return found_member
}

  if (!quadrant)
    return null;

  const items_talents = team_report.thinking_talents_detail
    .map(_quadrant =>
      _quadrant.quadrant_id === quadrant.id &&
      _quadrant.items.map(item =>
        ((item.count === 1 && !member_id) || (member_id && find_member(item.members))) && <div
          key={'i_' + item.item_id}
          className={"item talent-item mb-2 pb-2 border-bottom modal-quadrant-" + _quadrant.quadrant_name}
        >
          <div onClick={() => toggle(item.item_id)} id={`toggler${item.item_id}`} className="cursor-pointer">
            <Media className="align-items-center">
              <Media left middle className="mr-3">
                <Media object src={item.icon} width="60" height="60" alt={quadrant.name} style={{ backgroundColor: quadrant.color }} />
              </Media>
              <Media body>
                <Media heading tag="h5" className="mb-0 text-reset">{item.name}</Media>
              </Media>
            </Media>
          </div>
          <Collapse isOpen={isOpen && telant_id == item.item_id} toggler={`#toggler${item.item_id}`}>
            <StyledQuadrant quadrant_color={quadrant.color} dangerouslySetInnerHTML={{ __html: item.result_description }} className="pt-2"></StyledQuadrant>
          </Collapse>
        </div>
      )
    );


  return (
    <div className={"modal-quadrant-" + quadrant.name}>
      <div className="d-flex">
        <div className="d-inline-block mx-auto mb-2">
          <img className="icon" width="130" height="130" src={quadrant.icon} alt={quadrant.name} />
        </div>
      </div>
      <h2 className="text-center text-reset text-capitalize mb-3">{quadrant.name}</h2>
      <StyledQuadrant quadrant_color={quadrant.color} className="text-left" dangerouslySetInnerHTML={{ __html: quadrant.description }}></StyledQuadrant>

      <div class="bg-celeste p-3 rounded mb-4"><p class="text-left mb-md-0">Tap the quadrants to view more information</p></div>

      <hr className="mt-1 mb-2" />
      {items_talents}
    </div>
  )
}


export default QuadrantsDetail;
