import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Container } from 'reactstrap';
import { profileActions } from '../../../core/profile/actions';
import { EditForm } from './EditForm';
import { convertCamelCase, passwordStrengthChecker } from '../../../core/helper';
import { getSystemById, systemActions } from '../../../core/system';
import { SYSTEM_ID_SENIORITIES, SYSTEM_ID_STATES } from '../../../core/constants';

const animationClass = {
    appearActive: 'animated slideInRight',
    enter: 'animated',
    enterActive: 'animated slideInRight',
    exit: 'animated',
    exitActive: 'animated slideOutLeft',
}

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export const UserEditInfo = () => {
    const dispatch = useDispatch();
    const { user, profile } = useSelector(state => state);
    const state = useSelector(state => state);
    const seniorities = getSystemById(state, SYSTEM_ID_SENIORITIES);

    const [formErrors, setformErrors] = useState({
        firstName: '',
        lastName: '',
        email: profile.error?.data?.email || '',
        confirmEmail: '',
        currentPassword: '',
        password: '',
        confirmPassword: '',
        seniority: '',
    });
    const [inputValues, setInputValues] = useState({
        firstName: profile?.data?.first_name || user?.first_name,
        lastName: profile?.data?.last_name || user?.last_name,
        email: '',
        confirmEmail: '',
        currentPassword: '',
        password: '',
        confirmPassword: '',
        seniority: profile?.data?.seniority?.id || user?.seniority?.id,
    });
    const strength = passwordStrengthChecker(inputValues.password)

    useEffect(() => {
        dispatch(systemActions.loadSystem(SYSTEM_ID_SENIORITIES))

        return () => {

        }
    }, [])

    useEffect(() => {
        if (profile.error && profile.error.data) {
            const errors = profile.error.data;
            let updatedErrors = { ...formErrors };

            Object.keys(errors).forEach(key => {
                const camelCaseKey = convertCamelCase(key);

                updatedErrors = {
                    ...updatedErrors,
                    [camelCaseKey]: errors[key][0]
                };
            });

            setformErrors(updatedErrors);
        }
    }, [profile.error])

    const handleInputChange = async ({ target }) => {
        const { name, value } = target;

        switch (name) {
            case "firstName":
                formErrors.firstName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;
            case "lastName":
                formErrors.lastName =
                    value.length < 1 ? "Minimum 1 characters required" : "";
                break;
            case "email":
                !value ? formErrors.email = "" :
                    formErrors.email = emailRegex.test(value)
                        ? ""
                        : "Invalid email address";
                formErrors.confirmEmail = (inputValues.confirmEmail && inputValues.confirmEmail !== value)
                    ? "Emails do not match"
                    : ""
                break;
            case "currentPassword":
                formErrors.currentPassword = "";
                break;
            case "confirmEmail":
                let matchEmail = inputValues.email === value ? '' : 'Emails do not match';
                !value ? formErrors.confirmEmail = "" :
                    formErrors.confirmEmail = emailRegex.test(value)
                        ? matchEmail
                        : "Invalid email address";
                break;
            case "password":
                formErrors.password =
                    value && value.length < 8 ? "Minimum 8 characters required" : "";
                formErrors.confirmPassword = (inputValues.confirmPassword && inputValues.confirmPassword !== value)
                    ? "Passwords do not match"
                    : ""
                break;
            case "confirmPassword":
                let matchPass = inputValues.password === value ? "" : 'Passwords do not match';
                !value ? formErrors.confirmPassword = "" :
                    formErrors.confirmPassword = value.length < 8 ? "Minimum 8 characters required" : matchPass;
                break;
            default:
                break;
        }

        setInputValues({
            ...inputValues,
            [name]: value
        });
    }

    const onSubmitUpdateInfo = async (e) => {
        e && e.preventDefault();
        if (formValid()) {
            const info_profile = {
                first_name: inputValues.firstName,
                last_name: inputValues.lastName,
                current_password: inputValues.currentPassword,
                password: inputValues.password,
                confirm_password: inputValues.confirmPassword,
                email: inputValues.email,
                seniority_id: inputValues.seniority
            }

            dispatch(profileActions.updateProfile(info_profile));
        }
    }

    useEffect(() => {
        if (profile.success)
            window.location.reload();
    }, [profile.success]);

    const formValid = () => {
        let valid = true;
        let inputValuesRequired = ['firstName', 'lastName', 'seniority']

        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });

        // validate the form was filled out
        Object.keys(inputValues).forEach(key => {
            console.log(key, inputValues[key])
            if (inputValuesRequired.includes(key)) {
                (inputValues[key] == '' || inputValues[key] == undefined) && (valid = false);
            }
        });

        (inputValues.password !== "" && inputValues.confirmPassword === "") && (valid = false);

        (inputValues.email !== "" && inputValues.confirmEmail === "") && (valid = false);

        if (inputValues.password !== "" && strength === 'Too weak!') valid = false

        return valid;
    };

    return (
        <div className='view overflow-hidden'>
            <TransitionGroup className="content">
                <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='editInfo' appear>
                    <div className="register-page bg-gray mt-5">
                        <Container>
                            <div className="px-2 px-md-0 my-md-4">
                                <div className="content-view">
                                    <EditForm
                                        inputValues={inputValues}
                                        handleInputChange={handleInputChange}
                                        onSubmitUpdateInfo={onSubmitUpdateInfo}
                                        formErrors={formErrors}
                                        formValid={formValid}
                                        strength={strength}
                                        lists={
                                            {
                                                seniorities: seniorities?.items,
                                            }
                                        }
                                    />
                                </div>
                            </div>
                        </Container>
                    </div>
                </CSSTransition>
            </TransitionGroup>

        </div>
    )
}
