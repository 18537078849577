import { Record, List } from 'immutable';

export const Team = new Record({
  id: null,
  isNew: true,
  isPending: false,
  name: null,
  owner_id: null,
  members: 0,
  update_at: new Date(),
  error: null,
  mind_pattern: '',
  mind_pattern_first: null,
  mind_pattern_second: false,
  mind_pattern_third: false,
  mind_pattern_p1: null,
  mind_pattern_p2: null,
  mind_pattern_p3: null
});

export function createTeam(data) {
  return new Team({
    id: data.id,
    name: data.name,
    owner_id: data.owner_id,
    members: data.members,
    isNew: false,
    isPending: false,
    update_at: new Date(),
    mind_pattern: data.mind_pattern || '',
    mind_pattern_first: data.mind_pattern_first,
    mind_pattern_second: data.mind_pattern_second,
    mind_pattern_third: data.mind_pattern_third,
    mind_pattern_p1: data.mind_pattern_p1,
    mind_pattern_p2: data.mind_pattern_p2,
    mind_pattern_p3: data.mind_pattern_p3,
  });
}
