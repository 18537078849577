import { List , Map } from 'immutable';
import { teamsReportListActions } from './actions';
import { TeamsReport } from './teams-report';
import { DEFAULT_SESSION_ID } from '../constants';

export const initialState = new Map({
  currentTeamsReportId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new TeamsReport({id: DEFAULT_SESSION_ID, isNew: false})
});

export function teamsReportListReducer(state = initialState, action) {

  const { type, payload } = action;
  switch (type) {
    case teamsReportListActions.FETCH_TEAMS_REPORT_FAILED:
    case teamsReportListActions.FETCH_TEAMS_REPORT_SUCCESS:
    case teamsReportListActions.FETCH_TEAMS_REPORT_PENDING:
      return state.set(
        payload.teamsReportId,
        teamsReportReducer(state.get(payload.teamsReportId), action)
      );
    case teamsReportListActions.LOAD_TEAMS_REPORT:
      return state.merge({
        currentTeamsReportId: payload.teamsReportId,
        [payload.teamsReportId]: teamsReportReducer(state.get(payload.teamsReportId), action)
      });
    default:
      return state;
  }
}

export const teamsReportReducer = (state = new TeamsReport(),  {payload, type} ) => {
  const { data } = payload;

  switch (type) {
    case teamsReportListActions.FETCH_TEAMS_REPORT_FAILED:
      return state.merge({
        isPending: false,
        error: payload.code
      });
    case teamsReportListActions.FETCH_TEAMS_REPORT_SUCCESS:
      return state.withMutations( teamsreport => {
        teamsreport.merge({
          isNew: false,
          isPending: false,
          error: null,
          update_at: new Date(),
          name: data.name,
          owner_id: data.owner_id,
          mind_pattern: data.mind_pattern || '',
          mind_pattern_first: data.mind_pattern_first,
          mind_pattern_second: data.mind_pattern_second,
          mind_pattern_third: data.mind_pattern_third,
          mind_pattern_p1: data.mind_pattern_p1,
          mind_pattern_p2: data.mind_pattern_p2,
          mind_pattern_p3: data.mind_pattern_p3,
          thinking_talents_report: new List(data.thinking_talents_report),
          thinking_talents_detail: new List(data.thinking_talents_detail)
        });
      });
    case teamsReportListActions.FETCH_TEAMS_REPORT_PENDING:
      return state.set('isPending', true);
    case teamsReportListActions.LOAD_TEAMS_REPORT:
      return state.merge({
        id: payload.teamsReportId,
      });
    default:
      return state;
  }
}
