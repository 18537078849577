
export const questionnaireResumeActions = {
    FETCH_QUESTIONNAIRE_RESUME_FAILED: 'FETCH_QUESTIONNAIRE_RESUME_FAILED',
    FETCH_QUESTIONNAIRE_RESUME_SUCCESS: 'FETCH_QUESTIONNAIRE_RESUME_SUCCESS',
    FETCH_QUESTIONNAIRE_RESUME_PENDING: 'FETCH_QUESTIONNAIRE_RESUME_PENDING',
    LOAD_QUESTIONNAIRE_RESUME: 'LOAD_QUESTIONNAIRE_RESUME',
    CLEAN_QUESTIONNAIRE_RESUME: 'CLEAN_QUESTIONNAIRE_RESUME',

    fetchQuestionnaireResumeSuccess: (id, data) => ({
        type: questionnaireResumeActions.FETCH_QUESTIONNAIRE_RESUME_SUCCESS,
        payload: {
            id,
            data
        }
    }),

    fetchQuestionnaireResumeFailed: error => ({
        type: questionnaireResumeActions.FETCH_QUESTIONNAIRE_RESUME_FAILED,
        payload: error
    }),

    fetchQuestionnaireResumePending: () => ({
        type: questionnaireResumeActions.FETCH_QUESTIONNAIRE_RESUME_PENDING,
    }),

    loadQuestionnaireResume: () => ({
        type: questionnaireResumeActions.LOAD_QUESTIONNAIRE_RESUME,
    }),

    cleanQuestionnaireResume: () => ({
        type: questionnaireResumeActions.CLEAN_QUESTIONNAIRE_RESUME,
    })
};


export const questionnaireResumeRequestActions = {
    failed: questionnaireResumeActions.fetchQuestionnaireResumeFailed,
    success: questionnaireResumeActions.fetchQuestionnaireResumeSuccess,
    pending: questionnaireResumeActions.fetchQuestionnaireResumePending
};
