import { createSelector } from 'reselect';

export function getMindPatternsData(state) {
  return state.mind_patterns_data;
}

export function getMindPatternsDataById(state, systemId) {
  return getMindPatternsData(state).get(systemId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentMindPatternsData = createSelector(
  getMindPatternsData,
  system => system.get(system.get('currentMindPatternsDataId'))
);
