import React, { useState, useEffect } from 'react';
import { Form, FormGroup, FormText, Input, Label, Collapse, CustomInput, Button, NavbarToggler, Navbar } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faChevronDown, faChevronUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ModalRoles } from './ModalRoles';
import filter from '../../../assets/img/icon-filtro.svg';
import { communityActions } from '../../../core/community-search/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getSystemById } from '../../../core/system';
import { SYSTEM_ID_SENIORITIES } from '../../../core/constants';

export const CommunityFilters = ({ filters, setFilters, refresh }) => {
    const dispatch = useDispatch()

    const state = useSelector(state => state)
    const seniorities = getSystemById(state, SYSTEM_ID_SENIORITIES)
    const { quadrants } = useSelector(state => state.questions)
    const talents = useSelector(state => state.questions.items)

    const [search, setSearch] = useState('')
    const [quadrants_talents, setQuadrants_talents] = useState([])
    const [showThinkingTalents, setShowThinkingTalents] = useState(true)
    const [showMindPatterns, setShowMindPatterns] = useState(true)
    const [showRoles, setShowRoles] = useState(true)
    const [showModalRoles, setShowModalRoles] = useState(false)
    const [collapsed, setCollapsed] = useState(true)
    const [quadrantsChecked, setQuadrantsChecked] = useState([])
    const [keepFilters, setKeepfilters] = useState(null)
    const [keepQuadrantsChecked, setKeepQuadrantsChecked] = useState(null)

    const toggleThinkingTalents = () => setShowThinkingTalents(!showThinkingTalents)
    const toggleMindPatterns = () => setShowMindPatterns(!showMindPatterns)
    const toggleRoles = () => setShowRoles(!showRoles)
    const toggleModalRoles = () => setShowModalRoles(!showModalRoles)
    const toggleNavbar = () => setCollapsed(!collapsed)
    const toggleQuadrant = (index) => {
        let quadrants = [...quadrants_talents]
        quadrants[index].collapse = !quadrants[index].collapse
        setQuadrants_talents(quadrants)
    }

    useEffect(() => {
        getMemberByFilters()
    }, [])

    useEffect(() => {
        if(!quadrants_talents || !quadrants_talents.length)
        quadrants.forEach(quadrant => {
            setQuadrants_talents(
                quadrants_talents => [
                    ...quadrants_talents,
                    {
                        ['id']: quadrant.id,
                        ['name']: quadrant.name,
                        ['collapse']: false,
                        ['talents']: getTalents(quadrant.id)
                    }
                ]);
        })
    }, [quadrants])

    const searchMembers = () => {
        if(search) dispatch(communityActions.searchMemberByFilters({'name': search}, 1))
        else dispatch(communityActions.searchMemberByFilters({}, 1))
        
        clearAllFilters(false)
    }

    const getMemberByFilters = () => {
        let formatFilters = {}
        formatFilters['name'] = search
        filters.forEach(filter => {
            if (formatFilters[filter.type])
                formatFilters[filter.type].push(filter && filter.type === 'mind_patterns' ? {mp: filter.mp} : {id: filter.id})
            else {
                formatFilters[filter.type] = []
                formatFilters[filter.type].push(filter && filter.type === 'mind_patterns' ? {mp: filter.mp} : {id: filter.id})
            }
        });
        dispatch(communityActions.searchMemberByFilters(formatFilters, 1))
    }

    const getTalents = (id) => {
        let quadrantTalents = []
        talents.forEach(talent => {
            if (talent.quadrant.id === id) quadrantTalents.push(talent)
        });
        return quadrantTalents
    }

    const handleKeyPress = (event) => {
        if (event.charCode == 13) {
            searchMembers()
        }
    }

    const handleInputChange = (target, name, type, quadrant_id = null, calledOfModal = false) => {
        let id = parseInt(target.value) ? parseInt(target.value) : target.value
        let filter = filters
        let quadrantChecked = quadrantsChecked

        if (target.checked) {
            filter.push({ id: id, name: name, type: type, mp: name })
        } else {
            filter.splice(filter.map(function (item) { 
                if (item.type === type) return item.id
            }).indexOf(id), 1)
        }

        if(type === 'talents' && quadrant_id) {
            allSelected(quadrant_id) ? quadrantChecked.push(quadrant_id)
            : quadrantChecked.indexOf(quadrant_id) !== -1 && quadrantChecked.splice(quadrantChecked.indexOf(quadrant_id), 1)
        }

        setFilters(setFilters)
        refresh()
        
        if(!calledOfModal && collapsed) getMemberByFilters()
    }

    const handleQuadrantCheck = (quadrant_id) => {
        quadrantsChecked.find(id => id === quadrant_id) ? deselectAll(quadrant_id) : selectAll(quadrant_id)
    }

    const allSelected = (quadrant_id) => {
        let quadrant = quadrants_talents.find(quadrant => quadrant.id === quadrant_id)
        let allSelected = true
        if (quadrant)
            quadrant.talents.forEach(talent => {
                if (!filters.find(filter => filter.type === 'talents' && filter.id === talent.id)) allSelected = false
            });
        return allSelected
    }

    const deselectAll = (quadrant_id) => {
        let quadrant = quadrants_talents.find(quadrant => quadrant.id === quadrant_id)
        let quadrantChecked = quadrantsChecked
        let filter = filters
        if (quadrant)
            quadrant.talents.forEach(talent => {
                filter.splice(filter.map(function (item) {
                    if (item.type === 'talents') return item.id
                }).indexOf(talent.id), 1)
            });
        quadrantChecked.splice(quadrantChecked.indexOf(quadrant_id), 1)
        setFilters(filter)
        setQuadrantsChecked(quadrantChecked)
        refresh()
        if(collapsed) getMemberByFilters()
    }

    const selectAll = (quadrant_id) => {
        let quadrant = quadrants_talents.find(quadrant => quadrant.id === quadrant_id)
        let quadrantChecked = quadrantsChecked
        let filter = filters
        if (quadrant)
            quadrant.talents.forEach(talent => {
                if (!filter.find(filter => filter.type === 'talents' && filter.id === talent.id))
                    filter.push({ id: talent.id, name: talent.name, type: 'talents' })
            });
        quadrantChecked.push(quadrant_id)
        setFilters(filter)
        setQuadrantsChecked(quadrantChecked)
        refresh()
        if(collapsed) getMemberByFilters()
    }

    const clearAllFilters = (reload = true) => {
        if(!collapsed) {
            setKeepfilters([...filters])
            setKeepQuadrantsChecked([...quadrantsChecked])
            setFilters([])
            setQuadrantsChecked([])
        } else {
            setFilters([])
            setQuadrantsChecked([])
            refresh()
            if(reload) dispatch(communityActions.searchMemberByFilters({}, 1))
        }
    }

    useEffect(() => {
      if(!collapsed) {
        setKeepfilters([...filters])
        setKeepQuadrantsChecked([...quadrantsChecked])
      } else if(keepFilters) {
          setFilters(keepFilters)
          setQuadrantsChecked(keepQuadrantsChecked ? keepQuadrantsChecked : [])
          setKeepfilters(null)
          setKeepQuadrantsChecked(null)
      }
    }, [collapsed]);

    const filtersApply = () => {
        setKeepfilters(null)
        setKeepQuadrantsChecked(null)
        getMemberByFilters()
    }    

    return (
        <>
            <Form onSubmit={e => { e.preventDefault() }}>
                <FormGroup>
                    <Label className="my-md-2 mb-1 font-weight-semibold" for="search_member">Search a member or use the filtrers below</Label>
                    <div className="search-member-input">
                        <Input className="py-4 rounded-lg" type="text" id="search_member" name="search_member" defaultValue='' onKeyPress={(e) => handleKeyPress(e)} onChange={e => setSearch(e.target.value)} placeholder="Name, Last Name or Email"></Input>
                        <Button onClick={() => { searchMembers() }} className="rounded-circle p-1 border-0" ><FontAwesomeIcon className="text-white" size="lg" icon={faSearch} /></Button>
                    </div>
                    <FormText></FormText>
                </FormGroup>
            </Form>
            <Form className="pb-md-4" onSubmit={e => { e.preventDefault() }}>
                <Button onClick={toggleNavbar} color="link" className="mb-3 d-md-none p-0 text-black text-decoration-none">
                    <img src={filter} width="20" height="auto" className="mr-2" /> FILTER
                </Button>

                <Collapse isOpen={!collapsed} className="d-md-flex flex-md-column navfilter">
                    <div className="d-md-none encabezado text-center position-relative">
                        <div className="mb-3 pb-1 d-inline-flex align-items-end">
                            <h5 className="pl-4 mb-0 title-underline" style={{ fontSize: '1.25rem', lineHeight: 'normal' }}>Filter by</h5>
                        </div>
                        <Button onClick={toggleNavbar} color="link" className="p-0 close icon-close mt-1"></Button>
                    </div>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleThinkingTalents()} >
                        Thinking Talents
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showThinkingTalents ? faChevronUp : faChevronDown} />
                    </Button>

                    <Collapse isOpen={showThinkingTalents}>
                        <div className="border-bottom pl-3 mb-2"><p className="mb-1 font-weight-semibold">Quadrants</p></div>
                        {quadrants_talents && quadrants_talents.map((quadrant, index) => {
                            return (
                                <FormGroup key={'quadrant-' + quadrant.id} className="d-flex align-items-center justify-content-between flex-wrap pl-3 my-1">
                                    <CustomInput checked={quadrantsChecked && quadrantsChecked.find(id => id === quadrant.id)} onClick={() => handleQuadrantCheck(quadrant.id)} bsSize="sm" className="styled-checkbox pl-0 py-1" type="checkbox" label={`${quadrant.name !== 'All' ? quadrant.name : "All- Quadrant"} Talents`} id={'quadrant-' + quadrant.id} />
                                    <Button color="link" className="pr-3 p-0" onClick={() => toggleQuadrant(index)}>
                                        <FontAwesomeIcon className="ml-1 float-right" size="lg" icon={quadrant.collapse ? faChevronUp : faChevronDown} />
                                    </Button>
                                    <Collapse isOpen={quadrant.collapse} className="w-100 pl-4">
                                        <FormGroup className="mb-2">
                                            {quadrant.talents && quadrant.talents.map(talent => {
                                                return <CustomInput key={'talent-' + talent.id} checked={filters && filters.find(filter => filter.type === 'talents' && filter.id === talent.id)} onChange={({ target }) => { handleInputChange(target, talent.name, 'talents', talent.quadrant.id);}} value={talent.id} bsSize="sm" type="checkbox" className="styled-checkbox pl-0 py-1" label={talent.name} id={'talent-' + talent.id} />
                                            })
                                            }
                                        </FormGroup>
                                    </Collapse>
                                </FormGroup>
                            )
                        })
                        }
                    </Collapse>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleMindPatterns()} >
                        Mind Patterns
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showMindPatterns ? faChevronUp : faChevronDown} />
                    </Button>
                    <Collapse isOpen={showMindPatterns}>
                        <FormGroup className="mb-2">
                            <CustomInput checked={filters && filters.find(filter => filter.type === 'mind_patterns' && filter.id === "AKV")} onChange={({ target }) => { handleInputChange(target, "AKV", 'mind_patterns'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label="AKV" id="AKV" value="AKV" />
                            <CustomInput checked={filters && filters.find(filter => filter.type === 'mind_patterns' && filter.id === "AVK")} onChange={({ target }) => { handleInputChange(target, "AVK", 'mind_patterns'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label="AVK" id="AVK" value="AVK" />
                            <CustomInput checked={filters && filters.find(filter => filter.type === 'mind_patterns' && filter.id === "KAV")} onChange={({ target }) => { handleInputChange(target, "KAV", 'mind_patterns'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label="KAV" id="KAV" value="KAV" />
                            <CustomInput checked={filters && filters.find(filter => filter.type === 'mind_patterns' && filter.id === "KVA")} onChange={({ target }) => { handleInputChange(target, "KVA", 'mind_patterns'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label="KVA" id="KVA" value="KVA" />
                            <CustomInput checked={filters && filters.find(filter => filter.type === 'mind_patterns' && filter.id === "VAK")} onChange={({ target }) => { handleInputChange(target, "VAK", 'mind_patterns'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label="VAK" id="VAK" value="VAK" />
                            <CustomInput checked={filters && filters.find(filter => filter.type === 'mind_patterns' && filter.id === "VKA")} onChange={({ target }) => { handleInputChange(target, "VKA", 'mind_patterns'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label="VKA" id="VKA" value="VKA" />
                        </FormGroup>
                    </Collapse>

                    <Button size="lg" color="secondary" outline block className="rounded-pill px-3 text-petrol text-left mb-2" onClick={() => toggleRoles()} >
                        Roles
                        <FontAwesomeIcon className="ml-1 float-right text-naranja" size="lg" icon={showRoles ? faChevronUp : faChevronDown} />
                    </Button>
                    <Collapse isOpen={showRoles}>
                        <FormGroup className="mb-2">
                            {seniorities && seniorities.items && seniorities.items.map((seniority, index) => {
                                return <>{index < 3 &&
                                    <CustomInput key={'seniority-' + seniority.id} checked={filters && filters.find(filter => filter.type === 'seniorities' && filter.id === seniority.id)} onChange={({ target }) => { handleInputChange(target, seniority.name, 'seniorities'); }} bsSize="sm" className="styled-checkbox pl-3 py-1" type="checkbox" label={seniority.name} value={seniority.id} id={'seniority-' + seniority.id} />
                                }</>
                            })
                            }
                        </FormGroup>
                        <Button className="text-uppercase text-reset d-inline-flex p-0 mt-auto ml-3" color="link" onClick={setShowModalRoles} >View All Roles</Button>
                    </Collapse>

                    <Button onClick={() => clearAllFilters()} color="primary" outline block className="mt-3 rounded-pill text-uppercase">Clear all filters</Button>
                    <Button onClick={() => {filtersApply(); toggleNavbar();}} color="primary" block className="d-md-none mt-3 rounded-pill text-uppercase">Apply</Button>
                </Collapse>
            </Form>
            <ModalRoles open={showModalRoles} toggle={toggleModalRoles} handleInputChange={handleInputChange} filters={filters} showApply={collapsed} getMemberByFilters={getMemberByFilters} />
        </>
    )
}