import { createSelector } from 'reselect';

export function getTeamsReport(state) {
  return state.teams_reports;
}

export function getTeamsReportById(state, teamsReportId) {
  return getTeamsReport(state).get(teamsReportId);
}

//=====================================
//  MEMOIZED SELECTORS
//-------------------------------------

export const getCurrentTeamsReport = createSelector(
  getTeamsReport,
  teams_reports => teams_reports.get(teams_reports.get('currentTeamsReportId'))
)
