export const appActions = {
  INIT_APP: 'INIT_APP',
  NAVIGATE_TO_PATH: 'NAVIGATE_TO_PATH',
  initApp: config => ({
    type: appActions.INIT_APP,
    payload: config
  }),

  navigateToPath: (path) =>({
    type: appActions.NAVIGATE_TO_PATH,
    payload: {
      pathname: path,
    }
  })


};
