import React, { useEffect, useState } from 'react';
import { Button, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';

export const ConfirmCurrentPasswordModal = ({ isOpen, toggle, setCurrentPassword, error, onAccept }) => {
    useEffect(() => {
        if(!isOpen) {
            setCurrentPassword('')
        }
    }, [isOpen])

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Action Confirmation
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="password">Please enter your current password in order to update your email.</Label>
                    <Input
                        type="password"
                        name="currentPassword"
                        id="currentPassword"
                        onChange={(e) => { setCurrentPassword(e.target.value) }}
                        className="rounded-pill"
                    />
                    {error &&
                        <FormText>{error}</FormText>
                    }
                </FormGroup>
                <div className="d-flex justify-content-center">
                    <Button color="primary" className="text-uppercase rounded-pill px-md-5" onClick={onAccept} disabled={!!error}>Accept</Button>
                </div>
            </ModalBody>
        </Modal>
    )
}