
export const questionnaireStoreOptionsActions = {
    FETCH_QUESTIONNAIRE_STOREOPTIONS_FAILED: 'FETCH_QUESTIONNAIRE_STOREOPTIONS_FAILED',
    FETCH_QUESTIONNAIRE_STOREOPTIONS_SUCCESS: 'FETCH_QUESTIONNAIRE_STOREOPTIONS_SUCCESS',
    FETCH_QUESTIONNAIRE_STOREOPTIONS_PENDING: 'FETCH_QUESTIONNAIRE_STOREOPTIONS_PENDING',
    SAVE_QUESTIONNAIRE_STOREOPTIONS: 'SAVE_QUESTIONNAIRE_STOREOPTIONS',

    fetchQuestionnaireStoreOptionsSuccess: (id, data) => ({
        type: questionnaireStoreOptionsActions.FETCH_QUESTIONNAIRE_STOREOPTIONS_SUCCESS,
        payload: {
            id,
            data
        }
    }),

    fetchQuestionnaireStoreOptionsFailed: error => ({
        type: questionnaireStoreOptionsActions.FETCH_QUESTIONNAIRE_STOREOPTIONS_FAILED,
        payload: error
    }),

    fetchQuestionnaireStoreOptionsPending: () => ({
        type: questionnaireStoreOptionsActions.FETCH_QUESTIONNAIRE_STOREOPTIONS_PENDING,
    }),

    saveQuestionnaireStoreOptions: ({ questionnaireId, questionId, options }) => ({
        type: questionnaireStoreOptionsActions.SAVE_QUESTIONNAIRE_STOREOPTIONS,
        payload: {
            questionnaireId, questionId, options
        }
    })
};


export const questionnaireStoreOptionsRequestActions = {
    failed: questionnaireStoreOptionsActions.fetchQuestionnaireStoreOptionsFailed,
    success: questionnaireStoreOptionsActions.fetchQuestionnaireStoreOptionsSuccess,
    pending: questionnaireStoreOptionsActions.fetchQuestionnaireStoreOptionsPending
};
