import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSelector } from 'reselect';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Media } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { TransitionGroup, SwitchTransition, CSSTransition } from "react-transition-group";
import classNames from 'classnames';
import { List, Map } from 'immutable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { teamsListActions, getCurrentTeamsList, getTeamsForCurrentTeamsList } from '../../../core/teams-list';
import { getCurrentUser } from '../../../core/users'

import LoadingIndicator from '../../components/loading-indicator';

import icon_members from '../../../assets/img/avatar.png';

import session from '../../../core/session';

class AdminPage extends Component {

  constructor(props) {
    super(props)

    this.state = {
      activeTab: 'mine',
      animationClass: {
        appearActive: 'animated slideInRight',
        enter: 'animated',
        enterActive: 'animated slideInRight',
        exit: 'animated',
        exitActive: 'animated slideOutLeft',
      },
      animationClassTabs: {
        enter: 'animated',
        enterActive: 'animated fadeIn',
        exit: 'animated',
        exitActive: 'animated fadeOut',
      },
    }

    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.loadTeams('mine')
  }

  toggle = (tab) => {
    const { activeTab } = this.state
    if (activeTab !== tab) {
      this.setState({ activeTab: tab })
      this.props.loadTeams(tab)
    }
  }

  handleBack(e) {
    e.preventDefault();
    this.props.history.goBack()
  }

  render() {

    const { animationClass, activeTab, animationClassTabs } = this.state;

    const { displayLoadingIndicator, user, teams_list, teams } = this.props;
    if (user.checked && !user.id)
      return <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />;

    const btn_add_label = (!teams.size) ? "Create First Team" : "Add Team";

    const teams_items = teams.map(item => {

      return (<div key={item.id} className="team-item col">
        <Link to={`/teams/${item.id}`} className="cursor-pointer media media-center text-reset text-decoration-none border-top py-3 p-2 align-items-center">
          <Media left middle className="pr-3">
            <img className="media-object rounded-circle" width="40" height="40" src={icon_members} alt={item.name} />
          </Media>
          <Media body>
            <Media heading tag="h5" className="text-reset mb-1">{item.name}</Media>
            <p className="line-height-normal mb-0"><span className={(!item.members) ? 'text-danger' : ''}>{item.members} members</span></p>
          </Media>
        </Link>
      </div>)
    });

    return (
      <div className="view overflow-hidden">
        <TransitionGroup className="content">
          <CSSTransition classNames={animationClass} timeout={{ enter: 1000, exit: 1000 }} key='admin-page' appear >
            <div className="w-100">
              <Container>
                <div className="bg-white my-md-4 rounded-lg pb-4 pb-md-5 px-md-5 pt-md-2 content-report content-view">
                  <div className="px-md-4">
                      
                      <h1 className="mx-4 mx-md-0 mb-md-4 text-center text-md-left d-md-none">Teams</h1>
                      <div className="d-none d-sm-none d-md-block mb-md-3 encabezado"><div className="mb-3 mb-md-0 pt-md-2 pb-md-1 d-flex align-items-center"><h6 className="d-none d-sm-none d-md-block mb-0 title-underline">Teams</h6></div><span className="border border-info d-block"></span></div>

                      <Nav className="mb-3 mb-md-4 text-center text-uppercase" tabs>
                        <NavItem className="flex-xs-fill">
                          <NavLink className={classNames({ active: activeTab === 'mine', 'px-md-4': true })} onClick={() => { this.toggle('mine'); }}>My Teams</NavLink>
                        </NavItem>
                        <NavItem className="flex-xs-fill">
                          <NavLink className={classNames({ active: activeTab === 'all', 'px-md-4': true })} onClick={() => { this.toggle('all'); }}>All Teams</NavLink>
                        </NavItem>
                      </Nav>

                      <div className="content-tabs" style={{ overflow: 'hidden' }}>
                        <SwitchTransition>
                          <CSSTransition classNames={animationClassTabs} timeout={{ enter: 1000, exit: 300 }} key={activeTab} >
                            <TabContent activeTab={activeTab} className="pb-5">
                              <TabPane tabId="mine">
                                <div className="header-group mb-md-4 pt-md-2 mb-3">
                                  <Row className="no-gutters">
                                    <Col lg={{ size: 4, offset: 4 }} className="text-center">
                                      <Link to="/teams/create" className="btn text-uppercase rounded-pill btn-outline-primary d-block d-md-inline-block px-md-4">
                                        <i className="mr-3 h5 text-reset align-middle"><FontAwesomeIcon icon={faUsers} /></i>
                                        <span className="h6 font-weight-normal text-reset align-middle">{btn_add_label}</span>
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                                {
                                   teams_items.size > 0 &&
                                   <div className="bg-celeste p-3 rounded mb-4">
                                   <p className="mb-0">Tap on a team to view details</p>
                                   </div>
                                }
                                <div className="overflow-hidden row row-cols-lg-2 row-cols-1">
                                  {teams_items}
                                </div>
                              </TabPane>
                              <TabPane tabId="all">
                                <div className="header-group mb-md-4 pt-md-2 mb-3">
                                  <Row className="no-gutters">
                                    <Col lg={{ size: 4, offset: 4 }}>
                                      <Link
                                        to="/teams/create"
                                        className="btn text-uppercase rounded-pill btn-outline-primary btn-block px-md-5"
                                      >
                                        <i className="mr-3 h5 text-reset align-middle">
                                          <FontAwesomeIcon icon={faUsers} />
                                        </i>
                                        <span className="h6 font-weight-normal text-reset align-middle">{btn_add_label}</span>
                                      </Link>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="overflow-hidden columns-md-3">
                                  {teams_items}
                                </div>
                              </TabPane>
                            </TabContent>
                          </CSSTransition>
                        </SwitchTransition>
                      </div>
                  </div>
                </div>
              </Container>
              {
                displayLoadingIndicator &&
                <LoadingIndicator className="text-center" style={{ "marginTop": "15px", "display": "block" }} />
              }
            </div>
          </CSSTransition>
        </TransitionGroup>
      </div>
    );
  }
}

//=====================================
//  CONNECT
//-------------------------------------


const mapStateToProps = (state, props) => {
  const teams_list = getCurrentTeamsList(state, props);

  return {
    user: state.user,
    teams_list: teams_list,
    teams: getTeamsForCurrentTeamsList(state, props),
    displayLoadingIndicator: teams_list && teams_list.isPending || false,
  };
};

const mapDispatchToProps = {
  loadTeams: teamsListActions.loadTeams,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPage);
