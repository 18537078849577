import { List , Map } from 'immutable';
import { mindPatternsDataActions } from './actions';
import { MindPatternsData } from './mindpatterns-data';
import { DEFAULT_SESSION_ID } from '../constants';


export const initialState = new Map({
  currentMindPatternsDataId: DEFAULT_SESSION_ID,
  [DEFAULT_SESSION_ID]: new MindPatternsData({id: DEFAULT_SESSION_ID, isNew: false})
});


export function mindPatternsDatasReducer(state = initialState, action) {

  const { type, payload } = action;

  switch (type) {

    case mindPatternsDataActions.FETCH_MINDPATTERNS_FAILED:
    case mindPatternsDataActions.FETCH_MINDPATTERNS_SUCCESS:
    case mindPatternsDataActions.FETCH_MINDPATTERNS_PENDING:
      return state.set(
        payload.mindPatternsDataId,
        mindPatternsDataReducer(state.get(payload.mindPatternsDataId), action)
      );

    case mindPatternsDataActions.LOAD_MINDPATTERNS:
      return state.merge({
        currentMindPatternsDataId: payload.mindPatternsDataId,
        [payload.mindPatternsDataId]: mindPatternsDataReducer(state.get(payload.mindPatternsDataId), action)
      });
    default:
      return state;
  }
}

export const mindPatternsDataReducer = (state = new MindPatternsData(),  {payload, type} ) => {

  switch (type) {
    case mindPatternsDataActions.FETCH_MINDPATTERNS_FAILED:
      return state.merge({
        isPending: false,
        error: payload.error
      });
    case mindPatternsDataActions.FETCH_MINDPATTERNS_SUCCESS:
      return state.withMutations( mindPatternsData => {
        mindPatternsData.merge({
          isNew: false,
          isPending: false,
          items:  payload.data ? new List(payload.data) : new List(),
        });
      });
    case mindPatternsDataActions.FETCH_MINDPATTERNS_PENDING:
      return state.set('isPending', true);
    case mindPatternsDataActions.LOAD_MINDPATTERNS:
      return state.merge({
        id: payload.mindPatternsDataId,
      });
    default:
      return state;
  }
}
