import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Radio } from '../common'
import { Container, Row, Col, Media } from 'reactstrap';

import { mind_pattern_sections } from '../../../core/constants'
import { Checkbox } from '../common'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import { Disclaimers } from '../common';

const AssessmentPartOne = (props) => {

  const { hanndleNext, questions, current_step } = props;

  const [current_value, setCurrentValue] = useState(null);

  const toggle = (value) => {
    setCurrentValue(value)
  };

  const question_key = current_step-1

  let next = mind_pattern_sections.READY_PART_TWO

  if ( questions.get(current_step) ){
    next = mind_pattern_sections.ASSESSMENT
  }

  const select_option = new Set([current_value])

  const data = questions.get(question_key);

  return (
    <Row className="content-questions">
      <Col md={{ size: 8, offset: 2 }}>
        <div className="bg-white my-md-4 rounded-lg pb-4 px-md-5 py-3 content-view content-rearrenge d-flex flex-column">

          <div className="d-flex justify-content-between align-items-center header-questions mb-4 mb-md-5">
            <div></div>
            <div className="paginador">{current_step}<span className="text-muted">/{questions.size}</span></div>
            <div className="icons-funciones d-flex justify-content-center align-items-center">
              <a href="#" className="icon-close"></a>
            </div>
          </div>

          <Row className="contenido-after-fixed pb-5 pb-md-0">
            <Col md={{ size:10, offset: 1}} className="pb-2 pb-md-0">
              <div className="bg-celeste p-3 rounded mb-4">
              <p className="h6 text-reset text-center mb-0">Tap on the option that is most true for you and then tap “next” to go on to the next question</p>
              </div>

              <Media className="py-1 py-md-2 cursor-pointer align-items-center" onClick={ () => toggle(  data.value1 ) }>
                  <Media left className="mr-2 mr-md-4">
                    <Checkbox handleCheckboxChange={toggle} select_option={ select_option } label='' value={  data.value1 } />
                  </Media>
                  <Media body>
                    <p className="mb-0">{ data.description1 }</p>
                  </Media>
              </Media>

              <hr/>
              
              <Media className="py-1 py-md-2 cursor-pointer align-items-center" onClick={ () => toggle( data.value2 ) }>
                  <Media left className="mr-2 mr-md-4">
                    <Checkbox handleCheckboxChange={toggle} select_option={ select_option } label='' value={ data.value2 } />
                  </Media>
                  <Media body>
                    <p className="mb-0">{ data.description2 }</p>
                  </Media>
              </Media>
              
              <hr/>

              <Media className="py-1 py-md-2 cursor-pointer align-items-center" onClick={ () => toggle( data.value3 ) }>
                  <Media left className="mr-2 mr-md-4">
                    <Checkbox handleCheckboxChange={toggle} select_option={ select_option } label='' value={  data.value3 } />
                  </Media>
                  <Media body>
                    <p className="mb-0">{ data.description3 }</p>
                  </Media>
              </Media>

              <hr/>
            </Col>
          </Row>

          <Row className="contenido-bottom-fixed-mb mt-auto">
            <Col md={{ size:4, offset: 4}}>
                <button onClick={ () => hanndleNext(next, data, current_value, mind_pattern_sections.ASSESSMENT ) }
              className={ "btn text-uppercase btn-block rounded-pill " + (!current_value ? 'btn-outline-primary' : 'btn-primary')} disabled={ !current_value }>Next</button>
            </Col>
            <Col md="12">
              <Disclaimers view="propiedad-intelectual" />
            </Col>
          </Row>

        </div>        
      </Col>
    </Row>
  )

}

const mapStateToProps = (state, props) => {
  return { };
};

const mapDispatchToProps = {
};

export default  connect(
    mapStateToProps,
    mapDispatchToProps
)(AssessmentPartOne)
