import { verifyCodeActions } from './actions';
import { SesionVerify } from './sesionVerify';

export const verifyCodeReducer = (state = new SesionVerify(), { payload, type }) => {
    switch (type) {
        case verifyCodeActions.FETCH_VERIFYCODE_FAILED:
            const { error } = payload;
            return state.merge({
                isPending: false,
                success: error.success,
                error: error.data,
                isNew: true
            });
        case verifyCodeActions.FETCH_VERIFYCODE_SUCCESS:
            return state.merge({
                error: 0,
                isPending: false,
                success: true,
                isNew: false
            });
        case verifyCodeActions.FETCH_VERIFYCODE_PENDING:
            return state.set('isPending', true);

        case verifyCodeActions.RESET_VERIFY:
            return state.merge({
                isPending: false,
                success: true,
                error: 0,
                isNew: true,
            })
        default:
            return state;
    }
}
