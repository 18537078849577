import React from 'react';

import './loading-indicator.css';
import loader from '../../../assets/img/loader.png';

function LoadingIndicator() {

  return (
  	<div className="loading">
	    <div className="loading-indicator">
	  		<img src={loader} className="circle" alt="loader" />
	    </div>
    </div>
  );
}

export default LoadingIndicator;
