import React, { Component } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';

class Footer extends Component {
  render() {
    return (
      <footer className="footer mt-auto py-3 d-none">
        <div className="container">
        <Nav className="justify-content-center">
          <NavItem>
            <NavLink href="#">Test</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#">Team</NavLink>
          </NavItem>
        </Nav>
        </div>
      </footer>
    );
  }
}

export default Footer;
